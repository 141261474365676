// commonly used image in whole application

import { MEDIA_CDN_HOST } from "./setting";

export const Images = {
	logo: require("../assets/image/logo.png"),
	sample_image2: require("../assets/image/sample_image2.png"),
	profile_user: require("../assets/image/profile_user.jpg"),
	sample_image: require("../assets/image/sample_image.png"),
	thumb_up: require("../assets/image/thumb_up.png"),
	battery_static: require("../assets/image/battery_static.png"),
	paid_stamp: require("../assets/image/paid_stamp.png"),
	fatRedPin: require("../assets/icons/map-pins/fat-red-pin.png"),
	// marker: require('../assets/icons/map-pins/pin-default.png'),
	// marker_big: require('../assets/icons/map-pins/pin-default-big.png'),
	// marker_cloud: require('../assets/icons/map-pins/pin-default.png'),
	// marker_cloud_big: require('../assets/icons/map-pins/pin-default-big.png'),
	// marker_shadow: require('../assets/icons/map-pins/pin-default.png'),
	// marker_shadow_big: require('../assets/icons/map-pins/pin-default-big.png'),

	marker: `https://central-media.pulseenergy.in/location-pin/default.png`,
	marker_big: `https://central-media.pulseenergy.in/location-pin-big/default-big.png`,
	marker_cloud: `https://central-media.pulseenergy.in/location-pin-cloud/default-cloud.png`,
	marker_cloud_big: `https://central-media.pulseenergy.in/location-pin-cloud-big/default-cloud-big.png`,
	marker_private: `https://central-media.pulseenergy.in/location-pin-private/default-private.png`,
	marker_private_big: `https://central-media.pulseenergy.in/location-pin-private-big/default-private-big.png`,

	// user_location: require('../assets/icons/map-pins/user-location.png'),
	user_location: `https://central-media.pulseenergy.in/location-pin/user-location.png`,

	phone_frame: `${MEDIA_CDN_HOST}/miscellaneous/phone-frame.png`,
	android_app_download_badge: `${MEDIA_CDN_HOST}/miscellaneous/android-app-download-badge.png`,
	ios_app_download_badge: `${MEDIA_CDN_HOST}/miscellaneous/ios-app-download-badge.png`,

	paytm_logo: require("../assets/image/paytm-logo.png"),
	phonepe_logo: require("../assets/image/phonepe-logo.png"),
	paytm_logo_circle: require("../assets/image/paytm-logo-circle.png"),
	phonepe_logo_circle: require("../assets/image/phonepe-logo-circle.png"),

	fleet_illustration: require("../assets/image/fleets.png"),
	personal_ev_illustration: require("../assets/image/personal-evs.png"),

	green_check_icon: require("../assets/icons/green-check-icon.png"),
};
