import { StyleSheet } from "react-native";
import { THEME_COLORS } from "../../../theme/constants/themeColors";

export default StyleSheet.create({
	container: {
		borderRadius: 12,
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "row",
		borderWidth: 1,
		borderColor: THEME_COLORS.buttonBgColor,
	},
	shadowBox: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowOpacity: 0.32,
		shadowRadius: 5.46,
		elevation: 9,
	},
});
