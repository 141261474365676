import { UpdateActiveLanguageReqPayload, UpdateDriverUserProfileReqPayload } from "../central-atoms/types/requests/users";
import { FetchDriverUserProfileResponse, UpdateActiveLanguageResponse, UpdateDriverUserProfileResponse } from "../central-atoms/types/responses/users";
import { SyncUserPreferenceReqPayload } from "../central-atoms/types/user/requests";
import { SyncUserPreferenceResponse } from "../central-atoms/types/user/responses";
import HttpRequestService from "../config/HttpRequestService";
import { API_BASE_PATH, CENTRAL_HOST } from "../config/setting";
import { GLOBAL_VARS } from "../constants";
import { getGlobalVar } from "../miscellaneous/helpers";

export default class AuthenticatedUserRequestService {
    static fetchDriverUserProfile(): Promise<FetchDriverUserProfileResponse> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return httpRequest.getInstance()
            .get(`${API_BASE_PATH}/driver_user_profile`)
            .then((response: any) => {
                return response.data;
            });
    }

    static updateDriverUserProfile(payload: UpdateDriverUserProfileReqPayload): Promise<UpdateDriverUserProfileResponse> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return httpRequest.getInstance()
            .put(`${API_BASE_PATH}/driver_user_profile`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static updateActiveLanguage(payload: UpdateActiveLanguageReqPayload): Promise<UpdateActiveLanguageResponse> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return httpRequest.getInstance()
            .post(`${API_BASE_PATH}/active_lang`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static deleteUser(): Promise<void> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return httpRequest.getInstance()
            .delete(`/api/deactivate_user`)
            .then((response: any) => {
                return response.data;
            });
    }

    static syncPreferences(payload: SyncUserPreferenceReqPayload): Promise<SyncUserPreferenceResponse> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .put(`${CENTRAL_HOST}/user/sync_preferences`, payload)
            .then((response: any) => {
                return response.data;
            });
    }
}