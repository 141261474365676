import { TouchableHighlight, View } from "react-native";
import { COLORS } from "../../../config/colors";
import CText from "../CText";
import { ReactComponent as LocationPinFilledIcon } from "../../../assets/icons/location-pin-filled.svg";
import styles from "./styles";

type Props = {
  title: string;
  address: string;
  onPress: () => void;
};
export default function PlaceCard(props: Props) {
  const { title, address, onPress = () => {} } = props;

  return (
    <TouchableHighlight
      onPress={onPress}
      activeOpacity={1}
      underlayColor={COLORS.grey100}
      style={styles.container}
    >
      <>
        <View style={styles.textContainer}>
          <CText value={title} size={14} semiBold />
          <CText value={address} size={12} style={styles.textColor} />
        </View>
        <View style={styles.iconContainer}>
          <LocationPinFilledIcon style={styles.icon} />
        </View>
      </>
    </TouchableHighlight>
  );
}
