import { GetChargingStationsCountReqPayload } from "../central-atoms/types/charging/charging-station";
import HttpRequestService from "../config/HttpRequestService";
import { CENTRAL_HOST } from "../config/setting";
import { GLOBAL_VARS } from "../constants";
import { getGlobalVar } from "../miscellaneous/helpers";

export default class FiltersRequestService {
    static async fetchCSFilters(): Promise<any> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return await centralHttpRequest.getInstance()
            .get(`${CENTRAL_HOST}/charging_station/filters`)
            .then((response: any) => {
                return response.data;
            });
    }

    static async fetchCSFiltersCount(): Promise<any> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return await centralHttpRequest.getInstance()
            .get(`${CENTRAL_HOST}/charging_station/filters/count`)
            .then((response: any) => {
                return response.data;
            });
    }


    static fetchCSFiltersV3(): Promise<any> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            // .get(`/charging_station/filters`)
            .get(`/v3/charging_station/filters`)
            .then((response: any) => {
                return response.data;
            });
    }

    static fetchCSFiltersCountV3(params: GetChargingStationsCountReqPayload): Promise<any> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            // .get(`/charging_station/filters/count`)
            .put(`/v3/charging_station/filters/count`, {
                ...params
            })
            .then((response: any) => {
                return response.data;
            });
    }
}