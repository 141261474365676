import { isArray } from "lodash";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { Dimensions, Linking, Modal, ModalProps, ScrollView, TouchableOpacity, View } from "react-native";
import { ReactComponent as CallOutlinedIcon } from "../../../assets/icons/call-outlined.svg";
import CText from "../CText";
import SimpleButton from "../SimpleButton";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { useRef, useState } from "react";

type Props = {
  isOpen: boolean;
  phones: Array<{ phone: string }>;
  modalProps?: ModalProps | any;
  onClose?: () => void;
};
const PhonesModal = (props: Props) => {
  const { isOpen, phones, modalProps = {}, onClose } = props;
  const { t } = useTranslation();
  const Screen = Dimensions.get("window");
  const ScreenHeight = Screen.height;
  const [containerHeight, setContainerHeight] = useState(0);
  return (
    <Modal
      style={{
        flex: 1,
        margin: 0,
        justifyContent: "flex-end",
        bottom: 0
      }}
      visible={isOpen}
      transparent={true}
      // animationIn={'slideInUp'}
      // animationOut={'slideOutDown'}
      // animationOutTiming={500}
      // animationInTiming={400}
      // backdropTransitionInTiming={1000}
      // backdropTransitionOutTiming={1000}
      useNativeDriver={true}
      useNativeDriverForBackdrop={true}
      {...modalProps}>
      <View
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          setContainerHeight(height);
        }}
        style={{
          maxHeight: "80%",
          marginTop: ScreenHeight - containerHeight,
          // justifyContent: 'center',
          // justifyContent: 'flex-end',
          // alignItems: 'center',
          borderColor: THEME_COLORS.borderColor,
          borderWidth: 1,
          backgroundColor: THEME_COLORS.bgColor,
          borderTopEndRadius: 16,
          borderTopStartRadius: 16,
          padding: 16,
          paddingBottom: 40
        }}>
        <ScrollView>
          {/* Close Button */}
          <TouchableOpacity
            style={{
              position: "absolute",
              top: -6,
              right: -9,
              padding: 10,
              zIndex: 100
            }}
            onPress={onClose}>
            <IoMdClose size={20} />
          </TouchableOpacity>
          {isArray(phones) && !!phones.length ? (
            <>
              <CText
                semiBold
                style={{
                  marginBottom: 20
                }}>
                {phones.length > 1 ? t("choosePhoneNumberToCall") : t("pleaseTapToCall")}
              </CText>
              {phones?.map((phoneItem: { phone: string }) => {
                return (
                  <View
                    key={phoneItem.phone}
                    style={{
                      marginVertical: 8
                    }}>
                    <SimpleButton
                      title={phoneItem.phone}
                      onPress={() => {
                        Linking.openURL(`tel:${phoneItem.phone}`);
                      }}
                      leftIcon={
                        <CallOutlinedIcon
                          style={{
                            height: 24,
                            width: 24,
                            marginRight: 10
                          }}
                        />
                      }
                    />
                  </View>
                );
              })}
            </>
          ) : (
            <CText style={{ fontWeight: "500", paddingTop: 25 }}>{t("noPhoneNumberToCall")}</CText>
          )}
        </ScrollView>
      </View>
    </Modal>
  );
};

export default PhonesModal;
