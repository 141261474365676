import { INITIAL_STATE } from "../../store/defaultStore";
import { MainState, SET_APP_INITIALIZED, SET_APP_STATE, SET_EVALUATED_APP_UPDATE, SET_GPS_ENABLED, SET_LOCATION_PERMISSION_ENABLED, SET_MAP_COORDS, SET_SHOULD_UPDATE_USER_PROFILE, SET_SHOW_AC_CHARGERS_ONLY, SET_USERS_PHYSICAL_LOCATION, SET_USER_AUTHENTICATED, UPDATE_ALLOWED_QR_CODE_URL_PATTERNS, UPDATE_FIREBASE_REMOTE_CONFIG_VALUES, UPDATE_SHOULD_FETCH_WALLET_BALANCE } from "../../types";
import { SetEvaluatedAppUpdateDispatchedActionPayload, SetShouldUpdateUserProfileDispatchedActionPayload, SetShowACChargersOnlyDispatchedActionPayload, UpdateAllowedCPQrCodeUrlPatternsDispatchedActionPayload, UpdateFirebaseRemoteConfigValuesDispatchedActionPayload, UpdateShouldFetchWalletBalanceDispatchedActionPayload } from "../../types/actions";

export default function MainReducer(state = INITIAL_STATE.main, action: { type: string, payload: any }): MainState {
    const { type, payload } = action;

    switch (type) {
        case SET_APP_INITIALIZED: {
            const { appInitialized } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    appInitialized,
                },
            };
        }

        case SET_APP_STATE: {
            const { appState } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    appState,
                },
            };
        }

        case SET_GPS_ENABLED: {
            const { gpsEnabled } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    gpsEnabled,
                },
            };
        }

        case SET_LOCATION_PERMISSION_ENABLED: {
            const { locPermissionEnabled } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    locPermissionEnabled,
                },
            };
        }

        case SET_USER_AUTHENTICATED: {
            const { userAuthenticated } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    userAuthenticated,
                },
            };
        }

        case SET_USERS_PHYSICAL_LOCATION: {
            const { usersPhysicalLocation } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    usersPhysicalLocation,
                },
            };
        }

        case SET_MAP_COORDS: {
            const { mapCoords } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    mapCoords,
                },
            };
        }

        case UPDATE_FIREBASE_REMOTE_CONFIG_VALUES: {
            const pl = payload as UpdateFirebaseRemoteConfigValuesDispatchedActionPayload;
            const { values } = pl;

            return {
                ...state,
                app: {
                    ...state.app,
                    remoteConfigValues: values,
                }
            };
        }

        case UPDATE_ALLOWED_QR_CODE_URL_PATTERNS: {
            const pl = payload as UpdateAllowedCPQrCodeUrlPatternsDispatchedActionPayload;
            const { allowedCPQrCodeUrlPatterns } = pl;

            const newAllowedList = new Set([
                ...state.app.allowedCPQrCodePatterns,
                ...allowedCPQrCodeUrlPatterns,
            ]);

            return {
                ...state,
                app: {
                    ...state.app,
                    allowedCPQrCodePatterns: Array.from(newAllowedList),
                }
            };
        }

        case SET_EVALUATED_APP_UPDATE: {
            const pl = payload as SetEvaluatedAppUpdateDispatchedActionPayload;
            const { evaluatedAppUpdate } = pl;

            return {
                ...state,
                app: {
                    ...state.app,
                    evaluatedAppUpdate,
                }
            };
        }

        case SET_SHOW_AC_CHARGERS_ONLY: {
            const pl = payload as SetShowACChargersOnlyDispatchedActionPayload;
            const { showACChargersOnly } = pl;

            return {
                ...state,
                app: {
                    ...state.app,
                    showACChargersOnly,
                }
            };
        }

        case SET_SHOULD_UPDATE_USER_PROFILE: {
            const pl = payload as SetShouldUpdateUserProfileDispatchedActionPayload;
            const { shouldUpdateUserProfile } = pl;

            return {
                ...state,
                app: {
                    ...state.app,
                    shouldUpdateUserProfile,
                }
            };
        }

        case UPDATE_SHOULD_FETCH_WALLET_BALANCE: {
            const pl = payload as UpdateShouldFetchWalletBalanceDispatchedActionPayload;
            const { shouldFetchWalletBalance } = pl;

            return {
                ...state,
                app: {
                    ...state.app,
                    shouldFetchWalletBalance,
                }
            };
        }
    }

    return state;
}