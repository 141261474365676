import { useFocusEffect, useIsFocused, useNavigation } from "@react-navigation/native";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Linking,
  Modal,
  ModalProps,
  ScrollView,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions
} from "react-native";
import { NumericFormat } from "react-number-format";
import { useDispatch } from "react-redux";
import styles from "./styles";
// @ts-ignore
import ProgressBar from "@ramonak/react-progress-bar";
import { SCREEN_NAMES } from "../../../constants";
import config from "../../../config/config";
import { FirebaseRemoteConfigKeys } from "../../../constants";
import { useFirebaseRemoteConfigValues } from "../../../hooks";
import { ReactComponent as BoltIcon } from "../../../assets/icons/bolt.svg";
import { ReactComponent as CallOutlinedIcon } from "../../../assets/icons/call-outlined.svg";
import { ReactComponent as SupportHeadphonesIcon } from "../../../assets/icons/support-headphones.svg";
import { useActiveFleetOperatorOrgUser } from "../../../hooks";
import { ChargeTransactionStatus } from "../../../central-atoms/enums/charging/charge-txn";
import { ChargingMethodType } from "../../../central-atoms/enums/charging/charging-method";
import { LangCodes } from "../../../central-atoms/enums/generic/i18n/LangCodes";
import { ChargePointErrorCode, ChargePointStatus } from "../../../central-atoms/enums/ocpp/v1.6/generic";
import { ChargeTransaction } from "../../../central-atoms/types/charging/charge-txns";
import { HelpFaq, HelpMessageResponse, I18NRichMessageResponse } from "../../../central-atoms/types/responses/i18n";
import CText from "../../components/CText";
import { COLORS } from "../../../config/colors";
import AnimatedLottieView from "lottie-react";
import { getNonSIUnitChargingAmount } from "../../../central-atoms/functions/charge-points";
import { WHATSAPP_CHAT_LINK } from "../../../config/setting";
import { useSelectedLanguage } from "../../../hooks";
import RichError from "../../../miscellaneous/components/RichError";
import ScreenHeader from "../../../miscellaneous/components/ScreenHeader";
import Timer from "../../../miscellaneous/components/Timer";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { isIOS } from "../../../miscellaneous/helpers";
import { useInterval } from "../../../miscellaneous/hooks";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import ChargePointRequestService from "../../../services/ChargePointRequestService";
import { useTrackedCPConnectionError, useTrackedCPErrorConnector } from "../../../hooks";
import { setTrackedCPConnectionError, setTrackedCPErrorConnector } from "../../../actions/doChargingActions";
import { LastKnownValuesCard } from "./components/LastKnownValuesCard";
import { LottieAnimation } from "../../../config/lottie";
import { ScreenComponent } from "../../../types/components";
import PhonesModal from "../../components/PhonesModal";
import CButton from "../../components/CButton";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { horizontalSize, verticalSize } from "../../../functions/responsive";

const faultyCPCStatuses = new Set<ChargePointStatus>([
  ChargePointStatus.SuspendedEVSE,
  ChargePointStatus.SuspendedEV,
  ChargePointStatus.Faulted,
  ChargePointStatus.Unavailable
]);

type Props = ScreenComponent & { modalProps?: ModalProps | any };
export default function ChargingProcessScreen(props: Props) {
  const { t } = useTranslation();
  const { route, modalProps = {} } = props;

  const { chargeTxnId } = (route?.params as { chargeTxnId?: any }) || {};

  const navigation: any = useNavigation();
  const dispatch = useDispatch();
  const dimensions = useWindowDimensions();

  const firebaseRemoteConfigValues = useFirebaseRemoteConfigValues();
  const whatsappChatLink =
    firebaseRemoteConfigValues?.[FirebaseRemoteConfigKeys.whatsapp_chat_link] || WHATSAPP_CHAT_LINK;

  const isScreenFocused = useIsFocused();
  const isScreenActive = useRef<boolean>(true);

  const selectedLanguage = useSelectedLanguage();
  const activeFleetOperatorOrgUser = useActiveFleetOperatorOrgUser();

  const [showPhoneModal, setShowPhoneModal] = useState(false);

  const [faqHeading, setFaqHeading] = useState<null | string>("");
  const [faqs, setFaqs] = useState<HelpFaq[]>([]);
  const [faqsOpenStateMap, setFaqsOpenStateMap] = useState<{
    [id: string]: boolean;
  }>({});

  const [bgColor, setBgColor] = useState(COLORS.primaryColor);
  const [cpNotConnectedError, setCpNotConnectedError] = useState<I18NRichMessageResponse | null>(null);
  const [i18nError, setI18nError] = useState<I18NRichMessageResponse | undefined>(undefined);

  // CP connected check
  const [cpConnected, setCPConnected] = useState(true);
  const [waitForCPConnected, setWaitForCPConnected] = useState<boolean | null>(null);
  const [shouldContinueCPConnectedLongPolling, setShouldContinueCPConnectedLongPolling] = useState<boolean | null>(
    null
  );

  // charging status /*
  const [shouldContinueActiveChargeTxnLongPolling, setShouldContinueActiveChargeTxnLongPolling] = useState<
    boolean | null
  >(null);

  const [waitForActiveChargeTransaction, setWaitForActiveChargeTransaction] = useState<boolean>(false);
  const [activeChargeTransaction, setActiveChargeTransaction] = useState<ChargeTransaction | null>(null);
  const activeChargeTxnCPC = activeChargeTransaction?.charge_point_connector;

  const [waitForStartStopCharging, setWaitForStartStopCharging] = useState(false);
  const [waitForEmergencyStartStopCharging, setWaitForEmergencyStartStopCharging] = useState(false);

  const [cancelCharging, setCancelCharging] = useState(false);

  // analytics
  const trackedCPConnectionErr = useTrackedCPConnectionError();
  const trackedCPErrorConnector = useTrackedCPErrorConnector();

  const getChargingDetails = (): any => {
    if (!chargeTxnId || waitForActiveChargeTransaction) return Promise.resolve();
    setWaitForActiveChargeTransaction(true);
    return ChargePointRequestService.fetchChargingDetails(chargeTxnId)
      .then((response) => {
        const { data, help, i18n_error } = response;
        setWaitForActiveChargeTransaction(false);
        setActiveChargeTransaction(data);
        const langHelp = help[selectedLanguage ?? LangCodes.en] as HelpMessageResponse;
        setFaqHeading(langHelp.heading);
        setFaqs(langHelp.faqs);
        setI18nError(i18n_error);
      })
      .catch((e) => {
        setWaitForActiveChargeTransaction(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const handleStopCharging = () => {
    setCancelCharging(true);
  };

  const handleEmergencyStopCharging = () => {
    setWaitForEmergencyStartStopCharging(true);
    ChargePointRequestService.emergencyStopCharging({
      charge_point_public_uid: activeChargeTransaction?.charge_point.public_uid as string,
      transaction_id: activeChargeTransaction?.id as string
    })
      .then(({ data }) => {
        setActiveChargeTransaction(data);
      })
      .catch((e) => {
        setWaitForEmergencyStartStopCharging(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const checkIsConnected = async (): Promise<void> => {
    if (waitForCPConnected || !activeChargeTransaction?.charge_point?.public_uid) return Promise.resolve();

    setWaitForCPConnected(true);
    try {
      const { data, i18n_error } = await ChargePointRequestService.isConnected(
        activeChargeTransaction?.charge_point?.public_uid as string,
        {
          charge_transaction_id: activeChargeTransaction?.id as string
        }
      );
      setWaitForCPConnected(false);
      setCPConnected(data?.is_connected);

      if (!data.is_connected && i18n_error) setCpNotConnectedError(i18n_error);
      else setCpNotConnectedError(null);
    } catch (e) {
      setWaitForCPConnected(false);
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error"
        }
      });
    }
  };

  const handleInstaChargeSupportPress = () => {
    let msg =
      `I need help with my charging session on the CP: \`\`\`${activeChargeTransaction?.charge_point?.qr_code_id}\`\`\`` +
      `\n\nCharging Session Id: \`\`\`${activeChargeTransaction?.id}\`\`\`` +
      `\n\nTransaction Id: \`\`\`${activeChargeTransaction?.txn_id}\`\`\``;
    Linking.openURL(`${whatsappChatLink}?text=${msg}`)
      .then(() => {})
      .catch((e) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const handleCallStationOwner = () => {
    setShowPhoneModal(true);
  };

  const handleSetCancelCharging = () => {
    setCancelCharging(false);
    if (!activeChargeTransaction?.id) return;
    setWaitForStartStopCharging(true);
    ChargePointRequestService.stopCharging({
      charge_point_public_uid: activeChargeTransaction.charge_point.public_uid as string,
      transaction_id: activeChargeTransaction?.id
    })
      .then(({ data }) => {
        setActiveChargeTransaction(data);
        setCancelCharging(false);
      })
      .catch((e) => {
        setWaitForStartStopCharging(false);
        setCancelCharging(false);
        ErrorHandlerService.handleError(e, {
          openToast: isScreenFocused && isScreenActive.current
        });
      });
  };

  useEffect(() => {
    isScreenActive.current = true;
  }, []);

  // get chargeTxn initially
  useEffect(() => {
    getChargingDetails();
  }, [chargeTxnId]);

  useEffect(() => {
    if (cpConnected) {
      setBgColor(COLORS.primaryColor);
      StatusBar.setBackgroundColor(COLORS.primaryColor);
    } else {
      setBgColor(COLORS.primaryColor);
      StatusBar.setBackgroundColor(COLORS.primaryColor);
    }
  });

  // segment analytics for CP connection offline
  useEffect(() => {
    if (!cpConnected && !isEmpty(cpNotConnectedError) && !trackedCPConnectionErr) {
      dispatch(setTrackedCPConnectionError(true));
    }

    if (cpConnected) {
      dispatch(setTrackedCPConnectionError(null));
    }
  }, [cpConnected, cpNotConnectedError]);

  // segment analytics for CPC error
  useEffect(() => {
    if (
      // has status
      activeChargeTxnCPC?.status &&
      // has a faulty status
      faultyCPCStatuses.has(activeChargeTxnCPC?.status) &&
      // CPC has some error
      activeChargeTxnCPC.error !== ChargePointErrorCode.NoError &&
      // is not tracked
      (!trackedCPErrorConnector ||
        // already tracked, but...
        (trackedCPErrorConnector &&
          // ...is new, or...
          (trackedCPErrorConnector.id !== activeChargeTxnCPC?.id ||
            // ...is same, but...
            (trackedCPErrorConnector.id === activeChargeTxnCPC?.id &&
              // ...has different errors
              trackedCPErrorConnector.error !== activeChargeTxnCPC?.error) ||
            trackedCPErrorConnector.vendor_error_code !== activeChargeTxnCPC?.vendor_error_code)))
    ) {
      dispatch(setTrackedCPErrorConnector(activeChargeTxnCPC));
    } else {
      dispatch(setTrackedCPErrorConnector(null));
    }
  }, [activeChargeTxnCPC?.status, activeChargeTxnCPC?.error, activeChargeTxnCPC?.vendor_error_code]);

  // segment analytics for bill generation success/pending states
  useEffect(() => {
    if (
      activeChargeTransaction?.status === ChargeTransactionStatus.Stopped ||
      activeChargeTransaction?.status === ChargeTransactionStatus.Pending
    ) {
      if (activeChargeTransaction?.status === ChargeTransactionStatus.Stopped) {
        const additionalEventProps: any = {};
        if (activeFleetOperatorOrgUser?.org) {
          if (activeFleetOperatorOrgUser?.org?.operator_code)
            additionalEventProps.fleet_operator_code = activeFleetOperatorOrgUser?.org?.operator_code;
          additionalEventProps.fleet_name = activeFleetOperatorOrgUser.org.name;
          additionalEventProps.fleet_id = activeFleetOperatorOrgUser.org.id;
        }
      }

      if (isScreenFocused && isScreenActive.current) {
        navigation.navigate(
          SCREEN_NAMES.ChargingSuccessfulScreen as never,
          {
            chargeTxnId: activeChargeTransaction.id
          } as never
        );
      }
    }
  }, [activeChargeTransaction?.status]);

  // long poll for CP connections state
  useInterval(
    () => {
      checkIsConnected();
    },
    shouldContinueCPConnectedLongPolling ? 5000 : null
  );

  // long poll for Active Charge Txn
  useInterval(
    () => {
      getChargingDetails();
    },
    shouldContinueActiveChargeTxnLongPolling ? 5000 : null
  );

  // for setting/clearing all intervals when the screen is focused/exited
  useFocusEffect(
    useCallback(() => {
      setShouldContinueCPConnectedLongPolling(true);
      setShouldContinueActiveChargeTxnLongPolling(true);

      return () => {
        setShouldContinueCPConnectedLongPolling(false);
        setShouldContinueActiveChargeTxnLongPolling(false);
      };
    }, [])
  );

  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the completed value (or reset to 0 when it reaches 100)
      setCompleted((prevCompleted) => (prevCompleted + 1) % 101);
    }, 1600); // Match the animation duration with the library

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    /* NOTE: left and right are given to avoid it from automatically adding top. */
    <View style={styles.root}>
      <ScreenHeader
        navigation={navigation}
        style={{
          borderBottomWidth: 0,
          backgroundColor: COLORS.whiteColor,
          padding: 16,
          flexDirection: "row",
          position: "sticky" as any,
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100
        }}
        onBackPress={() => {
          navigation.navigate(SCREEN_NAMES.MapScreen as never);
        }}
      />
      <View
        style={{
          backgroundColor: bgColor,
          flex: 1
        }}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            ...StyleHelpers.pageSpacing
            // height: verticalSize(100)
          }}
          showsVerticalScrollIndicator={false}>
          {/* Energy Delivered */}
          <View style={styles.batteryPerCont}>
            {/* TODO: change this to custom */}
            <BoltIcon
              fill={COLORS.whiteColor}
              height={24}
              width={24}
              style={{
                marginRight: 2,
                marginBottom: isIOS() ? 18 : 32,
                opacity: cpConnected ? 1 : 0.6
              }}
            />

            <Text
              style={{
                opacity: cpConnected ? 1 : 0.6
              }}>
              {!activeChargeTransaction?.summary?.energy_delivered ? (
                <CText
                  size={38}
                  semiBold
                  value={"-"}
                  style={{
                    color: COLORS.whiteColor
                  }}
                />
              ) : (
                <NumericFormat
                  displayType={"text"}
                  value={Number(activeChargeTransaction?.summary?.energy_delivered)}
                  thousandSeparator={true}
                  thousandsGroupStyle={"lakh"}
                  renderText={(value) => (
                    <CText
                      size={48}
                      semiBold
                      style={{
                        color: COLORS.whiteColor,
                        fontWeight: "600"
                      }}
                      value={value}
                    />
                  )}
                />
              )}
            </Text>

            <CText
              value={"kWh"}
              size={16}
              style={{
                color: COLORS.whiteColor,
                marginLeft: 6,
                marginBottom: isIOS() ? 13 : 23,
                opacity: cpConnected ? 1 : 0.6
              }}
              semiBold
            />
          </View>

          <CText
            size={11}
            semiBold
            style={{
              color: COLORS.whiteColor,
              marginTop: isIOS() ? 0 : -12,
              lineHeight: 30,
              textAlign: "center"
            }}>
            {cpConnected ? t("charging...") : t("tryingToReconnect...")}
          </CText>

          {!cpConnected && (
            <View
              style={{
                ...StyleHelpers.totalCenter,
                marginBottom: 10,
                width: "100%"
              }}>
              <ProgressBar
                width={"200px"}
                completed={completed}
                bgColor={COLORS.whiteColor}
                baseBgColor={COLORS.primaryColor}
                transitionDuration={"3000"}
                animateOnRender={true}
                transitionTimingFunction={"linear"}
                height={"12px"}
              />
            </View>
          )}

          <CText
            size={12}
            semiBold
            style={{
              color: COLORS.whiteColor,
              marginTop: 10,
              marginBottom: 10,
              textAlign: "center",
              fontWeight: "600"
            }}>
            {activeChargeTransaction?.charging_method?.charging_option_type === ChargingMethodType.Amount && (
              <>
                {t("targetAmount")}: ~₹
                {activeChargeTransaction?.charging_method?.charging_option_unit}
              </>
            )}
            {activeChargeTransaction?.charging_method?.charging_option_type === ChargingMethodType.Units && (
              <>
                {t("targetEnergy")}: ~{Number(activeChargeTransaction?.charging_method?.charging_option_unit) / 1000}{" "}
                kWh
              </>
            )}
            {activeChargeTransaction?.charging_method?.charging_option_type === ChargingMethodType.Time && (
              <>
                {t("targetTime")}: ~
                {getNonSIUnitChargingAmount({
                  method: ChargingMethodType.Time,
                  amount: activeChargeTransaction?.charging_method?.charging_option_unit
                })}{" "}
                min
              </>
            )}
            {activeChargeTransaction?.charging_method?.charging_option_type === ChargingMethodType.SoC && (
              <>
                {t("targetSOC")}: ~{activeChargeTransaction?.charging_method?.charging_option_unit}%
              </>
            )}
          </CText>

          {/* charging animation */}
          {cpConnected && !cpNotConnectedError && (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}>
              <AnimatedLottieView
                animationData={LottieAnimation.charging}
                autoPlay
                style={{
                  height: dimensions.width * 0.36
                }}
              />
            </View>
          )}

          {/* internet error */}
          {!cpConnected && cpNotConnectedError && (
            <View style={{ marginTop: 25, marginBottom: 10 }}>
              <RichError i18nError={cpNotConnectedError} />
            </View>
          )}

          {/* stats */}
          {cpConnected && (
            <View>
              {!activeChargeTransaction?.summary && (
                <CText
                  size={11}
                  medium
                  style={{
                    color: COLORS.whiteColor,
                    marginTop: 8,
                    marginBottom: -6,
                    lineHeight: 30,
                    textAlign: "center"
                  }}>
                  {t("FetchingLatestDetails...")}
                </CText>
              )}

              <View style={[styles.rowStyle, { marginTop: 24 }]}>
                <View style={[styles.whiteBlockCont, { marginEnd: 8 }]}>
                  <CText value={t("currentSoC")} size={10} medium />
                  <Text>
                    {!activeChargeTransaction?.summary?.current_soc ? (
                      <CText size={18} semiBold value={"-"} />
                    ) : (
                      <NumericFormat
                        displayType={"text"}
                        suffix={" %"}
                        value={Number(activeChargeTransaction?.summary?.current_soc)}
                        thousandSeparator={true}
                        thousandsGroupStyle={"lakh"}
                        renderText={(value) => <CText size={18} semiBold value={value} style={{ fontWeight: "600" }} />}
                      />
                    )}
                  </Text>
                </View>
                <View style={[styles.whiteBlockCont, { marginStart: 8 }]}>
                  <CText value={t("presentPower")} size={10} medium />
                  <Text>
                    {!activeChargeTransaction?.summary?.power ? (
                      <CText size={18} semiBold value={"-"} />
                    ) : (
                      <NumericFormat
                        displayType={"text"}
                        suffix={" kW"}
                        value={(activeChargeTransaction?.summary?.power / 1000).toFixed(2)}
                        thousandSeparator={true}
                        thousandsGroupStyle={"lakh"}
                        renderText={(value) => <CText size={18} semiBold value={value} style={{ fontWeight: "600" }} />}
                      />
                    )}
                  </Text>
                </View>
              </View>

              <View style={[styles.rowStyle, { marginTop: 16 }]}>
                {/* Time Elapsed */}
                <View style={[styles.whiteBlockCont, { marginEnd: 8 }]}>
                  <CText value={t("timeElapsed")} size={10} medium />
                  <CText
                    value={<Timer startTimestamp={activeChargeTransaction?.start_timestamp as string} />}
                    size={18}
                    semiBold
                    style={{ fontWeight: "600" }}
                  />
                </View>

                {/* Estimated Cost */}
                <View style={[styles.whiteBlockCont, { marginStart: 8 }]}>
                  <CText value={t("estdCost")} size={10} medium />
                  <View style={styles.rowStyle}>
                    <Text>
                      {!activeChargeTransaction?.summary?.last_calculated_cost ? (
                        <CText size={18} semiBold value={"-"} />
                      ) : (
                        <NumericFormat
                          displayType={"text"}
                          prefix={"₹"}
                          value={Number(activeChargeTransaction?.summary?.last_calculated_cost).toFixed(2)}
                          thousandSeparator={true}
                          thousandsGroupStyle={"lakh"}
                          renderText={(value) => (
                            <CText size={18} semiBold value={value} style={{ fontWeight: "600" }} />
                          )}
                        />
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {/* last known value */}
          {!cpConnected && activeChargeTransaction && <LastKnownValuesCard chargeTxn={activeChargeTransaction} />}

          {/* need help Faqs */}
          <View style={{ marginTop: 40, marginBottom: 5 }}>
            <CText value={faqHeading} size={18} semiBold style={{ color: COLORS.whiteColor, fontWeight: "600" }} />

            <View style={{ marginTop: 16 }}>
              {faqs.map((item: HelpFaq, index: number) => {
                const isFaqOpen = faqsOpenStateMap[item.id];

                return (
                  <View key={item.id}>
                    <View>
                      {/* question */}
                      <TouchableOpacity
                        activeOpacity={0.7}
                        style={[styles.rowStyle, { alignItems: "flex-start" }]}
                        onPress={() => {
                          setFaqsOpenStateMap({
                            ...faqsOpenStateMap,
                            [item.id]: !isFaqOpen
                          });
                        }}>
                        <CText
                          value={item.q}
                          size={12}
                          semiBold
                          style={{
                            color: COLORS.whiteColor,
                            marginEnd: 24,
                            flex: 1,
                            fontWeight: "500"
                          }}
                        />
                        {!isFaqOpen ? (
                          <FaPlus
                            size={22}
                            color={COLORS.whiteColor}
                            style={{
                              marginTop: 4
                            }}
                          />
                        ) : (
                          <FaMinus
                            size={22}
                            color={COLORS.whiteColor}
                            style={{
                              marginTop: 4
                            }}
                          />
                        )}
                      </TouchableOpacity>
                      {/* answer */}
                      {isFaqOpen && (
                        <CText value={item.a} size={10} style={{ color: COLORS.whiteColor, marginEnd: 24 }} />
                      )}
                    </View>
                    {/* divider */}
                    {index != faqs.length - 1 && <View style={styles.divider} />}
                  </View>
                );
              })}
            </View>
          </View>

          {/* support */}
          <View style={[styles.rowStyle, { marginTop: 24 }]}>
            {/* TODO: add action items */}
            <TouchableOpacity
              activeOpacity={0.7}
              style={[styles.whiteBlockCont, styles.rowStyle, { marginEnd: 8 }]}
              onPress={handleInstaChargeSupportPress}>
              <CText
                value={t("instaChargeSupport").replace("[[APP_NAME]]", config.APP_NAME)}
                size={12}
                semiBold
                style={{ marginEnd: 8, flex: 1, fontWeight: "500" }}
              />
              <SupportHeadphonesIcon
                fill={COLORS.primaryColor}
                height={30}
                width={30}
                style={{
                  marginRight: 2
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              activeOpacity={0.7}
              style={[styles.whiteBlockCont, styles.rowStyle, { marginStart: 8 }]}
              onPress={handleCallStationOwner}>
              <CText
                value={t("callStationOwner")}
                size={12}
                semiBold
                style={{ marginEnd: 8, flex: 1, fontWeight: "500" }}
              />
              <CallOutlinedIcon
                fill={COLORS.primaryColor}
                stroke={COLORS.primaryColor}
                height={30}
                width={30}
                style={{
                  marginRight: 2
                }}
              />
            </TouchableOpacity>
          </View>

          {/* buttons */}
        </ScrollView>
        <View
          style={{
            backgroundColor: COLORS.whiteColor,
            marginTop: StyleHelpers.pageSpacing.paddingLeft * 2,
            paddingHorizontal: StyleHelpers.pageSpacing.paddingLeft,
            paddingVertical: StyleHelpers.pageSpacing.paddingLeft,
            marginBottom: -1
          }}>
          {/* CP Connected */}
          {cpConnected && (
            <TouchableOpacity
              style={styles.stopBtn}
              activeOpacity={0.7}
              onPress={handleStopCharging}
              disabled={waitForStartStopCharging}>
              <CText
                value={waitForStartStopCharging ? t("stoppingCharging") : t("stopCharging")}
                size={14}
                semiBold
                style={{
                  lineHeight: 21,
                  marginBottom: 2,
                  color: COLORS.whiteColor
                }}
              />
            </TouchableOpacity>
          )}
          {/* I pressed emergency stop */}
          {!cpConnected && (
            <TouchableOpacity
              style={[styles.stopBtn, { backgroundColor: COLORS.errorColor }]}
              activeOpacity={0.7}
              onPress={handleEmergencyStopCharging}
              disabled={waitForEmergencyStartStopCharging}>
              <CText
                value={waitForEmergencyStartStopCharging ? t("pleaseWait") : t("iPressedEmergencyStop")}
                size={14}
                semiBold
                style={{
                  lineHeight: 21,
                  marginBottom: 2,
                  color: COLORS.whiteColor
                }}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <Modal visible={cancelCharging} transparent={true}>
        <View
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            height: verticalSize(100)
          }}>
          <View
            style={{
              marginHorizontal: horizontalSize(3),
              alignItems: "center",
              alignSelf: "center",
              position: "absolute",
              top: verticalSize(33),
              borderColor: THEME_COLORS.borderColor,
              borderWidth: 1,
              backgroundColor: THEME_COLORS.bgColor,
              borderRadius: 16,
              padding: 16,
              paddingBottom: 30
            }}>
            <View>
              <CText
                semiBold
                style={{
                  marginBottom: 20,
                  fontSize: 20,
                  textAlign: "center",
                  fontWeight: "600"
                }}>
                {t("Are you sure you want to stop charging?")}
              </CText>
              <View style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "center" }}>
                <CButton
                  style={{
                    marginTop: 20,
                    height: 40,
                    width: 70
                  }}
                  buttonStyle={{ height: 40 }}
                  titleSize={15}
                  onPress={() => setCancelCharging(false)}
                  title={t("No")}
                />
                <CButton
                  style={{
                    marginTop: 20,
                    height: 40,
                    width: 70
                  }}
                  buttonStyle={{ height: 40 }}
                  titleSize={15}
                  onPress={handleSetCancelCharging}
                  title={t("Yes")}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>

      <PhonesModal
        isOpen={showPhoneModal}
        phones={activeChargeTransaction?.charge_point?.charging_station?.phones?.map((p) => ({ phone: p })) ?? []}
        modalProps={{
          onBackdropPress: () => setShowPhoneModal(false),
          onBackButtonPress: () => setShowPhoneModal(false)
        }}
        onClose={() => setShowPhoneModal(false)}
      />
    </View>
  );
}
