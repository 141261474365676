import { View } from "react-native";
import { ScreenComponent } from "../../../types/components";
import styles from "./styles";
import { ReactComponent as SplashPulseEnergyLogo } from "../../../assets/icons/pulse_energy_logo.svg";
import { useNavigation } from "@react-navigation/native";
import { GLOBAL_VARS, SCREEN_NAMES } from "../../../constants";
import { AvailableAppVariants } from "../../../theme/enums";
import config from "../../../config/config";

type Props = ScreenComponent & {};
export default function SplashScreen(props: Props) {
  const navigation = useNavigation<any>();

  setTimeout(() => {
    navigation.replace(SCREEN_NAMES.SelectLanguageScreen);
  }, 1500);

  console.log('GLOBAL_VARS.AppVariant', GLOBAL_VARS.AppVariant);

  return (
    <View style={styles.container}>
      <div style={{ width: '40%' }}>
        <img src={config.SPLASH_SCREEN_LOGO_URL} alt={config.APP_NAME} style={{ width: '100%' }} />
      </div>
    </View>
  );
}
