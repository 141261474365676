import AsyncStorage from '@react-native-async-storage/async-storage';
import { LS_KEYS } from '../constants';
import { CostBreakupLineItemCode } from '../central-atoms/bill/enums';
import { CostBreakupLineItem } from '../central-atoms/bill/types';
import { ControllabilityType } from '../central-atoms/enums/charge-point';

export const getPrivateCSAccessCodesMap = async (): Promise<{ [csId: string]: string } | null> => {
  const privateChargingStationAccessCodesMapStr = await AsyncStorage.getItem(
    LS_KEYS.privateChargingStationAccessCodesMap
  );
  if (privateChargingStationAccessCodesMapStr) {
    return JSON.parse(privateChargingStationAccessCodesMapStr);
  }
  return null;
};

export const getStoredCSAccessCodeForId = async (csId: string): Promise<string | null> => {
  const privateChargingStationAccessCodesMap = await getPrivateCSAccessCodesMap();
  if (privateChargingStationAccessCodesMap) {
    return privateChargingStationAccessCodesMap?.[csId];
  }

  return null;
};

export function getTotalChargingCostLineItem(lineItem: CostBreakupLineItem[]): CostBreakupLineItem | null {
  return lineItem.find((item) => item.code === CostBreakupLineItemCode.Total) ?? null;
}

export function getEstimatedChargingSummaryCostBreakupItems(lineItem: CostBreakupLineItem[]): CostBreakupLineItem[] {
  return lineItem.filter((item) => item.code !== CostBreakupLineItemCode.Total) ?? [];
}

export const canGetCPAvailability = (controllabilities: ControllabilityType[]): boolean => {
  return controllabilities.some(
    (c: ControllabilityType) =>
      c === ControllabilityType.OCPPControl ||
      c === ControllabilityType.OCPIControl ||
      c === ControllabilityType.APIControl
  );
};
