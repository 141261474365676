// export const APP_NAME = 'InstaCharge';

import { COLORS } from '../config/colors';
import { THEME_COLORS } from '../theme/constants/themeColors';

export const GLOBAL_VARS = {
  httpRequest: 'httpRequest',
  centralHttpRequest: 'centralHttpRequest',
  currentError: 'currentError',
  AppAnalytics: 'AppAnalytics',
  SmartlookId: 'SmartlookId',
  SmartlookUserProps: 'SmartlookUserProps',
  AppServiceInstance: 'AppServiceInstance',
  FirebaseServiceInstance: 'FirebaseServiceInstance',
  deviceInfo: 'deviceInfo',
  navigationRef: 'navigationRef',
  activeLang: 'activeLang',
  bgTaskFunction: 'bgTaskFunction',
  bgTaskFunctionIntervalRef: 'bgTaskFunctionIntervalRef',
  bgTaskFunctionIntervalTime: 'bgTaskFunctionIntervalTime',
  batteryLevelThreshold: 'batteryLevelThreshold',
  nearbyChargersLocationNotifId: 'nearbyChargersLocationNotifId',
  SegmentAnalytics: 'SegmentAnalyticsService',
  AppVariant: 'AppVariant',
};

export const SCREEN_NAMES = {
  // generic
  SplashScreen: 'SplashScreen',
  WebViewScreen: 'WebViewScreen',

  // unauthenticated
  SelectLanguageScreen: 'SelectLanguageScreen',
  EnterPhoneNumberScreen: 'EnterPhoneNumberScreen',
  EnterOTPScreen: 'EnterOTPScreen',

  // partial authentication
  EnterFleetCodeScreen: 'EnterFleetCodeScreen',

  // authenticated
  SearchingScreen: 'SearchingScreen',
  PreStartChargingSelectionScreen: 'PreStartChargingSelectionScreen',
  ChargingStationDetailScreen: 'ChargingStationDetailScreen',
  ProfileSelectLanguageScreen: 'ProfileSelectLanguageScreen',
  SelectOperatorScreen: 'SelectOperatorScreen',
  FiltersScreen: 'FiltersScreen',
  MapScreen: 'MapScreen',
  SearchScreen: 'SearchScreen',
  ConfirmCSScreen: 'ConfirmCSScreen',
  ProfileScreen: 'ProfileScreen',
  ChargePointQRScannerScreen: 'ChargePointQRScannerScreen',
  EnterChargePointIdScreen: 'EnterChargePointIdScreen',
  ChargingStartingSoonScreen: 'ChargingStartingSoonScreen',
  ChargingStatesScreen: 'ChargingStatesScreen',
  ChargingStationDetailsScreen: 'ChargingStationDetailsScreen',
  ChargingProcessScreen: 'ChargingProcessScreen',
  ChargingSuccessfulScreen: 'ChargingSuccessfulScreen',
  EditProfileScreen: 'EditProfileScreen',
  SavedChargingStationsScreen: 'SavedChargingStationsScreen',
  ChargingHistoryScreen: 'ChargingHistoryScreen',
  ChargingHistoryFiltersScreen: 'ChargingHistoryFiltersScreen',
  RfidListScreen: 'RfidListScreen',
  EditRfidScreen: 'EditRfidScreen',
  AddUserProfileDetailScreen: 'AddUserProfileDetailScreen',
  ChargerFailedScreen: 'ChargerFailedScreen',
  ChargerNotConnectedScreen: 'ChargerNotConnectedScreen',

  // partial login
  PartialLoginEnterAccessCodeScreen: 'PartialLoginEnterAccessCodeScreen',

  PaymentModeSelectionScreen: 'PaymentModeSelectionScreen',
  AddWalletPaymentModeSelectionScreen: 'AddWalletPaymentModeSelectionScreen',
  AddWalletEnterAccessCodeScreen: 'AddWalletEnterAccessCodeScreen',
  EnterAccessCodeScreen: 'EnterAccessCodeScreen',

  // wallet
  SwitchWalletScreen: 'SwitchWalletScreen',
  ExternalWalletPhoneNumberScreen: 'ExternalWalletPhoneNumberScreen',
  ExternalWalletVerifyOTPScreen: 'ExternalWalletVerifyOTPScreen',
  AddMoneyToWalletScreen: 'AddMoneyToWalletScreen',
  InsufficientBalanceScreen: 'InsufficientBalanceScreen',
  ShareWalletPhoneNumberScreen: 'ShareWalletPhoneNumberScreen',
  ShareWalletVerifyOTPScreen: 'ShareWalletVerifyOTPScreen',
  AddWalletScreen: 'AddWalletScreen',
  PaymentModeLinkWalletScreen: 'PaymentModeLinkWalletScreen',
  ManageWalletAccessScreen: 'ManageWalletScreen'
};

export const PIN_TYPES: { [pinType: string]: any } = {
  FatRedPin: require('../assets/icons/map-pins/fat-red-pin@2x.png')
};

export const Z_INDEXES = {
  LOADER: 110,
  TOAST: 109,
  MODAL: {}
  // since the zIndex of react-native-modalize is 9998, we need to set this to 10000
  // ref: https://github.com/jeremybarbet/react-native-modalize/blob/master/src/styles.ts#L8-L15
  // CONFETTI: 10000,
};

export const GOOGLE_MAPS_STYLE = [
  {
    featureType: 'all',
    elementType: 'labels',
    stylers: [
      {
        saturation: '-100'
      }
    ]
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on'
      },
      {
        saturation: '-100'
      },
      {
        lightness: '3'
      },
      {
        gamma: '1.40'
      }
    ]
  }
];

export const LS_KEYS = {
  activeLanguage: 'activeLanguage',
  authToken: 'authToken',
  paymentMode: 'paymentMode',
  enteredFleetCode: 'enteredFleetCode',
  enteredAccessCode: 'enteredAccessCode',
  privateChargingStationAccessCodesMap: 'privateChargingStationAccessCodesMap',
  nextAppUpdatePromptAt: 'nextAppUpdatePromptAt',
  smartlookUserId: 'smartlookUserId'
};

export const RNForegroundService = {
  bgLocationTask: 'bgLocationTask'
};

export const RefreshControlLoaderColors = [COLORS.darkBlack, THEME_COLORS.primaryColor, COLORS.cyanColor2];

export const ANALYTICS = {
  eventNames: {
    login_firstpage_appinstall: 'login_firstpage_appinstall',
    login_otppage_success: 'login_otppage_success',
    login_otppage_fail: 'login_otppage_fail',
    login_operatorcodepage_success: 'login_operatorcodepage_success',
    login_accesscodepage_success: 'login_accesscodepage_success',
    login_operatorcodepage_fail: 'login_operatorcodepage_fail',
    homepage_infrontstation_shown: 'homepage_infrontstation_shown',
    homepage_lastchargedcs_clicked: 'homepage_lastchargedcs_clicked',
    homepage_othercs_clicked: 'homepage_othercs_clicked',
    homepage_infrontstation_startchargingclicked: 'homepage_infrontstation_startchargingclicked',
    homepage_appupdatebottomsheet_shown: 'homepage_appupdatebottomsheet_shown',
    homepage_appupdatebottomsheet_updateclicked: 'homepage_appupdatebottomsheet_updateclicked',
    homepage_appupdatebottomsheet_laterclicked: 'homepage_appupdatebottomsheet_laterclicked',
    searchpage_viewchargerclicked: 'searchpage_viewchargerclicked',
    savedcspage_viewchargerclicked: 'savedcspage_viewchargerclicked',
    mapscreen_viewchargerclicked: 'mapscreen_viewchargerclicked',
    cscard_navigatebtn_clicked: 'cscard_navigatebtn_clicked',
    anypage_anyingress_scanqrcodeclicked: 'anypage_anyingress_scanqrcodeclicked',
    anypage_anyingress_scanqrcodesuccess: 'anypage_anyingress_scanqrcodesuccess',
    anypage_anyingress_scanqrcodefail: 'anypage_anyingress_scanqrcodefail',
    chargingpages_cpoffline: 'chargingpages_cpoffline',
    chargingpages_cperror: 'chargingpages_cperror',
    chargingpages_chargingmethodscreen_success: 'chargingpages_chargingmethodscreen_success',
    chargingpages_chargingmethodscreen_fail: 'chargingpages_chargingmethodscreen_fail',
    chargingpages_chargingprocess_cpoffline: 'chargingpages_chargingprocess_cpoffline',
    chargingpages_chargingprocess_cperror: 'chargingpages_chargingprocess_cperror',
    chargingpages_chargingprocess_emergencybuttonclicked: 'chargingpages_chargingprocess_emergencybuttonclicked',
    billpage_billgeneration_success: 'billpage_billgeneration_success',
    billpage_billgeneration_pending: 'billpage_billgeneration_pending',

    // generic
    share_to: 'share_to',
    user_lat_long: 'user_lat_long',
    user_lat_long_a: 'user_lat_long_a',
    user_lat_long_b: 'user_lat_long_b',

    // miscellaneous
    app_sign_in: 'app_sign_in',
    app_sign_up: 'app_sign_up',
    app_visible: 'app_visible',
    app_hidden: 'app_hidden'
  },
  properties: {
    name: 'name',
    email: 'email',
    phone: 'phone',
    operator_code: 'operator_code',
    access_code: 'access_code',
    otp: 'otp',
    error_message_displayed: 'error_message_displayed',
    active_lang: 'active_lang',
    active_fleet_id: 'active_fleet_id',
    active_fleet_name: 'active_fleet_name',
    nearest_cs_lat: 'nearest_cs_lat',
    nearest_cs_lng: 'nearest_cs_lng',
    cs_lat: 'cs_lat',
    cs_lng: 'cs_lng',
    charge_point_qr_code_id: 'charge_point_qr_code_id',
    scan_qr_code_raw_payload: 'scan_qr_code_raw_payload',
    charging_station: 'charging_station',
    full_url: 'full_url',
    charging_method: 'charging_method',
    charging_method_amount: 'charging_method_amount',
    cpc_id: 'cpc_id',
    cpc_type: 'cpc_type',
    cpc_error: 'cpc_error',
    cpc_vendor_error_code: 'cpc_vendor_error_code',
    cpc_status: 'cpc_status',
    source: 'source',
    screen: 'screen',

    // location
    latitude: 'latitude',
    longitude: 'longitude',
    altitude: 'altitude',
    accuracy: 'accuracy',
    altitudeAccuracy: 'altitudeAccuracy',
    heading: 'heading',
    speed: 'speed',
    speedAccuracy: 'speedAccuracy',
    course: 'course',
    courseAccuracy: 'courseAccuracy',
    fromMockProvider: 'fromMockProvider',
    timestamp: 'timestamp'
  },
  contentTypes: {
    charging_station: 'charging_station',
    invite: 'invite'
  },
  sources: {
    // charging station
    charging_station_preview: 'charging_station_preview',
    charging_station_detail_screen: 'charging_station_detail_screen',
    charging_station_detail_top_bar: 'charging_station_detail_top_bar',
    charging_station_suggestion_screen: 'charging_station_suggestion_screen',
    favorite_charging_stations_screen: 'favorite_charging_stations_screen',

    home_screen_bottom_bar: 'home_screen_bottom_bar',

    // generic
    home_screen: 'home_screen',
    map_screen: 'map_screen',
    app_bottom_bar: 'app_bottom_bar',
    profile_screen: 'profile_screen',

    unknown: 'unknown'
  },
  params: {
    source: 'source',
    item_id: 'item_id',
    action: 'action', // like, unlike
    content_type: 'content_type',
    method: 'method',
    title: 'title',
    section: 'section',
    search_query: 'search_query',
    current_lat_lng: 'current_lat_lng',
    userId: 'userId',
    vehicle_name: 'vehicle_name',
    start_location: 'start_location',
    stop_location: 'stop_location',
    charging_station: 'charging_station',
    quick_route: 'quick_route',
    number_of_stations_in_route: 'number_of_stations_in_route',
    charging_station_ids: 'charging_station_ids'
  },
  tapTargets: {
    charging_station_preview_detail: 'charging_station_preview_detail',
    charging_station_preview_view_more: 'charging_station_preview_view_more'
    // charging_station_brand_name: 'charging_station_brand_name',
    // charging_station_open_hours_price: 'charging_station_open_hours_price',
    // charging_station_name: 'charging_station_name',
    // charging_station_address: 'charging_station_address',
  }
};

export const MSGS = {
  UNABLE_TO_OPEN_SETTINGS: 'Could not open settings!',
  UNABLE_TO_DETERMINE_LOC: 'We could not reliably determine your location. Please try again!'
};

export const FirebaseRemoteConfigKeys = {
  app_versions_list: 'app_versions_list',
  env: 'env',
  allowed_qr_code_urls: 'allowed_qr_code_urls',
  otp_code_length: 'otp_code_length',
  disable_smartlook: 'disable_smartlook',
  location_reporting_interval: 'location_reporting_interval',
  battery_level_threshold: 'battery_level_threshold',

  // feature toggles
  payment_gateway: 'payment_gateway',
  about_page_link: 'about_page_link',
  terms_page_link: 'terms_page_link',
  privacy_page_link: 'privacy_page_link',
  whatsapp_chat_link: 'whatsapp_chat_link',
  show_my_rfids: 'show_my_rfids'
};

export const UPDATE_NEAREST_UCS_MINI_OBJECT = 'UPDATE_NEAREST_UCS_MINI_OBJECT';
export const UPDATE_NEARBY_UCS_MINI_OBJECTS_LIST = 'UPDATE_NEARBY_UCS_MINI_OBJECTS_LIST';
export const RESET_NEAREST_UCS_MINI_OBJECT = 'RESET_NEAREST_UCS_MINI_OBJECT';
export const RESET_NEARBY_UCS_MINI_OBJECTS_LIST = 'RESET_NEARBY_UCS_MINI_OBJECTS_LIST';
export const GOOGLE_MAP_KEY = {
  dev: 'AIzaSyA6CVMkSfo7OL-H2bOeloZ9zjUQTnHFSZY',
  stg: 'AIzaSyDRDQjosVDOidMKVQWpqHqaet8NQGEpjv0'
};
