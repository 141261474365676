import { ChargeTransaction } from "../../central-atoms/types/charging/charge-txns";
import { INITIAL_STATE } from "../../store/defaultStore";
import { DoChargingState, SET_SELECTED_CHARGE_POINT, SET_SELECTED_CHARGE_POINT_CONNECTOR, SET_SELECTED_CHARGING_STATION, SET_SELECTED_MIXED_CHARGING_STATION, SET_TRACKED_CP_CONNECTION_ERR, SET_TRACKED_CP_ERR_CONNECTOR, UPDATE_ONGOING_CHARGE_TXNS, UPDATE_ONGOING_CHARGE_TXN_BY_ID } from "../../types";

export default function doChargingReducer(state = INITIAL_STATE.doCharging, action: { type: string, payload: any }): DoChargingState {
    const { type, payload } = action;

    switch (type) {
        case SET_SELECTED_CHARGING_STATION: {
            const { selectedChargingStation } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    selectedChargingStation,
                },
            };
        }

        case SET_SELECTED_MIXED_CHARGING_STATION: {
            const { selectedMixedChargingStation } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    selectedMixedChargingStation,
                },
            };
        }

        case SET_SELECTED_CHARGE_POINT: {
            const { selectedChargePoint } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    selectedChargePoint,
                },
            };
        }

        case SET_SELECTED_CHARGE_POINT_CONNECTOR: {
            const { selectedChargePointConnector } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    selectedChargePointConnector,
                },
            };
        }
            
        case SET_TRACKED_CP_CONNECTION_ERR: {
            const { trackedCPConnectionError } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    trackedCPConnectionError,
                },
            };
        }
            
        case SET_TRACKED_CP_ERR_CONNECTOR: {
            const { trackedCPErrorConnector } = payload;

            return {
                ...state,
                app: {
                    ...state.app,
                    trackedCPErrorConnector,
                },
            };
        }

        case UPDATE_ONGOING_CHARGE_TXN_BY_ID: {
            const { ongoingChargeTxn } = payload;

            return {
                ...state,
                data: {
                    ...state.data,
                    ongoingChargeTxns: {
                        ...state.data.ongoingChargeTxns,
                        [ongoingChargeTxn.id]: ongoingChargeTxn,
                    },
                },
            };
        }
            
        case UPDATE_ONGOING_CHARGE_TXNS: {
            const { ongoingChargeTxns } = payload;

            const newOngoingChargeTxnsMap: {[chargeTxnId: string]: ChargeTransaction} = {}
            ongoingChargeTxns?.map((ct: ChargeTransaction) => { 
                newOngoingChargeTxnsMap[ct.id] = ct;
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    ongoingChargeTxns: newOngoingChargeTxnsMap,
                },
            };
        }
            
        default:
            return state;
    }
}