import { StyleSheet } from 'react-native';
import { THEME_COLORS } from '../../../theme/constants/themeColors';
import { verticalSize } from '../../../functions/responsive';

export default StyleSheet.create({
  root: {
    flexDirection: 'column',
    backgroundColor: THEME_COLORS.bgColor,
    justifyContent: 'space-between',
    height: verticalSize(100)
  },

  rowStyle: {
    flexDirection: 'row',
    alignItems: 'center'
  }
});
