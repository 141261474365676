import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ActivityIndicator, Image, RefreshControl, ScrollView, StatusBar, Text, View } from "react-native";
import { NumericFormat } from "react-number-format";
import GroupedLineItemsCard from "../../components/GroupedLineItemsCard";
import { BillPaymentStatusMap } from "../../../central-atoms/constants/charging/bill/bill";
import { BillPaymentStatus } from "../../../central-atoms/enums/charging/bill/bill";
import { ChargeTransactionStatus } from "../../../central-atoms/enums/charging/charge-txn";
import { ChargingMethodType } from "../../../central-atoms/enums/charging/charging-method";
import { ChargingMethod } from "../../../central-atoms/types/charging/charge-points";
import { ChargeTransaction } from "../../../central-atoms/types/charging/charge-txns";
import { I18NRichMessageResponse } from "../../../central-atoms/types/responses/i18n";
import ChargePointRequestService from "../../../services/ChargePointRequestService";
import { NonSIChargingAmountText } from "./components/NoSIChargingAmountText";
import CButton from "../../components/CButton";
import CText from "../../components/CText";
import { COLORS } from "../../../config/colors";
import { Images } from "../../../config/image";
import { NumberFormatText } from "../../../miscellaneous/components/NumberFormatText";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import styles from "./styles";
// @ts-ignore
import { useFocusEffect } from "@react-navigation/native";
import { isEmpty, uniqueId } from "lodash";
import { RefreshControlLoaderColors, SCREEN_NAMES } from "../../../constants";
import DashedLink from "../../components/DashedLink";
import SectionMessage from "../../components/SectionMessage";
import { ReactComponent as CheckCircleFilledIcon } from "../../../assets/icons/check-circle-filled.svg";

import { ReactComponent as ConnectorIcon } from "../../../assets/icons/connector.svg";
import { ReactComponent as InfoCircleIcon } from "../../../assets/icons/info-circle.svg";

import { ReactComponent as LocationPinFilledIcon } from "../../../assets/icons/location-pin-filled.svg";

import { BillRefundStatus, CostBreakupLineItemCode } from "../../../central-atoms/bill/enums";
import { CostBreakupLineItem } from "../../../central-atoms/bill/types";
import RichError from "../../../miscellaneous/components/RichError";
import ScreenBottomBar from "../../../miscellaneous/components/ScreenBottomBar";
import ScreenWrapper from "../../../miscellaneous/components/ScreenWrapper";
import Timer from "../../../miscellaneous/components/Timer";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { useInterval } from "../../../miscellaneous/hooks";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { getPaidByText } from "../../../helpers/walletHelpers";
import { LastKnownValuesCard } from "../ChargingProcessScreen/components/LastKnownValuesCard";
import { InfoIcon } from "../../../icons";
import { InfoOutline } from "../../../icon";
import ScreenHeader from "../../components/ScreenHeader";
import { verticalSize } from "../../../functions/responsive";

function getBillBreakdownLineItems(
  costBreakupItems: CostBreakupLineItem[],
  activeChargeTransaction: ChargeTransaction,
  navigation: any
) {
  const lineItems: any[] = [];

  costBreakupItems.forEach((item) => {
    lineItems.push({
      leftComponent: (
        <View>
          <CText value={item.name} size={14} />
          {/* note */}
          {!!item.note && (
            <CText size={10} style={{ color: COLORS.grey500 }}>
              {item.note}
            </CText>
          )}
          {/* details */}
          {!!item.details?.length && (
            <View
              style={{
                ...StyleHelpers.verticallyCenteredRow,
                flexWrap: "wrap"
              }}>
              <CText
                size={10}
                style={{
                  alignSelf: "flex-end",
                  paddingTop: 2
                }}>
                Previous unpaid bills:{" "}
              </CText>
              {item.details.map((detailItem, index) => {
                if (item.code === CostBreakupLineItemCode.UnpaidAmount) {
                  return (
                    <>
                      {index !== 0 && (
                        <CText key={uniqueId()} size={10}>
                          ,{" "}
                        </CText>
                      )}
                      <DashedLink
                        key={uniqueId()}
                        onPress={() => {
                          navigation.navigate(
                            SCREEN_NAMES.ChargingSuccessfulScreen as never,
                            {
                              chargeTxnId: detailItem.charge_transaction_id,
                              returnScreen: SCREEN_NAMES.PreStartChargingSelectionScreen
                            } as never
                          );
                        }}
                        style={{
                          borderBottomColor: COLORS.blue500
                        }}>
                        <CText
                          size={10}
                          semiBold
                          style={{
                            color: COLORS.blue500,
                            fontFamily: "monospace"
                          }}>
                          {detailItem.bill_uid}
                        </CText>
                      </DashedLink>
                    </>
                  );
                }

                return null;
              })}
            </View>
          )}
        </View>
      ),
      rightComponent: (
        <View>
          <CText size={14} semiBold style={{ fontWeight: "600" }}>
            {activeChargeTransaction.status === ChargeTransactionStatus.Pending ||
            !activeChargeTransaction?.bill?.amount?.charging_session_cost ? (
              "-"
            ) : (
              <NumberFormatText prefix={"₹"} value={Number(item.value).toFixed(2)} />
            )}
          </CText>
        </View>
      )
    });
  });

  return lineItems;
}

type Props = {
  navigation: any;
  route: any;
};

export default function ChargingSuccessfulScreen(props: Props) {
  const { navigation, route } = props;
  const { t } = useTranslation();

  const { chargeTxnId, returnScreen = null } = route?.params || {};

  const [i18nError, setI18nError] = useState<I18NRichMessageResponse | null>(null);

  const [waitForRefresh, setWaitForRefresh] = useState(false);

  // long polling of charge transaction
  const [activeChargeTransaction, setActiveChargeTransaction] = useState<ChargeTransaction | null>(null);
  const [waitForActiveChargeTransaction, setWaitForActiveChargeTransaction] = useState<boolean>(false);
  const [shouldContinueChargeTxnPolling, setShouldContinueChargeTxnPolling] = useState<boolean>(false);

  const chargePointQRCodeId = activeChargeTransaction?.charge_point.qr_code_id ?? "";
  const connectorType = activeChargeTransaction?.charge_point_connector.type ?? "";
  const connectorPowerRating = activeChargeTransaction?.charge_point_connector?.power_rating ?? "";
  const connectorCurrentType = activeChargeTransaction?.charge_point_connector?.current_type ?? "";
  const connectorInfoList: string[] = [];

  if (connectorType) connectorInfoList.push(connectorType);
  if (connectorPowerRating && connectorCurrentType)
    connectorInfoList.push(`${connectorPowerRating} kW ${connectorCurrentType}`);
  if (chargePointQRCodeId) connectorInfoList.push(chargePointQRCodeId);

  const connectorInfoText = connectorInfoList.join(" • ");

  const getChargingDetails = async (): Promise<void> => {
    if (!chargeTxnId || waitForActiveChargeTransaction) return Promise.resolve();

    setWaitForActiveChargeTransaction(true);
    try {
      const { data, help, i18n_error } = await ChargePointRequestService.fetchChargingDetails(chargeTxnId);
      setWaitForActiveChargeTransaction(false);
      setActiveChargeTransaction(data);

      if (i18n_error && !isEmpty(i18n_error)) {
        setI18nError(i18n_error);
      } else {
        setI18nError(null);
      }
    } catch (e) {
      setWaitForActiveChargeTransaction(false);
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error"
        }
      });
    }
  };

  const paymentStatusInfo = useMemo(() => {
    const paymentStatus = activeChargeTransaction?.bill?.payment_status;
    if (paymentStatus === BillPaymentStatusMap[BillPaymentStatus.Paid]) {
      return {
        backgroundColor: COLORS.primaryColor,
        userTextColor: COLORS.offWhite,
        textColor: COLORS.offWhite,
        text: t("SuccessfulCharge"),
        image: (
          <Image
            resizeMode="contain"
            source={Images.thumb_up}
            style={{
              height: 20,
              width: 20
            }}
          />
        )
      };
    } else if (paymentStatus === BillPaymentStatusMap[BillPaymentStatus.PartiallyPaid]) {
      return {
        backgroundColor: COLORS.teal300,
        userTextColor: COLORS.offWhite,
        textColor: COLORS.offWhite,
        text: t("PartiallyPaid"),
        image: (
          <Image
            resizeMode="contain"
            source={Images.thumb_up}
            style={{
              height: 20,
              width: 20
            }}
          />
        )
      };
    } else if (paymentStatus === BillPaymentStatusMap[BillPaymentStatus.Pending]) {
      return {
        backgroundColor: COLORS.deepOrange500,
        userTextColor: COLORS.grey100,
        textColor: COLORS.grey100,
        text: t(paymentStatus)
      };
    } else if (paymentStatus === BillPaymentStatusMap[BillPaymentStatus.Failed]) {
      return {
        backgroundColor: COLORS.brightRed,
        userTextColor: COLORS.grey100,
        textColor: COLORS.grey100,
        text: t(paymentStatus)
      };
    }

    return {
      backgroundColor: COLORS.grey400,
      userTextColor: COLORS.grey800,
      textColor: COLORS.grey800,
      text: paymentStatus ? t(paymentStatus) : ""
    };
  }, [activeChargeTransaction?.bill?.payment_status]);

  const unpaidAmount = useMemo(() => {
    const unpaidAmount = activeChargeTransaction?.bill?.unpaid_amount;
    if (unpaidAmount) {
      return {
        has: true,
        amount: unpaidAmount
      };
    }
    return null;
  }, [activeChargeTransaction?.bill?.unpaid_amount]);

  const refundDetails = useMemo(() => {
    const bill = activeChargeTransaction?.bill;
    if (bill?.refund_amount) {
      let statusTextColor = COLORS.grey800;
      let statusDescription = "";
      let icon = <InfoCircleIcon fill={statusTextColor} />;
      if (bill.refund_status === BillRefundStatus.Initiated) {
        statusTextColor = COLORS.blackColor;
        statusDescription = t("RefundOfAmountWillBeCredited").replace("[[AMOUNT]]", `₹${bill?.refund_amount}`);
        icon = <InfoOutline width={20} height={20} />;
      } else if (bill.refund_status === BillRefundStatus.Failed) {
        statusTextColor = COLORS.errorColor;
        statusDescription = t("RefundOfAmountFailed").replace("[[AMOUNT]]", `₹${bill?.refund_amount}`);
        icon = <InfoOutline width={20} height={20} />;
      } else if (bill.refund_status === BillRefundStatus.Completed) {
        statusTextColor = COLORS.blackColor;
        statusDescription = t("RefundOfAmountCompleted").replace("[[AMOUNT]]", `₹${bill?.refund_amount}`);
        icon = <InfoOutline width={20} height={20} />;
      }
      return {
        has: !!bill?.refund_amount,
        amount: bill.refund_amount,
        status: bill.refund_status,
        statusTextColor,
        statusDescription,
        icon
      };
    }
    return null;
  }, [activeChargeTransaction?.bill?.payment_txns?.[0]]);

  const onRefresh = () => {
    setWaitForRefresh(true);
    getChargingDetails()
      .then(() => {
        setWaitForRefresh(false);
      })
      .catch((e) => {
        setWaitForRefresh(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  useEffect(() => {
    if (activeChargeTransaction?.bill?.payment_status === BillPaymentStatusMap[BillPaymentStatus.NotGenerated]) {
      setShouldContinueChargeTxnPolling(true);
    } else {
      setShouldContinueChargeTxnPolling(false);
    }
  }, [activeChargeTransaction?.bill?.payment_status]);

  // long poll for Active Charge Txn
  useInterval(
    () => {
      getChargingDetails();
    },
    shouldContinueChargeTxnPolling ? 5000 : null
  );

  // for setting/clearing all intervals when the screen is focused/exited
  useFocusEffect(
    useCallback(() => {
      setShouldContinueChargeTxnPolling(true);
      StatusBar.setBackgroundColor(THEME_COLORS.defaultStatusBar);

      return () => {
        setShouldContinueChargeTxnPolling(false);
      };
    }, [])
  );

  useEffect(() => {
    StatusBar.setBackgroundColor(THEME_COLORS.defaultStatusBar);
  });

  useEffect(() => {
    getChargingDetails();
  }, [chargeTxnId]);

  return (
    <View style={styles.root}>
      <ScreenHeader
        navigation={navigation}
        style={{ borderBottomWidth: 0, position: "sticky" as any, top: 0, left: 0, right: 0, zIndex: 100 }}
        onBackPress={() => {
          if (returnScreen) navigation.navigate(returnScreen);
          else navigation.navigate(SCREEN_NAMES.MapScreen);
        }}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flexGrow: 1,
          padding: 16

          // height: verticalSize(78)
        }}
        refreshControl={
          <RefreshControl colors={RefreshControlLoaderColors} refreshing={waitForRefresh} onRefresh={onRefresh} />
        }>
        {/* loader */}
        {waitForActiveChargeTransaction && !activeChargeTransaction && (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center"
            }}>
            <ActivityIndicator size={40} color={COLORS.darkBlack} />
            <CText
              size={20}
              semiBold
              style={{
                marginTop: 10,
                textAlign: "center"
              }}>
              Loading...
            </CText>
          </View>
        )}
        {/* no such charge txn */}
        {!waitForActiveChargeTransaction && !activeChargeTransaction && (
          /* TODO: change this to have translated text */
          <View
            style={{
              flex: 1,
              ...StyleHelpers.totalCenter
            }}>
            <SectionMessage
              message={t("BillNotFound")}
              textStyle={{
                fontSize: 24
              }}
            />
            <CButton
              title={t("GoBack")}
              height={40}
              onPress={() => {
                navigation.goBack();
              }}
              buttonStyle={{
                minWidth: 100
              }}
            />
          </View>
        )}
        {/* charge txn */}
        {activeChargeTransaction && (
          <>
            {/* TODO: show Charging Stopped Text: https://www.figma.com/file/6HAsZOwwu4dtojxSQtQJOb/B2B-app?node-id=972%3A9450 */}
            {activeChargeTransaction?.bill?.payment_status === BillPaymentStatusMap[BillPaymentStatus.NotGenerated] && (
              <View style={{ marginVertical: 8 }}>
                <CText size={20} semiBold style={{ fontWeight: "600" }}>
                  {t("charging")}
                </CText>
                <CText size={20} semiBold style={{ marginTop: -6, fontWeight: "600" }}>
                  {t("Stopped")}
                </CText>
              </View>
            )}

            {/* charging & payment status */}
            {Number(activeChargeTransaction.energy_delivered) > 0 && (
              <View
                style={{
                  ...styles.successCont,
                  backgroundColor: paymentStatusInfo.backgroundColor
                }}>
                <View style={styles.thumbCont}>{paymentStatusInfo.image}</View>
                <View
                  style={{
                    marginStart: 16
                  }}>
                  <CText
                    semiBold
                    size={12}
                    style={{
                      color: paymentStatusInfo.textColor
                    }}>
                    {paymentStatusInfo.text}
                  </CText>
                  {(activeChargeTransaction?.bill?.payment_status ===
                    BillPaymentStatusMap[BillPaymentStatus.Generated] ||
                    activeChargeTransaction?.bill?.payment_status === BillPaymentStatusMap[BillPaymentStatus.Paid]) &&
                    !!activeChargeTransaction?.bill?.user?.name && (
                      <CText size={12} style={{ color: paymentStatusInfo.userTextColor }}>
                        By {activeChargeTransaction?.bill?.user?.name}
                      </CText>
                    )}
                </View>
              </View>
            )}

            {/* paid by box */}
            {Number(activeChargeTransaction?.energy_delivered) > 0 &&
              activeChargeTransaction?.bill?.payment_status === BillPaymentStatusMap[BillPaymentStatus.Paid] && (
                <View
                  style={[styles.whiteBox, styles.rowStyle, { marginVertical: 8, backgroundColor: COLORS.whiteColor }]}>
                  <View>
                    <CText size={10} medium>
                      {getPaidByText(activeChargeTransaction)}
                    </CText>

                    {/* amount */}
                    {!!activeChargeTransaction?.bill?.amount?.total && (
                      <CText size={22} semiBold style={{ marginBottom: -12, fontWeight: "600" }}>
                        <NumberFormatText
                          displayType={"text"}
                          prefix={"₹"}
                          decimalScale={2}
                          value={Number(activeChargeTransaction?.bill?.amount?.total).toFixed(2)}
                          thousandSeparator={true}
                          thousandsGroupStyle={"lakh"}
                        />
                      </CText>
                    )}
                  </View>
                  <Image
                    source={Images.paid_stamp}
                    style={{
                      height: 56,
                      width: 56
                    }}
                    resizeMode="contain"
                  />
                </View>
              )}

            {/* refund and unpaid msg box */}
            {(refundDetails?.has || unpaidAmount?.has) && (
              <View style={[styles.whiteBox, { marginVertical: 10 }]}>
                {refundDetails?.has && (
                  <View
                    style={{
                      flexDirection: "row"
                    }}>
                    <View
                      style={{
                        flex: 0.1,
                        paddingRight: 2,
                        marginTop: 3
                      }}>
                      {refundDetails?.icon}
                    </View>
                    <View style={{ flex: 0.9 }}>
                      <CText size={12} medium style={{ color: refundDetails.statusTextColor }}>
                        {refundDetails.statusDescription}
                      </CText>
                    </View>
                  </View>
                )}
                {unpaidAmount?.has && unpaidAmount?.amount > 0 && (
                  <CText size={12}>
                    {t("RemainingAmountWillBeDeducted").replace("[[AMOUNT]]", `₹${unpaidAmount?.amount}`)}
                  </CText>
                )}
              </View>
            )}

            {/* error */}
            {i18nError && (
              <RichError
                msgType={"info"}
                i18nError={i18nError}
                style={{
                  marginVertical: 10
                }}
              />
            )}

            {/* last known values */}
            {activeChargeTransaction?.bill?.payment_status === BillPaymentStatusMap[BillPaymentStatus.NotGenerated] && (
              <View style={{ marginVertical: 8, backgroundColor: "none" }}>
                <LastKnownValuesCard chargeTxn={activeChargeTransaction} />
              </View>
            )}

            {/* charging summary */}
            {activeChargeTransaction?.bill?.payment_status !== BillPaymentStatusMap[BillPaymentStatus.NotGenerated] && (
              <GroupedLineItemsCard
                style={{ marginVertical: 10 }}
                headingComponent={
                  <CText value={t("chargingSummary")} size={12} semiBold style={{ fontWeight: "600" }} />
                }
                lineItems={[
                  {
                    leftComponent: <CText value={t("chargingTime")} size={14} />,
                    rightComponent: (
                      <CText
                        value={
                          <Timer
                            startTimestamp={activeChargeTransaction?.start_timestamp as string}
                            endTimestamp={activeChargeTransaction?.stop_timestamp}
                          />
                        }
                        size={14}
                        semiBold
                        style={{ fontWeight: "600" }}
                      />
                    )
                  },
                  {
                    leftComponent: <CText value={t("energyDelivered")} size={14} />,
                    rightComponent: (
                      <Text>
                        {!activeChargeTransaction?.summary?.energy_delivered && (
                          <CText value={"-"} size={14} semiBold />
                        )}
                        {!!activeChargeTransaction?.summary?.energy_delivered && (
                          <NumericFormat
                            displayType={"text"}
                            suffix={" kWh"}
                            value={Number(activeChargeTransaction?.summary?.energy_delivered)}
                            thousandSeparator={true}
                            thousandsGroupStyle={"lakh"}
                            renderText={(value) => (
                              <CText value={value} size={14} semiBold style={{ fontWeight: "600" }} />
                            )}
                          />
                        )}
                      </Text>
                    )
                  },
                  {
                    leftComponent: <CText value={t("startingSoC")} size={14} />,
                    rightComponent: (
                      <CText
                        value={
                          <>
                            {!activeChargeTransaction?.summary?.initial_soc &&
                            activeChargeTransaction?.summary?.initial_soc !== 0
                              ? "-"
                              : `${activeChargeTransaction?.summary?.initial_soc}%`}
                          </>
                        }
                        size={14}
                        semiBold
                        style={{ fontWeight: "600" }}
                      />
                    )
                  },
                  {
                    leftComponent: <CText value={t("endingSoC")} size={14} />,
                    rightComponent: (
                      <CText
                        value={
                          <>
                            {!activeChargeTransaction?.summary?.current_soc &&
                            activeChargeTransaction?.summary?.current_soc !== 0
                              ? "-"
                              : `${activeChargeTransaction?.summary?.current_soc}%`}
                          </>
                        }
                        size={14}
                        semiBold
                        style={{ fontWeight: "600" }}
                      />
                    )
                  }
                ]}
              />
            )}

            {/* payment summary */}
            {activeChargeTransaction?.bill?.payment_status !== BillPaymentStatusMap[BillPaymentStatus.NotGenerated] && (
              <GroupedLineItemsCard
                style={{ marginVertical: 8 }}
                headingComponent={
                  <>
                    <CText size={12} semiBold style={{ fontWeight: "600" }}>
                      {t("billBreakdown")}
                    </CText>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                      <CText size={11} medium>
                        Bill Id:
                      </CText>
                      <CText size={11} semiBold style={{ fontWeight: "600" }}>
                        {" "}
                        {activeChargeTransaction.bill?.bill_uid}
                      </CText>
                    </View>
                  </>
                }
                lineItems={getBillBreakdownLineItems(
                  activeChargeTransaction.bill?.amount?.cost_breakup as CostBreakupLineItem[],
                  activeChargeTransaction,
                  navigation
                )}
              />
            )}

            {/* overview*/}

            <View style={[styles.whiteBox, { marginVertical: 10, backgroundColor: COLORS.whiteColor }]}>
              <CText value={t("overview")} size={12} semiBold style={{ fontWeight: "600" }} />

              <View style={styles.divider} />

              <View style={[styles.rowStyle, { marginTop: 10 }]}>
                <LocationPinFilledIcon width={20} height={20} fill={COLORS.primaryColor} />
                <CText
                  value={activeChargeTransaction?.charge_point?.charging_station?.name}
                  size={14}
                  style={{ flex: 1, marginStart: 8, marginTop: -2 }}
                />
              </View>

              <View style={[styles.rowStyle, { marginTop: 10 }]}>
                <ConnectorIcon width={20} height={20} fill={COLORS.primaryColor} />
                <CText value={connectorInfoText} size={14} style={{ flex: 1, marginStart: 8, marginTop: -2 }} />
              </View>

              {!!activeChargeTransaction?.charging_method && (
                <View style={[styles.rowStyle, { marginTop: 10 }]}>
                  <CheckCircleFilledIcon width={19} height={19} fill={COLORS.primaryColor} />
                  <CText size={14} style={{ flex: 1, marginStart: 8, marginTop: -2 }}>
                    {t("chargeBy")}
                    <> </>
                    {activeChargeTransaction?.charging_method?.charging_option_type ?? ""}
                    {activeChargeTransaction ? (
                      <>
                        <> • </>
                        <NonSIChargingAmountText
                          cm={
                            {
                              method: activeChargeTransaction?.charging_method
                                ?.charging_option_type as ChargingMethodType,
                              amount: activeChargeTransaction?.charging_method?.charging_option_unit as string
                            } as ChargingMethod
                          }
                        />
                      </>
                    ) : (
                      "-"
                    )}
                  </CText>
                </View>
              )}
            </View>
          </>
        )}
      </ScrollView>

      {activeChargeTransaction && (
        <ScreenBottomBar
          style={{
            padding: 16,
            backgroundColor: COLORS.whiteColor,
            position: "sticky" as any,
            bottom: 0,
            left: 0,
            right: 0
          }}>
          <CButton
            title={t("done")}
            onPress={() => {
              if (returnScreen) navigation.navigate(returnScreen);
              else navigation.navigate(SCREEN_NAMES.MapScreen);
            }}
          />
        </ScreenBottomBar>
      )}
    </View>
  );
}
