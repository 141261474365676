import { StyleSheet } from 'react-native';
import { StyleHelpers } from '../../../miscellaneous/constants/style-helpers';
import { THEME_COLORS } from '../../../theme/constants/themeColors';
import { verticalSize } from '../../../functions/responsive';

export default StyleSheet.create({
  root: {
    height: verticalSize(100),
    flexDirection: 'column',
    backgroundColor: THEME_COLORS.bgColor,
    justifyContent: 'space-between',
    position: 'relative'
  },
  logoStyle: {
    height: 40,
    width: 148
  },
  header: {
    ...StyleHelpers.header
  },
  profileCont: {
    alignItems: 'center',
    borderWidth: 4,
    borderRadius: 100,
    marginTop: 32,
    height: 100,
    width: 100,
    justifyContent: 'center',
    alignSelf: 'center',
    overflow: 'hidden'
  },
  profileStyle: {
    height: 100,
    width: 100,
    borderRadius: 100
  }
});
