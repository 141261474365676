import React from "react";
import { TouchableOpacity } from "react-native";
import styles from "./styles";
import CText from "../CText";

export default function SimpleButton(props: any) {
  const { title, leftIcon, rightIcon, style, onPress = () => {} } = props;
  return (
    <>
      <TouchableOpacity
        activeOpacity={0.7}
        style={{ ...styles.container, ...style }}
        onPress={onPress}
      >
        {leftIcon && leftIcon}
        <CText
          value={title}
          size={16}
          style={{ marginTop: 4, fontWeight: "600" }}
          semiBold
        />
        {rightIcon && rightIcon}
      </TouchableOpacity>
    </>
  );
}
