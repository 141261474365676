export const environments = {
  dev: {
    production: false,
    type: 'dev',
    appWebsiteHost: `https://stg-instachargeapp.pulseenergy.io`,
    apiHost: 'http://127.0.0.1:5000',
    apiBaseUrl: 'http://127.0.0.1:5000/api/app',
    centralHost: `http://localhost:8082/api/app`,
    // apiHost: 'https://primary.loca.lt',
    // apiBaseUrl: 'https://primary.loca.lt/api/app',
    // centralHost: `https://central.loca.lt/api/app`,
    apiBasePath: '/api/app',
    analyticsEnabled: false,
    mediaCdnHost: 'https://central-media-stg.pulseenergy.in',
    smartlookEnabled: false,
    smartlookProjectKey: `555350ba328f384329c8d3fe5b4bd0204e7cdba7`,
    paytmURL: `https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/PULSEE47044261608615`,
    googleMapsKey: 'AIzaSyA6CVMkSfo7OL-H2bOeloZ9zjUQTnHFSZY',
  },
  stg: {
    production: true,
    type: 'stg',
    appWebsiteHost: `https://stg-instachargeapp.pulseenergy.io`,
    apiHost: `https://stg-api.pulseenergy.io`,
    apiBaseUrl: 'https://stg-api.pulseenergy.io/api/app',
    centralHost: `https://stg-central-api.pulseenergy.io/api/app`,
    apiBasePath: '/api/app',
    analyticsEnabled: true,
    mediaCdnHost: 'https://central-media.pulseenergy.in',
    smartlookEnabled: true,
    smartlookProjectKey: `555350ba328f384329c8d3fe5b4bd0204e7cdba7`,
    paytmURL: `https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/PULSEE47044261608615`,
    googleMapsKey: 'AIzaSyDRDQjosVDOidMKVQWpqHqaet8NQGEpjv0',
  },
  prod: {
    production: true,
    type: 'prod',
    appWebsiteHost: `https://instachargeapp.pulseenergy.io`,
    apiHost: `https://lite-app-primary-api.pulseenergy.io`,
    apiBaseUrl: 'https://lite-app-primary-api.pulseenergy.io/api/app',
    centralHost: `https://lite-app-central-api.pulseenergy.io/api/app`,
    // apiHost: `https://api.pulseenergy.io`,
    // apiBaseUrl: 'https://api.pulseenergy.io/api/app',
    // centralHost: `https://central-api.pulseenergy.io/api/app`,
    apiBasePath: '/api/app',
    analyticsEnabled: true,
    mediaCdnHost: 'https://central-media.pulseenergy.in',
    smartlookEnabled: true,
    smartlookProjectKey: `555350ba328f384329c8d3fe5b4bd0204e7cdba7`,
    paytmURL: `https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/PULSEE47044261608615`,
    googleMapsKey: 'AIzaSyDRDQjosVDOidMKVQWpqHqaet8NQGEpjv0',
  }
};
