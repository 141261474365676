import { StyleSheet } from "react-native";
import { COLORS } from "../../../config/colors";

export default StyleSheet.create({
	container: {
		backgroundColor: COLORS.whiteColor,
		borderWidth: 1,
		borderBottomWidth: 3,
		borderColor: COLORS.blackColor,
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "row",
		width: "100%",
		paddingVertical: 12,
		paddingHorizontal: 16,
		borderRadius: 8,
	},
});
