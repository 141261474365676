import { MiniUCS, MixedChargingStation } from '../central-atoms/types/charging/charging-station';
import {
  RESET_NEARBY_UCS_MINI_OBJECTS_LIST,
  RESET_NEAREST_UCS_MINI_OBJECT,
  UPDATE_NEARBY_UCS_MINI_OBJECTS_LIST,
  UPDATE_NEAREST_UCS_MINI_OBJECT
} from '../constants';

const actions = {
  SET_NEARTEST_CHARGER: 'auth/SET_NEARTEST_CHARGER',
  SET_TRACKED_NEAREST_CHARGING_STATION: 'auth/SET_TRACKED_NEAREST_CHARGING_STATION',
  SET_LAST_CHARGE_TRANSACTION: 'SET_LAST_CHARGE_TRANSACTION',
  SET_NEARBY_CHARGING_STATIONS: 'auth/SET_NEARBY_CHARGING_STATIONS',
  SET_RECENT_CHARGER: 'auth/SET_RECENT_CHARGER',
  SET_FILTER_DATA: 'auth/SET_FILTER_DATA',
  RESET_DATA: 'auth/RESET_DATA',
  SET_SELECTED_STATION: 'auth/SET_SELECTED_STATION',
  SET_SELECTED_CHARGER: 'auth/SET_SELECTED_CHARGER',
  SET_SELECTED_CHARGER_POINT: 'auth/SET_SELECTED_CHARGER_POINT',
  SET_FILTER_OPTION: 'auth/SET_FILTER_OPTION',

  // maps
  UPDATE_MAPS_NEARBY_UCS: 'UPDATE_MAPS_NEARBY_UCS',
  RESET_MAPS_NEARBY_UCS: 'RESET_MAPS_NEARBY_UCS',
  UPDATE_UCS_MASTER_MAP: 'UPDATE_UCS_MASTER_MAP',

  setNearestCharger: (nearestCharger: any) => ({
    type: actions.SET_NEARTEST_CHARGER,
    nearestCharger
  }),

  setTrackedNearestChargingStation: (mixedChargingStation: any) => ({
    type: actions.SET_TRACKED_NEAREST_CHARGING_STATION,
    mixedChargingStation
  }),

  setLastChargeTransaction: (lastChargeTransaction: any) => ({
    type: actions.SET_LAST_CHARGE_TRANSACTION,
    lastChargeTransaction
  }),

  setNearbyChargingStations: (nearbyChargingStations: any) => ({
    type: actions.SET_NEARBY_CHARGING_STATIONS,
    nearbyChargingStations
  }),

  setRecentCharger: (recentCharger: any) => ({
    type: actions.SET_RECENT_CHARGER,
    recentCharger
  }),

  setFilterData: (filterData: any) => ({
    type: actions.SET_FILTER_DATA,
    filterData
  }),

  setSelectedCharger: (selectedCharger: any) => ({
    type: actions.SET_SELECTED_CHARGER,
    selectedCharger
  }),

  setSelectedChargerPoint: (selectedChargerPoint: any) => ({
    type: actions.SET_SELECTED_CHARGER_POINT,
    selectedChargerPoint
  }),

  setSelectedStation: (selectedStation: any) => ({
    type: actions.SET_SELECTED_STATION,
    selectedStation
  }),

  resetData: () => ({
    type: actions.RESET_DATA
  })
};

export const updateMapsNearbyUCS = (ucs: MixedChargingStation[]) => ({
  type: actions.UPDATE_MAPS_NEARBY_UCS,
  payload: {
    ucs
  }
});

export const resetMapsNearbyUCS = () => ({
  type: actions.RESET_MAPS_NEARBY_UCS
});

export const updateUCSMasterMap = (ucs: MixedChargingStation[]) => ({
  type: actions.UPDATE_UCS_MASTER_MAP,
  payload: {
    ucs
  }
});

export const updateNearestUCSMiniObject = (miniUCS: MiniUCS | null) => ({
  type: UPDATE_NEAREST_UCS_MINI_OBJECT,
  payload: {
    miniUCS
  }
});

export const updateNearbyUCSMiniObjectsList = (miniUCSList: MiniUCS[], updateStrategy: 'merge' | 'override') => ({
  type: UPDATE_NEARBY_UCS_MINI_OBJECTS_LIST,
  payload: {
    miniUCSList,
    updateStrategy
  }
});

export const resetNearestUCSMiniObject = () => ({
  type: RESET_NEAREST_UCS_MINI_OBJECT
});

export const resetOtherNearbyUCSMiniObjectList = () => ({
  type: RESET_NEARBY_UCS_MINI_OBJECTS_LIST
});

export default actions;
