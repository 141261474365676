import { View, ViewStyle } from "react-native";
import { StyleHelpers } from "../constants/style-helpers";

type Props = {
    children: React.ReactNode,
    style?: ViewStyle,
}
const ScreenBottomBar = (props: Props) => {
    const {
        children,
        style = {},
    } = props;

    return (
        <View style={[StyleHelpers.actionBar, {
            paddingBottom: StyleHelpers.pageSpacing.paddingLeft,
            ...style,
        }]}>
            {children}
        </View>
    );
}

export default ScreenBottomBar;