import { useSelector } from "react-redux";
import { APP_STATE } from "../types";
import { ChargingStation, MixedChargingStation } from "../central-atoms/types/charging/charging-station";
import { ChargePoint, ChargePointConnector } from "../central-atoms/types/charging/charge-points";
import { ChargeTransaction } from "../central-atoms/types/charging/charge-txns";


export const useSelectedChargingStation = (): ChargingStation | undefined => {
    return useSelector((state: APP_STATE) => state.doCharging.app.selectedChargingStation);
}

export const useSelectedMixedChargingStation = (): MixedChargingStation | undefined => {
    return useSelector((state: APP_STATE) => state.doCharging.app.selectedMixedChargingStation);
}

export const useSelectedChargePoint = (): ChargePoint | undefined => {
    return useSelector((state: APP_STATE) => state.doCharging.app.selectedChargePoint);
}

export const useSelectedChargePointConnector = (): ChargePointConnector | undefined => {
    return useSelector((state: APP_STATE) => state.doCharging.app.selectedChargePointConnector);
}

export const useTrackedCPErrorConnector = (): ChargePointConnector | null => {
    return useSelector((state: APP_STATE) => state.doCharging.app.trackedCPErrorConnector);
}

export const useTrackedCPConnectionError = (): boolean | null => {
    return useSelector((state: APP_STATE) => state.doCharging.app.trackedCPConnectionError);
}

export const useOngoingChargeTxns = (): {[chargeTxnId: string]: ChargeTransaction} => {
    return useSelector((state: APP_STATE) => state.doCharging.data.ongoingChargeTxns);
};
