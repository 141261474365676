import { useTranslation } from "react-i18next";
import { Dimensions, FlatList, ScrollView, View } from "react-native";
import { useDispatch } from "react-redux";
import { ChargePointStatus } from "../../../../../central-atoms/enums/ocpp/v1.6/generic";
import { ChargePoint } from "../../../../../central-atoms/types/charging/charge-points";
import { PrimaryChargingStationConnector } from "../../../../../central-atoms/types/charging/charging-station";
import CButton from "../../../../components/CButton";
import CText from "../../../../components/CText";
import { COLORS } from "../../../../../config/colors";
import RichError from "../../../../../miscellaneous/components/RichError";
import ScreenBottomBar from "../../../../../miscellaneous/components/ScreenBottomBar";
import ScreenHeader from "../../../../../miscellaneous/components/ScreenHeader";
import { StyleHelpers } from "../../../../../miscellaneous/constants/style-helpers";
import { ScreenComponent } from "../../../../../miscellaneous/types/components";
import { THEME_COLORS } from "../../../../../theme/constants/themeColors";
import CPConnectorCard from "../CpConnectorCard";
import {
  useSelectedChargePoint,
  useSelectedChargePointConnector,
  useSelectedChargingStation
} from "../../../../../hooks";
import { setSelectedChargePointConnector } from "../../../../../actions/doChargingActions";
import styles from "./styles";

type Props = ScreenComponent & {
  onBackPress: () => void;
  onNextPress: () => void;
  cpConnected: boolean;
  cpNotConnectedError: null | any;
  active: boolean;
};
export default function SelectConnector(props: Props) {
  const { navigation, onBackPress = () => {}, onNextPress = () => {}, cpConnected, cpNotConnectedError } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const selectedChargingStation = useSelectedChargingStation();
  const selectedChargePoint = useSelectedChargePoint();
  const selectedChargePointConnector = useSelectedChargePointConnector();

  const sortedConnectorsList = selectedChargePoint?.connectors?.sort((cpc1, cpc2) =>
    cpc1.connector_id > cpc2.connector_id ? 1 : -1
  );

  const renderChargePointConnector = ({ item: cpConnector, index }: any) => {
    // TODO: optimize this
    const csConnector = selectedChargingStation?.connectors.find(
      (csc) => csc.id == cpConnector.primary_connector_id
    ) as PrimaryChargingStationConnector;

    return (
      <View
        key={cpConnector.id}
        style={{
          width: "50%",
          paddingEnd: index % 2 == 0 ? 8 : 0,
          paddingStart: index % 2 == 0 ? 0 : 8,
          marginTop: 15,
          marginBottom: 8
        }}>
        <CPConnectorCard
          unifiedCPConnector={{
            cp_connector: cpConnector,
            cs_connector: csConnector
          }}
          chargePoint={selectedChargePoint as ChargePoint}
          // name={`${selectedChargePoint?.name}`}
          isSelectable={cpConnector.status !== ChargePointStatus.Charging}
          selected={selectedChargePointConnector?.id === cpConnector.id}
          onPress={() => {
            if (selectedChargePointConnector?.id === cpConnector.id) {
              dispatch(setSelectedChargePointConnector(null));
            } else {
              dispatch(setSelectedChargePointConnector(cpConnector));
            }
          }}
        />
      </View>
    );
  };

  const shouldDisableContinueButton = () => {
    if (selectedChargePointConnector?.id && cpConnected) {
      if (selectedChargePointConnector.status === ChargePointStatus.Charging) return true;
      return false;
    }
    return true;
  };

  return (
    <View style={styles.root}>
      <ScreenHeader
        navigation={navigation}
        style={{ borderBottomWidth: 0, position: "sticky" as any, top: 0, right: 0, left: 0 }}
        onBackPress={() => {
          onBackPress();
          dispatch(setSelectedChargePointConnector(null));
        }}
      />

      <ScrollView
        contentContainerStyle={{
          ...StyleHelpers.pageSpacing,
          paddingTop: 150,
          flexGrow: 1
        }}
        showsVerticalScrollIndicator={false}>
        <CText value={selectedChargePoint?.name} size={12} semiBold style={{ lineHeight: 60, fontWeight: "600" }} />
        <View style={styles.dash} />
        <CText value={t("selectA")} size={26} semiBold style={{ fontWeight: "600" }} />
        <CText
          value={t("connectorLC")}
          size={26}
          semiBold
          style={{
            lineHeight: 60,
            marginTop: -18,
            fontWeight: "600"
          }}
        />

        <CText value={t("selectOneUsed")} size={12} style={{ color: COLORS.blackColor }} />

        {!cpConnected && cpNotConnectedError && (
          <RichError
            i18nError={cpNotConnectedError}
            style={{
              marginTop: 20,
              marginBottom: 10
            }}
          />
        )}

        <View
          style={{
            marginTop: 20
          }}>
          <FlatList
            data={sortedConnectorsList}
            keyExtractor={(item, index) => item.id}
            renderItem={renderChargePointConnector}
            numColumns={2}
            showsVerticalScrollIndicator={false}
            scrollEnabled={false}
          />
          {!cpConnected && (
            <View
              style={{
                width: Dimensions.get("window").width,
                height: "100%",
                backgroundColor: THEME_COLORS.bgColor + "90",
                position: "absolute"
              }}
            />
          )}
        </View>
      </ScrollView>

      <ScreenBottomBar style={{ position: "sticky" as any, bottom: 0, right: 0, left: 0 }}>
        <CButton
          title={t("continue")}
          onPress={() => {
            onNextPress();
          }}
          disable={shouldDisableContinueButton()}
        />
      </ScreenBottomBar>
    </View>
  );
}
