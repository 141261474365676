import { TextStyle, View, ViewStyle } from "react-native";
import CText from "../CText";

type Props = {
	message: string;
	textStyle?: TextStyle;
	style?: ViewStyle;
};
export default function SectionMessage(props: Props) {
	const { message, textStyle = {}, style: viewStyle = {} } = props;

	return (
		<View style={[viewStyle]}>
			<CText
				style={{
					...{ textAlign: "center" },
					...textStyle,
				}}
			>
				{message}
			</CText>
		</View>
	);
}
