import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Linking,
  ScrollView,
  StatusBar,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
// @ts-ignore
import { Player } from "video-react";
// @ts-ignore
import { useIsFocused } from "@react-navigation/native";
import { isEmpty } from "lodash";
// @ts-ignore
import { ChargingRequestService } from "../../../services/ChargingRequestService";
import config from "../../../config/config";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import CText from "../../components/CText";
import { ReactComponent as CrossFilledIcon } from "../../../assets/icons/cross-filled.svg";
import { COLORS } from "../../../config/colors";
import {
  ChargingStatesScreenRouteParams,
  ScreenComponent,
} from "../../../types";
import {
  useFirebaseRemoteConfigValues,
  useSelectedChargePoint,
  useSelectedChargePointConnector,
} from "../../../hooks";
import { FirebaseRemoteConfigKeys, SCREEN_NAMES } from "../../../constants";
import { WHATSAPP_CHAT_LINK } from "../../../config/setting";
import { I18NRichMessageResponse } from "../../../central-atoms/types/responses/i18n";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import ChargePointRequestService from "../../../services/ChargePointRequestService";
import { ChargeTransaction } from "../../../central-atoms/types/charging/charge-txns";
import { ChargeTransactionStatus } from "../../../central-atoms/enums/charging/charge-txn";
import { consoleLog, isAndroid } from "../../../miscellaneous/helpers";
import { sleep } from "../../../central-atoms/functions/time";
import { useInterval } from "../../../miscellaneous/hooks";
import ScreenWrapper from "../../../miscellaneous/components/ScreenWrapper";
import ScreenHeader from "../../../miscellaneous/components/ScreenHeader";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import RichError from "../../../miscellaneous/components/RichError";
import { VerticalLine } from "../../../miscellaneous/views/miscellaneous";
import ScreenBottomBar from "../../../miscellaneous/components/ScreenBottomBar";
import { FullScreenOverlay } from "../../../miscellaneous/components/FullScreenOverlay";
import { FailedIcon } from "../../../icons";
import { BlueBoltIcon, BlueCheckIcon, WhiteCircleIcon } from "../../../icon";
import ProgressBar from "@ramonak/react-progress-bar";

enum ChargingProgressStepName {
  ConnectCharger = "ConnectCharger",
  StartCharging = "StartCharging",
  WaitForAcceptChargingRequest = "WaitForAcceptChargingRequest",
  FetchMeterReadings = "FetchMeterReadings",
}

enum ChargingProgressStepState {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Completed = "Completed",
  Failed = "Failed",
}

type ChargingProgressStep = {
  stepName: ChargingProgressStepName;
  notStartedDesc: string;
  inProgressDesc: string;
  completedDesc: string;
  failedDesc?: string;
  stepState: ChargingProgressStepState;
  requiresUserAction: boolean;
};

const getInitialSteps = (
  t: any
): { [stepName: string]: ChargingProgressStep } => {
  return {
    [ChargingProgressStepName.ConnectCharger]: {
      stepName: ChargingProgressStepName.ConnectCharger,
      notStartedDesc: t("pleaseConnectCharger"),
      inProgressDesc: t("WaitingForConnectCharger"),
      completedDesc: t("chargerConnected"),
      failedDesc: t("FailedPleaseTryAgain"),
      stepState: ChargingProgressStepState.NotStarted,
      requiresUserAction: false,
    },

    [ChargingProgressStepName.StartCharging]: {
      stepName: ChargingProgressStepName.StartCharging,
      notStartedDesc: t("startCharging"),
      inProgressDesc: t("chargingStartingSoon"),
      completedDesc: t("ChargingRequestSentByInstacharge")?.replace(
        "[[APP_NAME]]",
        config.APP_NAME
      ),
      failedDesc: t("FailedToStartCharging"),
      stepState: ChargingProgressStepState.NotStarted,
      requiresUserAction: false,
    },

    [ChargingProgressStepName.WaitForAcceptChargingRequest]: {
      stepName: ChargingProgressStepName.WaitForAcceptChargingRequest,
      notStartedDesc: t("WaitForAcceptChargingRequest"),
      inProgressDesc: t("WaitingForAcceptChargingRequest"),
      completedDesc: t("ChargerAcceptedChargingRequest"),
      failedDesc: t("FailedPleaseTryAgain"),
      stepState: ChargingProgressStepState.NotStarted,
      requiresUserAction: false,
    },

    [ChargingProgressStepName.FetchMeterReadings]: {
      stepName: ChargingProgressStepName.FetchMeterReadings,
      notStartedDesc: t("FetchEnergyData"),
      inProgressDesc: t("WaitingForEnergyData"),
      completedDesc: t("EnergyDataFetched"),
      failedDesc: t("FailedPleaseTryAgain"),
      stepState: ChargingProgressStepState.NotStarted,
      requiresUserAction: false,
    },
  };
};

const ConnectChargerStepPrimaryView = (props: {
  reconnectCharger: boolean;
  t: any;
}) => {
  const { reconnectCharger, t } = props;

  return (
    <>
      <View style={styles.videoCont}>
        <Player>
          <source src={require("../../../assets/connect_charger_video.mp4")} />
        </Player>
      </View>

      <CText
        value={
          reconnectCharger
            ? t("PleaseReconnectCharger")
            : t("pleaseConnectCharger")
        }
        size={22}
        semiBold
        style={{
          ...styles.currentActionText,
        }}
      />
    </>
  );
};

const ChargingStartingSoonStepPrimaryView = (props: { t: any }) => {
  const { t } = props;

  return (
    <>
      <View style={styles.lottieCont}>
        <WhiteCircleIcon height={180} width={180} />
      </View>

      <CText
        value={t("chargingStartingSoon")}
        size={22}
        semiBold
        style={{
          ...styles.currentActionText,
          fontWeight: "700",
          width: 300,
        }}
      />
    </>
  );
};

const ChargingStartedStepPrimaryView = (props: { t: any }) => {
  const { t } = props;
  return (
    <>
      <View style={styles.lottieCont}>
        <BlueBoltIcon
          style={{
            width: 200,
            height: 200,
          }}
        />
      </View>

      <CText
        value={t("ChargingStarted")}
        size={22}
        semiBold
        style={{
          ...styles.currentActionText,
          width: 200,
          color: COLORS.whiteColor,
        }}
      />
    </>
  );
};

const ChargingFailedStepPrimaryView = (props: { t: any }) => {
  const { t } = props;

  return (
    <>
      <View style={styles.lottieCont}>
        <FailedIcon
          style={{
            width: 200,
            height: 200,
          }}
        />
      </View>

      <CText
        value={t("ChargingFailed")}
        size={22}
        semiBold
        style={{
          ...styles.currentActionText,
          width: 200,
          color: COLORS.whiteColor,
          marginBottom: 10,
        }}
      />
    </>
  );
};

// primary step view components */

type Props = ScreenComponent & {};
export default function ChargingStartingSoonScreen(props: Props) {
  const { navigation, route } = props;
  const { startChargingData, requiredBalance = 50 } =
    (route?.params as ChargingStatesScreenRouteParams) || {};
  const { t } = useTranslation();
  const firebaseRemoteConfigValues = useFirebaseRemoteConfigValues();
  const whatsappChatLink =
    firebaseRemoteConfigValues?.[FirebaseRemoteConfigKeys.whatsapp_chat_link] ||
    WHATSAPP_CHAT_LINK;
  const isScreenFocused = useIsFocused();
  const selectedChargePoint = useSelectedChargePoint();
  const selectedChargePointConnector = useSelectedChargePointConnector();
  const [waitForStartCharging, setWaitForStartCharging] = useState(false);
  const [chargeTxnPollingInterval, setChargeTxnPollingInterval] = useState<
    number | null
  >(null);

  const [showStartingSoon, setStartingSoon] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setStartingSoon(false);
    }, 3000);
  }, []);

  const [chargeTxnId, setChargeTxnId] = useState<string | null>(
    startChargingData?.charge_transaction_id || null
  );

  // steps /*
  const [isChargingAllowedInterval, setIsChargingAllowedInterval] = useState<
    number | null
  >(null);
  const [bgColor, setBgColor] = useState(THEME_COLORS.bgColor);
  const [chargingProgressStepsMap, setChargingProgressStepsMap] = useState<{
    [stepName: string]: ChargingProgressStep;
  }>(getInitialSteps(t));
  const [chargingProgressSteps, setChargingProgressSteps] = useState<
    ChargingProgressStepName[]
  >([]);
  const [currentChargingProgressStepName, setCurrentChargingProgressStepName] =
    useState<ChargingProgressStepName>(chargingProgressSteps[0] || "");
  const [reconnectCharger, setReconnectCharger] = useState<boolean>(false);
  // steps */

  // CP connection state polling
  const [cpConnected, setCPConnected] = useState(true);
  const [cpNotConnectedError, setCpNotConnectedError] =
    useState<I18NRichMessageResponse | null>(null);
  const [waitForCPConnected, setWaitForCPConnected] = useState<boolean | null>(
    null
  );
  const [waitForIsChargingAllowed, setWaitForIsChargingAllowed] = useState<
    boolean | null
  >(null);
  const [
    shouldContinueCPConnectedLongPolling,
    setShouldContinueCPConnectedLongPolling,
  ] = useState<boolean>(true);

  const [waitForChargingDetails, setWaitForChargingDetails] = useState<
    boolean | null
  >(null);
  const [waitForChargeTxn, setWaitForChargeTxn] = useState<boolean | null>(
    null
  );
  const [waitForCancelCharging, setWaitForCancelCharging] = useState<
    boolean | null
  >(null);

  const scrollViewRef = useRef<any>();

  const isScreenActive = useRef<boolean>(true);
  const isCancelChargingInitiated = useRef<boolean>(false);

  const startCharging = () => {
    if (waitForStartCharging || !isScreenFocused || !isScreenActive.current) {
      return;
    }

    if (startChargingData.charge_point_connector_id) {
      delete startChargingData.charge_point_connector_id;
    }

    setWaitForStartCharging(true);
    ChargePointRequestService.startCharging(startChargingData)
      .then(async (response) => {
        setWaitForStartCharging(false);

        const chargeTxn = response?.data;
        if (chargeTxn?.id) {
          setChargeTxnId(chargeTxn?.id);
        }

        onStepComplete(ChargingProgressStepName.StartCharging, {
          chargeTxnId: chargeTxn?.id,
        });
      })
      .catch((e) => {
        setWaitForStartCharging(false);

        onStepError({
          error: e,
        });
      });
  };

  const updateStepStateByChargeTxn = (chargeTxn: ChargeTransaction) => {
    if (chargeTxn.status === ChargeTransactionStatus.Stopped) {
      // move to bill page
      goToChargingCompleteScreen(chargeTxn?.id);
    } else if (
      currentChargingProgressStepName ===
        ChargingProgressStepName.WaitForAcceptChargingRequest &&
      chargeTxn.status === ChargeTransactionStatus.Ongoing &&
      !Number(chargeTxn?.summary?.energy_delivered)
    ) {
      onStepComplete(ChargingProgressStepName.WaitForAcceptChargingRequest, {
        chargeTxnId: chargeTxn?.id,
      });
    } else if (
      currentChargingProgressStepName ===
        ChargingProgressStepName.FetchMeterReadings &&
      chargeTxn.status === ChargeTransactionStatus.Ongoing &&
      Number(chargeTxn?.summary?.energy_delivered)
    ) {
      onStepComplete(ChargingProgressStepName.FetchMeterReadings, {
        chargeTxnId: chargeTxn?.id,
      });
    } else if (
      chargeTxn.status === ChargeTransactionStatus.Ongoing &&
      Number(chargeTxn?.summary?.energy_delivered)
    ) {
      // for going to charging details screen if charge txn is ongoing and energy is delivered
      onStepComplete(ChargingProgressStepName.FetchMeterReadings, {
        chargeTxnId: chargeTxn?.id,
      });
    }
  };

  const exitViewCleanup = () => {
    setShouldContinueCPConnectedLongPolling(false);
    setIsChargingAllowedInterval(null);
    setChargeTxnPollingInterval(null);
  };

  const getChargingDetails = async (
    chargeTxnId: string
  ): Promise<void | null> => {
    if (waitForChargingDetails) {
      return Promise.resolve(null);
    }

    setWaitForChargingDetails(true);
    try {
      const { data } = await ChargePointRequestService.fetchChargingDetails(
        chargeTxnId
      );
      setWaitForChargingDetails(false);
      updateStepStateByChargeTxn(data);
      return data;
    } catch (e) {
      setWaitForChargingDetails(false);
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error",
        },
      });
    }
  };

  const getChargeTxnForCPCId = async (cpcId: string): Promise<void | null> => {
    if (waitForChargeTxn) {
      return Promise.resolve(null);
    }

    setWaitForChargeTxn(true);
    try {
      const { data } = await ChargePointRequestService.fetchChargeTxnForCPCId(
        cpcId
      );
      setWaitForChargeTxn(false);
      updateStepStateByChargeTxn(data);
      return data;
    } catch (e) {
      setWaitForChargeTxn(false);
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error",
        },
      });
    }
  };

  const handleFacingIssuesPress = () => {
    let msg = `I am facing issues with Start Charging on the charger: \`\`\`${selectedChargePoint?.qr_code_id}\`\`\` `;
    if (isAndroid()) {
      msg = encodeURIComponent(msg);
    }
    Linking.openURL(`${whatsappChatLink}?text=${msg}`)
      .then(() => {
        // pass
      })
      .catch((e) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      });
  };

  const exitView = () => {
    if (isScreenFocused && isScreenActive.current) {
      navigation.goBack();
    }
  };

  const onStepComplete = async (
    stepName: ChargingProgressStepName,
    stepPayload: {
      chargeTxnId?: string;
    } = {}
  ) => {
    if (!isScreenFocused || !isScreenActive.current) {
      return;
    }
    if (!!stepName) {
      updateStep(stepName, {
        stepState: ChargingProgressStepState.Completed,
      });
    }

    if (stepName === ChargingProgressStepName.ConnectCharger) {
      setIsChargingAllowedInterval(null);
    } else if (stepName === ChargingProgressStepName.StartCharging) {
      (scrollViewRef.current as ScrollView)?.scrollToEnd();
    } else if (
      stepName === ChargingProgressStepName.WaitForAcceptChargingRequest
    ) {
      (scrollViewRef.current as ScrollView)?.scrollToEnd();
    } else if (stepName === ChargingProgressStepName.FetchMeterReadings) {
      await sleep(800);
      goToChargingDetailsScreen(
        stepPayload?.chargeTxnId || (chargeTxnId as string)
      );
    }

    goToNextStep();
  };

  const onStepError = async (stepPayload: { error: any }) => {
    if (!!currentChargingProgressStepName) {
      updateStep(currentChargingProgressStepName, {
        stepState: ChargingProgressStepState.Failed,
      });
    }
  };

  const executeCurrentStep = async () => {
    if (!isScreenFocused || !isScreenActive.current) {
      consoleLog("Screen is not focused");
      return;
    }

    if (
      chargingProgressStepsMap[currentChargingProgressStepName]?.stepState !==
      ChargingProgressStepState.NotStarted
    ) {
      return;
    }

    if (
      currentChargingProgressStepName ===
      ChargingProgressStepName.ConnectCharger
    ) {
      updateStep(currentChargingProgressStepName, {
        stepState: ChargingProgressStepState.InProgress,
      });
      setIsChargingAllowedInterval(3000);
    } else if (
      currentChargingProgressStepName === ChargingProgressStepName.StartCharging
    ) {
      updateStep(currentChargingProgressStepName, {
        stepState: ChargingProgressStepState.InProgress,
      });

      startCharging();
    } else if (
      currentChargingProgressStepName ===
      ChargingProgressStepName.WaitForAcceptChargingRequest
    ) {
      updateStep(currentChargingProgressStepName, {
        stepState: ChargingProgressStepState.InProgress,
      });

      // start polling for if charging should be allowed or not
      setChargeTxnPollingInterval(3000);
    } else if (
      currentChargingProgressStepName ===
      ChargingProgressStepName.FetchMeterReadings
    ) {
      updateStep(currentChargingProgressStepName, {
        stepState: ChargingProgressStepState.InProgress,
      });
      setChargeTxnPollingInterval(3000);
    }
  };

  const goToNextStep = () => {
    const nextStep = getNextStep();
    console.log("next step is", nextStep);
    if (nextStep) setCurrentChargingProgressStepName(nextStep);
  };

  const updateStep = (
    stepName: ChargingProgressStepName,
    stepUpdate: Partial<ChargingProgressStep>
  ): ChargingProgressStep | null => {
    let updatedStep: ChargingProgressStep | null = null;

    setChargingProgressStepsMap((prevState) => {
      const newState = { ...prevState };
      const existingStep = chargingProgressStepsMap[stepName];
      if (existingStep) {
        updatedStep = {
          ...existingStep,
          ...stepUpdate,
        };
        newState[stepName] = updatedStep;
      }
      return newState;
    });

    if ((updatedStep as never as ChargingProgressStep)?.stepName)
      setCurrentChargingProgressStepName(
        (updatedStep as never as ChargingProgressStep)?.stepName
      );

    return updatedStep;
  };

  const goToChargingDetailsScreen = (_chargeTxnId: string) => {
    if (
      isScreenFocused &&
      isScreenActive.current &&
      !isCancelChargingInitiated.current
    ) {
      exitViewCleanup();
      navigation.navigate(SCREEN_NAMES.ChargingProcessScreen, {
        chargeTxnId: _chargeTxnId,
      });
    }
  };

  const goToChargingCompleteScreen = (_chargeTxnId: string) => {
    // console.log(isScreenFocused, isScreenActive.current);
    if (
      isScreenFocused &&
      isScreenActive.current &&
      !isCancelChargingInitiated.current
    ) {
      exitViewCleanup();
      navigation.navigate(SCREEN_NAMES.ChargingSuccessfulScreen, {
        chargeTxnId: _chargeTxnId,
      });
    }
  };

  const getIsChargingAllowed = async (): Promise<any | null> => {
    if (!selectedChargePointConnector?.id || waitForIsChargingAllowed) {
      return Promise.resolve(null);
    }

    setWaitForIsChargingAllowed(true);
    try {
      const response = await ChargingRequestService.isChargingAllowed({
        charge_point_connector_id: selectedChargePointConnector?.id,
      });
      setWaitForIsChargingAllowed(false);

      if (
        response.data.is_charging_allowed &&
        currentChargingProgressStepName ===
          ChargingProgressStepName.ConnectCharger
      )
        onStepComplete(ChargingProgressStepName.ConnectCharger);
      return response;
    } catch (e) {
      setWaitForIsChargingAllowed(false);
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error",
        },
      });
    }
  };

  const buildChargingProgressSteps = (): ChargingProgressStepName[] => {
    const steps: ChargingProgressStepName[] = [];
    steps.push(ChargingProgressStepName.ConnectCharger);
    steps.push(ChargingProgressStepName.StartCharging);
    steps.push(ChargingProgressStepName.WaitForAcceptChargingRequest);
    steps.push(ChargingProgressStepName.FetchMeterReadings);

    return steps;
  };

  const getNextStep = (
    nextStepName?: ChargingProgressStepName
  ): ChargingProgressStepName => {
    let currentIndex = chargingProgressSteps.findIndex(
      (stepName) =>
        stepName === currentChargingProgressStepName ||
        stepName === nextStepName
    );
    if (currentIndex === -1) {
      currentIndex = 0;
    } else {
      currentIndex++;
    }

    return chargingProgressSteps[currentIndex];
  };

  const checkIsConnected = async (): Promise<void> => {
    if (waitForCPConnected) return Promise.resolve();

    setWaitForCPConnected(true);
    try {
      const { data, i18n_error } = await ChargePointRequestService.isConnected(
        selectedChargePoint?.public_uid as string
      );
      setWaitForCPConnected(false);
      setCPConnected(data?.is_connected);

      if (!data.is_connected && i18n_error) setCpNotConnectedError(i18n_error);
      else setCpNotConnectedError(null);
    } catch (e) {
      setWaitForCPConnected(false);
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error",
        },
      });
    }
  };

  // EFFECTS /*

  useEffect(() => {
    isScreenActive.current = true;
    const steps = buildChargingProgressSteps();
    setChargingProgressSteps(steps);
    setCurrentChargingProgressStepName(steps[0]);

    return () => {
      isScreenActive.current = false;
      consoleLog("unmounting...", isScreenActive.current);
      exitViewCleanup();
    };
  }, []);

  useInterval(() => {
    getIsChargingAllowed();
  }, isChargingAllowedInterval);

  // long poll for CP connections state
  useInterval(() => {
    if (chargeTxnId) getChargingDetails(chargeTxnId);
    else if (selectedChargePointConnector?.id)
      getChargeTxnForCPCId(selectedChargePointConnector?.id);
  }, chargeTxnPollingInterval);

  // auto execute the next step
  useEffect(() => {
    if (
      !chargingProgressStepsMap[currentChargingProgressStepName]
        ?.requiresUserAction
    ) {
      executeCurrentStep();
    }
  }, [currentChargingProgressStepName]);

  useEffect(() => {
    const currentStepState =
      chargingProgressStepsMap[currentChargingProgressStepName]?.stepState;
    // set colors /*
    let color = THEME_COLORS.bgColor;
    if (
      (currentChargingProgressStepName ===
        ChargingProgressStepName.StartCharging &&
        chargingProgressStepsMap[ChargingProgressStepName.StartCharging]
          ?.stepState === ChargingProgressStepState.Completed) ||
      currentChargingProgressStepName ===
        ChargingProgressStepName.WaitForAcceptChargingRequest ||
      currentChargingProgressStepName ===
        ChargingProgressStepName.FetchMeterReadings
    ) {
      color = COLORS.primaryColor;
    }

    if (currentStepState === ChargingProgressStepState.Failed) {
      color = COLORS.errorColor;
    }

    // consoleLog('Color Evaluation:', color, currentChargingProgressStepName, chargingProgressStepsMap[ChargingProgressStepName.StartCharging]?.stepState, currentStepState);

    StatusBar.setBackgroundColor(color);
    setBgColor(color);
    // set colors */
  }, [
    currentChargingProgressStepName,
    chargingProgressStepsMap[currentChargingProgressStepName]?.stepState,
  ]);

  // long poll for CP connections state
  useInterval(
    () => {
      checkIsConnected();
    },
    shouldContinueCPConnectedLongPolling ? 3000 : null
  );

  useEffect(() => {
    if (!cpConnected) {
      if (
        chargingProgressStepsMap[ChargingProgressStepName.ConnectCharger]
          .stepState === ChargingProgressStepState.InProgress
      ) {
        updateStep(ChargingProgressStepName.ConnectCharger, {
          stepState: ChargingProgressStepState.NotStarted,
        });
      } else if (
        [ChargingProgressStepState.Completed].includes(
          chargingProgressStepsMap[ChargingProgressStepName.StartCharging]
            .stepState
        )
      ) {
        goToChargingDetailsScreen(chargeTxnId as string);
      }
    } else {
      if (
        chargingProgressStepsMap[ChargingProgressStepName.ConnectCharger]
          .stepState === ChargingProgressStepState.InProgress
      )
        executeCurrentStep();
    }
  }, [cpConnected, chargeTxnId]);

  if (isEmpty(chargingProgressStepsMap)) {
    return <ScreenWrapper {...props}></ScreenWrapper>;
  }

  return (
    <ScreenWrapper
      {...props}
      style={{
        backgroundColor: bgColor,
      }}
    >
      <View style={styles.root}>
        <ScreenHeader
          navigation={navigation}
          style={{
            borderBottomWidth: 0,
            backgroundColor: COLORS.whiteColor,
          }}
          onBackPress={() => {
            navigation.navigate(SCREEN_NAMES.MapScreen as never);
          }}
        />

        <ScrollView
          style={{
            backgroundColor:
              currentChargingProgressStepName ===
              ChargingProgressStepName.FetchMeterReadings
                ? COLORS.primaryColor
                : bgColor,
            height: 200,
            overflow: "scroll",
          }}
        >
          <View style={styles.mainCont}>
            {/* CP not connected error */}
            {!cpConnected &&
              cpNotConnectedError &&
              chargingProgressStepsMap[ChargingProgressStepName.StartCharging]
                .stepState !== ChargingProgressStepState.Completed && (
                <View
                  style={{
                    marginTop: 120,
                    marginBottom: 10,
                  }}
                >
                  <RichError i18nError={cpNotConnectedError} />
                </View>
              )}

            {/* step primary view */}
            {!(
              !cpConnected &&
              cpNotConnectedError &&
              chargingProgressStepsMap[ChargingProgressStepName.StartCharging]
                .stepState !== ChargingProgressStepState.Completed
            ) && (
              <View
                style={{
                  marginTop: 30,
                  height: 275,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* NotStarted */}
                {(chargingProgressStepsMap[currentChargingProgressStepName]
                  ?.stepState === ChargingProgressStepState.NotStarted ||
                  chargingProgressStepsMap[currentChargingProgressStepName]
                    ?.stepState === ChargingProgressStepState.InProgress) && (
                  <>
                    {/* connect charger */}
                    {currentChargingProgressStepName ===
                      ChargingProgressStepName.ConnectCharger && (
                      <ConnectChargerStepPrimaryView
                        reconnectCharger={reconnectCharger}
                        t={t}
                      />
                    )}
                    {/* charging starting soon */}
                    {(currentChargingProgressStepName ===
                      ChargingProgressStepName.StartCharging ||
                      currentChargingProgressStepName ===
                        ChargingProgressStepName.WaitForAcceptChargingRequest) && (
                      <ChargingStartingSoonStepPrimaryView t={t} />
                    )}

                    {/* charging started */}
                    {currentChargingProgressStepName ===
                      ChargingProgressStepName.FetchMeterReadings && (
                      <ChargingStartedStepPrimaryView t={t} />
                    )}
                  </>
                )}

                {/* Completed */}
                {chargingProgressStepsMap[currentChargingProgressStepName]
                  ?.stepState === ChargingProgressStepState.Completed && (
                  <>
                    {(currentChargingProgressStepName ===
                      ChargingProgressStepName.ConnectCharger ||
                      currentChargingProgressStepName ===
                        ChargingProgressStepName.StartCharging ||
                      currentChargingProgressStepName ===
                        ChargingProgressStepName.WaitForAcceptChargingRequest ||
                      currentChargingProgressStepName ===
                        ChargingProgressStepName.FetchMeterReadings) && (
                      <ChargingStartedStepPrimaryView t={t} />
                    )}
                  </>
                )}

                {/* Failed */}
                {chargingProgressStepsMap[currentChargingProgressStepName]
                  ?.stepState === ChargingProgressStepState.Failed && (
                  <ChargingFailedStepPrimaryView t={t} />
                )}
              </View>
            )}

            {/* progress steps card */}
            {showStartingSoon ? (
              <View
                style={{
                  width: "100%",
                  marginTop: 200,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ProgressBar
                  width={"100%"}
                  completed={80}
                  bgColor={COLORS.primaryColor}
                  baseBgColor={COLORS.primaryLight}
                  transitionDuration={"1000"}
                  animateOnRender={true}
                  transitionTimingFunction={"linear"}
                  height={"8px"}
                  customLabel=""
                  labelColor={COLORS.primaryColor}
                />
              </View>
            ) : (
              <View
                style={{
                  ...StyleHelpers.themeBorder,
                  backgroundColor: COLORS.whiteColor,
                  width: "100%",
                  marginTop: 15,
                  maxHeight: 290,
                  paddingHorizontal: 20,
                  paddingVertical: 24,
                }}
              >
                <ScrollView
                  ref={scrollViewRef}
                  showsVerticalScrollIndicator={false}
                >
                  {chargingProgressSteps.map((stepName, index) => {
                    const step = chargingProgressStepsMap[stepName];
                    let description = step.notStartedDesc;
                    let descColor = COLORS.greyText;
                    let sepColor = COLORS.dividerColor;

                    if (
                      step.stepState === ChargingProgressStepState.InProgress
                    ) {
                      description = step.inProgressDesc;
                      descColor = COLORS.darkBlack;
                      sepColor = COLORS.darkBlack;
                    } else if (
                      step.stepState === ChargingProgressStepState.Completed
                    ) {
                      description = step.completedDesc;
                      descColor = COLORS.primaryColor;
                      sepColor = COLORS.primaryColor;
                    } else if (
                      step.stepState === ChargingProgressStepState.Failed
                    ) {
                      description = step.failedDesc || "Failed";
                      descColor = COLORS.brightRed;
                      sepColor = COLORS.brightRed;
                    }

                    return (
                      <>
                        {index !== 0 && (
                          <View
                            key={`sep-${index}`}
                            style={{
                              ...StyleHelpers.verticallyCenteredRow,
                              zIndex: 1,
                            }}
                          >
                            <View
                              style={{
                                flex: 0.2,
                                position: "relative",
                                height: 20,
                                ...StyleHelpers.totalCenter,
                              }}
                            >
                              <VerticalLine
                                style={{
                                  height: "100%",
                                  width: 3,
                                  backgroundColor: sepColor,
                                  borderRadius: 1,
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  position: "absolute",
                                }}
                              />
                            </View>
                            <View style={{ flex: 0.8 }} />
                          </View>
                        )}

                        <View
                          key={`${step.stepName}`}
                          style={{
                            ...StyleHelpers.verticallyCenteredRow,
                            minHeight: 60,
                            zIndex: 2,
                          }}
                        >
                          <View
                            style={{
                              flex: 0.2,
                              ...StyleHelpers.totalCenter,
                            }}
                          >
                            {step.stepState ===
                              ChargingProgressStepState.NotStarted && (
                              <View
                                style={{
                                  ...StyleHelpers.totalCenter,
                                  backgroundColor: COLORS.lightGrey,
                                  height: 40,
                                  width: 40,
                                  borderRadius: 40,
                                }}
                              >
                                <View
                                  style={{
                                    backgroundColor: COLORS.whiteColor,
                                    height: 15,
                                    width: 15,
                                    borderRadius: 15,
                                  }}
                                />
                              </View>
                            )}
                            {step.stepState ===
                              ChargingProgressStepState.InProgress && (
                              <View>
                                <ActivityIndicator
                                  color={COLORS.blackColor}
                                  size={45}
                                />
                              </View>
                            )}
                            {step.stepState ===
                              ChargingProgressStepState.Completed && (
                              <View
                                style={{
                                  height: 40,
                                  width: 40,
                                  ...StyleHelpers.totalCenter,
                                }}
                              >
                                <BlueCheckIcon height={120} width={120} />
                              </View>
                            )}
                            {step.stepState ===
                              ChargingProgressStepState.Failed && (
                              <View
                                style={{
                                  height: 40,
                                  width: 40,
                                  ...StyleHelpers.totalCenter,
                                }}
                              >
                                <CrossFilledIcon />
                              </View>
                            )}
                          </View>
                          <View
                            style={{
                              flex: 0.8,
                            }}
                          >
                            <CText
                              semiBold
                              size={14}
                              style={{
                                color: COLORS.blackColor,
                              }}
                            >
                              {description}
                            </CText>
                            {step.stepName ===
                              ChargingProgressStepName.FetchMeterReadings &&
                              chargingProgressStepsMap[step.stepName]
                                ?.stepState ===
                                ChargingProgressStepState.InProgress && (
                                <CText
                                  medium
                                  size={11}
                                  style={{
                                    color: COLORS.grey600,
                                  }}
                                >
                                  {t("Wait2to3minsForMeterValues")}
                                </CText>
                              )}
                          </View>
                        </View>
                      </>
                    );
                  })}
                </ScrollView>
              </View>
            )}
          </View>
          <ScreenBottomBar>
            {/* {!waitForBalanceCheck && ( */}
            <TouchableOpacity
              activeOpacity={0.7}
              style={styles.bottomLine}
              onPress={handleFacingIssuesPress}
            >
              <CText value={t("facingIssues?")} size={12} semiBold />
            </TouchableOpacity>
            {/* )} */}
          </ScreenBottomBar>
        </ScrollView>

        {!cpConnected &&
          cpNotConnectedError &&
          chargingProgressStepsMap[ChargingProgressStepName.StartCharging]
            .stepState !== ChargingProgressStepState.Completed && (
            <FullScreenOverlay
              style={{
                top: 310,
                backgroundColor:
                  chargingProgressStepsMap[currentChargingProgressStepName]
                    ?.stepState === ChargingProgressStepState.Failed
                    ? COLORS.errorTransparent04
                    : THEME_COLORS.bgColor + "90",
              }}
            />
          )}
      </View>
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  mainCont: {
    ...StyleHelpers.pageSpacing,
    paddingBottom: 20,
    flex: 1,
  },
  root: {
    ...StyleHelpers.root,
  },

  videoCont: {
    padding: 16,
    alignSelf: "center",
    borderRadius: 180,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: 180,
    width: 180,
    backgroundColor: THEME_COLORS.primaryBorder,
  },
  lottieCont: {
    borderRadius: 200,
    height: 180,
    width: 180,
    ...StyleHelpers.totalCenter,
  },
  bottomLine: {
    marginTop: 5,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.blackColor,
    alignSelf: "center",
  },
  currentActionText: {
    textAlign: "center",
    marginTop: 16,
    minHeight: 80,
  },
});
