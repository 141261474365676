import { Dimensions, PixelRatio } from "react-native-web";

export const rFont = (percent: any) => {
    const { height, width } = Dimensions.get("window");
    const standardLength = width > height ? width : height;
  
    const deviceHeight = standardLength;
  
    const heightPercent = (percent * deviceHeight) / 100;
    return Math.round(heightPercent);
  };
  
  export const horizontalSize = (widthPercent: any) => {
    const screenWidth = Dimensions.get("window").width;
    const elemWidth = parseFloat(widthPercent.toString());
    return PixelRatio.roundToNearestPixel((screenWidth * elemWidth) / 100);
  };
  
  // For height
  export const verticalSize = (heightPercent: any) => {
    if (heightPercent === 100) {
      return window.innerHeight;
    }
    
    const screenHeight = Dimensions.get("window").height;
    const elemHeight = parseFloat(heightPercent.toString());
    return PixelRatio.roundToNearestPixel((screenHeight * elemHeight) / 100);
  };