import AsyncStorage from '@react-native-async-storage/async-storage';
import { isArray } from 'lodash';
import { batch } from 'react-redux';
import { Dispatch } from 'redux';
import { LS_KEYS } from '../constants';
import { ErrorHandlerService } from './ErrorHandlerService';
import { CentralUser, PrimaryUser } from '../central-atoms/types/user';
import { LangCodes } from '../central-atoms/enums/generic/i18n/LangCodes';
import AuthAction from '../redux/reducer/auth/actions';
import { PaymentMethodType } from '../central-atoms/payment-methods/types/enum';
import { updateActivePaymentMethodType, updateActiveWallet } from '../actions/walletActions';
import { getCentralUserWallet } from '../helpers/walletHelpers';
import { setAppliedFilters } from '../actions/filtersActions';
import { resetStore, setAppInitialized, setUserAuthenticated } from '../actions/mainActions';
import { AppliedFilters } from '../central-atoms/filters/types';
export default class AuthenticationService {
  static getAuthToken() {
    return AsyncStorage.getItem(LS_KEYS.authToken)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: 'error'
          }
        });
      });
  }
  static setAuthToken(token: string) {
    return AsyncStorage.setItem(LS_KEYS.authToken, token).catch((e) => {
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: 'error'
        }
      });
    });
  }
  static removeAuthToken() {
    return AsyncStorage.removeItem(LS_KEYS.authToken).catch((e) => {
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: 'error'
        }
      });
    });
  }
  static async login(
    dispatch: Dispatch,
    args: {
      centralUser: CentralUser;
      primaryUser: PrimaryUser;
      fleetCode: string | null;
      accessCode: string | null;
      paymentMode: string | null;
      selectedLanguage: LangCodes;
      sessionToken?: string;
    }
  ) {
    const { centralUser, primaryUser, fleetCode, paymentMode, sessionToken } = args;
    const { setPartialLogin, setUserData, setToken, setCentralUserData, setActiveFleetOperatorOrgUser } = AuthAction;
    batch(() => {
      if (sessionToken) dispatch(setToken(sessionToken));
      dispatch(setUserData(primaryUser));
      dispatch(setCentralUserData(centralUser));
      dispatch(setPartialLogin(paymentMode === null));
      const activePaymentMethodType = centralUser.active_payment_method;
      if (activePaymentMethodType === PaymentMethodType.Wallet) {
        dispatch(updateActivePaymentMethodType(activePaymentMethodType));
        const wallet = getCentralUserWallet(centralUser);
        if (wallet) dispatch(updateActiveWallet(wallet));
      } else {
        dispatch(updateActivePaymentMethodType(activePaymentMethodType));
        dispatch(updateActiveWallet(null));
      }
      const orgArr = centralUser?.org_users;
      if (isArray(orgArr)) {
        orgArr.map((orgUser) => {
          if (fleetCode == orgUser?.org?.operator_code) {
            dispatch(setActiveFleetOperatorOrgUser(orgUser));
          }
        });
      }
      if (centralUser.preferences?.app_exp?.selected_cs_filters) {
        dispatch(setAppliedFilters(centralUser.preferences?.app_exp?.selected_cs_filters as AppliedFilters));
      }
      dispatch(setUserAuthenticated(true));
      dispatch(setAppInitialized(true));
    });
  }
  static async logout(dispatch: Dispatch) {
    batch(() => {
      dispatch(resetStore());
    });
    await AuthenticationService.removeAuthToken();
    await AsyncStorage.removeItem(LS_KEYS.paymentMode);
    await AsyncStorage.removeItem(LS_KEYS.enteredFleetCode);
    await AsyncStorage.removeItem(LS_KEYS.enteredAccessCode);
  }
}
