import { FC, ReactElement, useMemo } from "react";
import { FlatList, SectionList, StyleSheet, View } from "react-native";
import CText from "../../components/CText";
import FilterItem from "./FilterItem";
import { Filter, FilterGroup } from "../../../types";
import { AppliedFilters } from "../../../central-atoms/filters/types";
import { CSFilterGroup } from "../../../central-atoms/filters/enums";
import { COLORS } from "../../../config/colors";
import { horizontalSize } from "../../../functions/responsive";

enum SIZE {
  SMALL = "small",
  LARGE = "large",
}

type Props = {
  filterGroup: FilterGroup;
  filters: any;
  appliedFilters: AppliedFilters;
  onSelectFilter: (
    filter: Filter,
    isSelected: boolean,
    subGroup: string,
    group: CSFilterGroup
  ) => void;
};

const FilterSubgroups: FC<Props> = (props: Props) => {
  const { filters, onSelectFilter, filterGroup, appliedFilters } = props;

  const data = useMemo(() => {
    return filters.filter_subgroup.map((item: any) => {
      return {
        title: item.name,
        data: item.filters,
      };
    });
  }, [filters]);

  const renderList = (
    index = 0,
    listData: any[],
    subGroup = ""
  ): ReactElement | null => {
    if (index !== 0) return null;

    return (
      <FlatList
        ListHeaderComponent={() =>
          subGroup ? (
            <CText medium size={14} style={styles.sectionHeader}>
              {subGroup}
            </CText>
          ) : null
        }
        numColumns={2}
        data={listData}
        style={{
          marginBottom: 20,
        }}
        keyExtractor={(item: any) => item.value}
        renderItem={({ item }) => {
          const isSelected = !!appliedFilters?.[filterGroup.value]?.find(
            (filter) => filter === item.value
          );

          return (
            <FilterItem
              size={
                filterGroup.value === CSFilterGroup.Amenities
                  ? SIZE.SMALL
                  : SIZE.LARGE
              }
              item={item}
              isSelected={isSelected}
              onPress={() =>
                onSelectFilter(item, isSelected, subGroup, filterGroup.value)
              }
            />
          );
        }}
      />
    );
  };

  return (
    <View
      style={[
        styles.container,
        {
          width: horizontalSize(76),
        },
      ]}
    >
      <SectionList
        sections={data}
        keyExtractor={(item, index) => item + index}
        renderItem={({ section, index }) =>
          renderList(index, section.data as any[], section.title)
        }
        contentContainerStyle={styles.listContainer}
        stickySectionHeadersEnabled={false}
        ListFooterComponent={() =>
          filterGroup.value === CSFilterGroup.Brands ? <></> : null
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.whiteColor,
  },
  sectionHeader: {
    padding: 8,
  },
  listContainer: {
    paddingTop: 8,
    paddingBottom: 16,
    paddingHorizontal: 8,
    width: "100%",
    justifyContent: "space-between",
    alignContent: "center",
  },
});

export default FilterSubgroups;
