import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { isArray, isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Dimensions, StyleSheet, TouchableOpacity, View } from "react-native";
import { HandledError } from "../../../miscellaneous/types";
import { FleerOperator } from "../../../central-atoms/types/org";
import { batch, useDispatch } from "react-redux";
import SmoothPinCodeInput from "../SmoothPincodeInput";
import AuthAction from "../../../redux/reducer/auth/actions";
import { VerifyOperatorCodePayload } from "../../../central-atoms/types/requests/auth/org";
import AuthenticatedOrgRequestService from "../../../services/AuthenticatedOrgRequestService";
import { LS_KEYS, SCREEN_NAMES } from "../../../constants";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import AuthenticationService from "../../../services/AuthenticationService";
import { COLORS } from "../../../config/colors";
import CText from "../CText";
import ScreenBottomBar from "../ScreenBottomBar";
import CButton from "../CButton";
import { FontFamily } from "../../../config/typography";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { useTranslation } from "react-i18next";

type Props = {
  onVerificationSuccess?: (operatorCode: string) => void;
  onVerificationError?: (
    operatorCode: string,
    handledError?: HandledError
  ) => void;
  showLogoutButton?: boolean;
  fleetOperator: FleerOperator | null;
};
export default function EnterFleetOperatorCodeContent(props: Props) {
  const {
    onVerificationSuccess = () => {},
    onVerificationError = () => {},
    fleetOperator,
    showLogoutButton = false,
  } = props;

  const navigation = useNavigation<any>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const operatorCodeInputRef: any = useRef<SmoothPinCodeInput>();
  const {
    setUserData,
    setCentralUserData,
    setPartialLogin,
    setActiveFleetOperatorOrgUser,
  } = AuthAction;
  const [fleetCodeError, setFleetCodeError] = useState("");
  const [fleetCode, setFleetCode] = useState("");
  const [loading, setLoading] = useState(false);

  const verifyFleetCode = () => {
    if (!fleetCode) {
      setFleetCodeError(t("PleaseEnterOperatorCode"));
      shakeOtpInput();
      return;
    }

    setLoading(true);
    const data: VerifyOperatorCodePayload = {
      operator_code: fleetCode, // 559633 // 423809
    };
    if (fleetOperator?.id) {
      data.org_id = fleetOperator.id;
    }

    AuthenticatedOrgRequestService.verifyOperatorCode(data)
      .then(async ({ data }) => {
        setFleetCodeError("");
        batch(() => {
          dispatch(setUserData(data?.user));
          dispatch(setCentralUserData(data?.central_user));
          dispatch(setPartialLogin(false));
          const orgArr = data?.central_user?.org_users;
          if (isArray(orgArr)) {
            orgArr.map((orgUser) => {
              if (fleetCode == orgUser?.org?.operator_code) {
                dispatch(setActiveFleetOperatorOrgUser(orgUser));
              }
            });
          }
        });

        await AsyncStorage.setItem(LS_KEYS.enteredFleetCode, fleetCode);
        await AsyncStorage.setItem(LS_KEYS.enteredAccessCode, fleetCode);

        onVerificationSuccess(fleetCode);

        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        setFleetCodeError(e?.response?.data?.msg || e?.response?.data?.message);
        const handledError = ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
        onVerificationError(fleetCode, handledError);
      });
  };

  const logoutUser = () => {
    AuthenticationService.logout(dispatch);
    navigation.navigate(SCREEN_NAMES.SelectLanguageScreen);
  };

  const shakeOtpInput = () => {
    operatorCodeInputRef.current?.animate({
      animation: "shake",
      duration: 600,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      operatorCodeInputRef.current?.focus();
    }, 500);
  }, []);

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View style={styles.root}>
        <View
          style={{
            ...StyleHelpers.pageSpacing,
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              alignItems: "flex-end",
              marginTop: 15,
            }}
          >
            {showLogoutButton && (
              <TouchableOpacity
                activeOpacity={0.7}
                onPress={() => {
                  if (window.confirm(t("AreYouSureYouWantToLogout")) === true) {
                    logoutUser();
                  } else {
                  }
                }}
                style={{
                  borderBottomWidth: 2,
                  borderBottomColor: COLORS.blackColor,
                }}
              >
                <CText value={t("logOut")} size={12} semiBold />
              </TouchableOpacity>
            )}
          </View>

          <View>
            <CText value={t("enterFleet")} size={26} semiBold />
            <CText
              value={t("operatorCodeLC")}
              size={26}
              semiBold
              style={{ marginTop: -12 }}
            />
            {fleetOperator && !isEmpty(fleetOperator) && (
              <CText
                size={16}
                style={{
                  marginVertical: 10,
                }}
              >
                {t("operatorCodeUpdatedEnterNewOne")}
              </CText>
            )}
            <View style={{ marginTop: 12, justifyContent: "center" }}>
              <SmoothPinCodeInput
                ref={operatorCodeInputRef}
                cellSpacing={16}
                codeLength={6}
                cellSize={Dimensions.get("window").width / 9}
                maskDelay={0}
                textStyle={styles.textStyle}
                cellStyle={[
                  styles.cellStyle,
                  {
                    borderColor: !!fleetCodeError
                      ? COLORS.errorColor
                      : COLORS.darkBlack,
                  },
                ]}
                cellStyleFocused={[
                  styles.cellStyleFocused,
                  {
                    borderColor: !!fleetCodeError
                      ? COLORS.errorColor
                      : COLORS.darkBlack,
                  },
                ]}
                value={fleetCode}
                onTextChange={(code: string) => setFleetCode(code)}
                restrictToNumbers
                autoFocus={true}
              />
            </View>

            {!!fleetCodeError && (
              <CText
                value={`${fleetCodeError}`}
                size={12}
                style={{
                  color: COLORS.errorColor,
                  marginTop: 4,
                }}
              />
            )}

            <View
              style={{
                marginTop: 64,
              }}
            >
              <CText
                value={`${t("pleaseContact")}`}
                size={12}
                style={{ marginBottom: 8 }}
              />
            </View>
          </View>
        </View>

        <ScreenBottomBar
          style={{
            zIndex: 1,
          }}
        >
          <CButton
            title={t("continue")}
            onPress={() => {
              verifyFleetCode();
            }}
            loading={loading}
          />
        </ScreenBottomBar>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    ...StyleHelpers.root,
  },
  cellStyle: {
    borderRadius: 8,
    backgroundColor: COLORS.whiteColor,
    borderWidth: 1,
    borderColor: COLORS.darkBlack,
    borderBottomColor: COLORS.blackColor,
    paddingTop: 4,
  },
  textStyle: {
    fontSize: 17,
    fontWeight: "bold",
    marginTop: -5,
    color: COLORS.blackColor,
    fontFamily: FontFamily.Poppins_Regular,
  },
  cellStyleFocused: {
    borderWidth: 3,
    borderColor: COLORS.darkBlack,
  },
  rowStyle: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  modalCont: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: THEME_COLORS.bgColor,
  },
  logoStyle: {
    height: 56,
    width: "90%",
  },
});
