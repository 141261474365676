import moment from "moment";

enum ChartUnit {
    HOUR = 'hour',
    DAY = 'day',
    MONTH = 'month',
    WEEK = 'week',
};

export const getTimeDiff = (startTimestamp: string, endTimestamp: string) => {

    // ref: https://stackoverflow.com/a/18624295/4331993
    const ms = moment(endTimestamp).diff(moment(startTimestamp));
    const d = moment.duration(ms);

    const diffDays: string | number = d.days();
    let diffHours: string | number = d.hours();
    let diffMins: string | number = d.minutes();
    const diffTotalSecs: string | number = d.seconds();
    let diffSecs: string | number = diffTotalSecs % 60;

    diffHours = diffHours < 10 ? `0${diffHours}` : diffHours;
    diffMins = diffMins < 10 ? `0${diffMins}` : diffMins;
    diffSecs = diffSecs < 10 ? `0${diffSecs}` : diffSecs;

    return {
        days: diffDays,
        hours: diffHours,
        minutes: diffMins,
        seconds: diffSecs,
        totalSeconds: diffTotalSecs,
    };
};

// eslint-disable-next-line no-promise-executor-return
export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));


// Helper function to pad single-digit numbers with a leading zero
export function padZero(number) {
    return (number < 10 ? '0' : '') + number;
}

export function formatDuration(seconds: number): string {
    // Calculate hours, minutes, and remaining seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format the duration as HH:mm:ss
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`;
}

// convert utc time to local time
export function convertUTCToLocalTime(input: string) {
    return moment.utc(input).local().format('YYYY-MM-DDTHH:mm:ss');
}

// calculate the time difference between two dates, and returns unit of time
export function calculateRunningAverageUnit(times: string[]): string {
    if (!times?.[0] || !times?.[1]) {
        return ChartUnit.HOUR;
    }

    const startTime = new Date(times[0]);
    const endTime = new Date(times[1]);

    const timeDiff = endTime.getTime() - startTime.getTime();

    const dayDiff = timeDiff / (1000 * 3600 * 24);
    const monthDiff = dayDiff / 30;
    const hourDiff = timeDiff / (1000 * 3600);

    if (monthDiff >= 1) {
        if (monthDiff > 1) {
            return `${monthDiff.toFixed(0)} ${ChartUnit.MONTH}s`;
        }
        return ChartUnit.MONTH;
    }

    if (dayDiff >= 1) {
        // eslint-disable-next-line eqeqeq
        if (dayDiff == 7) {
            return ChartUnit.WEEK;
        }
        if (dayDiff > 1) {
            return `${dayDiff.toFixed(0)} ${ChartUnit.DAY}s`;
        }
        return ChartUnit.DAY;
    }

    if (hourDiff > 1) {
        return `${hourDiff.toFixed(0)} ${ChartUnit.HOUR}s`;
    }

    return ChartUnit.HOUR;
};

export function getTimeDifferenceInDays(a: string, b: string): number {
    return (new Date(b).getTime() - new Date(a).getTime()) / (1000 * 3600 * 24);
}

/**
 * This function uses moment.js to validate if a string is a full ISO formatted date time string.
 * It will return true if it includes both date and time, false otherwise
 * 
 * @param dateTimeString the ISO String of any date, eg: '2024-09-21T12:34:56.000Z'
 * @returns boolean
 */
export function isValidISODateTimeString(dateTimeString: string): boolean {
    return moment(dateTimeString, moment.ISO_8601, true).isValid() && dateTimeString.includes('T');
}
