// 7.2
export enum AuthorizationStatus {
    Accepted = 'Accepted',
    Blocked = 'Blocked',
    Expired = 'Expired',
    Invalid = 'Invalid',
    ConcurrentTx = 'ConcurrentTx'
};

// 7.3
export enum AvailabilityStatus {
    Accepted = 'Accepted',
    Rejected = 'Rejected',
    Scheduled = 'Scheduled'
};

// 7.4
export enum AvailabilityType {
    Inoperative = 'Inoperative',
    Operative = 'Operative'
};

// 7.5
export enum CancelReservationStatus {
    Accepted = 'Accepted',
    Rejected = 'Rejected'
};

// 7.6
export enum ChargePointErrorCode {
    ConnectorLockFailure = 'ConnectorLockFailure',
    EVCommunicationError = 'EVCommunicationError',
    GroundFailure = 'GroundFailure',
    HighTemperature = 'HighTemperature',
    InternalError = 'InternalError',
    LocalListConflict = 'LocalListConflict',
    NoError = 'NoError',
    OtherError = 'OtherError',
    OverCurrentFailure = 'OverCurrentFailure',
    OverVoltage = 'OverVoltage',
    PowerMeterFailure = 'PowerMeterFailure',
    PowerSwitchFailure = 'PowerSwitchFailure',
    ReaderFailure = 'ReaderFailure',
    ResetFailure = 'ResetFailure',
    UnderVoltage = 'UnderVoltage',
    WeakSignal = 'WeakSignal'
};

// 7.7
export enum ChargePointStatus {
    Available = 'Available',
    Preparing = 'Preparing',
    Charging = 'Charging',
    SuspendedEVSE = 'SuspendedEVSE',
    SuspendedEV = 'SuspendedEV',
    Finishing = 'Finishing',
    Reserved = 'Reserved',
    Unavailable = 'Unavailable',
    Faulted = 'Faulted'
};


// 7.20
export enum ClearCacheStatus {
    Accepted = 'Accepted',
    Rejected = 'Rejected'
};

// 7.21
export enum ClearChargingProfileStatus {
    Accepted = 'Accepted',
    Unknown = 'Unknown'
};

// 7.22
export enum ConfigurationStatus {
    Accepted = 'Accepted',
    Rejected = 'Rejected',
    RebootRequired = 'RebootRequired',
    NotSupported = 'NotSupported'
};

// 7.23
export enum DataTransferStatus {
    Accepted = 'Accepted',
    Rejected = 'Rejected',
    UnknownMessageId = 'UnknownMessageId',
    UnknownVendorId = 'UnknownVendorId'
};

// 7.24
export enum DiagnosticsStatus {
    Idle = 'Idle',
    Uploaded = 'Uploaded',
    UploadFailed = 'UploadFailed',
    Uploading = 'Uploading'
};

// 7.25
export enum FirmwareStatus {
    Downloaded = 'Downloaded',
    DownloadFailed = 'DownloadFailed',
    Downloading = 'Downloading',
    Idle = 'Idle',
    InstallationFailed = 'InstallationFailed',
    Installing = 'Installing',
    Installed = 'Installed'
};

// 7.26
export enum GetCompositeScheduleStatus {
    Accepted = 'Accepted',
    Rejected = 'Rejected'
};


// 7.34
export enum Phase {
    L1 = 'L1',
    L2 = 'L2',
    L3 = 'L3',
    N = 'N',
    L1N = 'L1-N',
    L2N = 'L2-N',
    L3N = 'L3-N',
    L1L2 = 'L1-L2',
    L2L3 = 'L2-L3',
    L3L1 = 'L3-L1'
};

// 7.35
export enum ReadingContext {
    InterruptionBegin = 'Interruption.Begin',
    InterruptionEnd = 'Interruption.End',
    Other = 'Other',
    SampleClock = 'Sample.Clock',
    SamplePeriodic = 'Sample.Periodic',
    TransactionBegin = 'Transaction.Begin',
    TransactionEnd = 'Transaction.End',
    Trigger = 'Trigger'
};

// 7.36
export enum Reason {
    DeAuthorized = 'DeAuthorized',
    EmergencyStop = 'EmergencyStop',
    EVDisconnected = 'EVDisconnected',
    HardReset = 'HardReset',
    Local = 'Local',
    Other = 'Other',
    PowerLoss = 'PowerLoss',
    Reboot = 'Reboot',
    Remote = 'Remote',
    SoftReset = 'SoftReset',
    UnlockCommand = 'UnlockCommand'
};

// 7.37
export enum RecurrencyKindType {
    Daily = 'Daily',
    Weekly = 'Weekly'
};

// 7.38
export enum RegistrationStatus {
    Accepted = 'Accepted',
    Pending = 'Pending',
    Rejected = 'Rejected'
};

// 7.39
export enum RemoteStartStopStatus {
    Accepted = 'Accepted',
    Rejected = 'Rejected'
};

// 7.40
export enum ReservationStatus {
    Accepted = 'Accepted',
    Faulted = 'Faulted',
    Occupied = 'Occupied',
    Rejected = 'Rejected',
    Unavailable = 'Unavailable'
};

// 7.41
export enum ResetStatus {
    Accepted = 'Accepted',
    Rejected = 'Rejected'
};

// 7.42
export enum ResetType {
    Hard = 'Hard',
    Soft = 'Soft'
};


// 7.30
export enum Location {
    Body = 'Body',
    Cable = 'Cable',
    EV = 'EV',
    Inlet = 'Inlet',
    Outlet = 'Outlet'
};

// 7.31
export enum Measurand {
    CurrentExport = 'Current.Export',
    CurrentImport = 'Current.Import',
    CurrentOffered = 'Current.Offered',
    EnergyActiveExportRegister = 'Energy.Active.Export.Register',
    EnergyActiveImportRegister = 'Energy.Active.Import.Register',
    EnergyReactiveExportRegister = 'Energy.Reactive.Export.Register',
    EnergyReactiveImportRegister = 'Energy.Reactive.Import.Register',
    EnergyActiveExportInterval = 'Energy.Active.Export.Interval',
    EnergyActiveImportInterval = 'Energy.Active.Import.Interval',
    EnergyReactiveExportInterval = 'Energy.Reactive.Export.Interval',
    EnergyReactiveImportInterval = 'Energy.Reactive.Import.Interval',
    Frequency = 'Frequency',
    PowerActiveExport = 'Power.Active.Export',
    PowerActiveImport = 'Power.Active.Import',
    PowerFactor = 'Power.Factor',
    PowerOffered = 'Power.Offered',
    PowerReactiveExport = 'Power.Reactive.Export',
    PowerReactiveImport = 'Power.Reactive.Import',
    RPM = 'RPM',
    SoC = 'SoC',
    Temperature = 'Temperature',
    Voltage = 'Voltage',
};

// 7.32
export enum MessageTrigger {
    BootNotification = 'BootNotification',
    DiagnosticsStatusNotification = 'DiagnosticsStatusNotification',
    FirmwareStatusNotification = 'FirmwareStatusNotification',
    Heartbeat = 'Heartbeat',
    MeterValues = 'MeterValues',
    StatusNotification = 'StatusNotification'
};

export const MessageTriggerMap = {
    [MessageTrigger.BootNotification]: 'BootNotification',
    [MessageTrigger.DiagnosticsStatusNotification]: 'DiagnosticsStatusNotification',
    [MessageTrigger.FirmwareStatusNotification]: 'FirmwareStatusNotification',
    [MessageTrigger.Heartbeat]: 'Heartbeat',
    [MessageTrigger.MeterValues]: 'MeterValues',
    [MessageTrigger.StatusNotification]: 'StatusNotification',
};
// 7.9
export enum ChargingProfileKindType {
    Absolute = 'Absolute',
    Recurring = 'Recurring',
    Relative = 'Relative'
};

// 7.10
export enum ChargingProfilePurposeType {
    ChargePointMaxProfile = 'ChargePointMaxProfile',
    TxDefaultProfile = 'TxDefaultProfile',
    TxProfile = 'TxProfile'
};

// 7.11
export enum ChargingProfileStatus {
    Accepted = 'Accepted',
    Rejected = 'Rejected',
    NotSupported = 'NotSupported'
};

// 7.12
export enum ChargingRateUnitType {
    W = 'W',
    A = 'A'
};


// 7.44
export enum TriggerMessageStatus {
    Accepted = 'Accepted',
    Rejected = 'Rejected',
    NotImplemented = 'NotImplemented'
};

// 7.45
export enum UnitOfMeasure {
    Wh = 'Wh',
    kWh = 'kWh',
    varh = 'varh',
    kvarh = 'kvarh',
    W = 'W',
    kW = 'kW',
    VA = 'VA',
    kVA = 'kVA',
    var = 'var',
    kvar = 'kvar',
    A = 'A',
    V = 'V',
    Celsius = 'Celsius',
    Fahrenheit = 'Fahrenheit',
    K = 'K',
    Percent = 'Percent'
};

// 7.46
export enum UnlockStatus {
    Unlocked = 'Unlocked',
    UnlockFailed = 'UnlockFailed',
    NotSupported = 'NotSupported'
};

// 7.47
export enum UpdateStatus {
    Accepted = 'Accepted',
    Failed = 'Failed',
    NotSupported = 'NotSupported',
    VersionMismatch = 'VersionMismatch'
};

// 7.48
export enum UpdateType {
    Differential = 'Differential',
    Full = 'Full'
};

// 7.49
export enum ValueFormat {
    Raw = 'Raw',
    SignedData = 'SignedData'
};
