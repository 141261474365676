import { useTranslation } from "react-i18next";
import React from "react";
import { Image, TouchableOpacity, View } from "react-native";
import styles from "./styles";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { COLORS } from "../../../config/colors";
import CText from "../CText";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";

export default function LanguageSelectionCard(props: any) {
  const { t } = useTranslation();
  const {
    btnText,
    transTitle,
    langText,
    onPress = () => {},
    selected = false,
    selectedTxt = t("selected"),
    image,
    name,
  } = props;

  const textColor = selected
    ? THEME_COLORS.cardSelectedTextColor
    : THEME_COLORS.cardUnselectedTextColor;

  return (
    <TouchableOpacity
      activeOpacity={1}
      style={[
        styles.cont_card,
        {
          backgroundColor: selected
            ? THEME_COLORS.cardSelectedBgColor
            : COLORS.whiteColor,
        },
      ]}
      onPress={onPress}
    >
      {transTitle && langText ? (
        <View>
          <CText
            value={transTitle}
            size={14}
            semiBold
            style={{
              color: textColor,
              fontWeight: "600",
            }}
          />
          <CText
            value={langText}
            size={12}
            style={{
              color: COLORS.secondaryBlackColor,
            }}
          />
        </View>
      ) : (
        <View
          style={{
            ...styles.view,
            paddingHorizontal: image ? 0 : 16,
          }}
        >
          {image ? (
            <Image source={image} resizeMode="contain" style={styles.img} />
          ) : (
            <CText value={name} size={14} semiBold />
          )}
        </View>
      )}
      {selected ? (
        <View style={styles.rowStyle}>
          <CheckIcon fill={textColor} height={13} width={13} />
          <CText
            value={selectedTxt}
            size={12}
            semiBold
            style={{
              color: textColor,
              marginStart: 8,
              fontWeight: "600",
            }}
          />
        </View>
      ) : (
        <View style={styles.btnCont}>
          <CText
            value={btnText}
            size={12}
            semiBold
            style={{
              color: COLORS.whiteColor,
              fontWeight: "600",
              // marginBottom: isIOS() ? 3 : 0,
            }}
          />
        </View>
      )}
    </TouchableOpacity>
  );
}
