import { ReactNode } from "react";
import { KeyboardAvoidingView, ViewStyle } from "react-native";
import {
  SafeAreaView,
  SafeAreaViewProps,
} from "react-native-safe-area-context";
import { StyleHelpers } from "../constants/style-helpers";
import { getKeyboardAvoidingViewBehavior } from "../helpers";
import { ScreenComponent } from "../types/components";

type Props = ScreenComponent &
  SafeAreaViewProps & {
    children?: ReactNode;
    style?: ViewStyle;
  };
const ScreenWrapper = (props: Props) => {
  const { children = null, style = {}, edges = ["top", "bottom"] } = props;

  return (
    <SafeAreaView edges={edges} style={[StyleHelpers.safeAreaView, {}, style]}>
      <KeyboardAvoidingView
        style={{
          flexGrow: 1,
        }}
        behavior={getKeyboardAvoidingViewBehavior()}
      >
        {children}
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default ScreenWrapper;
