import { StyleSheet } from 'react-native';
import { COLORS } from '../../../config/colors';
import { verticalSize } from '../../../functions/responsive';
import { THEME_COLORS } from '../../../theme/constants/themeColors';

export default StyleSheet.create({
  root: {
    backgroundColor: THEME_COLORS.bgColor,
    justifyContent: 'space-between',
    height: verticalSize(100)
  },
  mainCont: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  batteryPerCont: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginTop: 48
  },
  whiteBlockCont: {
    backgroundColor: COLORS.whiteColor,
    borderWidth: 1,
    // borderBottomWidth: 3,
    borderColor: COLORS.primaryLight,
    padding: 12,
    borderRadius: 16,
    minHeight: 75,
    flex: 1
  },
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  divider: {
    height: 1,
    backgroundColor: '#ffffff60',
    marginVertical: 16
  },
  bottomCont: {
    backgroundColor: COLORS.darkBlack,
    padding: 16
  },
  stopBtn: {
    backgroundColor: COLORS.brightRed,
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center'
  }
});
