import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import { TouchableOpacity, Vibration, View, useWindowDimensions } from "react-native";
import { batch, useDispatch } from "react-redux";
import styles from "./styles";
import { ScreenComponent } from "../../../types";
import { useActiveFleetOperatorOrgUser } from "../../../hooks";
import { useActiveWallet } from "../../../hooks/walletHooks";
import { MixedChargingStation } from "../../../central-atoms/types/charging/charging-station";
import { ChargePoint } from "../../../central-atoms/types/charging/charge-points";
import {
  setSelectedChargePoint,
  setSelectedChargingStation,
  setSelectedMixedChargingStation
} from "../../../actions/doChargingActions";
import { LS_KEYS, SCREEN_NAMES } from "../../../constants";
import { AccessibilityType } from "../../../central-atoms/accessibility/enums";
import { getStoredCSAccessCodeForId } from "../../../helpers/charginStationHelpers";
import AuthenticatedOrgRequestService from "../../../services/AuthenticatedOrgRequestService";
import { AccessCodeType } from "../../../central-atoms/access-code/enums";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import { isFleetWallet, isSharedWallet } from "../../../helpers/walletHelpers";
import ChargePointRequestService from "../../../services/ChargePointRequestService";
import ScreenHeader from "../../../miscellaneous/components/ScreenHeader";
import CText from "../../components/CText";
import { COLORS } from "../../../config/colors";
import { ReactComponent as ScanAreaIcon } from "../../../assets/icons/scan-area.svg";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { ReactComponent as ScanAreaBorderIcon } from "../../../assets/icons/scan-area-border.svg";
import ScreenBottomBar from "../../../miscellaneous/components/ScreenBottomBar";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { QrReader } from "react-qr-reader";
import { useIsFocused } from "@react-navigation/native";
import { verticalSize } from "../../../functions/responsive";
import { useTranslation } from "react-i18next";

type Props = ScreenComponent & {};
export default function ChargePointQRScannerScreen(props: Props) {
  const { navigation } = props;
  const { t } = useTranslation();
  const [isMount, setIsMount] = useState(true);
  const dispatch = useDispatch();
  const winDimensions = useWindowDimensions();
  const isFocused = useIsFocused();
  const activeFleetOperatorOrgUser = useActiveFleetOperatorOrgUser();
  const activeWallet = useActiveWallet();
  const [waitForChargerData, setWaitForChargerData] = useState(false);

  useEffect(() => {
    setIsMount(isFocused);
  }, [isFocused]);

  const handleOnRead = (e: any) => {
    const qrCodePayload: any = e?.text ?? "";
    if (!waitForChargerData && !!qrCodePayload) {
      Vibration.vibrate([2, 300, 50, 100]);
      setWaitForChargerData(true);
      getChargerData(qrCodePayload)
        .then(() => {
          setWaitForChargerData(false);
        })
        .catch((e: any) => {
          setWaitForChargerData(false);
        });
    }
  };

  const goToStartChargingScreen = async (
    ucs: MixedChargingStation,
    selectedCP: ChargePoint,
    shouldPromptForOrgVerification: boolean
  ) => {
    const cp = ucs?.central_charging_station.charge_points.find((cp) => cp.id === selectedCP.id);

    if (cp) {
      batch(() => {
        dispatch(setSelectedChargingStation(ucs?.charging_station ?? null));
        dispatch(setSelectedMixedChargingStation(ucs ?? null));
        dispatch(setSelectedChargePoint(cp));
      });

      navigation.navigate(
        SCREEN_NAMES.PreStartChargingSelectionScreen as never,
        {
          // slideIndex: 2,
          shouldPromptForOrgVerification: shouldPromptForOrgVerification
        } as never
      );
    } else {
      navigation.navigate(
        SCREEN_NAMES.PreStartChargingSelectionScreen as never,
        {
          // slideIndex: 2,
          shouldPromptForOrgVerification: shouldPromptForOrgVerification
        } as never
      );
    }
  };

  const handleStartCharging = async (ucs: MixedChargingStation, cp: ChargePoint) => {
    try {
      const enteredAccessCode = await AsyncStorage.getItem(LS_KEYS.enteredAccessCode);
      if (cp?.accessibility === AccessibilityType.Private) {
        const storedCSAccessCode = await getStoredCSAccessCodeForId(`${ucs?.central_charging_station?.id}`);
        if (storedCSAccessCode) {
          try {
            if (storedCSAccessCode) {
              await AuthenticatedOrgRequestService.verifyAccessCode({
                access_code: storedCSAccessCode,
                code_type: AccessCodeType.ChargingStationAccessCode,
                charging_station_id: `${ucs?.central_charging_station?.id}`
              });

              goToStartChargingScreen(ucs, cp, false);
            } else {
              goToStartChargingScreen(ucs, cp, true);
            }
          } catch (e) {
            ErrorHandlerService.handleError(e, {
              openToast: false
            });

            goToStartChargingScreen(ucs, cp, true);
          }
        } else {
          goToStartChargingScreen(ucs, cp, true);
        }
      } else {
        if (isFleetWallet(activeWallet) || isSharedWallet(activeWallet)) {
          if (enteredAccessCode) {
            await AuthenticatedOrgRequestService.verifyAccessCode({
              access_code: enteredAccessCode,
              org_id: activeFleetOperatorOrgUser.org_id
            });

            goToStartChargingScreen(ucs, cp, false);
          } else {
            goToStartChargingScreen(ucs, cp, true);
          }
        } else {
          goToStartChargingScreen(ucs, cp, false);
        }
      }
    } catch (e) {
      setTimeout(() => {
        goToStartChargingScreen(ucs, cp, true);
      }, 2000);

      ErrorHandlerService.handleError(e, {
        openToast: false
      });
    }
  };

  const getChargerData = (chargerID: string) => {
    return ChargePointRequestService.fetchChargePointByQuery({ q: chargerID })
      .then(({ data }) => {
        const chargePoint =
          data.central_charging_station?.charge_points?.find(
            (cp) => cp.qr_code_id === chargerID || cp.public_uid === chargerID
          ) ?? data.central_charging_station?.charge_points[0];
        handleStartCharging(data, chargePoint);
      })
      .catch((e: any) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const winWidth = winDimensions.width;
  const winHeight = winDimensions.height;
  const scanAreaWidth = Math.min(winHeight * 0.4, winWidth * 0.77);
  const scanAreaHeight = scanAreaWidth;
  const scanAreaMarkerSize = scanAreaWidth + 55;
  const scanAreaHeightHalf = scanAreaHeight / 2;
  const sideGaps = (winWidth - scanAreaWidth) / 2 + 4;
  const topHalfHeight = winHeight / 2 - scanAreaHeightHalf;
  const stickerStyleWrprOpacity = 0.6;

  return (
    <View style={styles.root}>
      <ScreenHeader
        navigation={navigation}
        style={{
          position: "sticky" as any,
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: COLORS.whiteColor,
          borderBottomWidth: 0,
          zIndex: 100
          // flex: 1,
          // width: "100%"
        }}
      />

      {/* only instacharge QR text */}
      <View
        style={{
          position: "absolute",
          top: topHalfHeight / 2 - 20,
          zIndex: 1,
          width: "100%",
          alignItems: "center"
        }}>
        <CText
          style={{
            textAlign: "center",
            width: "80%",
            color: COLORS.whiteColor,
            lineHeight: 29
          }}
          size={15}
          semiBold>
          {t("Scan charger point QR code")}
        </CText>
      </View>

      {/* main content */}
      <View
        style={{
          flex: 1,
          backgroundColor: COLORS.blackColor,
          position: "relative"
        }}>
        {isMount ? (
          <QrReader
            constraints={{
              facingMode: "environment"
            }}
            onResult={(result: any, error) => {
              if (!!result) {
                handleOnRead(result);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            videoContainerStyle={{ height: "100%", width: "auto" }}
            videoStyle={{ height: verticalSize(100), width: "auto" }}
          />
        ) : null}
        {/* scan area markers */}
        <ScanAreaIcon
          fill={"transparent"}
          stroke={COLORS.whiteColor}
          height={scanAreaMarkerSize}
          width={scanAreaMarkerSize}
          style={{
            zIndex: 2,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: `translateX(${-scanAreaMarkerSize / 2}px) translateY(${-scanAreaMarkerSize / 2}px)`
          }}
        />

        {/* sticker style wrapper */}
        <View
          style={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            height: "100%",
            // height: verticalSize(100),
            ...StyleHelpers.totalCenter
          }}>
          <View
            style={{
              height: 0,
              width: "100%"
            }}>
            {/* top half */}
            <View
              style={{
                opacity: stickerStyleWrprOpacity,
                backgroundColor: THEME_COLORS.qrCodeTopHalfBg,
                height: winHeight / 2,
                width: "100%",
                position: "absolute",
                bottom: scanAreaHeightHalf
              }}
            />
            {/* left gap */}
            <View
              style={{
                opacity: stickerStyleWrprOpacity,
                height: scanAreaHeight,
                width: sideGaps,
                position: "absolute",
                top: -scanAreaHeightHalf,
                left: 0
              }}>
              <View
                style={{
                  flex: 0.5,
                  backgroundColor: THEME_COLORS.qrCodeTopHalfBg
                }}
              />
              <View
                style={{
                  flex: 0.5,
                  backgroundColor: THEME_COLORS.qrCodeBottomHalfBg
                }}
              />
            </View>
            {/* right gap */}
            <View
              style={{
                opacity: stickerStyleWrprOpacity,
                height: scanAreaHeight + 0.5,
                width: sideGaps,
                position: "absolute",
                top: -scanAreaHeightHalf,
                right: 0
              }}>
              <View
                style={{
                  flex: 0.5,
                  backgroundColor: THEME_COLORS.qrCodeTopHalfBg
                }}
              />
              <View
                style={{
                  flex: 0.5,
                  backgroundColor: THEME_COLORS.qrCodeBottomHalfBg
                }}
              />
            </View>
            {/* bottom half */}
            <View
              style={{
                opacity: stickerStyleWrprOpacity,
                backgroundColor: THEME_COLORS.qrCodeBottomHalfBg,
                height: winHeight / 3,
                width: "100%",
                position: "absolute",
                top: scanAreaHeightHalf
              }}
            />

            {/* scan area border */}
            <View
              style={{
                position: "absolute",
                width: "100%",
                zIndex: 12,
                top: -scanAreaHeightHalf - 2.5,
                ...StyleHelpers.totalCenter
              }}>
              <ScanAreaBorderIcon height={scanAreaWidth + 5} width={scanAreaWidth + 5} fill={COLORS.whiteColor} />
            </View>
          </View>
        </View>
      </View>
      <ScreenBottomBar
        style={{
          ...styles.bottomCont,
          position: "sticky" as any,
          backgroundColor: THEME_COLORS.qrCodeBottomHalfBg,
          bottom: 0,
          right: 0,
          left: 0,
          paddingVertical: 20
        }}>
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.btnCont}
          onPress={() => {
            navigation.navigate(SCREEN_NAMES.EnterChargePointIdScreen);
          }}>
          <CText value={t("enterChargerID")} size={11} medium />
        </TouchableOpacity>
      </ScreenBottomBar>
    </View>
  );
}
