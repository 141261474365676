import { useNavigation, useRoute } from "@react-navigation/native";
import { AxiosError } from "axios";
import { uniqueId } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { batch, useDispatch } from "react-redux";
import { AppliedFilters } from "../../../central-atoms/filters/types";
import {
  useAppliedFilters,
  useAppliedFiltersCount,
  useCSFilters,
  useCustomFiltersApplied,
  useMapCoords,
  useStoredUserPreferences,
  useUsersPhysicalLocation,
} from "../../../hooks";
import {
  AvailableFilters,
  Filter,
  FilterGroup,
  FiltersScreenRouteParams,
  MapsScreenRouteParams,
} from "../../../types";
import {
  getDefaultFilters,
  getNewAppliedFilters,
} from "../../../helpers/filtersHelpers";
import {
  setAppliedFilters,
  setAppliedFiltersChangeVersion,
  setCustomFiltersApplied,
  setDefaultAppliedFilters,
} from "../../../actions/filtersActions";
import AuthenticatedUserService from "../../../services/AuthenticatedUserService";
import { FiltersService } from "../../../services/FilterService";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import { FetchChargingStationsNearbyReqPayload } from "../../../central-atoms/types/requests/charging/charging-station";
import { ChargingStationRequestService } from "../../../miscellaneous/services/ChargingStationRequestService";
import {
  updateMapsNearbyUCS,
  updateUCSMasterMap,
} from "../../../actions/chargingActions";
import { SCREEN_NAMES } from "../../../constants";
import CSFiltersHeader from "../../components/Filter/CSFiltersHeader";
import FilterGroups from "../../components/Filter/FilterGroups";
import FilterSubgroups from "../../components/Filter/FilterSubgroups";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { COLORS } from "../../../config/colors";
import { verticalSize } from "../../../functions/responsive";
import BottomContent from "../../components/Filter/BottomContent";
import { CSFilterGroup } from "../../../central-atoms/filters/enums";
import FiltersRequestService from "../../../services/FiltersRequestService";

type Props = {};

const FiltersScreen = (props: Props) => {
  const route = useRoute();
  const routeParams = route.params as FiltersScreenRouteParams;
  const context = routeParams?.context ?? "maps_screen";
  const filterGroupToOpen = routeParams?.filterGroupToOpen ?? "";

  const appliedFilters = useAppliedFilters();
  const csFilters = useCSFilters();
  const currentMapCoords = useMapCoords();
  const customFiltersApplied = useCustomFiltersApplied();
  const storedUserPreferences = useStoredUserPreferences();
  const usersPhysicalLocation = useUsersPhysicalLocation();

  const dispatch = useDispatch();
  const navigation: any = useNavigation();

  const [csFilterGroups, setCSFilterGroups] = useState<FilterGroup[]>([]);
  const [availableFilters, setAvailableFilters] = useState<
    AvailableFilters | any
  >({});
  const [activeFilterGroup, setActiveFilterGroup] =
    useState<FilterGroup | null>(null);

  const [waitForUpdatedCount, setWaitForUpdatedCount] =
    useState<boolean>(false);
  const [waitForNearbyChargingStations, setWaitForNearbyChargingStations] =
    useState<boolean>(false);
  const [csCount, setCSCount] = useState<number>(0);

  const appliedFiltersCount = useAppliedFiltersCount();

  const onSelectFilter = (
    filter: Filter,
    isSelected: boolean,
    subGroup: string,
    group: CSFilterGroup
  ): void => {
    const newAppliedFilters = getNewAppliedFilters(
      appliedFilters,
      filter,
      isSelected,
      group
    );

    batch(() => {
      dispatch(setAppliedFilters(newAppliedFilters));
      dispatch(setAppliedFiltersChangeVersion());
    });
    syncPreferences(newAppliedFilters);
  };

  const syncPreferences = (newAppliedFilters: AppliedFilters) => {
    AuthenticatedUserService.syncPreferences(
      newAppliedFilters,
      storedUserPreferences,
      dispatch
    );
  };

  const getAvailableCSCount = useCallback((): void => {
    const paramFilters = FiltersService.getAppliedFiltersParams(appliedFilters);

    setWaitForUpdatedCount(true);
    FiltersRequestService.fetchCSFiltersCountV3(paramFilters)
      .then((response) => {
        setCSCount(response.data);
        setWaitForUpdatedCount(false);
      })
      .catch((error: AxiosError) => {
        setWaitForUpdatedCount(false);
        ErrorHandlerService.handleError(error, {
          toastOptions: {
            type: "error",
          },
        });
      });
  }, [appliedFilters]);

  const onPressShowCS = (): void => {
    if (context === "maps_screen") {
      const paramFilters =
        FiltersService.getAppliedFiltersParams(appliedFilters);
      const params: FetchChargingStationsNearbyReqPayload = {
        lat: 21.0686228, // India Lat
        lng: 82.7525294, // India Lng
        radius: csCount <= 10 ? 3500 : currentMapCoords.radius || 1000, // India Radius or Map Radius
        user_lat: usersPhysicalLocation?.latitude,
        user_lng: usersPhysicalLocation?.longitude,
        ...paramFilters,
      };

      setWaitForNearbyChargingStations(true);
      ChargingStationRequestService.fetchChargingStationsNearbyV3(params)
        .then((response: any) => {
          batch(() => {
            dispatch(updateUCSMasterMap(response.data));
            dispatch(updateMapsNearbyUCS(response.data));
            dispatch(setCustomFiltersApplied(appliedFiltersCount > 0));
          });
          setWaitForNearbyChargingStations(false);
          navigation.navigate(
            SCREEN_NAMES.MapScreen as any as never,
            {
              type: "filter",
              changeId: uniqueId(),
            } as MapsScreenRouteParams as never
          );
        })
        .catch((e: any) => {
          ErrorHandlerService.handleError(e, {
            toastOptions: {
              type: "error",
            },
          });
          setWaitForNearbyChargingStations(false);
        });
    } else if (context === "search_screen") {
      navigation.navigate(
        SCREEN_NAMES.SearchScreen as any as never,
        {
          type: "filter",
          changeId: uniqueId(),
        } as any as never
      );
    } else {
      navigation.goBack();
    }
  };

  const onPressClear = (): void => {
    dispatch(setDefaultAppliedFilters());
    syncPreferences(getDefaultFilters());
  };

  useEffect(() => {
    getAvailableCSCount();
  }, [getAvailableCSCount]);

  useEffect(() => {
    if (csFilters) {
      let filterGroupItem = csFilters.filter_group[0];
      if (filterGroupToOpen) {
        filterGroupItem =
          csFilters.filter_group.find((fg) => fg.value === filterGroupToOpen) ||
          csFilters.filter_group[0];
      }
      setActiveFilterGroup(filterGroupItem);
      setCSFilterGroups(csFilters.filter_group);
      setAvailableFilters(csFilters.available_filters);
    }
  }, [csFilters]);

  const setDefaultSelectedFilters = (): void => {
    if (csFilters) {
      csFilters.filter_group.forEach((filterGroup: FilterGroup) => {
        csFilters.available_filters[filterGroup.value].filter_subgroup.forEach(
          (subGroup: any) => {
            subGroup.filters.forEach((filter: Filter) => {
              if (filter.selected) {
                onSelectFilter(filter, false, subGroup.name, filterGroup.value);
              }
            });
          }
        );
      });
    }
  };

  useEffect(() => {
    if (!customFiltersApplied) {
      setDefaultSelectedFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.container}>
      <CSFiltersHeader
        onClearPress={onPressClear}
        style={{
          borderBottomColor: COLORS.primaryLight,
          position: "sticky" as any,
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
        }}
        filterCount={appliedFiltersCount}
      />
      <View style={styles.contentContainer}>
        <FilterGroups
          groups={csFilterGroups}
          selectedGroup={activeFilterGroup}
          onSelectGroup={(item: FilterGroup) => {
            setActiveFilterGroup(item);
          }}
        />
        {activeFilterGroup && availableFilters && (
          <FilterSubgroups
            filterGroup={activeFilterGroup}
            filters={availableFilters[activeFilterGroup.value as CSFilterGroup]}
            onSelectFilter={onSelectFilter}
            appliedFilters={appliedFilters}
          />
        )}
      </View>
      <BottomContent
        csCount={csCount}
        onButtonPress={onPressShowCS}
        isLoading={waitForUpdatedCount || waitForNearbyChargingStations}
        isDisabled={csCount === 0}
        context={context}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: THEME_COLORS.bgColor,
    height: verticalSize(100),
  },
  contentContainer: {
    flex: 1,
    flexDirection: "row",
  },
});

export default FiltersScreen;
