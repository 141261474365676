import { AxiosResponse } from "axios";
import { FetchFullChargingStationResponse, FetchLastUsedChargingStationResponse, FetchSearchSuggestionsResponse } from "../../central-atoms/types/responses/charging/charging-station";
import HttpRequestService from "../../config/HttpRequestService";
import { getGlobalVar } from "../helpers";
import { GLOBAL_VARS } from "../../constants";
import { API_BASE_PATH, PULSE_API_HOST } from "../../config/setting";
import { FetchChargingStationsNearbyReqPayload, FetchChargingStationsSuggestionsReqPayload, FetchLastUsedCSQueryParams, FetchNearbyCSQueryParams } from "../../central-atoms/types/requests/charging/charging-station";
import { ChargingStation, MixedChargingStation, PostHomePageChargingStationsReqPayload } from "../../central-atoms/types/charging/charging-station";
import { AddUserLocationReqPayload } from "../../central-atoms/types/requests/users";
import { FetchNearestChargingStationsResponse } from "../../central-atoms/charging/charging-stations/types";
import { ControllabilityType } from "../../central-atoms/enums/charge-point";
import { ChargePoint } from "../../central-atoms/types/charging/charge-points";


export class ChargingStationRequestService {
    static async fetchChargingStation(csId: string | number): Promise<FetchFullChargingStationResponse> {
        const httpRequest: HttpRequestService =  getGlobalVar(GLOBAL_VARS.httpRequest);
        return await httpRequest.getInstance()
            .get(`${API_BASE_PATH}/charging_station/${csId}`)
            .then((response: any) => {
                return response.data;
            });
    }
    static async fetchRecentChargingSession(params: FetchLastUsedCSQueryParams): Promise<FetchLastUsedChargingStationResponse> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return await httpRequest.getInstance()
            .get(`${API_BASE_PATH}/recent_charging_session`, {
                params,
            })
            .then((response: any) => {
                return response.data;
            });
    }

    static async fetchChargingStationsNearby(payload: FetchChargingStationsNearbyReqPayload): Promise<{data: MixedChargingStation[]}> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return await httpRequest.getInstance()
            .put(`${API_BASE_PATH}/central_charging_stations/nearby`, payload)
            .then((response: AxiosResponse) => {
                return response.data;
            });
    }

    static fetchChargingStationsNearbyV3(payload: FetchChargingStationsNearbyReqPayload): Promise<{ data: MixedChargingStation[] }> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .post(`/v3/charging_stations/nearby`, payload)
            .then((response: AxiosResponse) => {
                return response.data;
            });
    }

    static async fetchChargingStationsNearbyUEI(payload: FetchChargingStationsNearbyReqPayload): Promise<{data: MixedChargingStation[]}> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return await httpRequest.getInstance()
            .put(`/v3/unified_charging_stations/nearby`, payload)
            .then((response: AxiosResponse) => {
                return response.data;
            });
    }

    static async fetchNearbyChargingStations(params: FetchNearbyCSQueryParams): Promise<any> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return await httpRequest.getInstance()
            .get(`${API_BASE_PATH}/central_charging_stations`, {params})
            .then((response: any) => {
                return response.data;
            });
    }

    static fetchNearbyChargingStationsV3(payload: PostHomePageChargingStationsReqPayload): Promise<any> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .post(`/v3/charging_stations`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static async fetchNearestChargingStations(payload: AddUserLocationReqPayload): Promise<FetchNearestChargingStationsResponse> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return await httpRequest.getInstance()
            .post(`${API_BASE_PATH}/central_charging_stations/nearest`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static fetchNearestChargingStationsV3(payload: AddUserLocationReqPayload): Promise<FetchNearestChargingStationsResponse> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .post(`/v3/charging_stations/nearest`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static async fetchSuggestions(params: FetchChargingStationsSuggestionsReqPayload): Promise<FetchSearchSuggestionsResponse> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return await httpRequest.getInstance()
            .get(`${API_BASE_PATH}/central_charging_stations/suggestions`, {
                params,
            })
            .then((response: any) => {
                return response.data;
            });
    }

    static fetchSuggestionsV3(payload: FetchChargingStationsSuggestionsReqPayload): Promise<FetchSearchSuggestionsResponse> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .post(`/v3/charging_stations/suggestions`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static async fetchChargePointByQRCodeId(qr_code_id: string): Promise<{data: ChargingStation}> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return await httpRequest.getInstance()
            .get(`${API_BASE_PATH}/app/charging_station_by_qr_code_id/${qr_code_id}`)
            .then((response: any) => {
                return response.data;
            });
    }

    static async fetchChargingStationByQuery(qr_code_id: string | number): Promise<FetchFullChargingStationResponse> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return await httpRequest.getInstance()
            .get(`${API_BASE_PATH}/charging_station/query/${qr_code_id}`)
            .then((response: any) => {
                return response.data;
            });
    }

    
    static async fetchLatLngForPlaceId(placeId: string) {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return await httpRequest.getInstance()
            .get(`${PULSE_API_HOST}/api/get_lat_lng/${placeId}`)
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }

    static getExclusionList(csIds: Array<number | string>): string {
        return btoa(Array.from(new Set(csIds)).join(','));
    }

    static getControllableCPs(cps: ChargePoint[]): ChargePoint[] {
        return cps?.filter((cp: ChargePoint) => (
            cp?.controllability?.includes(ControllabilityType.OCPPControl) || 
            cp?.controllability?.includes(ControllabilityType.OCPIControl) || 
            cp?.controllability?.includes(ControllabilityType.APIControl)
        ));
    }
}