import { useTranslation } from "react-i18next";
import { Image, TouchableHighlight, View, ViewStyle } from "react-native";
import { AccessibilityType } from "../../../../../central-atoms/accessibility/enums";
import { ChargePointStatus } from "../../../../../central-atoms/enums/ocpp/v1.6/generic";
import { ChargePoint } from "../../../../../central-atoms/types/charging/charge-points";
import { MergedConnector } from "../../../../../central-atoms/types/charging/connectors";
import CText from "../../../../components/CText";
import { COLORS } from "../../../../../config/colors";
import { DEFAULT_PLUG_IMG } from "../../../../../config/setting";
import Pill from "../../../../../miscellaneous/components/Pill";
import { StyleHelpers } from "../../../../../miscellaneous/constants/style-helpers";
import { THEME_COLORS } from "../../../../../theme/constants/themeColors";
import styles from "./styles";
import CPConnectorStatusChip from "../ConnectorStatusChip";
import { CheckIcon, LockIcon } from "../../../../../icons";
import { horizontalSize } from "../../../../../functions/responsive";

type Props = {
  unifiedCPConnector: MergedConnector;
  chargePoint: ChargePoint;
  name?: string;
  selected?: boolean;
  showStatus?: boolean;
  isSelectable: boolean;
  onPress?: () => void;
  inuse?: string;
  style?: ViewStyle;
};

export default function CPConnectorCard(props: Props) {
  const { t } = useTranslation();

  const {
    unifiedCPConnector: { cp_connector, cs_connector },
    chargePoint,
    name = "",
    selected,
    showStatus = true,
    isSelectable,
    onPress = () => {},
    style = {},
  } = props;

  const canSelect =
    cp_connector?.status === ChargePointStatus.Available ||
    cp_connector?.status === ChargePointStatus.Preparing;

  const showPowerRating =
    !!cp_connector.power_rating && cp_connector.power_rating !== "0";

  return (
    <TouchableHighlight
      activeOpacity={1}
      underlayColor={
        isSelectable && canSelect ? COLORS.grey50 : COLORS.whiteColor
      }
      style={{
        backgroundColor: THEME_COLORS.cardUnselectedBgColor,
        ...StyleHelpers.themeBorder,
        borderColor: selected ? COLORS.darkblue : COLORS.primaryLight,
        paddingHorizontal: 10,
        paddingTop: 16,
        paddingBottom: 12,
        alignItems: "center",
        justifyContent: "space-between",
        ...style,
      }}
      onPress={() => {
        if (isSelectable && canSelect) {
          onPress();
        }
      }}
    >
      <>
        <View style={{ width: "100%", justifyContent: "center" }}>
          {/* qr code id */}
          {!!chargePoint?.qr_code_id && (
            <CText
              value={chargePoint?.qr_code_id}
              size={10}
              semiBold
              style={{
                alignSelf: "center",
                color: selected ? COLORS.grey700 : COLORS.greyText,
                textAlign: "center",
                maxWidth: "60%",
                fontWeight: "600",
              }}
            />
          )}
          {/* name */}
          {!!name && (
            <CText
              value={name}
              size={9}
              semiBold
              style={{
                alignSelf: "center",
                color: selected ? COLORS.grey700 : COLORS.greyText,
                textAlign: "center",
                maxWidth: "60%",
                fontWeight: "600",
              }}
            />
          )}
          <View style={{ alignItems: "center" }}>
            <View
              style={{
                padding: 16,
                borderWidth: 1,
                borderColor: selected ? COLORS.cyanColor : COLORS.lightGrey,
                borderRadius: 100,
                marginTop: 12,
                backgroundColor: COLORS.whiteColor,
              }}
            >
              <Image
                source={{
                  uri: cs_connector?.connector?.image_url || DEFAULT_PLUG_IMG,
                }}
                style={{
                  height: 38,
                  width: 38,
                  borderRadius: 6,
                }}
                resizeMode="contain"
              />
            </View>
            {showStatus && (
              <CPConnectorStatusChip
                status={cp_connector.status}
                style={{
                  marginTop: -12,
                }}
              />
            )}
          </View>
        </View>

        <View>
          {/* connector type */}
          <CText
            size={14}
            semiBold
            style={{
              marginTop: 24,
              color: COLORS.blackColor,
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            {cp_connector.type}
          </CText>
          {/* price */}
          <View style={{ flexDirection: "row", ...StyleHelpers.totalCenter }}>
            <CText
              size={12}
              style={{
                color: selected ? COLORS.grey700 : COLORS.greyText,
                textAlign: "center",
              }}
            >
              {showPowerRating && `${cp_connector.power_rating} kW`}
              {showPowerRating && !!cp_connector.price && ` · `}
              {!!cp_connector.price && cp_connector.price}
            </CText>
          </View>

          {isSelectable && canSelect && (
            <View
              style={{ marginTop: 16, marginBottom: 8, alignItems: "center" }}
            >
              {selected ? (
                <View
                  style={[
                    styles.rowStyle,
                    {
                      paddingVertical: 4,
                      alignItems: "center",
                      backgroundColor: COLORS.dividerGrey,
                      borderRadius: 8,
                      width: horizontalSize(35),
                      justifyContent: "center",
                    },
                  ]}
                >
                  <CheckIcon
                    fill={COLORS.blackColor}
                    style={{
                      height: 12,
                      width: 12,
                    }}
                  />

                  <CText
                    value={t("selected")}
                    size={12}
                    semiBold
                    style={{
                      color: COLORS.blackColor,
                      marginLeft: 8,
                      marginTop: 2,
                      textAlign: "center",
                    }}
                  />
                </View>
              ) : (
                <View style={[styles.btnCont]}>
                  <CText
                    value={t("select")}
                    size={12}
                    semiBold
                    style={{
                      color: COLORS.whiteColor,
                      marginTop: 2,
                      textAlign: "center",
                    }}
                  />
                </View>
              )}
            </View>
          )}
        </View>

        {chargePoint?.accessibility === AccessibilityType.Private && (
          <Pill
            flag={t("Private")}
            style={{
              backgroundColor: COLORS.cyan400,
              top: -14,
            }}
            textStyle={{
              color: COLORS.blackColor,
            }}
            icon={
              <LockIcon
                fill={COLORS.blackColor}
                height={12}
                width={12}
                style={{
                  marginRight: 6,
                }}
              />
            }
          />
        )}
      </>
    </TouchableHighlight>
  );
}
