import { byDate } from "../../central-atoms/functions/array";
import { Wallet, WalletConsumer } from "../../central-atoms/types/wallet";
import { SharedWallet } from "../../central-atoms/wallets/types";
import { Action } from "../../miscellaneous/types";
import { INITIAL_STATE } from "../../store/defaultStore";
import { REMOVE_SHARED_WALLET_FROM_ACTIVE_WALLET, REMOVE_WALLET_CONSUMER, UPDATE_ACTIVE_PAYMENT_METHOD_TYPE, UPDATE_ACTIVE_WALLET, UPDATE_FLEET_WALLETS, UPDATE_PERSONAL_WALLETS, UPDATE_SHARED_WALLETS, UPDATE_SINGLE_WALLET_CONSUMER, UPDATE_WALLET_CONSUMERS } from "../../types";

const WalletReducer = (state = INITIAL_STATE.wallet, action: Action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_ACTIVE_PAYMENT_METHOD_TYPE:
            return {
                ...state,
                data: {
                    ...state.data,
                    activePaymentMethodType: payload.activePaymentMethodType,
                },
            };
        
        case UPDATE_ACTIVE_WALLET:
            return {
                ...state,
                data: {
                    ...state.data,
                    activeWallet: payload.activeWallet,
                },
            };

        case REMOVE_SHARED_WALLET_FROM_ACTIVE_WALLET: {
            const { sharedWalletId } = payload;

            const updatedWallet = { ...state.data.activeWallet };
            const index = updatedWallet.shared_wallets.findIndex((sw: SharedWallet) => sw.id === sharedWalletId);
            if (index !== -1) {
                updatedWallet.shared_wallets.splice(index, 1);
            }
            
            return {
                ...state,
                data: {
                    ...state.data,
                    activeWallet: updatedWallet,
                },
            };
        }

        case UPDATE_FLEET_WALLETS:
            return {
                ...state,
                data: {
                    ...state.data,
                    fleetWallets: payload.wallets,
                },
            };

        case UPDATE_PERSONAL_WALLETS:
            return {
                ...state,
                data: {
                    ...state.data,
                    personalWallets: payload.wallets,
                },
            };

        case UPDATE_SHARED_WALLETS: {
            const updatedSharedWallets = payload.wallets.map((sw: Wallet) => {
                return {
                    ...sw,
                    is_shared: true,
                }
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    sharedWallets: updatedSharedWallets,
                },
            };
        }

        case UPDATE_SINGLE_WALLET_CONSUMER: {
            const { walletId, sharedWalletId, sharedWallet } = payload;

            const updatedSharedWallets = [...(state.data.walletConsumers[walletId] ?? [])];
            const index = updatedSharedWallets.findIndex((sw) => sw.id === sharedWalletId);
            if (index !== -1) {
                updatedSharedWallets[index] = sharedWallet;
            }
            
            return {
                ...state,
                data: {
                    ...state.data,
                    walletConsumers: {
                        ...state.data.walletConsumers,
                        [walletId]: updatedSharedWallets,
                    },
                },
            };
        }
            
        case REMOVE_WALLET_CONSUMER: {
            const { walletId, sharedWalletId } = payload;

            const updatedSharedWallets = [...(state.data.walletConsumers[walletId] ?? [])];
            const index = updatedSharedWallets.findIndex((sw) => sw.id === sharedWalletId);
            if (index !== -1) {
                updatedSharedWallets.splice(index, 1);
            }
            
            return {
                ...state,
                data: {
                    ...state.data,
                    walletConsumers: {
                        ...state.data.walletConsumers,
                        [walletId]: updatedSharedWallets,
                    },
                },
            };
        }

        case UPDATE_WALLET_CONSUMERS: {
            const { walletId, walletConsumers } = payload;
            let updatedWalletConsumersList = [...(state.data.walletConsumers[walletId] ?? [])];
            walletConsumers.forEach((wc: WalletConsumer) => {
                const index = updatedWalletConsumersList.findIndex((i) => i.id === wc.id);
                if (index === -1) {
                    // add
                    updatedWalletConsumersList.push(wc);
                }
                else {
                    // update
                    updatedWalletConsumersList[index] = wc;
                }
            });

        updatedWalletConsumersList = updatedWalletConsumersList.sort(byDate('created_on'));


            return {
                ...state,
                data: {
                    ...state.data,
                    walletConsumers: {
                        ...state.data.walletConsumers,
                        [walletId]: updatedWalletConsumersList,
                    },
                },
            };
        }

        default:
            return state;
    }
}

export default WalletReducer;