import { StyleSheet } from "react-native";
import { COLORS } from "../../../config/colors";
import { verticalSize } from "../../../functions/responsive";

export default StyleSheet.create({
  root: {
    height: verticalSize(100),
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative"
  },
  rowStyle: {
    flexDirection: "row",
    alignItems: "center"
  },
  btnCont: {
    backgroundColor: COLORS.whiteColor,
    borderRadius: 12,
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: COLORS.blackColor,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 1
  },
  bottomCont: {
    // width: "100%",
    borderTopEndRadius: 12,
    borderTopStartRadius: 12,
    backgroundColor: COLORS.skyBlue,
    marginTop: -15
    // position: 'absolute',
    // bottom: 0,
  },
  flashBtnCont: {
    padding: 8,
    paddingHorizontal: 16,
    position: "absolute",
    bottom: 140,
    alignSelf: "center",
    backgroundColor: COLORS.darkBlack,
    // borderWidth: 1,
    // borderColor: COLORS.whiteColor,
    borderRadius: 100,
    flexDirection: "row",
    alignItems: "center"
  }
});
