import {
  TouchableHighlightProps,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { THEME_STYLES } from "../../theme/constants/themeStyles";
import CountBadge from "./CountBadge";
import { COLORS } from "../../config/colors";
import { verticalSize } from "../../functions/responsive";

type Props = {
  icon: any;
  touchableHighlightProps?: TouchableHighlightProps;
  style?: ViewStyle;
  iconColor?: string;
  btnContStyles?: ViewStyle;
  onPress?: () => void;
  showIndicator?: boolean;
  indicatorText?: string;
};

const IconButton = (props: Props) => {
  const {
    icon,
    style = {},
    touchableHighlightProps = {},
    onPress = () => {},
    btnContStyles = {},
    showIndicator = false,
    indicatorText = "",
  } = props;

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[
        {
          alignSelf: "flex-start",
        },
        style,
      ]}
      onPress={onPress}
    >
      <View>
        <View
          style={{
            backgroundColor: COLORS.whiteColor,
            borderRadius: 7,
            borderColor: COLORS.primaryLight,
            borderWidth: 1,
            flexDirection: "row",
            alignItems: "center",
            alignSelf: "flex-start",
            paddingVertical: 6,
            paddingHorizontal: 9,
            height: verticalSize(4),
            zIndex: 9,
            ...THEME_STYLES.buttonBorder,
            ...btnContStyles,
          }}
        >
          <View>{icon}</View>
        </View>
        <View
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            bottom: -1,
            left: 0,
            right: 0,
            zIndex: 0,
            borderRadius: 10,
            alignSelf: "flex-start",
          }}
        />
      </View>
      {showIndicator && (
        <CountBadge
          count={indicatorText}
          style={{
            top: -8,
            right: -8,
          }}
        />
      )}
    </TouchableOpacity>
  );
};

export default IconButton;
