import { isArray } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import {
  Image,
  ScrollView,
  TextProps,
  TouchableHighlight,
  View,
  ViewStyle,
} from "react-native";
import { AccessibilityType } from "../../../central-atoms/accessibility/enums";
import { MediaType } from "../../../central-atoms/enums/generic/file-upload";
import { CentralMedia, Coords } from "../../../central-atoms/types";
import { MixedChargingStation } from "../../../central-atoms/types/charging/charging-station";
import { useUsersPhysicalLocation } from "../../../hooks";
import Pill from "../../../miscellaneous/components/Pill";
import styles from "./styles";
import { COLORS } from "../../../config/colors";
import CText from "../CText";
import ChargingStationService from "../../../services/ChargingStationService";
import CButton from "../CButton";
import { ReactComponent as DC001Icon } from "../../../assets/icons/dc-001.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock-icon.svg";
import { ReactComponent as NavigateIcon } from "../../../assets/icons/navigate.svg";
import { ReactComponent as PowerIcon } from "../../../assets/icons/power_icon.svg";
import { ReactComponent as RupeeIcon } from "../../../assets/icons/rupee.svg";
import { ReactComponent as ScanQRIcon } from "../../../assets/icons/scan-qr.svg";
import { useTranslation } from "react-i18next";
import { Media } from "../../../central-atoms/media/types";

type Props = {
  mixedChargingStation: MixedChargingStation;
  type?: "startCharging" | "navigate";
  coords: Coords;
  showPrimaryActionButton?: boolean;
  showPower?: boolean;
  showPrice?: boolean;
  showConnectors?: boolean;
  cardType?: "saved";
  onViewChargerPress?: () => void;
  onSavePress?: () => void;
  onStartChargingPress?: () => void;
  style?: ViewStyle;
  source?: string;
};
export default function ChargingStationCard(props: Props) {
  const {
    mixedChargingStation,
    cardType,
    coords,
    showPrimaryActionButton = false,
    showConnectors = true,
    showPower = true,
    showPrice = true,
    onViewChargerPress = () => {},
    onStartChargingPress = () => {},
    onSavePress = () => {},
    style = {},
    source = undefined,
  } = props;
  const { t } = useTranslation();
  const { central_charging_station, charging_station } = mixedChargingStation;
  const usersPhysicalLocation = useUsersPhysicalLocation();

  const { name, address, favorited } = charging_station;

  const { available, last_used_time, power, price, connector_types } =
    central_charging_station ?? {};

  const [controllableCPsNum, setControllableCPsNum] = useState<number | null>(
    null
  );

  const [brandImg, setBrandImg] = useState<CentralMedia | Media | undefined | null>(null);

  const hasControllableCPs =
    controllableCPsNum !== null && controllableCPsNum > 0;
  const distanceOffset = 400;
  const canShowConnectors =
    isArray(connector_types) && connector_types.length > 0;
  const canShowPower = !!power;
  const canShowPrice = !!price;

  const TopChip = ({
    text,
    color,
    borderColor = COLORS.greenBorderColor,
  }: any) => {
    return (
      <View style={[styles.chipCont, { borderColor: borderColor }]}>
        <CText
          value={text}
          size={10}
          medium
          style={{ color: color, marginTop: 2, fontWeight: "500" }}
        />
      </View>
    );
  };

  const availabilityMetrics = useMemo(() => {
    let text = t("unknown"),
      color = COLORS.errorColor,
      borderColor = COLORS.errorBorderColor;

    if (central_charging_station?.availability === "Available") {
      text = t("available");
      color = COLORS.greenColor;
      borderColor = COLORS.greenBorderColor;
    } else if (
      central_charging_station?.availability === "Preparing" ||
      central_charging_station?.availability === "Charging" ||
      central_charging_station?.availability === "Finishing"
    ) {
      text = t("occupied");
      color = COLORS.blue500;
      borderColor = COLORS.blue100;
    } else if (central_charging_station?.availability === "Unavailable") {
      text = t("unavailable");
      color = COLORS.errorColor;
      borderColor = COLORS.errorBorderColor;
    }

    return {
      text,
      color,
      borderColor,
    };
  }, [central_charging_station?.availability]);

  useEffect(() => {
    const img = central_charging_station?.brand?.medias?.find(
      (m: any) => m.type === MediaType.Logo
    );
    setBrandImg(img);
  }, [central_charging_station?.brand?.medias]);

  useEffect(() => {
    const num =
      ChargingStationService.getControllableCPs(
        central_charging_station?.charge_points
      )?.length ?? null;
    setControllableCPsNum(num);
  }, [central_charging_station?.charge_points]);

  const handleOpenDirections = () => {
    const sourceLatitude = usersPhysicalLocation.latitude;
    const sourceLongitude = usersPhysicalLocation.longitude;

    const destinationLatitude = coords.latitude;
    const destinationLongitude = coords.longitude;
    const source = `${sourceLatitude},${sourceLongitude}`;
    const destination = `${destinationLatitude},${destinationLongitude}`;

    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${source}&destination=${destination}`;

    window.open(googleMapsUrl, "_blank");
  };

  return (
    <View style={[styles.cont, style]}>
      <TouchableHighlight
        underlayColor={COLORS.offWhite}
        activeOpacity={1}
        onPress={() => {
          onViewChargerPress();
        }}
      >
        <>
          {/* Top chips */}
          {cardType !== "saved" && (
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{
                marginBottom: 8,
              }}
            >
              {/* distance */}
              {!!charging_station.distance && (
                <TopChip
                  text={
                    charging_station.distance <= 1000
                      ? `${charging_station.distance} m`
                      : `${Number(
                          charging_station.distance / 1000
                        ).toFixed()} km`
                  }
                  color={COLORS.darkBlack}
                  borderColor={COLORS.dividerColor}
                />
              )}
              {/* real time status not available for all */}
              {controllableCPsNum === 0 && (
                <TopChip
                  text={t("realtimeStatusNotAvailable")}
                  color={COLORS.darkBlack}
                  borderColor={COLORS.dividerColor}
                />
              )}
              {/* real time status not available for some */}
              {controllableCPsNum !== null &&
                controllableCPsNum > 0 &&
                Number(central_charging_station?.charge_points?.length) -
                  controllableCPsNum >
                  0 && (
                  <TopChip
                    text={t("realtimeStatusNotAvailableForSome")}
                    color={COLORS.darkBlack}
                    borderColor={COLORS.dividerColor}
                  />
                )}
              {/* available/unavailable */}
              {controllableCPsNum !== 0 && available !== undefined && (
                <TopChip
                  text={availabilityMetrics.text}
                  color={COLORS.primaryColor}
                  borderColor={COLORS.primaryLight}
                />
              )}
              {/* last used tag */}
              {!!last_used_time && (
                <TopChip
                  text={`${t("lastUsed")} ${moment(last_used_time).fromNow()}`}
                  color={COLORS.primaryColor}
                  borderColor={COLORS.dividerColor}
                />
              )}
            </ScrollView>
          )}

          <View
            style={[
              styles.rowStyle,
              {
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginBottom: 8,
              },
            ]}
          >
            {/* name and address */}
            <View style={{ flex: 1 }}>
              <CText
                value={name?.trim()}
                size={14}
                semiBold
                style={{
                  ...styles.mt8,
                  height: source === "map_screen" ? 30 : "auto",
                  fontWeight: "600",
                }}
                textProps={
                  {
                    ...(source === "map_screen"
                      ? { numberOfLines: 2, ellipsizeMode: "tail" }
                      : {}),
                  } as TextProps
                }
              />
              <CText
                value={address?.trim()}
                size={12}
                style={{
                  color: COLORS.greyText,
                  height: source === "map_screen" ? 50 : "auto",
                }}
                textProps={
                  {
                    ...(source === "map_screen"
                      ? { numberOfLines: 2, ellipsizeMode: "tail" }
                      : {}),
                  } as TextProps
                }
              />
              {/* includes private chargers */}
              {central_charging_station?.accessibility.includes(
                AccessibilityType.Private
              ) && (
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Pill
                    flag={t("IncludesPrivateChargers")}
                    style={{
                      backgroundColor: COLORS.primaryColor,
                      borderColor: COLORS.primaryLight,
                      position: "relative",
                      top: 0,
                      borderBottomWidth: 1,
                      marginTop: 6,
                      paddingTop: 4,
                      paddingBottom: 4,
                    }}
                    textStyle={{
                      color: COLORS.whiteColor,
                      fontWeight: "600",
                    }}
                    icon={
                      <LockIcon
                        fill={COLORS.whiteColor}
                        height={12}
                        width={12}
                        style={{
                          marginRight: 6,
                        }}
                      />
                    }
                  />
                </View>
              )}
            </View>

            {/* brand image */}
            {!!brandImg && (
              <View style={styles.iconCont}>
                <Image
                  source={{
                    uri: brandImg.full_url,
                  }}
                  style={styles.iconImg}
                  resizeMode={"contain"}
                />
              </View>
            )}
          </View>

          {((showConnectors && canShowConnectors) ||
            (showPower && canShowPower) ||
            (showPrice && canShowPrice)) && (
            <View>
              <View style={styles.divider} />

              <View
                style={[
                  styles.rowStyle,
                  {
                    justifyContent: "space-around",
                    paddingVertical: 4,
                  },
                ]}
              >
                {showPower && canShowPower && (
                  <View style={styles.detailBlock}>
                    <View style={styles.greyIconCirculerCont}>
                      <PowerIcon
                        fill={COLORS.blackColor}
                        height={15}
                        width={15}
                      />
                    </View>
                    <CText
                      value={power}
                      size={10}
                      medium
                      style={{ marginTop: 4 }}
                    />
                  </View>
                )}
                {showConnectors && canShowConnectors && (
                  <>
                    {connector_types.length > 1 ? (
                      <View style={styles.detailBlock}>
                        <View style={styles.rowStyle}>
                          <View style={styles.greyIconCirculerCont}>
                            <DC001Icon height={16} width={16} />
                          </View>
                          <View style={styles.multipleChargeIconCont}>
                            <View style={styles.greyIconCirculerCont}>
                              <DC001Icon height={16} width={16} />
                            </View>
                          </View>
                          {isArray(connector_types) &&
                            connector_types.length > 2 && (
                              <View style={styles.moreChargeCont}>
                                <CText
                                  value={`+${connector_types.length - 2}`}
                                  medium
                                  size={10}
                                />
                              </View>
                            )}
                        </View>
                        <CText
                          value={
                            isArray(connector_types) &&
                            connector_types.length > 1
                              ? `${connector_types.length} ${t("connectorsLC")}`
                              : connector_types
                          }
                          size={10}
                          medium
                          style={{ marginTop: 4 }}
                        />
                      </View>
                    ) : (
                      <View style={styles.detailBlock}>
                        <View style={styles.greyIconCirculerCont}>
                          <DC001Icon height={16} width={16} />
                        </View>
                        <CText
                          value={connector_types.join(", ")}
                          size={10}
                          medium
                          style={{ marginTop: 4 }}
                        />
                      </View>
                    )}
                  </>
                )}

                {/* price */}
                {showPrice && canShowPrice && (
                  <View style={styles.detailBlock}>
                    <View style={styles.greyIconCirculerCont}>
                      <RupeeIcon
                        fill={COLORS.blackColor}
                        height={13}
                        width={13}
                      />
                    </View>
                    <CText
                      value={price}
                      size={10}
                      medium
                      style={{ marginTop: 4 }}
                    />
                  </View>
                )}
              </View>
            </View>
          )}
        </>
      </TouchableHighlight>

      {/* action buttons */}
      {showPrimaryActionButton && (
        <>
          {/* navigate button */}
          {(!hasControllableCPs ||
            (hasControllableCPs &&
              charging_station.distance > distanceOffset)) && (
            <CButton
              title={t("navigate")}
              style={{
                marginTop: 8,
              }}
              icon={() => {
                return (
                  <NavigateIcon
                    height={13}
                    width={13}
                    stroke={COLORS.whiteColor}
                    style={{
                      marginRight: 10,
                    }}
                  />
                );
              }}
              onPress={() => {
                handleOpenDirections();
              }}
            />
          )}

          {/* User is near the CS */}
          {hasControllableCPs &&
            charging_station.distance <= distanceOffset && (
              <CButton
                title={t("startCharging")}
                style={{ marginTop: 8 }}
                icon={() => {
                  return (
                    <ScanQRIcon
                      fill={COLORS.whiteColor}
                      height={20}
                      width={20}
                      style={{
                        marginRight: 10,
                      }}
                    />
                  );
                }}
                onPress={() => {
                  onStartChargingPress();
                }}
              />
            )}
        </>
      )}

      {!showPrimaryActionButton && (
        <View style={[styles.rowStyle, { marginTop: 8 }]}>
          <CButton
            title={t("viewCharger")}
            style={{ marginEnd: 8, flex: 1 }}
            onPress={onViewChargerPress}
            height={40}
            titleSize={10}
          />
          {cardType === "saved" ? (
            <CButton
              title={favorited ? t("saved") : t("save")}
              style={{ marginStart: 8, flex: 1 }}
              height={40}
              onPress={() => {
                onSavePress();
              }}
              bordered
              titleSize={10}
            />
          ) : (
            <CButton
              title={t("navigate")}
              icon={() => (
                <NavigateIcon
                  height={12}
                  width={12}
                  stroke={COLORS.blackColor}
                  style={{
                    marginRight: 10,
                  }}
                />
              )}
              style={{
                marginStart: 8,
                flex: 1,
              }}
              height={40}
              onPress={() => {
                handleOpenDirections();
              }}
              bordered
              titleSize={10}
              buttonStyle={{
                borderColor: COLORS.primaryLight,
              }}
            />
          )}
        </View>
      )}
    </View>
  );
}
