import { BillRefundStatus } from '../../../bill/enums';
import { BillPaymentMethod, BillPaymentStatus, BillPaymentStatusStr } from '../../../enums/charging/bill/bill';

export const BillPaymentMethodMap = {
    [BillPaymentMethod.DebitCard]: 'Debit Card',
    [BillPaymentMethod.CreditCard]: 'Credit Card',
    [BillPaymentMethod.UserWallet]: 'Wallet',
    [BillPaymentMethod.OrgWallet]: 'Organization Wallet',
    [BillPaymentMethod.FleetOperatorWallet]: 'Fleet operator wallet',
    [BillPaymentMethod.PaytmWallet]: 'Paytm Wallet',
    [BillPaymentMethod.WhatsApp]: 'WhatsApp',
    [BillPaymentMethod.UPI]: 'UPI',
    [BillPaymentMethod.NetBanking]: 'Net Banking',
    [BillPaymentMethod.Card]: 'Card',
    [BillPaymentMethod.RazorpayWallet]: 'Razorpay Wallet',
    [BillPaymentMethod.EMI]: 'EMI',
    [BillPaymentMethod.NumadicFastagWallet]: 'FASTag Wallet',
    [BillPaymentMethod.PulseWallet]: 'Pulse Wallet',
};

export const BillPaymentStatusMap = {
    [BillPaymentStatus.NotGenerated]: 'NotGenerated',
    [BillPaymentStatus.Generated]: 'Generated',
    [BillPaymentStatus.Pending]: 'Pending',
    [BillPaymentStatus.Paid]: 'Paid',
    [BillPaymentStatus.PartiallyPaid]: 'PartiallyPaid',
    [BillPaymentStatus.Failed]: 'Failed'
};

export const BillPaymentStatusTypeMap = {
    [BillPaymentStatusStr.NotGenerated]: 'Not Generated',
    [BillPaymentStatusStr.Generated]: 'Generated',
    [BillPaymentStatusStr.Pending]: 'Pending',
    [BillPaymentStatusStr.Failed]: 'Failed',
    [BillPaymentStatusStr.PartiallyPaid]: 'Partially Paid',
    [BillPaymentStatusStr.Paid]: 'Paid'
};

export const BillRefundStatusMap = {
    [BillRefundStatus.Initiated]: 'Initiated',
    [BillRefundStatus.Pending]: 'Pending',
    [BillRefundStatus.Failed]: 'Failed',
    [BillRefundStatus.Completed]: 'Completed'
};

export const GST_REGEX = '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$';
