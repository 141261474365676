import { Platform, Vibration } from "react-native";
import { APP_ENV } from "../../config/setting";

export function setGlobalVar(variable: string, value: any): any {
    // @ts-ignore
    window[variable] = value;
}

export function getGlobalVar(variable: string): any {
    // @ts-ignore
    return window[variable];
}

export function isIOS(): boolean {
    return Platform.OS === 'ios';
}

export function isAndroid(): boolean {
    return Platform.OS === 'android';
}

export const getFileTypeFromMime = (mime: string): 'img' | 'vid' | '' => {
    if (mime?.includes('image'))
        return 'img';
    else if (mime?.includes('video'))
        return 'vid';
    return '';
};

export function getKeyboardAvoidingViewBehavior(): "padding" | "height" | "position" | undefined {
    return Platform.OS === 'ios' ? 'padding' : undefined;
}

export const isNewVersionSameOrMore = (oldVersion: string, newVersion: string, versionLen = 3): boolean => {
    return getNumericalVersion(oldVersion, versionLen) >= getNumericalVersion(newVersion, versionLen);
};

export const isNewVersionGreater = (oldVersion: string, newVersion: string, versionLen = 3): boolean => {
    return getNumericalVersion(newVersion, versionLen) > getNumericalVersion(oldVersion, versionLen);
};

export const getNumericalVersion = (version: string, versionLen = 3): number => {
    const versionParts = version.split('.');
    const newVersionParts = versionParts.map((vPart) => {
        const fillers = '0'.repeat(versionLen - `${vPart}`.length);
        return fillers + vPart;
    });
    return Number(newVersionParts.join(''));
};

export const vibrate = () => {
    if (Platform.OS === 'android') {
        Vibration.vibrate([1, 20]);
    }
}

export const consoleLog = (...args: any[]) => {
    // eslint-disable-next-line no-undef
    if (APP_ENV === 'prod') return;
    console.log(...args);
}