import { AxiosError } from 'axios';
import { t } from 'i18next';
import { LangCodes } from '../central-atoms/enums/generic/i18n/LangCodes';
import HttpRequestService from '../config/HttpRequestService';
import { API_BASE_PATH, ENVIRONMENT } from '../config/setting';
import { getGlobalVar } from '../miscellaneous/helpers';
import { HandledError } from '../miscellaneous/types';
import { storeDispatch } from '../redux/store';
import AuthenticationService from './AuthenticationService';
import { GLOBAL_VARS, SCREEN_NAMES } from '../constants';
import { showToast } from '../helpers';

export class ErrorHandlerService {
  static async reportError(error: any): Promise<any> {
    // Sentry.captureException(payload);

    const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
    const axiosInstance = await httpRequest?.getInstance();
    if (axiosInstance) {
      const body: any = {
        error,
        ENV: ENVIRONMENT.type,
        smartlook_id: getGlobalVar(GLOBAL_VARS.SmartlookId),
        device_info: getGlobalVar(GLOBAL_VARS.deviceInfo)
      };
      if (error instanceof Error) {
        body['error_message'] = error.message;
        body['error_stack'] = error.stack;
      }
      return axiosInstance
        .post(`${API_BASE_PATH}/report_error`, body)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          // intentionally don't throw error
          // return Promise.reject(e);
        });
    }

    return Promise.resolve();
  }

  static handleError(
    e: any,
    options?: {
      lang?: LangCodes;
      reportError?: boolean;
      openToast?: boolean;
      errorText?: string;
      toastOptions?: any;
    }
  ): HandledError | undefined {
    const activeLang = getGlobalVar(GLOBAL_VARS.activeLang);

    const {
      lang = activeLang ?? LangCodes.en,
      openToast = true,
      reportError = true,
      errorText = undefined,
      toastOptions = {
        message: '',
        position: 'top'
      }
    } = options ?? {};

    try {
      if (reportError) {
        ErrorHandlerService.reportError(e).catch(() => {});
      }
    } catch (err) {
      console.log('REPORTING ERROR:', err);
    }

    // check if AxiosError
    let errorMsg: string = errorText ?? t('OopsSomethingWentWrong');
    if (e instanceof AxiosError) {
      // e?.response?.data?.msg || e?.response?.data?.message
      const responseData = e.response?.data;
      if (
        responseData?.error_code === 'SESSION_EXPIRED' ||
        responseData?.error_code === 'INVALID_SESSION' ||
        (responseData?.msg &&
          typeof responseData?.msg === 'string' &&
          responseData?.msg?.toLowerCase() === 'unauthorized access')
      ) {
        // Log the user out
        AuthenticationService.logout(storeDispatch);
        const navigationRef = getGlobalVar(GLOBAL_VARS.navigationRef);
        if (navigationRef) {
          navigationRef.current?.navigate(SCREEN_NAMES.SelectLanguageScreen);
        }
        return {
          errorMsg: t('SessionExpired')
        };
      } else {
        if (responseData?.i18n_error && responseData?.i18n_error?.[lang]) errorMsg = responseData?.i18n_error?.[lang];
        else if (responseData?.error) errorMsg = responseData?.message ?? responseData?.error;
        else if (responseData?.message) errorMsg = responseData?.message;
        else if (responseData?.msg) errorMsg = responseData?.msg;
        else if (e?.response?.status === 504) errorMsg = t('RequestTimedOutPleaseTryAgain');
      }
    }

    if (openToast) {
      showToast(errorMsg, toastOptions, e);
      return {
        errorMsg
      };
    }
  }
}
