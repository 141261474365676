import { StyleSheet } from 'react-native';
import { COLORS } from '../../../../../config/colors';
import { horizontalSize } from '../../../../../functions/responsive';

export default StyleSheet.create({
  cont_card: {
    backgroundColor: COLORS.whiteColor,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: COLORS.blackColor,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btnCont: {
    backgroundColor: COLORS.blackColor,
    borderRadius: 8,
    padding: 4,
    width: horizontalSize(35),
    justifyContent: "center",
  },
  rowStyle: {
    flexDirection: 'row',
    alignItems: 'center'
  }
});
