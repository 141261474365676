import { t } from "i18next";
import { isEmpty } from "lodash";
import { Wallet } from "../central-atoms/types/wallet";
import { WalletType } from "../central-atoms/wallets/enums";
import { CentralUser } from "../central-atoms/types/user";
import { ChargeTransaction } from "../central-atoms/types/charging/charge-txns";
import { BillPaymentMethod } from "../central-atoms/enums/charging/bill/bill";

export function isFleetWallet(wallet: Wallet | null): boolean {
    if (wallet === null) return false;
    return wallet?.type === WalletType.InstaCharge && !isEmpty(wallet.org_id);
};

export function isSharedWallet(wallet: Wallet | null): boolean {
    if (wallet === null) return false;
    return !!wallet?.is_shared;
};

export function getCentralUserWallet(centralUser: CentralUser): Wallet | null {
    let wallet: any = null;
    if (centralUser.active_wallet || centralUser.active_shared_wallet) {
        if (centralUser.active_wallet)
            wallet = { ...centralUser.active_wallet, is_shared: false };
        else if (centralUser.active_shared_wallet) {
            wallet = {
                ...centralUser.active_shared_wallet.wallet as Wallet,
                is_shared: true,
                shared_wallet_id: centralUser.active_shared_wallet_id,
            };
        }
    }
    return wallet;
};


export function getPaidByText(chargeTxn: ChargeTransaction): string {
    // console.log('chargeTxn', chargeTxn);

    const firstPaymentTxn = chargeTxn?.bill?.payment_txns?.[0];
    const paymentMethod = chargeTxn?.bill?.payment_method;
    
    if (paymentMethod === BillPaymentMethod.FleetOperatorWallet ||
        paymentMethod === BillPaymentMethod.OrgWallet) {
        let wallet;
        if (firstPaymentTxn?.wallet) {
            wallet = firstPaymentTxn.wallet;
        }
        else if (firstPaymentTxn?.shared_wallet) {
            wallet = firstPaymentTxn?.shared_wallet?.wallet;
        }
        if (wallet?.org?.name)
            return `${t('paidBy')} ${wallet?.org?.name} ${t('walletLC')}`;
        else
            return `${t('paidBy')} ${t('fleetOperators')} ${t('walletLC')}`;
    }
    else if (paymentMethod === BillPaymentMethod.PaytmWallet) {
        let wallet;
        if (firstPaymentTxn?.wallet) {
            wallet = firstPaymentTxn?.wallet;
            if (wallet?.type === WalletType.Paytm)
                return `${t('paidBy')} Paytm ${t('walletLC')}`;
            // else if (wallet.type === WalletType.PhonePe)
            //     return `${t('paidBy')} PhonePe ${t('walletLC')}`;
        }
        else if (firstPaymentTxn?.shared_wallet) {
            wallet = firstPaymentTxn?.shared_wallet?.wallet;
            if (wallet?.user?.name)
                return `${t('paidBy')} ${wallet?.user?.name} ${t('walletLC')}`;
        }

        return `${t('PaidFromYourPaytmWallet')}`;
    }
    else if (paymentMethod === BillPaymentMethod.WhatsApp) {
        return `${t('PaidViaWhatsApp')}`;
    }
    else if (paymentMethod === BillPaymentMethod.UPI) {
        return `${t('PaidViaUPI')}`;
    }
    else if (paymentMethod === BillPaymentMethod.CreditCard) {
        return `${t('PaidFromCreditCard')}`;
    }
    else if (paymentMethod === BillPaymentMethod.DebitCard) {
        return `${t('PaidFromDebitCard')}`;
    }
    else if (paymentMethod === BillPaymentMethod.NetBanking) {
        return `${t('PaidViaNetBanking')}`;
    }
    return 'Paid';
}