import { StyleSheet } from "react-native";
import { isIOS } from "../../../helpers";

export default StyleSheet.create({
	container: {
		borderBottomWidth: 1,
		borderStyle: isIOS() ? "solid" : "dashed",
		borderRadius: 1,
	},
});
