import { PaginationQueryParams } from "../central-atoms/generic/types";
import HttpRequestService from "../config/HttpRequestService";
import { API_BASE_PATH, CENTRAL_HOST } from "../config/setting";
import { GLOBAL_VARS } from "../constants";
import { getGlobalVar } from "../miscellaneous/helpers";

export class ChargeTxnsRequestService {
    static fetchOngoingSessions(): Promise<any> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return httpRequest.getInstance()
            .get(`${API_BASE_PATH}/ongoing_sessions`)
            .then((response: any) => {
                return response.data;
            });
    }

    static fetchChargingHistory(params: PaginationQueryParams & {wallet_id?: string}): Promise<any> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .get(`${CENTRAL_HOST}/charging_history`, {
                params,
            })
            .then((response: any) => {
                return response.data;
            });
    }
}
