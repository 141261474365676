import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Linking,
  ScrollView,
  StatusBar,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
// @ts-ignore
import ReactPlayer from "react-player";
// @ts-ignore
import { useIsFocused } from "@react-navigation/native";
import { isEmpty } from "lodash";
// @ts-ignore
import { ChargingRequestService } from "../../../services/ChargingRequestService";
import config from "../../../config/config";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import CText from "../../components/CText";
import { LottieAnimation } from "../../../config/lottie";
import { ReactComponent as CloseCircleIcon } from "../../../assets/icons/close-circle.svg";
import { COLORS } from "../../../config/colors";
import {
  ChargingStatesScreenRouteParams,
  InsufficientWalletBalanceScreenRouteParams,
  ScreenComponent,
} from "../../../types";
import {
  useFirebaseRemoteConfigValues,
  useSelectedChargePoint,
  useSelectedChargePointConnector,
  useSelectedLanguage,
} from "../../../hooks";
import { FirebaseRemoteConfigKeys, SCREEN_NAMES } from "../../../constants";
import { WHATSAPP_CHAT_LINK } from "../../../config/setting";
import {
  useActivePaymentMethodType,
  useActiveWallet,
} from "../../../hooks/walletHooks";
import { I18NRichMessageResponse } from "../../../central-atoms/types/responses/i18n";
import {
  CheckWalletBalance,
  PostCancelChargingReq,
} from "../../../central-atoms/types/app-requests";
import { isSharedWallet } from "../../../helpers/walletHelpers";
import ExternalWalletRequestService from "../../../services/ExternalWalletRequestService";
import { PaymentMethodType } from "../../../central-atoms/payment-methods/types/enum";
import { WalletType } from "../../../central-atoms/wallets/enums";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import ChargePointRequestService from "../../../services/ChargePointRequestService";
import { ChargeTransaction } from "../../../central-atoms/types/charging/charge-txns";
import { ChargeTransactionStatus } from "../../../central-atoms/enums/charging/charge-txn";
import { consoleLog, isAndroid } from "../../../miscellaneous/helpers";
import { sleep } from "../../../central-atoms/functions/time";
import { useInterval } from "../../../miscellaneous/hooks";
import ScreenWrapper from "../../../miscellaneous/components/ScreenWrapper";
import ScreenHeader from "../../../miscellaneous/components/ScreenHeader";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import RichError from "../../../miscellaneous/components/RichError";
import { VerticalLine } from "../../../miscellaneous/views/miscellaneous";
import ScreenBottomBar from "../../../miscellaneous/components/ScreenBottomBar";
import CButton from "../../components/CButton";
import { FullScreenOverlay } from "../../../miscellaneous/components/FullScreenOverlay";
import { BlueBoltIcon, BlueCheckIcon, CloseFilledIcon } from "../../../icon";
import BackButton from "../../components/BackButton";
import ReactModal from "../../components/ReactModal";
import { useTranslation } from "react-i18next";
import { verticalSize } from "../../../functions/responsive";

enum ChargingProgressStepName {
  ConnectCharger = "ConnectCharger",
  CheckWalletBalance = "CheckWalletBalance",
  StartCharging = "StartCharging",
  WaitForAcceptChargingRequest = "WaitForAcceptChargingRequest",
  FetchMeterReadings = "FetchMeterReadings",
}

enum ChargingProgressStepState {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Completed = "Completed",
  Failed = "Failed",
}

type ChargingProgressStep = {
  stepName: ChargingProgressStepName;
  notStartedDesc: string;
  inProgressDesc: string;
  completedDesc: string;
  failedDesc?: string;
  stepState: ChargingProgressStepState;
  requiresUserAction: boolean;
};

const getInitialSteps = (t): { [stepName: string]: ChargingProgressStep } => {
  return {
    [ChargingProgressStepName.ConnectCharger]: {
      stepName: ChargingProgressStepName.ConnectCharger,
      notStartedDesc: t("pleaseConnectCharger"),
      inProgressDesc: t("WaitingForConnectCharger"),
      completedDesc: t("chargerConnected"),
      failedDesc: t("FailedPleaseTryAgain"),
      stepState: ChargingProgressStepState.NotStarted,
      requiresUserAction: false,
    },

    [ChargingProgressStepName.CheckWalletBalance]: {
      stepName: ChargingProgressStepName.CheckWalletBalance,
      notStartedDesc: t("CheckPaytmWalletBalance"),
      inProgressDesc: t("CheckingPaytmWalletBalance..."),
      completedDesc: t("PaytmBalanceChecked"),
      failedDesc: t("FailedToCheckPaytmBalance"),
      stepState: ChargingProgressStepState.NotStarted,
      requiresUserAction: false,
    },

    [ChargingProgressStepName.StartCharging]: {
      stepName: ChargingProgressStepName.StartCharging,
      notStartedDesc: t("startCharging"),
      inProgressDesc: t("chargingStartingSoon"),
      completedDesc: t("ChargingRequestSentByInstacharge").replace(
        "[[APP_NAME]]",
        config.APP_NAME
      ),
      failedDesc: t("FailedToStartCharging"),
      stepState: ChargingProgressStepState.NotStarted,
      requiresUserAction: false,
    },

    [ChargingProgressStepName.WaitForAcceptChargingRequest]: {
      stepName: ChargingProgressStepName.WaitForAcceptChargingRequest,
      notStartedDesc: t("WaitForAcceptChargingRequest"),
      inProgressDesc: t("WaitingForAcceptChargingRequest"),
      completedDesc: t("ChargerAcceptedChargingRequest"),
      failedDesc: t("FailedPleaseTryAgain"),
      stepState: ChargingProgressStepState.NotStarted,
      requiresUserAction: false,
    },

    [ChargingProgressStepName.FetchMeterReadings]: {
      stepName: ChargingProgressStepName.FetchMeterReadings,
      notStartedDesc: t("FetchEnergyData"),
      inProgressDesc: t("WaitingForEnergyData"),
      completedDesc: t("EnergyDataFetched"),
      failedDesc: t("FailedPleaseTryAgain"),
      stepState: ChargingProgressStepState.NotStarted,
      requiresUserAction: false,
    },
  };
};

// primary step view components /*

const ConnectChargerStepPrimaryView = (props: {
  reconnectCharger: boolean;
}) => {
  const { reconnectCharger } = props;
  const { t } = useTranslation();
  return (
    <>
      <View style={styles.videoCont}>
        <ReactPlayer
          url={require("../../../assets/connect_charger_video.mp4")}
          muted
          loop
          width={180}
          height={180}
          playing
        />
      </View>

      <CText
        value={
          reconnectCharger
            ? t("PleaseReconnectCharger")
            : t("pleaseConnectCharger")
        }
        size={22}
        semiBold
        style={{
          ...styles.currentActionText,
        }}
      />
    </>
  );
};

const CheckPaytmWalletBalanceStepPrimaryView = (props: {}) => {
  const { t } = useTranslation();
  return (
    <>
      <View style={styles.lottieCont}>
        <Lottie
          animationData={LottieAnimation.checking_wallet_balance}
          autoplay
          loop={true}
          style={{
            height: 200,
            width: 200,
          }}
        />
      </View>

      <CText
        value={t("CheckingPaytmWalletBalance...")}
        size={22}
        semiBold
        style={{
          ...styles.currentActionText,
          paddingHorizontal: 20,
        }}
      />
    </>
  );
};

const ChargingStartingSoonStepPrimaryView = (props: {}) => {
  const { t } = useTranslation();
  return (
    <>
      <View style={styles.lottieCont}>
        <Lottie
          animationData={LottieAnimation.charging_wait_time}
          autoplay
          loop={true}
          style={{
            height: 180,
            width: 180,
          }}
        />
      </View>

      <CText
        value={t("chargingStartingSoon")}
        size={22}
        semiBold
        style={{
          ...styles.currentActionText,
          width: 250,
          fontWeight: "600",
        }}
      />
    </>
  );
};

const ChargingStartedStepPrimaryView = () => {
  const { t } = useTranslation();
  return (
    <>
      <View style={styles.lottieCont}>
        <BlueBoltIcon
          style={{
            width: 200,
            height: 200,
          }}
        />
      </View>

      <CText
        value={t("ChargingStarted")}
        size={22}
        semiBold
        style={{
          ...styles.currentActionText,
          width: 200,
          color: COLORS.whiteColor,
          fontWeight: "600",
        }}
      />
    </>
  );
};

const ChargingFailedStepPrimaryView = () => {
  const { t } = useTranslation();
  return (
    <>
      <View style={styles.lottieCont}>
        <CloseCircleIcon
          style={{
            width: 200,
            height: 200,
          }}
        />
      </View>

      <CText
        value={t("ChargingFailed")}
        size={22}
        semiBold
        style={{
          ...styles.currentActionText,
          width: 200,
          color: COLORS.whiteColor,
          fontWeight: "600",
        }}
      />
    </>
  );
};

// primary step view components */

type Props = ScreenComponent & {};
export default function ChargingStatesScreen(props: Props) {
  const { navigation, route } = props;
  const { t } = useTranslation();
  const { startChargingData, requiredBalance = 50 } =
    (route?.params as ChargingStatesScreenRouteParams) || {};
  const firebaseRemoteConfigValues = useFirebaseRemoteConfigValues();
  const whatsappChatLink =
    firebaseRemoteConfigValues?.[FirebaseRemoteConfigKeys.whatsapp_chat_link] ||
    WHATSAPP_CHAT_LINK;
  const activePaymentMethodType = useActivePaymentMethodType();
  const activeWallet = useActiveWallet();
  const selectedLanguage = useSelectedLanguage();
  const isScreenFocused = useIsFocused();
  const selectedChargePoint = useSelectedChargePoint();
  const selectedChargePointConnector = useSelectedChargePointConnector();
  const [
    waitForIHaveConnectedChargerCheck,
    setWaitForIHaveConnectedChargerCheck,
  ] = useState(false);
  const [waitForBalanceCheck, setWaitForBalanceCheck] = useState(false);
  const [waitForStartCharging, setWaitForStartCharging] = useState(false);
  const [chargeTxnPollingInterval, setChargeTxnPollingInterval] = useState<
    number | null
  >(null);
  const [showCancelChargingButton, setShowCancelChargingButton] =
    useState(true);

  const [chargeTxnId, setChargeTxnId] = useState<string | null>(
    startChargingData?.charge_transaction_id || null
  );

  // steps /*
  const [isChargingAllowedInterval, setIsChargingAllowedInterval] = useState<
    number | null
  >(null);
  const [bgColor, setBgColor] = useState(THEME_COLORS.bgColor);
  const [chargingProgressStepsMap, setChargingProgressStepsMap] = useState<{
    [stepName: string]: ChargingProgressStep;
  }>(getInitialSteps(t));
  const [chargingProgressSteps, setChargingProgressSteps] = useState<
    ChargingProgressStepName[]
  >([]);
  const [currentChargingProgressStepName, setCurrentChargingProgressStepName] =
    useState<ChargingProgressStepName>(chargingProgressSteps[0] || "");
  const [reconnectCharger, setReconnectCharger] = useState<boolean>(false);

  // CP connection state polling
  const [cpConnected, setCPConnected] = useState(true);
  const [cpNotConnectedError, setCpNotConnectedError] =
    useState<I18NRichMessageResponse | null>(null);
  const [waitForCPConnected, setWaitForCPConnected] = useState<boolean | null>(
    null
  );
  const [waitForIsChargingAllowed, setWaitForIsChargingAllowed] = useState<
    boolean | null
  >(null);
  const [
    shouldContinueCPConnectedLongPolling,
    setShouldContinueCPConnectedLongPolling,
  ] = useState<boolean>(true);

  const [waitForChargingDetails, setWaitForChargingDetails] = useState<
    boolean | null
  >(null);
  const [waitForChargeTxn, setWaitForChargeTxn] = useState<boolean | null>(
    null
  );
  const [waitForCancelCharging, setWaitForCancelCharging] = useState<
    boolean | null
  >(null);
  const [isCancelModalVisible, setCancelModalVisible] =
    useState<boolean>(false);

  const scrollViewRef = useRef<any>();

  const isScreenActive = useRef<boolean>(true);
  const isCancelChargingInitiated = useRef<boolean>(false);

  const checkWalletBalance = async () => {
    const data: CheckWalletBalance = {
      charging_option_type: startChargingData.charging_option_type,
      charging_option_unit: startChargingData.charging_option_unit,
      charge_point_id: startChargingData.charge_point_id,
      charge_point_connector_id: startChargingData.charge_point_connector_id,
    };
    if (isSharedWallet(activeWallet))
      data.shared_wallet_id = activeWallet?.shared_wallet_id as string;
    else data.wallet_id = activeWallet?.id;

    try {
      const response = await ExternalWalletRequestService.checkWalletBalance(
        data
      );
      return response;
    } catch (e) {
      return await Promise.reject(e);
    }
  };

  const triggerPaytmWalletBalanceCheck = async () => {
    if (waitForBalanceCheck) {
      return;
    }

    if (
      activePaymentMethodType === PaymentMethodType.Wallet &&
      activeWallet?.type === WalletType.Paytm
    ) {
      try {
        setWaitForBalanceCheck(true);
        const response = await checkWalletBalance();
        const balance = response.data.balance;
        setWaitForBalanceCheck(false);

        if (balance <= requiredBalance) {
          return;
        }

        onStepComplete(ChargingProgressStepName.CheckWalletBalance);
      } catch (e: any) {
        setWaitForBalanceCheck(false);

        if (
          e?.response?.data?.message
            ?.toLowerCase()
            ?.includes("insufficient balance")
        ) {
          if (e?.response?.data?.data?.prompt_recharge_wallet) {
            if (
              isScreenFocused &&
              isScreenActive.current &&
              !isCancelChargingInitiated.current
            ) {
              navigation.replace(SCREEN_NAMES.InsufficientBalanceScreen, {
                requiredBalance:
                  e?.response?.data?.data?.estimated_charging_cost ||
                  requiredBalance,
              } as InsufficientWalletBalanceScreenRouteParams);
            }
            return;
          }
        }

        onStepError({
          error: e,
        });

        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
        return;
      }
    }
  };

  const startCharging = () => {
    if (waitForStartCharging || !isScreenFocused || !isScreenActive.current) {
      return;
    }

    if (startChargingData.charge_point_connector_id) {
      delete startChargingData.charge_point_connector_id;
    }

    setWaitForStartCharging(true);
    ChargePointRequestService.startCharging(startChargingData)
      .then(async (response) => {
        setWaitForStartCharging(false);

        const chargeTxn = response?.data;

        if (chargeTxn?.id) {
          setChargeTxnId(chargeTxn?.id);
        }

        onStepComplete(ChargingProgressStepName.StartCharging, {
          chargeTxnId: chargeTxn?.id,
        });
      })
      .catch((e) => {
        setWaitForStartCharging(false);

        onStepError({
          error: e,
        });
      });
  };

  const updateStepStateByChargeTxn = (chargeTxn: ChargeTransaction) => {
    if (chargeTxn.status === ChargeTransactionStatus.Stopped) {
      // move to bill page
      goToChargingCompleteScreen(chargeTxn?.id);
    } else if (
      currentChargingProgressStepName ===
        ChargingProgressStepName.WaitForAcceptChargingRequest &&
      chargeTxn.status === ChargeTransactionStatus.Ongoing &&
      !Number(chargeTxn?.summary?.energy_delivered)
    ) {
      onStepComplete(ChargingProgressStepName.WaitForAcceptChargingRequest, {
        chargeTxnId: chargeTxn?.id,
      });
    } else if (
      currentChargingProgressStepName ===
        ChargingProgressStepName.FetchMeterReadings &&
      chargeTxn.status === ChargeTransactionStatus.Ongoing &&
      Number(chargeTxn?.summary?.energy_delivered)
    ) {
      onStepComplete(ChargingProgressStepName.FetchMeterReadings, {
        chargeTxnId: chargeTxn?.id,
      });
    } else if (
      chargeTxn.status === ChargeTransactionStatus.Ongoing &&
      Number(chargeTxn?.summary?.energy_delivered)
    ) {
      // for going to charging details screen if charge txn is ongoing and energy is delivered
      onStepComplete(ChargingProgressStepName.FetchMeterReadings, {
        chargeTxnId: chargeTxn?.id,
      });
    }
  };

  const exitViewCleanup = () => {
    setShouldContinueCPConnectedLongPolling(false);
    setIsChargingAllowedInterval(null);
    setChargeTxnPollingInterval(null);
  };

  const getChargingDetails = async (
    chargeTxnId: string
  ): Promise<void | null> => {
    if (waitForChargingDetails) {
      return Promise.resolve(null);
    }

    setWaitForChargingDetails(true);
    try {
      const { data } = await ChargePointRequestService.fetchChargingDetails(
        chargeTxnId
      );
      setWaitForChargingDetails(false);
      updateStepStateByChargeTxn(data);
      return data;
    } catch (e) {
      setWaitForChargingDetails(false);
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error",
        },
      });
    }
  };

  const getChargeTxnForCPCId = async (cpcId: string): Promise<void | null> => {
    if (waitForChargeTxn) {
      return Promise.resolve(null);
    }

    setWaitForChargeTxn(true);
    try {
      const { data } = await ChargePointRequestService.fetchChargeTxnForCPCId(
        cpcId
      );
      setWaitForChargeTxn(false);
      updateStepStateByChargeTxn(data);
      return data;
    } catch (e) {
      setWaitForChargeTxn(false);
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error",
        },
      });
    }
  };

  const handleFacingIssuesPress = () => {
    let msg = `I am facing issues with Start Charging on the charger: \`\`\`${selectedChargePoint?.qr_code_id}\`\`\` `;
    if (isAndroid()) {
      msg = encodeURIComponent(msg);
    }
    Linking.openURL(`${whatsappChatLink}?text=${msg}`)
      .then(() => {
        // pass
      })
      .catch((e) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      });
  };

  const exitView = () => {
    if (isScreenFocused && isScreenActive.current) {
      navigation.goBack();
    }
  };

  const onStepComplete = async (
    stepName: ChargingProgressStepName,
    stepPayload: {
      chargeTxnId?: string;
    } = {}
  ) => {
    if (!isScreenFocused || !isScreenActive.current) {
      return;
    }

    // update the status of the current state
    if (!!stepName) {
      updateStep(stepName, {
        stepState: ChargingProgressStepState.Completed,
      });
    }

    if (stepName === ChargingProgressStepName.ConnectCharger) {
      setIsChargingAllowedInterval(null);
    } else if (stepName === ChargingProgressStepName.CheckWalletBalance) {
      // pass
    } else if (stepName === ChargingProgressStepName.StartCharging) {
      (scrollViewRef.current as ScrollView)?.scrollToEnd();
    } else if (
      stepName === ChargingProgressStepName.WaitForAcceptChargingRequest
    ) {
      (scrollViewRef.current as ScrollView)?.scrollToEnd();
    } else if (stepName === ChargingProgressStepName.FetchMeterReadings) {
      await sleep(800);
      goToChargingDetailsScreen(
        stepPayload?.chargeTxnId || (chargeTxnId as string)
      );
    }

    goToNextStep();
  };

  const onStepError = async (stepPayload: { error: any }) => {
    // update the status of the current state
    if (!!currentChargingProgressStepName) {
      updateStep(currentChargingProgressStepName, {
        stepState: ChargingProgressStepState.Failed,
      });
    }
  };

  const executeCurrentStep = async () => {
    if (!isScreenFocused || !isScreenActive.current) {
      return;
    }

    if (
      chargingProgressStepsMap[currentChargingProgressStepName]?.stepState !==
      ChargingProgressStepState.NotStarted
    ) {
      return;
    }

    if (
      currentChargingProgressStepName ===
      ChargingProgressStepName.ConnectCharger
    ) {
      updateStep(currentChargingProgressStepName, {
        stepState: ChargingProgressStepState.InProgress,
      });

      // start polling for if charging should be allowed or not
      setIsChargingAllowedInterval(3000);
    } else if (
      currentChargingProgressStepName ===
      ChargingProgressStepName.CheckWalletBalance
    ) {
      updateStep(currentChargingProgressStepName, {
        stepState: ChargingProgressStepState.InProgress,
      });

      triggerPaytmWalletBalanceCheck();
    } else if (
      currentChargingProgressStepName === ChargingProgressStepName.StartCharging
    ) {
      updateStep(currentChargingProgressStepName, {
        stepState: ChargingProgressStepState.InProgress,
      });

      startCharging();
    } else if (
      currentChargingProgressStepName ===
      ChargingProgressStepName.WaitForAcceptChargingRequest
    ) {
      updateStep(currentChargingProgressStepName, {
        stepState: ChargingProgressStepState.InProgress,
      });

      // start polling for if charging should be allowed or not
      setChargeTxnPollingInterval(3000);
    } else if (
      currentChargingProgressStepName ===
      ChargingProgressStepName.FetchMeterReadings
    ) {
      updateStep(currentChargingProgressStepName, {
        stepState: ChargingProgressStepState.InProgress,
      });

      // start polling for if charging should be allowed or not
      setChargeTxnPollingInterval(3000);
    }
  };

  const goToNextStep = () => {
    const nextStep = getNextStep();
    // update the next step's state to inprogress
    if (nextStep) setCurrentChargingProgressStepName(nextStep);
  };

  const updateStep = (
    stepName: ChargingProgressStepName,
    stepUpdate: Partial<ChargingProgressStep>
  ): ChargingProgressStep | null => {
    let updatedStep: ChargingProgressStep | null = null;
    setChargingProgressStepsMap((prevState) => {
      const newState = { ...prevState };
      const existingStep = chargingProgressStepsMap[stepName];
      if (existingStep) {
        updatedStep = {
          ...existingStep,
          ...stepUpdate,
        };
        newState[stepName] = updatedStep;
      }
      return newState;
    });

    if ((updatedStep as never as ChargingProgressStep)?.stepName)
      setCurrentChargingProgressStepName(
        (updatedStep as never as ChargingProgressStep)?.stepName
      );

    return updatedStep;
  };

  const goToChargingDetailsScreen = (_chargeTxnId: string) => {
    if (
      isScreenFocused &&
      isScreenActive.current &&
      !isCancelChargingInitiated.current
    ) {
      exitViewCleanup();
      navigation.navigate(SCREEN_NAMES.ChargingProcessScreen, {
        chargeTxnId: _chargeTxnId,
      });
    }
  };

  const goToChargingCompleteScreen = (_chargeTxnId: string) => {
    if (
      isScreenFocused &&
      isScreenActive.current &&
      !isCancelChargingInitiated.current
    ) {
      exitViewCleanup();
      navigation.navigate(SCREEN_NAMES.ChargingSuccessfulScreen, {
        chargeTxnId: _chargeTxnId,
      });
    }
  };

  const handleCancelCharging = () => {
    if (
      cpNotConnectedError ||
      chargingProgressStepsMap[currentChargingProgressStepName]?.stepState ===
        ChargingProgressStepState.Failed
    ) {
      cancelCharging();
      return;
    }

    if (
      startChargingData?.payment_method_type ===
        PaymentMethodType.PaymentGateway ||
      [
        ChargingProgressStepName.StartCharging,
        ChargingProgressStepName.WaitForAcceptChargingRequest,
        ChargingProgressStepName.FetchMeterReadings,
      ].includes(currentChargingProgressStepName)
    ) {
      handleCancelPress();
    } else {
      exitView();
    }
  };

  const cancelCharging = () => {
    if (
      chargeTxnId &&
      (startChargingData?.payment_method_type ===
        PaymentMethodType.PaymentGateway ||
        [
          ChargingProgressStepName.StartCharging,
          ChargingProgressStepName.WaitForAcceptChargingRequest,
          ChargingProgressStepName.FetchMeterReadings,
        ].includes(currentChargingProgressStepName))
    ) {
      triggerCancelCharging()
        .then(() => {
          exitView();
        })
        .catch((e) => {
          // do not exit
        });
    } else {
      exitView();
    }
  };

  const triggerCancelCharging = async (): Promise<any> => {
    if (waitForCancelCharging) {
      return Promise.resolve(null);
    }

    const payload: PostCancelChargingReq = {};
    if (chargeTxnId) {
      payload.charge_transaction_id = chargeTxnId;
    } else {
      payload.charge_point_connector_id =
        startChargingData.charge_point_connector_id;
    }

    setWaitForCancelCharging(true);
    isCancelChargingInitiated.current = true;
    try {
      const response = await ChargingRequestService.cancelCharging(payload);
      setWaitForCancelCharging(false);

      if (response?.i18n_msg?.[selectedLanguage]) {
      } else if (response?.message) {
      }

      isCancelChargingInitiated.current = false;
    } catch (e) {
      setWaitForCancelCharging(false);
      ErrorHandlerService.handleError(e, {
        openToast: isScreenFocused && isScreenActive.current,
        toastOptions: {
          type: "error",
        },
      });
      isCancelChargingInitiated.current = false;
      return await Promise.reject(e);
    }
  };

  const getIsChargingAllowed = async (): Promise<any | null> => {
    // const selectedChargePointConnector = { id: "x1zpYOR386NTbQEFhBrM" };
    if (!selectedChargePointConnector?.id || waitForIsChargingAllowed) {
      return Promise.resolve(null);
    }

    setWaitForIsChargingAllowed(true);
    try {
      const response = await ChargingRequestService.isChargingAllowed({
        charge_point_connector_id: selectedChargePointConnector?.id,
      });
      setWaitForIsChargingAllowed(false);

      if (
        response.data.is_charging_allowed &&
        currentChargingProgressStepName ===
          ChargingProgressStepName.ConnectCharger
      )
        onStepComplete(ChargingProgressStepName.ConnectCharger);
      return response;
    } catch (e) {
      setWaitForIsChargingAllowed(false);
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error",
        },
      });
    }
  };

  const buildChargingProgressSteps = (): ChargingProgressStepName[] => {
    const steps: ChargingProgressStepName[] = [];
    steps.push(ChargingProgressStepName.ConnectCharger);

    if (
      activePaymentMethodType === PaymentMethodType.Wallet &&
      activeWallet?.type === WalletType.Paytm
    ) {
      steps.push(ChargingProgressStepName.CheckWalletBalance);
    }

    steps.push(ChargingProgressStepName.StartCharging);
    steps.push(ChargingProgressStepName.WaitForAcceptChargingRequest);
    steps.push(ChargingProgressStepName.FetchMeterReadings);
    return steps;
  };

  const getNextStep = (
    nextStepName?: ChargingProgressStepName
  ): ChargingProgressStepName => {
    let currentIndex = chargingProgressSteps.findIndex(
      (stepName) =>
        stepName === currentChargingProgressStepName ||
        stepName === nextStepName
    );
    if (currentIndex === -1) {
      currentIndex = 0;
    } else {
      currentIndex++;
    }

    return chargingProgressSteps[currentIndex];
  };

  const checkIsConnected = async (): Promise<void> => {
    if (waitForCPConnected) return Promise.resolve();

    setWaitForCPConnected(true);
    try {
      const { data, i18n_error } = await ChargePointRequestService.isConnected(
        selectedChargePoint?.public_uid as string
      );
      setWaitForCPConnected(false);
      setCPConnected(data?.is_connected);

      if (!data.is_connected && i18n_error) setCpNotConnectedError(i18n_error);
      else setCpNotConnectedError(null);
    } catch (e) {
      setWaitForCPConnected(false);
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error",
        },
      });
    }
  };

  // EFFECTS /*

  useEffect(() => {
    isScreenActive.current = true;
    const steps = buildChargingProgressSteps();
    setChargingProgressSteps(steps);
    setCurrentChargingProgressStepName(steps[0]);

    return () => {
      isScreenActive.current = false;
      consoleLog("unmounting...", isScreenActive.current);
      exitViewCleanup();
    };
  }, []);

  useInterval(() => {
    getIsChargingAllowed();
  }, isChargingAllowedInterval);

  // long poll for CP connections state
  useInterval(() => {
    if (chargeTxnId) getChargingDetails(chargeTxnId);
    else if (selectedChargePointConnector?.id)
      getChargeTxnForCPCId(selectedChargePointConnector?.id);
  }, chargeTxnPollingInterval);

  // auto execute the next step
  useEffect(() => {
    if (
      !chargingProgressStepsMap[currentChargingProgressStepName]
        ?.requiresUserAction
    ) {
      executeCurrentStep();
    }
  }, [currentChargingProgressStepName]);

  useEffect(() => {
    const currentStepState =
      chargingProgressStepsMap[currentChargingProgressStepName]?.stepState;

    // set colors /*
    let color = THEME_COLORS.defaultStatusBar;
    if (
      (currentChargingProgressStepName ===
        ChargingProgressStepName.StartCharging &&
        chargingProgressStepsMap[ChargingProgressStepName.StartCharging]
          ?.stepState === ChargingProgressStepState.Completed) ||
      currentChargingProgressStepName ===
        ChargingProgressStepName.WaitForAcceptChargingRequest ||
      currentChargingProgressStepName ===
        ChargingProgressStepName.FetchMeterReadings
    ) {
      color = COLORS.primaryColor;
    }

    if (currentStepState === ChargingProgressStepState.Failed) {
      color = COLORS.errorColor;
    }

    StatusBar.setBackgroundColor(color);
    setBgColor(color);

    if (
      currentChargingProgressStepName ===
        ChargingProgressStepName.StartCharging &&
      currentStepState === ChargingProgressStepState.InProgress
    ) {
      setShowCancelChargingButton(false);
    } else {
      setShowCancelChargingButton(true);
    }
  }, [
    currentChargingProgressStepName,
    chargingProgressStepsMap[currentChargingProgressStepName]?.stepState,
  ]);

  // long poll for CP connections state
  useInterval(
    () => {
      checkIsConnected();
    },
    shouldContinueCPConnectedLongPolling ? 3000 : null
  );

  useEffect(() => {
    if (!cpConnected) {
      if (
        chargingProgressStepsMap[ChargingProgressStepName.ConnectCharger]
          .stepState === ChargingProgressStepState.InProgress
      ) {
        updateStep(ChargingProgressStepName.ConnectCharger, {
          stepState: ChargingProgressStepState.NotStarted,
        });
      } else if (
        [ChargingProgressStepState.Completed].includes(
          chargingProgressStepsMap[ChargingProgressStepName.StartCharging]
            .stepState
        )
      ) {
        goToChargingDetailsScreen(chargeTxnId as string);
      }
    } else {
      if (
        chargingProgressStepsMap[ChargingProgressStepName.ConnectCharger]
          .stepState === ChargingProgressStepState.InProgress
      )
        executeCurrentStep();
    }
  }, [cpConnected, chargeTxnId]);

  if (isEmpty(chargingProgressStepsMap)) {
    return <ScreenWrapper {...props}></ScreenWrapper>;
  }

  const handleCancelPress = () => {
    setCancelModalVisible(true);
  };

  const closeModal = () => {
    setCancelModalVisible(false);
  };

  const handleModalCancelCharging = () => {
    cancelCharging();
    closeModal();
  };

  return (
    <View
      style={{
        flexDirection: "column",
        backgroundColor: THEME_COLORS.bgColor,
        justifyContent: "space-between",
        height: verticalSize(100),
      }}
    >
      <ScreenHeader
        showBackButton={false}
        style={{
          borderBottomWidth: 0,
          backgroundColor: bgColor,
          height: 60,
        }}
        leftComponent={
          showCancelChargingButton && (
            <TouchableOpacity
              activeOpacity={0.6}
              onPress={handleCancelCharging}
              style={{
                ...StyleHelpers.verticallyCenteredRow,
                marginTop: 5,
                marginLeft: -5,
              }}
            >
              <BackButton shadow onPress={handleCancelCharging} />
              <CText
                semiBold
                size={12}
                style={{
                  color:
                    chargingProgressStepsMap[currentChargingProgressStepName]
                      ?.stepState === ChargingProgressStepState.Failed
                      ? COLORS.whiteColor
                      : COLORS.darkBlack,
                  marginLeft: 10,
                  fontWeight: "600",
                }}
              >
                {t("CancelCharging")}
              </CText>
            </TouchableOpacity>
          )
        }
      />

      <ScrollView
        style={{
          backgroundColor: bgColor,
          height: 200,
          overflow: "scroll",
        }}
      >
        <View style={styles.mainCont}>
          {/* CP not connected error */}
          {!cpConnected &&
            cpNotConnectedError &&
            chargingProgressStepsMap[ChargingProgressStepName.StartCharging]
              .stepState !== ChargingProgressStepState.Completed && (
              <View
                style={{
                  marginTop: 120,
                  marginBottom: 10,
                }}
              >
                <RichError i18nError={cpNotConnectedError} />
              </View>
            )}

          {/* step primary view */}
          {!(
            !cpConnected &&
            cpNotConnectedError &&
            chargingProgressStepsMap[ChargingProgressStepName.StartCharging]
              .stepState !== ChargingProgressStepState.Completed
          ) && (
            <View
              style={{
                marginTop: 30,
                height: 275,
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* NotStarted */}
              {(chargingProgressStepsMap[currentChargingProgressStepName]
                ?.stepState === ChargingProgressStepState.NotStarted ||
                chargingProgressStepsMap[currentChargingProgressStepName]
                  ?.stepState === ChargingProgressStepState.InProgress) && (
                <>
                  {/* connect charger */}
                  {currentChargingProgressStepName ===
                    ChargingProgressStepName.ConnectCharger && (
                    <ConnectChargerStepPrimaryView
                      reconnectCharger={reconnectCharger}
                    />
                  )}

                  {/* checking balance */}
                  {currentChargingProgressStepName ===
                    ChargingProgressStepName.CheckWalletBalance && (
                    <CheckPaytmWalletBalanceStepPrimaryView />
                  )}

                  {/* charging starting soon */}
                  {(currentChargingProgressStepName ===
                    ChargingProgressStepName.StartCharging ||
                    currentChargingProgressStepName ===
                      ChargingProgressStepName.WaitForAcceptChargingRequest) && (
                    <ChargingStartingSoonStepPrimaryView />
                  )}

                  {/* charging started */}
                  {currentChargingProgressStepName ===
                    ChargingProgressStepName.FetchMeterReadings && (
                    <ChargingStartedStepPrimaryView />
                  )}
                </>
              )}

              {/* Completed */}
              {chargingProgressStepsMap[currentChargingProgressStepName]
                ?.stepState === ChargingProgressStepState.Completed && (
                <>
                  {(currentChargingProgressStepName ===
                    ChargingProgressStepName.ConnectCharger ||
                    currentChargingProgressStepName ===
                      ChargingProgressStepName.StartCharging ||
                    currentChargingProgressStepName ===
                      ChargingProgressStepName.WaitForAcceptChargingRequest ||
                    currentChargingProgressStepName ===
                      ChargingProgressStepName.FetchMeterReadings) && (
                    <ChargingStartedStepPrimaryView />
                  )}
                </>
              )}

              {/* Failed */}
              {chargingProgressStepsMap[currentChargingProgressStepName]
                ?.stepState === ChargingProgressStepState.Failed && (
                <ChargingFailedStepPrimaryView />
              )}
            </View>
          )}

          {/* progress steps card */}
          <View
            style={{
              ...StyleHelpers.themeBorder,
              backgroundColor: COLORS.whiteColor,
              width: "100%",
              marginTop: 35,
              maxHeight: 320,
              paddingHorizontal: 20,
              paddingVertical: 24,
            }}
          >
            <ScrollView
              ref={scrollViewRef}
              showsVerticalScrollIndicator={false}
            >
              {chargingProgressSteps.map((stepName, index) => {
                const step = chargingProgressStepsMap[stepName];
                let description = step.notStartedDesc;
                let descColor = COLORS.greyText;
                let sepColor = COLORS.dividerColor;

                if (step.stepState === ChargingProgressStepState.InProgress) {
                  description = step.inProgressDesc;
                  descColor = COLORS.darkBlack;
                  sepColor = COLORS.primaryLight;
                } else if (
                  step.stepState === ChargingProgressStepState.Completed
                ) {
                  description = step.completedDesc;
                  descColor = COLORS.primaryColor;
                  sepColor = COLORS.primaryColor;
                } else if (
                  step.stepState === ChargingProgressStepState.Failed
                ) {
                  description = step.failedDesc || "Failed";
                  descColor = COLORS.brightRed;
                  sepColor = COLORS.brightRed;
                }

                return (
                  <>
                    {index !== 0 && (
                      <View
                        key={`sep-${index}`}
                        style={{
                          ...StyleHelpers.verticallyCenteredRow,
                          zIndex: 1,
                        }}
                      >
                        <View
                          style={{
                            flex: 0.2,
                            position: "relative",
                            height: 20,
                            ...StyleHelpers.totalCenter,
                          }}
                        >
                          <VerticalLine
                            style={{
                              height: "100%",
                              width: 3,
                              backgroundColor: sepColor,
                              borderRadius: 1,
                              paddingTop: 0,
                              paddingBottom: 0,
                              position: "absolute",
                            }}
                          />
                        </View>
                        <View style={{ flex: 0.8 }} />
                      </View>
                    )}

                    <View
                      key={`${step.stepName}`}
                      style={{
                        ...StyleHelpers.verticallyCenteredRow,
                        minHeight: 60,
                        zIndex: 2,
                      }}
                    >
                      <View
                        style={{
                          flex: 0.2,
                          ...StyleHelpers.totalCenter,
                        }}
                      >
                        {step.stepState ===
                          ChargingProgressStepState.NotStarted && (
                          <View
                            style={{
                              ...StyleHelpers.totalCenter,
                              backgroundColor: COLORS.lightGrey,
                              height: 40,
                              width: 40,
                              borderRadius: 40,
                            }}
                          >
                            <View
                              style={{
                                backgroundColor: COLORS.whiteColor,
                                height: 15,
                                width: 15,
                                borderRadius: 15,
                              }}
                            />
                          </View>
                        )}
                        {step.stepState ===
                          ChargingProgressStepState.InProgress && (
                          <View>
                            <ActivityIndicator
                              color={COLORS.blackColor}
                              size={45}
                            />
                          </View>
                        )}
                        {step.stepState ===
                          ChargingProgressStepState.Completed && (
                          <View
                            style={{
                              height: 45,
                              width: 45,
                              ...StyleHelpers.totalCenter,
                            }}
                          >
                            <BlueCheckIcon height={140} width={140} />
                          </View>
                        )}
                        {step.stepState ===
                          ChargingProgressStepState.Failed && (
                          <View
                            style={{
                              height: 45,
                              width: 45,
                              ...StyleHelpers.totalCenter,
                            }}
                          >
                            <CloseFilledIcon
                              style={{
                                width: 140,
                                height: 140,
                              }}
                            />
                          </View>
                        )}
                      </View>
                      <View
                        style={{
                          flex: 0.8,
                        }}
                      >
                        <CText
                          semiBold
                          size={14}
                          style={{
                            color: descColor,
                            fontWeight: "600",
                          }}
                        >
                          {description}
                        </CText>
                        {step.stepName ===
                          ChargingProgressStepName.FetchMeterReadings &&
                          chargingProgressStepsMap[step.stepName]?.stepState ===
                            ChargingProgressStepState.InProgress && (
                            <CText
                              medium
                              size={11}
                              style={{
                                color: COLORS.grey600,
                              }}
                            >
                              {t("Wait2to3minsForMeterValues")}
                            </CText>
                          )}
                      </View>
                    </View>
                  </>
                );
              })}
            </ScrollView>
          </View>
        </View>
      </ScrollView>

      <ScreenBottomBar
        style={{
          backgroundColor: bgColor,
        }}
      >
        <View
          style={{
            backgroundColor: THEME_COLORS.bgColor,
            width: "100%",
          }}
        >
          {currentChargingProgressStepName ===
            ChargingProgressStepName.ConnectCharger && (
            <CButton
              title={
                waitForIHaveConnectedChargerCheck
                  ? t("Checking...")
                  : t("iHaveConnected")
              }
              disable={waitForIHaveConnectedChargerCheck}
              onPress={async () => {
                setWaitForIHaveConnectedChargerCheck(true);

                try {
                  const response = await getIsChargingAllowed();
                  if (response?.data?.is_charging_allowed) {
                    onStepComplete(ChargingProgressStepName.ConnectCharger);
                  } else {
                    setReconnectCharger(true);
                  }
                  setWaitForIHaveConnectedChargerCheck(false);
                } catch (e) {
                  setWaitForIHaveConnectedChargerCheck(false);
                  ErrorHandlerService.handleError(e, {
                    toastOptions: {
                      type: "error",
                    },
                  });
                }
              }}
              style={{
                marginVertical: 5,
              }}
            />
          )}
        </View>
        {
          <TouchableOpacity
            activeOpacity={0.7}
            style={styles.bottomLine}
            onPress={handleFacingIssuesPress}
          >
            <CText value={t("facingIssues?")} size={12} semiBold />
          </TouchableOpacity>
        }
      </ScreenBottomBar>

      <ReactModal
        isOpen={isCancelModalVisible}
        closeModal={closeModal}
        handleAccept={handleModalCancelCharging}
        description="Are you sure you want to cancel charging?'"
      />

      {!cpConnected &&
        cpNotConnectedError &&
        chargingProgressStepsMap[ChargingProgressStepName.StartCharging]
          .stepState !== ChargingProgressStepState.Completed && (
          <FullScreenOverlay
            style={{
              top: 310,
              backgroundColor:
                chargingProgressStepsMap[currentChargingProgressStepName]
                  ?.stepState === ChargingProgressStepState.Failed
                  ? COLORS.errorTransparent04
                  : THEME_COLORS.bgColor + "90",
            }}
          />
        )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainCont: {
    ...StyleHelpers.pageSpacing,
    paddingBottom: 20,
    flex: 1,
  },
  videoCont: {
    padding: 16,
    alignSelf: "center",
    borderRadius: 180,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: 180,
    width: 180,
    backgroundColor: THEME_COLORS.primaryBorder,
  },
  lottieCont: {
    borderRadius: 200,
    height: 180,
    width: 180,
    ...StyleHelpers.totalCenter,
  },
  bottomLine: {
    marginTop: 5,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.blackColor,
    alignSelf: "center",
  },
  currentActionText: {
    textAlign: "center",
    marginTop: 16,
    minHeight: 80,
  },
});
