import { FC, ReactElement } from "react";
import {
  FlatList,
  Image,
  StyleSheet,
  TouchableHighlight,
  View,
} from "react-native";
import CText from "../../components/CText";
import { FilterGroup } from "../../../types";
import { useAppliedFiltersGroupCount } from "../../../hooks";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { COLORS } from "../../../config/colors";
import CountBadge from "../CountBadge";
import { horizontalSize } from "../../../functions/responsive";

type Props = {
  groups: FilterGroup[];
  selectedGroup: FilterGroup | null;
  onSelectGroup: (group: FilterGroup) => void;
};

type FilterGroupItemProps = {
  item: FilterGroup;
  isSelected?: boolean;
  onPress: () => void;
  indicatorText?: string;
};

const FilterGroups: FC<Props> = (props: Props) => {
  const { groups = [], selectedGroup = null, onSelectGroup = () => {} } = props;

  const appliedFiltersGroupCount = useAppliedFiltersGroupCount();

  return (
    <View
      style={[
        styles.container,
        {
          maxWidth: horizontalSize(28),
        },
      ]}
    >
      <FlatList
        data={groups}
        keyExtractor={(item: FilterGroup) => item.value}
        renderItem={({ item }: { item: FilterGroup }) => {
          const isSelected = selectedGroup?.value === item.value;

          return (
            <FilterGroupItem
              item={item}
              isSelected={isSelected}
              onPress={() => onSelectGroup(item)}
              indicatorText={`${appliedFiltersGroupCount[item.value] || ""}`}
            />
          );
        }}
      />
    </View>
  );
};

export default FilterGroups;

const FilterGroupItem = (props: FilterGroupItemProps): ReactElement => {
  const {
    item,
    isSelected = false,
    indicatorText = "",
    onPress = () => {},
  } = props;

  return (
    <TouchableHighlight
      underlayColor={COLORS.lightPrimaryBackgroundColor}
      onPress={onPress}
      style={[
        styles.itemContainer,
        isSelected
          ? {
              backgroundColor: COLORS.whiteColor,
              borderLeftColor: COLORS.blackColor,
              borderLeftWidth: 4,
            }
          : {
              paddingLeft: 16,
              backgroundColor: COLORS.lightPrimaryBackgroundColor,
            },
      ]}
    >
      <>
        {!!item.flag && (
          <View
            style={[
              styles.flagContainer,
              { backgroundColor: item.flag_color || THEME_COLORS.primaryColor },
            ]}
          >
            <CText style={{ color: item.flag_text_color || COLORS.whiteColor }}>
              {item.flag}
            </CText>
          </View>
        )}
        <View style={styles.imageContainer}>
          <Image source={{ uri: item.group_cover.src }} style={styles.image} />
          {!!indicatorText && (
            <CountBadge
              count={indicatorText}
              style={{
                top: -4,
                right: -4,
              }}
            />
          )}
        </View>
        <CText
          size={10}
          medium
          style={{
            ...styles.itemLabel,
            fontWeight: isSelected ? "600" : "500",
          }}
          textProps={{ numberOfLines: 3 }}
        >
          {item.name}
        </CText>
      </>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.lightPrimaryBackgroundColor,
    paddingTop: 24,
  },
  itemContainer: {
    alignItems: "center",
    paddingVertical: 14,
    paddingHorizontal: 14,
  },
  imageContainer: {
    marginBottom: 4,
  },
  image: {
    height: 64,
    width: 64,
  },
  itemLabel: {
    textAlign: "center",
  },
  flagContainer: {
    marginBottom: -12,
    paddingHorizontal: 6,
    borderRadius: 2,
    zIndex: 1,
  },
});
