import { View, ViewStyle } from "react-native";
import { COLORS } from "../../config/colors";

type Props = {
  style?: ViewStyle;
};
export function FullScreenOverlay(props: Props) {
  const { style = {} } = props;

  return (
    <View
      style={{
        backgroundColor: COLORS.blackTint,
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        ...style,
      }}
    />
  );
}
