import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LangCodes } from '../central-atoms/enums/generic/i18n/LangCodes';
import bn from './bn.json';
import en from './en.json';
import gu from './gu.json';
import hi from './hi.json';
import kn from './kn.json';
import ml from './ml.json';
import mr from './mr.json';
import ta from './ta.json';
import te from './te.json';

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: LangCodes.en,
    fallbackLng: LangCodes.en,
    resources: {
        en: en,
        hi: hi,
        kn: kn,
        mr: mr,
        ml: ml,
        ta: ta,
        te: te,
        gu: gu,
        bn: bn,
    },
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18n;
