import { APP_VARIANT } from "../../config/setting";
import { AvailableAppVariants } from "../enums";
import { APP_CONFIG } from "./themeConfig";

/**
 * @WHITE_LABEL_CHANGES
 */

const themeStyles = APP_CONFIG?.[APP_VARIANT]?.styles ?? APP_CONFIG[AvailableAppVariants.PulseEnergyLite].styles;

export const THEME_STYLES = {
    input: themeStyles.input,
    buttonBorder: themeStyles.buttonBorder,
    homeScreenLogo: themeStyles.homeScreenLogo,
};