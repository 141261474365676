//External dependencies
import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import { SCREEN_NAMES } from "../../constants";
import SplashScreen from "../../ui/screens/SplashScreen";
import SelectLanguageScreen from "../../ui/screens/SelectLanguageScreen";
import EnterPhoneNumberScreen from "../../ui/screens/EnterPhoneNumberScreen";
import EnterOtpScreen from "../../ui/screens/EnterOtpScreen";

const AuthStackNavigator = createStackNavigator();

export const AuthStack = () => {
  return (
    <AuthStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
      initialRouteName={SCREEN_NAMES.SplashScreen}
    >
      <AuthStackNavigator.Screen
        name={SCREEN_NAMES.SplashScreen}
        component={SplashScreen}
      />
      <AuthStackNavigator.Screen
        name={SCREEN_NAMES.SelectLanguageScreen}
        component={SelectLanguageScreen}
      />
      <AuthStackNavigator.Screen
        name={SCREEN_NAMES.EnterPhoneNumberScreen}
        component={EnterPhoneNumberScreen}
      />
      <AuthStackNavigator.Screen
        name={SCREEN_NAMES.EnterOTPScreen}
        component={EnterOtpScreen}
      />
    </AuthStackNavigator.Navigator>
  );
};
