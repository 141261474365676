import { StyleSheet } from 'react-native';
import { THEME_COLORS } from '../theme/constants/themeColors';
import { COLORS } from '../config/colors';

const padding = 16;

export const StyleHelpers = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    backgroundColor: THEME_COLORS.bgColor,
    marginTop: -5
  },
  totalCenter: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  rowStyle: {
    flexDirection: 'row'
  },
  verticalCenter: {
    justifyContent: 'center'
  },
  verticallyCenteredRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 0,
    padding: padding,
    borderBottomWidth: 1,
    backgroundColor: THEME_COLORS.bgHeader,
    borderBottomColor: THEME_COLORS.primaryBorder
  },
  root: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: THEME_COLORS.bgColor,
    justifyContent: 'space-between'
  },
  actionBar: {
    padding: padding
  },
  horizontallyCenteredRow: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  pageSpacing: {
    paddingLeft: padding,
    paddingRight: padding
  },
  shadow: {
    // iOS
    shadowColor: COLORS.darkBlack,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.15,
    shadowRadius: 6,
    // android
    elevation: 3
  },
  themeBorder: {
    borderColor: THEME_COLORS.cardBorderColor,
    borderWidth: 1,
    borderBottomWidth: 3,
    borderRadius: 16
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: COLORS.dividerColor
  }
});
