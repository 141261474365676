import { useEffect, useRef, useState } from "react";
import { getTimeDiff } from "../../../functions/time";

type Props = {
	startTimestamp: string;
	endTimestamp?: string | null;
};
const Timer = (props: Props) => {
	const { startTimestamp, endTimestamp } = props;

	const timerInterval = useRef<any>(null);
	const [timeDiff, setTimeDiff] = useState<number | string | null>(null);

	// const [shouldContinueTimer, setShouldContinueTimer] = useState<boolean>(false);

	const startTimer = () => {
		clearInterval(timerInterval.current);
		timerInterval.current = setInterval(() => {
			computeDiff();
		}, 1000);
	};

	const computeDiff = () => {
		if (startTimestamp) {
			const timeDiff = getTimeDiff(
				startTimestamp,
				endTimestamp ?? new Date().toISOString()
			);
			setTimeDiff(
				`${timeDiff.hours}:${timeDiff.minutes}:${timeDiff.seconds}`
			);
		} else setTimeDiff(null);
	};

	// useInterval(() => {
	//     console.log('compute diff');
	//     computeDiff();
	// }, shouldContinueTimer ? 1000 : null);

	useEffect(() => {
		if (endTimestamp) {
			computeDiff();
			// setShouldContinueTimer(false);
			clearInterval(timerInterval.current);
		} else {
			startTimer();
			// setShouldContinueTimer(true);
		}

		return () => {
			// setShouldContinueTimer(false);
			clearInterval(timerInterval.current);
		};
	}, [startTimestamp, endTimestamp]);

	return <>{timeDiff ?? "-"}</>;
};

export default Timer;
