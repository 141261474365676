import { View, ViewStyle } from "react-native";
import CText from "../../components/CText";
import styles from "./styles";

type Props = {
	count: number | string;
	style?: ViewStyle;
};
export default function CountBadge(props: Props) {
	const { count = "", style = {} } = props;

	return (
		<View
			style={{
				...styles.container,
				...style,
			}}
		>
			<CText bold size={9} style={styles.text}>
				{count}
			</CText>
		</View>
	);
}
