import { useSelector } from 'react-redux';
import { SharedWallet } from '../types/index';
import { APP_STATE } from '../types/index';
import { Wallet } from '../central-atoms/types/wallet';
import { PaymentMethodType } from '../central-atoms/payment-methods/types/enum';

export const useActivePaymentMethodType = (): PaymentMethodType | null => {
  return useSelector((state: APP_STATE) => state.wallet.data.activePaymentMethodType);
};

export const useActiveWallet = (): Wallet | null => {
  return useSelector((state: APP_STATE) => state.wallet.data.activeWallet);
};

export const useFleetWallets = (): Wallet[] => {
  return useSelector((state: APP_STATE) => state.wallet.data.fleetWallets);
};

export const useSharedWallets = (): Wallet[] => {
  return useSelector((state: APP_STATE) => state.wallet.data.sharedWallets);
};

export const usePersonalWallets = (): Wallet[] => {
  return useSelector((state: APP_STATE) => state.wallet.data.personalWallets);
};

export const useWalletConsumers = (walletId: string): SharedWallet[] => {
  // if (!walletId) return [];
  return useSelector((state: APP_STATE) => state.wallet?.data?.walletConsumers?.[`${walletId}`] ?? []);
};
