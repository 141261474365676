import { CENTRAL_HOST } from "../config/setting";
import { TextInput } from "react-native";
import { useNavigationContainerRef } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { useEffect, useState } from "react";
import { GLOBAL_VARS, LS_KEYS } from "../constants";
import AsyncStorage from "@react-native-async-storage/async-storage";
import i18n from "../lang/i18n";
import AuthenticationService from "../services/AuthenticationService";
import { setAuthToken } from "../actions/AuthAction";
import { sleep } from "../functions/time";
import AuthenticatedUserRequestService from "../services/AuthenticatedUserRequestService";
import { ErrorHandlerService } from "../services/ErrorHandlerService";
import { batch, useDispatch } from "react-redux";
import {
  setAppInitialized,
  setUserAuthenticated,
} from "../actions/mainActions";
import HttpRequestService from "../config/HttpRequestService";
import { setGlobalVar } from "../helpers";
import {
  useAuthToken,
  useCentralUserProfile,
  useSelectedLanguage,
} from "../hooks";
import AuthAction from "../redux/reducer/auth/actions";
import { AuthStack } from "./stacks/AuthStack";
import { MainStack } from "./stacks/MainStack";

// Remove font scale
(Text as any).defaultProps = (Text as any).defaultProps || {};
(Text as any).defaultProps.allowFontScaling = false;
(TextInput as any).defaultProps = (TextInput as any).defaultProps || {};
(TextInput as any).defaultProps.allowFontScaling = false;

export default function NavStart() {
  const navigationRef = useNavigationContainerRef();
  const Stack = createStackNavigator();
  const [loading, setLoading] = useState(true);
  const [dataObj, setDataObj] = useState<any>(null);
  const dispatch = useDispatch();

  const centralUserProfile = useCentralUserProfile();

  const authToken = useAuthToken();
  const { setCurrentLanguage, setPartialLogin } = AuthAction;

  const selectedLanguage = useSelectedLanguage();

  const initialize = async () => {
    try {
      const language = await AsyncStorage.getItem(LS_KEYS.activeLanguage);
      if (language) {
        await i18n.changeLanguage(language);
        dispatch(setCurrentLanguage(language));
      }

      const token = await AuthenticationService.getAuthToken();
      const paymentMode = await AsyncStorage.getItem(LS_KEYS.paymentMode);

      const enteredFleetCode = await AsyncStorage.getItem(
        LS_KEYS.enteredFleetCode
      );
      const enteredAccessCode = await AsyncStorage.getItem(
        LS_KEYS.enteredAccessCode
      );
      if (typeof token === "string") {
        dispatch(setAuthToken(token));
        dispatch(setPartialLogin(paymentMode === null));

        await sleep(100);

        try {
          const { data } = await AuthenticatedUserRequestService.fetchDriverUserProfile();

          AuthenticationService.login(dispatch, {
            centralUser: data.central_user,
            primaryUser: data.user,
            fleetCode: enteredFleetCode,
            accessCode: enteredAccessCode,
            paymentMode: paymentMode,
            selectedLanguage: selectedLanguage,
          });
          setDataObj(data);
          setLoading(false);
        } catch (e: any) {
          if (e?.response?.status === 401) {
            console.log("Logging out...");
            AuthenticationService.logout(dispatch);
          }
          ErrorHandlerService.handleError(e, {
            toastOptions: {
              type: "error",
            },
            openToast: false,
          });
          setLoading(false);
          setDataObj(null);
        }
      } else {
        batch(() => {
          dispatch(setAuthToken(null));
          dispatch(setUserAuthenticated(false));
          dispatch(setPartialLogin(null));
          dispatch(setAppInitialized(true));
        });
        setLoading(false);
        setDataObj(null);
      }

      // wait for some time before handling the initial notification
      await sleep(100);
    } catch (e) {
      // console.log('Logging out...');
      AuthenticationService.logout(dispatch);
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error",
        },
      });
      setLoading(false);
      setDataObj(null);
    }
  };

  useEffect(() => {
    const httpRequestInstance = new HttpRequestService();
    const centralHttpRequestInstance = new HttpRequestService();
    if (authToken) httpRequestInstance.setInstance(authToken);
    else httpRequestInstance.setInstance();
    if (centralUserProfile?.central_token) {
      centralHttpRequestInstance.setCustomInstance({
        authToken: centralUserProfile?.central_token,
        config: {
          baseURL: CENTRAL_HOST,
        },
      });
    } else {
      centralHttpRequestInstance.setCustomInstance({
        config: {
          baseURL: CENTRAL_HOST,
        },
      });
    }
    setGlobalVar(GLOBAL_VARS.httpRequest, httpRequestInstance);
    setGlobalVar(GLOBAL_VARS.centralHttpRequest, centralHttpRequestInstance);
  }, [authToken, centralUserProfile?.central_token]);

  useEffect(() => {
    const httpRequestInstance = new HttpRequestService();
    if (authToken) httpRequestInstance.setInstance(authToken);
    else httpRequestInstance.setInstance();

    setGlobalVar(GLOBAL_VARS.httpRequest, httpRequestInstance);
  }, [authToken]);

  useEffect(() => {
    initialize();
  }, []);

  return loading ? null : (
    <Stack.Navigator
      initialRouteName={
        loading || !dataObj || dataObj === null ? "AuthStack" : "MainStack"
      }
    >
      <Stack.Screen
        name="MainStack"
        component={MainStack}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="AuthStack"
        component={AuthStack}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
}
