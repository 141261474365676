import { StyleSheet } from "react-native";
import { COLORS } from "../../../config/colors";

export default StyleSheet.create({
	container: {
		backgroundColor: COLORS.whiteColor,
		padding: 16,
		borderRadius: 16,
		borderWidth: 1,
		borderColor: COLORS.primaryLight,
		flexDirection: "row",
	},
	iconContainer: {
		backgroundColor: COLORS.lightGrey,
		borderRadius: 16,
		alignItems: "center",
		justifyContent: "center",
		alignSelf: "flex-start",
		height: 44,
		width: 44,
	},
	icon: {
		width: 20,
		height: 20,
	},
	textContainer: { flex: 1, marginEnd: 8 },
	textColor: {
		color: COLORS.secondaryBlackColor,
	},
});
