import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { t } from 'i18next';
import moment from 'moment';
import { useMemo } from 'react';
import { TouchableHighlight, View } from 'react-native';
import { SCREEN_NAMES } from '../../../constants';
// import { LocationPinFilledIcon, WalletIcon } from '../../../assets/icons';
import { BillRefundStatus } from '../../../central-atoms/bill/enums';
import { BillPaymentStatusMap } from '../../../central-atoms/constants/charging/bill/bill';
import { BillPaymentStatus } from '../../../central-atoms/enums/charging/bill/bill';
import { ChargeTransaction } from '../../../central-atoms/types/charging/charge-txns';
import CText from '../../components/CText';
import { COLORS } from '../../../config/colors';
import { NumberFormatText } from '../../../miscellaneous/components/NumberFormatText';
import { StyleHelpers } from '../../../miscellaneous/constants/style-helpers';
import { LocationPinFilledIcon, WalletIcon } from '../../../icons';

type Props = {
  chargeTxn: ChargeTransaction | any;
};
export default function ChargeTxnCard(props: Props) {
  const { chargeTxn } = props;

  const navigation: any = useNavigation();

  // console.log('chargeTxn', chargeTxn);

  const paymentStatus = useMemo(() => {
    if (chargeTxn?.bill?.payment_status === BillPaymentStatusMap[BillPaymentStatus.Paid]) {
      if (chargeTxn?.bill?.refund_status === BillRefundStatus.Completed) {
        return {
          textColor: COLORS.greenColor,
          text: t('RefundCompleted')
        };
      }

      if (Number(chargeTxn.energy_delivered) > 0) {
        return {
          textColor: COLORS.greenColor,
          text: t('SuccessfulCharge')
        };
      }
    } else if (chargeTxn?.bill?.payment_status === BillPaymentStatusMap[BillPaymentStatus.PartiallyPaid]) {
      return {
        textColor: COLORS.teal300,
        text: t(chargeTxn?.bill?.payment_status)
      };
    } else if (chargeTxn?.bill?.payment_status === BillPaymentStatusMap[BillPaymentStatus.Pending]) {
      return {
        textColor: COLORS.lightOrange,
        text: t(chargeTxn?.bill?.payment_status)
      };
    } else if (chargeTxn?.bill?.payment_status === BillPaymentStatusMap[BillPaymentStatus.Failed]) {
      return {
        textColor: COLORS.red600,
        text: t(chargeTxn?.bill?.payment_status)
      };
    }

    return {
      textColor: COLORS.greyText,
      text: chargeTxn?.bill?.payment_status ? t(chargeTxn?.bill?.payment_status) : ''
    };
  }, [chargeTxn?.bill?.payment_status]);

  return (
    <TouchableHighlight
      underlayColor={COLORS.grey50}
      activeOpacity={0.8}
      onPress={() => {
        navigation.navigate(
          SCREEN_NAMES.ChargingSuccessfulScreen as never,
          {
            chargeTxnId: chargeTxn.id,
            returnScreen: SCREEN_NAMES.ChargingHistoryScreen
          } as never
        );
      }}
      style={{
        ...StyleHelpers.themeBorder,
        paddingHorizontal: StyleHelpers.pageSpacing.paddingLeft,
        paddingVertical: StyleHelpers.pageSpacing.paddingLeft - 4,
        marginVertical: 8,
        backgroundColor: COLORS.whiteColor
      }}>
      <>
        <View
          style={{
            ...StyleHelpers.verticallyCenteredRow,
            justifyContent: 'space-between',
            borderBottomColor: COLORS.dividerColor,
            borderBottomWidth: 1,
            paddingBottom: StyleHelpers.pageSpacing.paddingLeft - 4,
            marginBottom: StyleHelpers.pageSpacing.paddingLeft - 4
          }}>
          <CText semiBold size={14} style={{ fontWeight: '500' }}>
            {moment(chargeTxn.created_on).format('MMM DD, YYYY')}
          </CText>
          <View>
            <CText
              medium
              size={12}
              style={{
                textAlign: 'right',
                color: paymentStatus.textColor
              }}>
              {paymentStatus.text}
            </CText>
          </View>
        </View>

        {/* cs name */}
        <View
          style={{
            // ...StyleHelpers.verticallyCenteredRow,
            flexDirection: 'row',
            marginBottom: 4
          }}>
          <View
            style={{
              flex: 0.1
            }}>
            <LocationPinFilledIcon
              height={20}
              width={20}
              style={{
                marginRight: 10,
                marginTop: 5
              }}
            />
          </View>
          <View
            style={{
              flex: 0.9
            }}>
            <CText size={14}>{chargeTxn?.charge_point.charging_station.name}</CText>
          </View>
        </View>

        {/* bill amount */}
        {(!!chargeTxn.bill?.amount.total || !!chargeTxn.bill?.refund_amount) && (
          <View
            style={{
              ...StyleHelpers.verticallyCenteredRow
            }}>
            <View
              style={{
                flex: 0.1
              }}>
              <WalletIcon
                height={20}
                width={20}
                style={{
                  marginRight: 10
                }}
              />
            </View>
            <View
              style={{
                flex: 0.9
              }}>
              <CText size={14}>
                <NumberFormatText
                  displayType={'text'}
                  prefix={'₹'}
                  decimalScale={2}
                  // value={Number(activeChargeTransaction?.bill?.amount?.total).toFixed(2)}
                  value={chargeTxn.bill?.amount.total || chargeTxn.bill?.refund_amount}
                  thousandSeparator={true}
                  thousandsGroupStyle={'lakh'}
                  s
                />
              </CText>
            </View>
          </View>
        )}
      </>
    </TouchableHighlight>
  );
}
