import { combineReducers } from 'redux';
import auth from './auth/reducers';
import chargeTxnsReducer from '../../store/chargeTxnsReducer';
import MainReducer from '../store/mainReducers';
import chargingStationReducer from '../../store/chargingStationReducer';
import doChargingReducer from '../store/doChargingReducer';
import { FiltersReducer } from '../store/filterReducers';
import WalletReducer from '../store/walletReducers';
import { RESET_STORE } from '../../types';
import { INITIAL_STATE } from '../../store/defaultStore';


const appReducer = combineReducers({
    main: MainReducer,
    auth,
    chargingStation: chargingStationReducer,
    doCharging: doChargingReducer,
    filters: FiltersReducer,
    chargeTxns: chargeTxnsReducer,
    wallet: WalletReducer,
});

// ref: https://stackoverflow.com/a/35641992/4331993
const rootReducer = (state: any, action: any) => {
    if (action.type === RESET_STORE) {
        return appReducer(INITIAL_STATE, action);
    }
    return appReducer(state, action)
}

export default rootReducer;
