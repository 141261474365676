import { Dimensions, Image, ScrollView, View } from "react-native";
import { Images } from "../../../../../config/image";
import ScreenBottomBar from "../../../../../miscellaneous/components/ScreenBottomBar";
import ScreenHeader from "../../../../../miscellaneous/components/ScreenHeader";
import { ScreenComponent } from "../../../../../miscellaneous/types/components";
import styles from "./styles";
import { useSelectedChargingStation, useUsersPhysicalLocation } from "../../../../../hooks";
import { COLORS } from "../../../../../config/colors";
import CText from "../../../../components/CText";
import CButton from "../../../../components/CButton";
import { GOOGLE_MAP_KEY, PIN_TYPES } from "../../../../../constants";
import GoogleMapReact from "google-map-react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useTranslation } from "react-i18next";

type Props = ScreenComponent & {
  onBackPress: () => void;
  onNextPress: () => void;
  cpConnected: boolean;
  cpNotConnectedError: null | any;
  active: boolean;
};

export default function ConfirmCS(props: Props) {
  const { navigation, onBackPress = () => {}, onNextPress = () => {} } = props;
  const { t } = useTranslation();
  const usersPhysicalLocation = useUsersPhysicalLocation();
  const selectedChargingStation = useSelectedChargingStation();

  const mediaList = ["location", ...(selectedChargingStation?.media?.files ?? [])];

  const createMapOptions = () => {
    return {
      draggable: true,
      zoomControl: false, // Hide the default zoom control
      mapTypeControl: false, // Hide the default map type control,
      gestureHandling: "greedy",
      fullscreenControl: false,
      disableDefaultUI: true
    };
  };

  const createMarkerClusterer = (map, markers) => {
    return new MarkerClusterer({ map, markers });
  };

  const createMarker = (map, maps, position, iconUrl, isCurrentLocationMarker = false) => {
    const marker = new maps.Marker({
      position,
      map,
      icon: {
        url: iconUrl,
        scaledSize: new maps.Size(36, 46) // Adjust the size as needed
      }
    });

    return marker;
  };

  const handleApiLoaded = (map, maps) => {
    // Add your markers
    let markers: any = [];
    markers.push(
      createMarker(
        map,
        maps,
        {
          lat: selectedChargingStation?.latitude as number,
          lng: selectedChargingStation?.longitude as number
        },
        PIN_TYPES.FatRedPin,
        true
      )
    );

    if (usersPhysicalLocation) {
      markers.push(
        createMarker(
          map,
          maps,
          {
            lat: usersPhysicalLocation.latitude,
            lng: usersPhysicalLocation.longitude
          },
          Images.user_location,
          true
        )
      );
    }

    // Create a marker clusterer
    const markerCluster = createMarkerClusterer(map, markers);
  };

  const defaultProps = {
    center: {
      lat: parseFloat(`${selectedChargingStation?.latitude}`),
      lng: parseFloat(`${selectedChargingStation?.longitude}`)
    },
    zoom: 15
  };

  return (
    <View style={styles.root}>
      <ScreenHeader
        navigation={navigation}
        style={{ borderBottomWidth: 0, position: "sticky" as any, top: 0, right: 0, left: 0 }}
        onBackPress={() => {
          onBackPress();
        }}
      />

      <ScrollView
        contentContainerStyle={{
          flex: 1,
          justifyContent: "space-between"
        }}>
        <View style={styles.mainCont}>
          <View style={{ padding: 16 }}>
            <CText value={selectedChargingStation?.name} size={26} semiBold style={{ fontWeight: "600" }} />
            <CText value={selectedChargingStation?.address} size={12} />
          </View>

          <View
            style={{
              minHeight: 300,
              height: "40%"
            }}>
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={{
                padding: 8
              }}>
              {mediaList.map((item, index) => {
                if (item === "location" && selectedChargingStation?.latitude && selectedChargingStation?.longitude) {
                  return (
                    <View
                      key={item}
                      style={[
                        styles.imageCont,
                        {
                          position: "relative",
                          width:
                            mediaList.length === 1
                              ? Dimensions.get("window").width - 32
                              : Dimensions.get("window").width - 128
                        }
                      ]}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY.stg }}
                        center={defaultProps.center}
                        zoom={defaultProps.zoom}
                        options={createMapOptions}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}></GoogleMapReact>
                    </View>
                  );
                }

                return (
                  <View key={item.id} style={styles.imageCont}>
                    <Image
                      source={{ uri: item?.full_url ?? item?.url }}
                      style={{
                        height: "100%",
                        width: "100%"
                      }}
                      resizeMode={"cover"}
                    />
                  </View>
                );
              })}
            </ScrollView>
          </View>
        </View>
      </ScrollView>

      <ScreenBottomBar>
        <CButton
          textStyle={{ color: COLORS.whiteColor, position: "sticky" as any, bottom: 0, right: 0, left: 0 }}
          title={t("iAmHere")}
          onPress={() => {
            onNextPress();
          }}
        />
      </ScreenBottomBar>
    </View>
  );
}
