import { StyleSheet } from "react-native";
import { COLORS } from "../../../config/colors";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { verticalSize } from "../../../functions/responsive";

export default StyleSheet.create({
  root: {
    backgroundColor: THEME_COLORS.bgColor,
    position: "relative",
    height: "100%"
  },
  rowStyle: {
    flexDirection: "row",
    alignItems: "center"
  },
  headerCont: {
    // flexDirection: 'row',
    backgroundColor: THEME_COLORS.bgColor,
    borderBottomWidth: 2,
    borderBottomColor: THEME_COLORS.borderColor,
    padding: 16,
    justifyContent: "space-between",
    ...StyleHelpers.verticallyCenteredRow
  },
  backCont: {
    padding: 16,
    margin: -16
  },
  iconStyle: {
    height: 40,
    width: 40
    // borderRadius: 8,
    // borderWidth: 1,
    // borderColor: COLORS.whiteColor,
    // ...StyleHelpers.shadow,
  },
  topDetailCont: {
    backgroundColor: COLORS.whiteColor,
    padding: 16,
    ...StyleHelpers.themeBorder
  },
  cont: {
    padding: 16
  },
  rateTxt: {
    marginTop: 6
  },
  successCont: {
    backgroundColor: COLORS.greenColor,
    borderRadius: 16,
    padding: 16,
    borderWidth: 1,
    borderBottomWidth: 3,
    borderColor: COLORS.blackColor,
    marginTop: 16,
    flexDirection: "row",
    alignItems: "center"
  },
  thumbCont: {
    backgroundColor: COLORS.darkGreen,
    borderRadius: 100,
    padding: 12,
    justifyContent: "center",
    alignItems: "center"
  },
  bottomCont: {
    padding: 16,
    borderTopWidth: 2,
    borderTopColor: COLORS.primaryLight
  }
});
