import { VerifyCode } from "../central-atoms/types/app-requests";
import { VerifySMSOTPReqPayload } from "../central-atoms/types/requests/auth";
import { VerifyAccessCodeResponse, VerifySMSOTPResponse } from "../central-atoms/types/responses/auth";
import HttpRequestService from "../config/HttpRequestService";
import { API_BASE_PATH, CENTRAL_HOST } from "../config/setting";
import { GLOBAL_VARS } from "../constants";
import { getGlobalVar } from "../miscellaneous/helpers";

export default class AuthenticationRequestService {
    static verifySmsOTP(payload: VerifySMSOTPReqPayload): Promise<VerifySMSOTPResponse> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return httpRequest.getInstance()
            .post(`${API_BASE_PATH}/verify_sms_otp`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static verifyAccessCode(payload: VerifyCode): Promise<VerifyAccessCodeResponse> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .post(`${CENTRAL_HOST}/wallet/verify_access_code`, payload)
            .then((response: any) => {
                return response.data;
            });
    }
}
