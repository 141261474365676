import { NumberFormatText } from '../../../../../miscellaneous/components/NumberFormatText';
import { ChargingMethodType } from '../../../../../central-atoms/enums/charging/charging-method';
import { getNonSIUnitChargingAmount } from '../../../../../central-atoms/functions/charge-points';
import { ChargingMethod } from '../../../../../central-atoms/types/charging/charge-points';

type Props = {
  cm: ChargingMethod;
};
export const NonSIChargingAmountText = (props: Props) => {
  const { cm } = props;

  const chargingAmount = cm ? getNonSIUnitChargingAmount(cm) : '-';
  const prefix = cm.method === ChargingMethodType.Amount ? '₹' : '';
  let suffix = '';
  if (cm.method === ChargingMethodType.Units) suffix = ' kWh';
  if (cm.method === ChargingMethodType.Time) suffix = ' mins';
  if (cm.method === ChargingMethodType.SoC) suffix = ' %';

  return <NumberFormatText prefix={prefix} suffix={suffix} value={chargingAmount} />;
};
