import { StyleSheet } from "react-native";
import { COLORS } from "../../../config/colors";
import { THEME_COLORS } from "../../../theme/constants/themeColors";

export default StyleSheet.create({
  root: {
    flexDirection: "column",
    backgroundColor: THEME_COLORS.bgColor,
    justifyContent: "space-between",
    position: "relative"
  },
  logoStyle: {
    height: 40,
    width: 148
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    margin: -16,
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.amberColor
  }
});
