import { TextStyle, View, ViewStyle } from "react-native";
import { THEME_COLORS } from "../../theme/constants/themeColors";
import { StyleHelpers } from "../constants/style-helpers";
import { COLORS } from "../../config/colors";
import CText from "../../ui/components/CText";

type Props = {
  flag: string;
  icon?: any;
  style?: ViewStyle;
  textStyle?: TextStyle;
};
export default function Pill(props: Props) {
  const { flag, style = {}, textStyle = {}, icon = null } = props;

  return (
    <View
      style={{
        ...StyleHelpers.themeBorder,
        borderColor: COLORS.darkBlack,
        borderRadius: 20,
        paddingVertical: 2,
        alignItems: "center",
        position: "absolute",
        top: -12,
        paddingHorizontal: 10,
        backgroundColor: THEME_COLORS.borderColor,
        ...style,
      }}
    >
      <View
        style={{
          ...StyleHelpers.verticallyCenteredRow,
        }}
      >
        {icon && icon}
        <CText
          medium
          size={10}
          style={{
            color: COLORS.blackColor,
            ...textStyle,
          }}
        >
          {flag}
        </CText>
      </View>
    </View>
  );
}
