import React, { useState } from 'react';
import { TouchableOpacity, View, ViewStyle } from 'react-native';
import RenderHtml, { HTMLContentModel, defaultHTMLElementModels } from 'react-native-render-html';
import { I18NRichMessageResponse, RichMessageResponse } from '../../../central-atoms/types/responses/i18n';
import { useSelectedLanguage } from '../../../hooks';
import { COLORS } from '../../../config/colors';
import CText from '../../../ui/components/CText';
// import { AlertTriangleIcon } from "../../../assets/icons";

type Props = {
  i18nError?: I18NRichMessageResponse;
  style?: ViewStyle;
  msgType?: 'error' | 'warn' | 'info' | 'success';
  expandFirstAction?: boolean;
};
export default function RichError(props: Props) {
  // enableAnimateInEaseOut();

  const { i18nError, style = {}, msgType = 'error', expandFirstAction = false } = props;

  const selectedLanguage = useSelectedLanguage();
  const richError: RichMessageResponse = (i18nError as any)?.[selectedLanguage] as RichMessageResponse;
  const [actionsOpenStateMap, setActionsOpenStateMap] = useState<{
    [id: string]: boolean;
  }>({});

  let mainTextBgColor = COLORS.lightGrey2;
  let mainTextTextColor = COLORS.darkBlack;
  if (msgType === 'error') {
    mainTextBgColor = COLORS.errorColor;
    mainTextTextColor = COLORS.whiteColor;
  } else if (msgType === 'warn') {
    mainTextBgColor = COLORS.yellow600;
    mainTextTextColor = COLORS.whiteColor;
  }

  // ref: https://meliorence.github.io/react-native-render-html/docs/content/images#via-useinternalrenderer
  // function CustomImageRenderer(props: any) {
  //     const { Renderer, rendererProps } = useInternalRenderer('img', props);
  //     const [isModalOpen, setIsModalOpen] = useState(false);
  //     const onPress = () => setIsModalOpen(true);
  //     const onModalClose = () => setIsModalOpen(false);
  //     const uri = rendererProps.source.uri;
  //     const thumbnailSource = {
  //         ...rendererProps.source,
  //         // You could change the uri here, for example to provide a thumbnail.
  //         // uri: uri?.replace('1200', '300').replace('800', '200')
  //     };
  //     console.log(rendererProps, props);
  //     return (
  //         <View style={{ alignItems: 'center' }}>
  //             <Renderer {...rendererProps} source={thumbnailSource} onPress={onPress} />
  //             <Image
  //                 source={{
  //                     uri: WHATSAPP_LOGO,
  //                 }}
  //                 resizeMode={'contain'}
  //                 style={{
  //                     height: 20,
  //                     width: 20,
  //                 }}
  //             />
  //             {/* <Modal visible={isModalOpen} onRequestClose={onModalClose}>
  //           <Renderer {...rendererProps} />
  //           <Text>A full resolution image!</Text>
  //           <Button title="Close Modal" onPress={onModalClose} />
  //         </Modal> */}
  //         </View>
  //     );
  // }

  const customHTMLElementModels = {
    img: defaultHTMLElementModels.img.extend({
      contentModel: HTMLContentModel.mixed
    })
    // enableExperimentalPercentWidth: true,
  };

  // const renderers = {
  //     img: CustomImageRenderer
  // }

  return (
    <View
      style={{
        borderRadius: 16,
        borderTopEndRadius: 16,
        borderTopStartRadius: 16,
        borderWidth: 1,
        borderColor: COLORS.primaryLight,
        borderBottomEndRadius: 16,
        borderBottomStartRadius: 16,
        borderBottomWidth: 3,
        overflow: 'hidden',
        backgroundColor: COLORS.whiteColor,
        ...style
      }}>
      {(!!richError?.main_txt || !!richError) && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: mainTextBgColor,
            padding: 16,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            overflow: 'hidden',
            width: '100%'
          }}>
          {/* <AlertTriangleIcon fill={mainTextTextColor} height={24} width={24} /> */}
          <CText
            value={richError?.main_txt ?? richError}
            size={12}
            semiBold
            style={{
              color: mainTextTextColor,
              marginStart: 16,
              marginTop: 4
            }}
          />
        </View>
      )}

      {!!richError?.sub_txt && (
        <View
          style={{
            backgroundColor: COLORS.whiteColor,
            padding: 16,
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15
          }}>
          <RenderHtml
            customHTMLElementModels={customHTMLElementModels}
            // renderers={renderers}
            // contentWidth={}
            baseStyle={{
              lineHeight: 24,
              color: COLORS.darkBlack
            }}
            // systemFonts={[FontFamily.Poppins_Regular, ...defaultSystemFonts]}
            source={{
              html: `${richError?.sub_txt}`
            }}
          />
          {/* <CText value={richError?.sub_txt} size={12} /> */}
        </View>
      )}

      {richError?.actions && (
        <>
          <View
            style={{
              height: 1,
              backgroundColor: COLORS.lightGrey,
              marginHorizontal: 16,
              borderBottomLeftRadius: 15,
              borderBottomRightRadius: 15
              // marginVertical: 8,
            }}
          />
          {richError?.actions.map((action, index) => {
            let isActionItemOpen = actionsOpenStateMap[action.btn_txt];
            if (index === 0 && expandFirstAction && actionsOpenStateMap[action.btn_txt] === undefined) {
              isActionItemOpen = true;
            }

            return (
              <View
                key={action.btn_txt}
                style={{
                  padding: 16
                }}>
                {/* answer */}
                {isActionItemOpen && (
                  <View
                    style={
                      {
                        // marginTop: 20,
                      }
                    }>
                    <CText size={13} semiBold style={{ marginBottom: 10 }}>
                      {action.btn_txt}
                    </CText>
                    <RenderHtml
                      customHTMLElementModels={customHTMLElementModels}
                      // renderers={renderers}
                      // contentWidth={}
                      baseStyle={{
                        lineHeight: 24
                      }}
                      source={{
                        html: action.info
                      }}
                    />
                  </View>
                )}
                <TouchableOpacity
                  activeOpacity={0.7}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    borderTopColor: COLORS.lightGrey,
                    borderTopWidth: isActionItemOpen ? 1 : 0,
                    paddingTop: isActionItemOpen ? 12 : 0
                  }}
                  onPress={() => {
                    setActionsOpenStateMap({
                      ...actionsOpenStateMap,
                      [action.btn_txt]: !isActionItemOpen
                    });
                  }}>
                  <View
                    style={{
                      borderBottomColor: COLORS.blackColor,
                      borderBottomWidth: 2
                      // borderStyle: 'dashed',
                      // borderRadius: 1,
                    }}>
                    <CText
                      size={12}
                      semiBold
                      style={{
                        textAlign: 'center'
                      }}>
                      {isActionItemOpen ? action.close_btn_txt || 'Hide' : action.btn_txt}
                    </CText>
                  </View>
                </TouchableOpacity>
              </View>
            );
          })}
        </>
      )}
    </View>
  );
}
