export enum GenericPaymentTxnStatus {
    Unknown = 'Unknown',
    Initiated = 'Initiated',
    Pending = 'Pending',
    Success = 'Successful', // Payment Status
    Failed = 'Failed',
    Completed = 'Completed', // Refund status
};

export enum PaymentGatewaySettlementStatus {
    /**
     * We don't know the status of the settlement
     */
    // null

    /**
     * 
     * SETTLEMENT_CONFIRMATION_PENDING
     * We have got settlement confirmation from the payment gateway and we are waiting for the settlement confirmation from the bank
     */
    SETTLEMENT_CONFIRMATION_PENDING = '10',
    /**
     * SETTLED
     * The settlement is successful to our bank
     */
    SETTLED = '20',
}
