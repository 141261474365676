import { StyleSheet } from 'react-native';
import { StyleHelpers } from '../../../miscellaneous/constants/style-helpers';
import { THEME_COLORS } from '../../../theme/constants/themeColors';

export default StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: THEME_COLORS.bgColor,
    position: 'relative'
  },
  header: {
    ...StyleHelpers.header
  }
});
