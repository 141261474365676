import { RequestSMSOTPReqPayload } from "../central-atoms/types/requests/auth";
import { RequestSMSOTPResponse } from "../central-atoms/types/responses/auth";
import HttpRequestService from "../config/HttpRequestService";
import { API_BASE_PATH } from "../config/setting";
import { GLOBAL_VARS } from "../constants";
import { getGlobalVar } from "../miscellaneous/helpers";

export default class AuthService {
    static requestSmsOtp(payload: RequestSMSOTPReqPayload): Promise<RequestSMSOTPResponse> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return httpRequest.getInstance()
            .post(`${API_BASE_PATH}/request_sms_otp`, payload, {
                params: {
                    len: 4
                }
            })
            .then((response: any) => {
                return response.data;
            });
    }
}