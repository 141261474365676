import { t } from 'i18next';
import { Text } from 'react-native';
import { NumericFormat } from 'react-number-format';
import { ChargeTransaction } from '../../../../../central-atoms/types/charging/charge-txns';
import CText from '../../../../components/CText';
import { NumberFormatText } from '../../../../../miscellaneous/components/NumberFormatText';
import GroupedLineItemsCard from '../../../../components/GroupedLineItemsCard';

type Props = {
  chargeTxn: ChargeTransaction;
};
export const LastKnownValuesCard = (props: Props) => {
  const { chargeTxn } = props;

  return (
    <GroupedLineItemsCard
      style={{ marginTop: 16 }}
      headingComponent={<CText value={t('lastKnownValues')} size={12} medium />}
      lineItems={[
        {
          leftComponent: <CText value={t('energyDelivered')} size={14} />,
          rightComponent: (
            <CText size={14}>
              {!chargeTxn?.summary?.energy_delivered ? (
                '- kWh'
              ) : (
                <NumberFormatText
                  displayType={'text'}
                  suffix={' kWh'}
                  value={Number(chargeTxn?.summary?.energy_delivered)}
                  thousandSeparator={true}
                  thousandsGroupStyle={'lakh'}
                  renderText={(value) => <Text>{value}</Text>}
                />
              )}
            </CText>
          )
        },
        {
          leftComponent: <CText value={t('power')} size={14} />,
          rightComponent: (
            <CText size={14}>
              {!chargeTxn?.summary?.power ? (
                '-'
              ) : (
                <NumericFormat
                  displayType={'text'}
                  suffix={' W'}
                  value={Number(chargeTxn?.summary?.power / 1000).toFixed(2)}
                  thousandSeparator={true}
                  thousandsGroupStyle={'lakh'}
                  renderText={(value) => <Text>{value}</Text>}
                />
              )}
            </CText>
          )
        },
        {
          leftComponent: <CText value={t('voltage')} size={14} />,
          rightComponent: (
            <CText size={14}>
              {!chargeTxn?.summary?.voltage ? (
                '-'
              ) : (
                <NumericFormat
                  displayType={'text'}
                  suffix={' V'}
                  value={Number(chargeTxn?.summary?.voltage)}
                  thousandSeparator={true}
                  thousandsGroupStyle={'lakh'}
                  renderText={(value) => <Text>{value}</Text>}
                />
              )}
            </CText>
          )
        },
        {
          leftComponent: <CText value={t('estdCost')} size={14} />,
          rightComponent: (
            <CText size={14}>
              {!chargeTxn?.summary?.last_calculated_cost ? (
                '₹-'
              ) : (
                <NumericFormat
                  displayType={'text'}
                  prefix={'₹'}
                  value={Number(chargeTxn?.summary?.last_calculated_cost).toFixed(2)}
                  thousandSeparator={true}
                  thousandsGroupStyle={'lakh'}
                  renderText={(value) => <Text>{value}</Text>}
                />
              )}
            </CText>
          )
        }
      ]}
    />
  );
};
