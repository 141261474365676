import { ChargePoint, ChargePointConnector } from '../central-atoms/types/charging/charge-points';
import { ChargeTransaction } from '../central-atoms/types/charging/charge-txns';
import {
  ChargingStation,
  MixedChargingStation,
  PrimaryChargingStation
} from '../central-atoms/types/charging/charging-station';
import {
  SET_SELECTED_CHARGE_POINT,
  SET_SELECTED_CHARGE_POINT_CONNECTOR,
  SET_SELECTED_CHARGING_STATION,
  SET_SELECTED_MIXED_CHARGING_STATION,
  SET_TRACKED_CP_CONNECTION_ERR,
  SET_TRACKED_CP_ERR_CONNECTOR,
  UPDATE_ONGOING_CHARGE_TXNS,
  UPDATE_ONGOING_CHARGE_TXN_BY_ID
} from '../../src/types';

export const setSelectedChargingStation = (
  selectedChargingStation: ChargingStation | PrimaryChargingStation | null
) => {
  return {
    type: SET_SELECTED_CHARGING_STATION,
    payload: {
      selectedChargingStation
    }
  };
};

export const setSelectedMixedChargingStation = (selectedMixedChargingStation: MixedChargingStation | null) => {
  return {
    type: SET_SELECTED_MIXED_CHARGING_STATION,
    payload: {
      selectedMixedChargingStation
    }
  };
};

export const setSelectedChargePoint = (selectedChargePoint: ChargePoint | null) => {
  return {
    type: SET_SELECTED_CHARGE_POINT,
    payload: {
      selectedChargePoint
    }
  };
};

export const setSelectedChargePointConnector = (selectedChargePointConnector: ChargePointConnector | null) => {
  return {
    type: SET_SELECTED_CHARGE_POINT_CONNECTOR,
    payload: {
      selectedChargePointConnector
    }
  };
};

export const setTrackedCPErrorConnector = (trackedCPErrorConnector: any | null) => {
  return {
    type: SET_TRACKED_CP_ERR_CONNECTOR,
    payload: {
      trackedCPErrorConnector
    }
  };
};

export const setTrackedCPConnectionError = (trackedCPConnectionError: any | null) => {
  return {
    type: SET_TRACKED_CP_CONNECTION_ERR,
    payload: {
      trackedCPConnectionError
    }
  };
};

export const updateOngoingChargeTxns = (ongoingChargeTxns: ChargeTransaction[]) => {
  return {
    type: UPDATE_ONGOING_CHARGE_TXNS,
    payload: {
      ongoingChargeTxns
    }
  };
};

export const updateOngoingChargeTxnById = (ongoingChargeTxn: ChargeTransaction) => {
  return {
    type: UPDATE_ONGOING_CHARGE_TXN_BY_ID,
    payload: {
      ongoingChargeTxn
    }
  };
};
