import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { Platform } from 'react-native';
// import { ErrorHandlerService } from "../services/ErrorHandlerService";
import { API_APP_VARIANT, API_VERSION, APP_BUILD_NUMBER, APP_VERSION, ENVIRONMENT, PULSE_API_HOST } from './setting';
import { ErrorHandlerService } from '../services/ErrorHandlerService';

export default class HttpRequestService {
  config: AxiosRequestConfig = {
    baseURL: PULSE_API_HOST,
    // withCredentials: true,
    // timeout: 20000,
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      ENV: ENVIRONMENT.type,
      'App-Env': ENVIRONMENT.type,
      'App-Version': APP_VERSION,
      // 'App-Variant': APP_VARIANT_HEADERS_MAP[APP_VARIANT],
      'App-Variant': API_APP_VARIANT,
      'App-Build': APP_BUILD_NUMBER,
      'Api-Version': API_VERSION,
      Platform: Platform.OS,
      ...(PULSE_API_HOST.includes('loca.lt') ? { 'Bypass-Tunnel-Reminder': true } : {})
      // 'Bypass-Tunnel-Reminder': true,
    }
  };

  private axiosRequest: AxiosInstance | undefined;

  constructor() {
    this.setInstance();
  }

  setRequestInterceptor(): void {
    // docs: https://rnfirebase.io/perf/axios-integration#request-interceptor

    this.axiosRequest?.interceptors.request.use(
      async (config: AxiosRequestConfig & any) => {
        try {
          // console.log('config before', config.headers['App-Variant']);
          if (config.url.includes('/v2')) {
            // console.log('changing for /v2');
            // if (API_APP_VARIANT) {
            // config.headers['App-Variant'] = API_APP_VARIANT;
            config.headers['App-Variant'] = 'instacharge_suph79gtgk9z9ft';
            // }
          }
          // console.log('config after', config.headers['App-Variant']);

          // const httpMetric = perf().newHttpMetric(
          // 	config?.url,
          // 	config.method?.toUpperCase()
          // );
          // config["metadata"] = { httpMetric };

          // add any extra metric attributes, if required
          // httpMetric.putAttribute('userId', '12345678');

          // await httpMetric.start();
        } catch (e) {
          ErrorHandlerService.handleError(e, {
            openToast: false
          });
        }

        return config;
      },
      async (e: any) => {
        ErrorHandlerService.handleError(e, {
          openToast: false
        });
        // Do something with request error
        return Promise.reject(e);
      }
    );
  }

  setResponseInterceptor(): void {
    // docs: https://rnfirebase.io/perf/axios-integration#response-interceptor

    this.axiosRequest?.interceptors.response.use(
      async (response: AxiosResponse & any) => {
        try {
          // Request was successful, e.g. HTTP code 200

          const { httpMetric } = response.config.metadata;

          // add any extra metric attributes if needed
          // httpMetric.putAttribute('userId', '12345678');

          httpMetric.setHttpResponseCode(response.status);
          httpMetric.setResponseContentType(response.headers['content-type']);
          await httpMetric.stop();

          if (response.status === 401) {
            // showToast(MSGS.UNAUTHORIZED, {
            //     type: 'error',
            // });
          }
        } catch (e) {
          ErrorHandlerService.handleError(e, {
            openToast: false
          });
        }

        return response;
      },
      async (error: any) => {
        try {
          // Request failed, e.g. HTTP code 500

          const { httpMetric } = error.config.metadata;

          // add any extra metric attributes if needed
          // httpMetric.putAttribute('userId', '12345678');

          httpMetric.setHttpResponseCode(error?.response?.status ?? null);
          httpMetric.setResponseContentType(error?.response?.headers['content-type'] ?? null);

          await httpMetric.stop();
        } catch (e) {
          ErrorHandlerService.handleError(e, {
            openToast: false
          });
        }

        // Ensure failed requests throw after interception
        return Promise.reject(error);
      }
    );
  }

  setCustomInstance(options?: {
    authToken?: string;
    config?: AxiosRequestConfig;
    headers?: AxiosRequestHeaders;
  }): AxiosInstance {
    const {
      authToken = null,
      config = {
        ...this.config
      },
      headers = {}
    } = options || {};

    this.axiosRequest = axios.create({
      ...{
        ...this.config,
        ...config
      },
      headers: {
        ...this.config.headers,
        ...headers,
        // set token in header if it's provided
        ...(authToken
          ? {
              Authorization: `Bearer ${authToken}`
            }
          : {})
      }
    });

    this.setRequestInterceptor();
    // this.setResponseInterceptor();

    return this.axiosRequest;
  }

  setInstance(authToken?: string | void): AxiosInstance {
    this.axiosRequest = axios.create({
      ...this.config,
      headers: {
        ...this.config.headers,
        // set token in header if it's provided
        ...(authToken
          ? {
              Authorization: `Bearer ${authToken}`
            }
          : {})
      }
    });

    this.setRequestInterceptor();
    // this.setResponseInterceptor();

    return this.axiosRequest;
  }

  getInstance(): AxiosInstance {
    return this.axiosRequest as AxiosInstance;
  }
}
