import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Dimensions, Keyboard, TouchableOpacity, View } from "react-native";
// @ts-ignore
import styles from "./styles";
import { ScreenComponent } from "../../../types/components";
import { useDispatch } from "react-redux";
import { useFirebaseRemoteConfigValues, useSelectedLanguage } from "../../../hooks";
import { SCREEN_NAMES } from "../../../constants";
import ScreenHeader from "../../components/ScreenHeader";
import { StyleHelpers } from "../../../constants/style-helpers";
import CText from "../../components/CText";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { COLORS } from "../../../config/colors";
import ScreenBottomBar from "../../components/ScreenBottomBar";
import CButton from "../../components/CButton";
import { LottieAnimation } from "../../../config/lottie";
import Lottie from "lottie-react";
import AuthenticationRequestService from "../../../services/AuthenticationRequestService";
import AuthenticationService from "../../../services/AuthenticationService";
import { sleep } from "../../../functions/time";
import AuthService from "../../../services/AuthService";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import SmoothPinCodeInput from "../../components/SmoothPincodeInput";

type Props = ScreenComponent & {};
export default function EnterOtpScreen(props: Props) {
  const { navigation, route } = props;
  const number = (route?.params as any)?.number;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [otp, setOtp] = useState("");
  const [sendAgain, setSendAgain] = useState(false);
  const [count, setCount] = useState<string | number>(59);
  const [invalidOtp, setInvalidOtp] = useState(false);

  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const otpInputRef: any = useRef();

  const selectedLanguage = useSelectedLanguage();

  const remoteConfigValues = useFirebaseRemoteConfigValues();

  const codeLength = remoteConfigValues ?? 4;
  let enterText = t("EnterOTP");
  if (codeLength === 4) enterText = t("Enter4digitOTP");
  else if (codeLength === 6) enterText = t("Enter6digitOTP");

  useEffect(() => {
    setCount(59);
    const interval = setInterval(() => {
      setCount((lastTimerCount) => {
        if (Number(lastTimerCount) <= 1) {
          clearInterval(interval);
          setSendAgain(true);
        }
        return zeroPad(Number(lastTimerCount) - 1, 2);
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [sendAgain]);

  const zeroPad = (num: number, places: number) => String(num).padStart(places, "0");

  const shakeOtpInput = () => {
    otpInputRef.current?.animate({
      animation: "shake",
      duration: 600
    });
  };

  const validateOtp = (otpToCheck?: number | string) => {
    const payloadOTP = otpToCheck ?? otp;

    if (isEmpty(payloadOTP)) {
      shakeOtpInput();
      setInvalidOtp(true);
    } else {
      setInvalidOtp(false);
      verifySmsOtp(payloadOTP);
    }

    Keyboard.dismiss();
  };

  const verifySmsOtp = (otpToCheck?: number | string) => {
    const payloadOTP = otpToCheck ?? otp;
    if (isEmpty(number) || isEmpty(payloadOTP)) return;

    const data = {
      phone: "+91" + number,
      otp: `${payloadOTP}`
    };

    setLoading(true);
    AuthenticationRequestService.verifySmsOTP(data)
      .then(async ({ data }) => {
        await AuthenticationService.setAuthToken(data.token);

        setLoginSuccess(true);

        // wait for the animation to complete
        await sleep(2500);

        AuthenticationService.login(dispatch, {
          sessionToken: data.token,
          selectedLanguage: selectedLanguage,
          fleetCode: null,
          accessCode: null,
          paymentMode: null,
          centralUser: data.central_user,
          primaryUser: data.user
        });

        navigation.navigate("MainStack", {
          screen: SCREEN_NAMES.SearchingScreen
        });
      })
      .catch((e: any) => {
        setLoading(false);
        shakeOtpInput();
        setInvalidOtp(true);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const requestSmsOtpAgain = () => {
    const data = {
      phone: `+91${number}`
    };

    AuthService.requestSmsOtp(data)
      .then(() => {})
      .catch((e) => {
        setLoading(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  return (
    <View style={styles.root}>
      <ScreenHeader
        navigation={navigation}
        style={{
          borderBottomWidth: 0,
          position: "sticky" as any,
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100
        }}
      />

      <View
        style={{
          ...StyleHelpers.pageSpacing,
          flex: 1,
          flexDirection: "column",
          justifyContent: "flex-end"
        }}>
        <View>
          <CText
            value={enterText}
            size={26}
            semiBold
            style={{
              color: THEME_COLORS.sectionHeader,
              fontWeight: "600"
            }}
          />
          <CText value={`${t("sentTo")} ${number}`} size={12} />
          <View style={{ marginTop: 16 }}>
            <SmoothPinCodeInput
              ref={otpInputRef}
              cellSpacing={16}
              codeLength={codeLength}
              cellSize={Dimensions.get("window").width / 9}
              maskDelay={0}
              textStyle={styles.textStyle}
              cellStyle={[
                styles.cellStyle,
                {
                  borderColor: invalidOtp ? COLORS.errorColor : COLORS.darkBlack
                }
              ]}
              cellStyleFocused={[
                styles.cellStyleFocused,
                {
                  borderColor: invalidOtp ? COLORS.errorColor : COLORS.darkBlack
                }
              ]}
              value={otp}
              onTextChange={(code: string) => setOtp(code)}
              restrictToNumbers
              autoFocus={true}
              animated={true}
            />
          </View>
          <View
            style={[
              styles.rowStyle,
              {
                marginTop: 20,
                marginBottom: 8
              }
            ]}>
            {sendAgain ? (
              <TouchableOpacity
                onPress={() => {
                  setSendAgain(false);
                  requestSmsOtpAgain();
                }}>
                <CText
                  value={`${t("sendOtpAgain")}`}
                  size={12}
                  semiBold
                  style={{
                    textDecorationLine: "underline"
                  }}
                />
              </TouchableOpacity>
            ) : (
              <CText value={`${t("sendAgainIn")} 00:${count}`} size={12} />
            )}
          </View>
        </View>
      </View>

      <ScreenBottomBar
        style={{
          position: "sticky" as any,
          bottom: 0,
          left: 0,
          right: 0,
          display: "block" as any,
          borderBottomWidth: 0,
          marginTop: 32
        }}>
        <CButton
          title={t("continue")}
          // style={{ marginTop: 32 }}
          onPress={() => {
            validateOtp();
          }}
          disable={loading}
          loading={loading}
        />
      </ScreenBottomBar>
      {loginSuccess && (
        <View
          style={{
            top: 0,
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 1,
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: THEME_COLORS.bgColor
          }}>
          <Lottie
            animationData={LottieAnimation.check}
            autoPlay
            loop={false}
            style={{
              height: 240,
              width: 240
            }}
          />
          <CText value={t("loginSuccessful")} size={26} semiBold style={{ fontWeight: "600" }} />
        </View>
      )}
    </View>
  );
}
