import { StyleSheet } from 'react-native';
import { COLORS } from '../../../config/colors';
import { StyleHelpers } from '../../../miscellaneous/constants/style-helpers';
import { THEME_COLORS } from '../../../theme/constants/themeColors';
import { verticalSize } from '../../../functions/responsive';

export default StyleSheet.create({
  root: {
    flexDirection: 'column',
    backgroundColor: THEME_COLORS.bgColor,
    justifyContent: 'space-between',
    height: verticalSize(100),
    position: 'relative'
  },
  topRowStyle: {
    ...StyleHelpers.verticallyCenteredRow,
    justifyContent: 'space-between'
  },
  profileCont: {
    borderRadius: 200,
    overflow: 'hidden'
  },
  profileStyle: {
    marginTop: 20,
    borderRadius: 100,
    height: 100,
    width: 100,
    borderWidth: 3,
    borderColor: COLORS.darkBlack
  },
  dottedLine: {
    borderBottomWidth: 2,
    borderBottomColor: COLORS.blackColor,
  },
  optionCont: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8
  },
  rowStyle: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  divider: {
    backgroundColor: '#ffffff60',
    // backgroundColor: COLORS.amberColor,
    height: 1,
    width: '100%',
    alignSelf: 'center',
    marginVertical: 8,
    borderRadius: 80
  }
});
