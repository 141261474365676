import { ReactElement } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { COLORS } from '../../../config/colors';

type Props = {
  headingComponent: ReactElement;
  lineItems: Array<{
    shouldShow?: boolean;
    leftComponent: ReactElement;
    rightComponent: ReactElement;
  }>;
  style?: ViewStyle;
};

export const GroupedLineItemsCard = (props: Props) => {
  const { headingComponent, lineItems, style = {} } = props;

  return (
    <View style={[styles.whiteBox, style]}>
      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row'
        }}>
        <>{headingComponent}</>
      </View>

      <View style={styles.divider} />

      {lineItems.map((lineItem, index) => {
        const { leftComponent, rightComponent, shouldShow = true } = lineItem;

        if (!shouldShow) return null;

        return (
          <View key={index} style={[styles.rowStyle, { marginTop: 8 }]}>
            <View
              style={{
                flex: 0.8
              }}>
              {leftComponent}
            </View>
            <View>{rightComponent}</View>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  rowStyle: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  whiteBox: {
    backgroundColor: COLORS.whiteColor,
    borderWidth: 1,
    borderBottomWidth: 3,
    borderColor: COLORS.primaryLight,
    borderRadius: 16,
    padding: 16,
    paddingBottom: 12
  },
  divider: {
    height: 2,
    backgroundColor: COLORS.primaryLight,
    marginTop: 12,
    marginBottom: 6,
    borderRadius: 8
  }
});

export default GroupedLineItemsCard;
