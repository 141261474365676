import { ChargeTransaction } from '../central-atoms/types/charging/charge-txns';
import { UpdateChargeTxnsListDispatchPayloadAction } from '../types/actions';
import { SET_CHARGE_TXNS_FILTERS, UPDATE_CHARGE_TXNS_LIST, UPDATE_CHARGE_TXNS_LIST_PAGINATION } from '../types';

export const updateChargeTxnsList = (
  chargeTxns: ChargeTransaction[],
  updateStrategy = 'merge'
): UpdateChargeTxnsListDispatchPayloadAction => {
  return {
    type: UPDATE_CHARGE_TXNS_LIST,
    payload: {
      chargeTxns,
      updateStrategy
    }
  };
};

export const setChargeTxnsListPagination = (pagination: { hasNext: boolean; page: number | string }) => {
  return {
    type: UPDATE_CHARGE_TXNS_LIST_PAGINATION,
    payload: {
      pagination
    }
  };
};

export const setChargeTxnsFilters = (chargeTxnsFilters: { wallet_ids: string[] }) => {
  return {
    type: SET_CHARGE_TXNS_FILTERS,
    payload: {
      chargeTxnsFilters
    }
  };
};
