import { VerifyCode } from '../central-atoms/types/app-requests';
import { VerifyOperatorCodePayload, VerifyOrgUserPayload } from '../central-atoms/types/requests/auth/org';
import { VerifyAccessCodeResponse } from '../central-atoms/types/responses/auth';
import { VerifyOperatorCodeResponse, VerifyOrgUserResponse } from '../central-atoms/types/responses/org-users';
import HttpRequestService from '../config/HttpRequestService';
import { API_BASE_PATH, CENTRAL_HOST } from '../config/setting';
import { GLOBAL_VARS } from '../constants';
import { getGlobalVar } from '../miscellaneous/helpers';

export default class AuthenticatedOrgRequestService {
  static verifyOrgUser(payload: VerifyOrgUserPayload): Promise<{ data: VerifyOrgUserResponse }> {
    const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
    return httpRequest
      .getInstance()
      .post(`${API_BASE_PATH}/verify_org_user`, payload)
      .then((response: any) => {
        return response.data;
      });
  }

  static verifyOperatorCode(payload: VerifyOperatorCodePayload): Promise<{ data: VerifyOperatorCodeResponse }> {
    const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
    return httpRequest
      .getInstance()
      .post(`${API_BASE_PATH}/verify_operator_code`, payload)
      .then((response: any) => {
        return response.data;
      });
  }

  static async verifyAccessCode(payload: VerifyCode): Promise<VerifyAccessCodeResponse> {
    const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
    return await centralHttpRequest
      .getInstance()
      .post(`${CENTRAL_HOST}/verify_code`, payload)
      .then((response: any) => {
        return response.data;
      });
  }
}
