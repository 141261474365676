export enum CSFilterGroup {
    ConnectorTypes = 'connector_types',
    Amenities = 'amenities',
    Brands = 'brands',
    Payable = 'payable',
    Accessibility = 'accessibility',
    Mobility = 'mobility',
    PowerRating = 'power_rating',
    CPOs = 'cpos',
    OCPIClients = 'ocpi_clients',
    Availability = 'availability',
}

export enum CSFilterSubGroupKey {
    FourWheelerConnectors = '4w_connectors',
    TwoThreeWheelerConnectors = '2w_3w_connectors',
}

export enum CSFilterSubGroupName {
    FourWheelerConnectors = '4 Wheeler Connectors',
    TwoThreeWheelerConnectors = '2/3 Wheeler Connectors',
}
