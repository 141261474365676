import AsyncStorage from '@react-native-async-storage/async-storage';
import { useRoute } from '@react-navigation/native';
import { isEmpty } from 'lodash';
import { useSelectedLanguage, useUserAuthenticated } from '../../../hooks';
import ScreenWrapper from '../../../miscellaneous/components/ScreenWrapper';
import { ScreenComponent } from '../../../miscellaneous/types/components';
import { ErrorHandlerService } from '../../../services/ErrorHandlerService';
import { LangCodes } from '../../../central-atoms/enums/generic/i18n/LangCodes';
import AuthenticatedUserRequestService from '../../../services/AuthenticatedUserRequestService';
import EnterAccessCodeContent from '../../components/EnterAccessCodeContent';
import { LS_KEYS, SCREEN_NAMES } from '../../../constants';
import { PartialLoginEnterAccessCodeScreenRouteParams } from '../../../types';

type Props = ScreenComponent & {};
export default function PartialLoginEnterAccessCodeScreen(props: Props) {
  const {
    navigation
    // route,
  } = props;

  const route = useRoute();
  const params = route.params as PartialLoginEnterAccessCodeScreenRouteParams;
  const nextScreen = params?.nextScreen ?? null;

  const userAuthenticated = useUserAuthenticated();
  const selectedLanguage = useSelectedLanguage();

  // const activeFleetOperatorOrgUser = useActiveFleetOperatorOrgUser();

  const updateActiveLanguage = (lang: LangCodes) => {
    AuthenticatedUserRequestService.updateActiveLanguage({ lang: lang }).catch((e) => {
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: 'error'
        }
      });
    });
  };

  // useEffect(() => {
  //     const backAction = () => {
  //         BackHandler.exitApp();
  //         return true;
  //     };

  //     const backHandler = BackHandler.addEventListener(
  //         'hardwareBackPress',
  //         backAction,
  //     );

  //     return () => backHandler.remove();
  // }, []);

  return (
    <ScreenWrapper {...props}>
      <EnterAccessCodeContent
        fleetOperator={null}
        showLogoutButton={false}
        onVerificationSuccess={async (accessCode) => {
          await AsyncStorage.setItem(LS_KEYS.paymentMode, 'access-code');

          if (userAuthenticated && !isEmpty(selectedLanguage)) {
            updateActiveLanguage(selectedLanguage);
          }

          // setFleetNameModalVisible(true);
          // setTimeout(() => {
          //     setFleetNameModalVisible(false);
          //     setFleetCode('');
          //     navigation.navigate(SCREEN_PATHS.SearchingScreen);
          // }, 2000);

          navigation.navigate(nextScreen || SCREEN_NAMES.SearchingScreen);
        }}
        onVerificationError={(operatorCode, handledError) => {}}
      />
    </ScreenWrapper>
  );
}
