import { Coords } from "../central-atoms/types";
import { EvaluatedAppUpdate } from "../miscellaneous/types";
import { RESET_STORE, SET_APP_INITIALIZED, SET_APP_STATE, SET_EVALUATED_APP_UPDATE, SET_GPS_ENABLED, SET_LOCATION_PERMISSION_ENABLED, SET_MAP_COORDS, SET_SHOULD_UPDATE_USER_PROFILE, SET_SHOW_AC_CHARGERS_ONLY, SET_USERS_PHYSICAL_LOCATION, SET_USER_AUTHENTICATED, UPDATE_ALLOWED_QR_CODE_URL_PATTERNS, UPDATE_FIREBASE_REMOTE_CONFIG_VALUES, UPDATE_SHOULD_FETCH_WALLET_BALANCE } from "../types";
import { ResetStoreDispatchedAction, SetEvaluatedAppUpdateDispatchedAction, SetShouldUpdateUserProfileDispatchedAction, SetShowACChargersOnlyDispatchedAction, UpdateAllowedCPQrCodeUrlPatternsDispatchedAction, UpdateFirebaseRemoteConfigValuesDispatchedAction, UpdateShouldFetchWalletBalanceDispatchedAction } from "../types/actions";

export const setAppState = (appState: string | undefined) => {
    return {
        type: SET_APP_STATE,
        payload: {
            appState,
        }
    };
}

export const setAppInitialized = (appInitialized: boolean) => {
    return {
        type: SET_APP_INITIALIZED,
        payload: {
            appInitialized,
        }
    };
}

export const setUsersPhysicalLocation = (usersPhysicalLocation: Coords) => {
    return {
        type: SET_USERS_PHYSICAL_LOCATION,
        payload: {
            usersPhysicalLocation,
        }
    };
}

export const setMapCoords = (mapCoords: Coords & { radius: number }) => {
    return {
        type: SET_MAP_COORDS,
        payload: {
            mapCoords,
        }
    };
}

export const setUserAuthenticated = (userAuthenticated: boolean) => {
    return {
        type: SET_USER_AUTHENTICATED,
        payload: {
            userAuthenticated,
        }
    };
}

export const setGPSEnabled = (gpsEnabled: boolean) => {
    return {
        type: SET_GPS_ENABLED,
        payload: {
            gpsEnabled,
        }
    };
}

export const setLocationPermissionEnabled = (locPermissionEnabled: boolean) => {
    return {
        type: SET_LOCATION_PERMISSION_ENABLED,
        payload: {
            locPermissionEnabled,
        }
    };
}

export const updateFirebaseRemoteConfigValues = (values: any): UpdateFirebaseRemoteConfigValuesDispatchedAction => {
    return {
        type: UPDATE_FIREBASE_REMOTE_CONFIG_VALUES,
        payload: {
            values,
        }
    }
}

export const updateAllowedCPQrCodeUrlPatterns = (allowedCPQrCodeUrlPatterns: string[]): UpdateAllowedCPQrCodeUrlPatternsDispatchedAction => {
    return {
        type: UPDATE_ALLOWED_QR_CODE_URL_PATTERNS,
        payload: {
            allowedCPQrCodeUrlPatterns,
        }
    }
}

export const setEvaluatedAppUpdate = (evaluatedAppUpdate: EvaluatedAppUpdate): SetEvaluatedAppUpdateDispatchedAction => {
    return {
        type: SET_EVALUATED_APP_UPDATE,
        payload: {
            evaluatedAppUpdate,
        }
    }
}


export const setShowACChargersOnly = (showACChargersOnly: boolean): SetShowACChargersOnlyDispatchedAction => {
    return {
        type: SET_SHOW_AC_CHARGERS_ONLY,
        payload: {
            showACChargersOnly,
        }
    }
}


export const setShouldUpdateUserProfile = (shouldUpdateUserProfile: boolean): SetShouldUpdateUserProfileDispatchedAction => {
    return {
        type: SET_SHOULD_UPDATE_USER_PROFILE,
        payload: {
            shouldUpdateUserProfile,
        }
    }
}


export const updateShouldFetchWalletBalance = (shouldFetchWalletBalance: boolean): UpdateShouldFetchWalletBalanceDispatchedAction => {
    return {
        type: UPDATE_SHOULD_FETCH_WALLET_BALANCE,
        payload: {
            shouldFetchWalletBalance,
        }
    }
}

export const resetStore = (): ResetStoreDispatchedAction => {
    return {
        type: RESET_STORE,
        payload: {
        }
    }
}
