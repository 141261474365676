import { View, ViewStyle } from 'react-native';
import { ReactComponent as InfoCircleIcon } from '../../assets/icons/info-circle.svg';
import { StyleHelpers } from '../constants/style-helpers';
import { COLORS } from '../../config/colors';

type MiddotProps = {
  style?: ViewStyle;
};
export const Middot = (props: MiddotProps) => {
  const { style = {} } = props;

  return (
    <View
      style={{
        height: 2,
        width: 2,
        borderRadius: 100,
        backgroundColor: COLORS.darkBlack,
        ...style
      }}
    />
  );
};

type VerticalBarProps = {
  style?: ViewStyle;
};
export const VerticalLine = (props: VerticalBarProps) => {
  const { style = {} } = props;

  return (
    <View
      style={{
        height: '100%',
        width: 1,
        borderRadius: 100,
        backgroundColor: COLORS.darkBlack,
        ...style
      }}
    />
  );
};

type HorizontalBarProps = {
  style?: ViewStyle;
};
export const HorizontalLine = (props: HorizontalBarProps) => {
  const { style = {} } = props;

  return (
    <View
      style={{
        width: '100%',
        height: 1,
        borderRadius: 100,
        backgroundColor: COLORS.darkBlack,
        ...style
      }}
    />
  );
};

type HelpInfoMessageProps = {
  message: any;
  style?: ViewStyle;
};
export const HelpInfoMessage = (props: HelpInfoMessageProps) => {
  const { message, style = {} } = props;

  return (
    <View
      style={{
        ...StyleHelpers.rowStyle,
        ...style
      }}>
      <View
        style={{
          marginRight: 24,
          marginTop: 4,
          flex: 0
        }}>
        <InfoCircleIcon height={16} width={16} fill={COLORS.grey700} />
      </View>
      <View
        style={{
          flex: 1
        }}>
        {message}
      </View>
    </View>
  );
};
