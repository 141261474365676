import React from 'react';
import { THEME_STYLES } from '../../../theme/constants/themeStyles';
import { COLORS } from '../../../config/colors';
import { FontFamily } from '../../../config/typography';
import { TextInput, View } from 'react-native';
import CText from '../CText';

const CInput = React.forwardRef((props: any, ref) => {
  const {
    value,
    placeholder,
    onChangeText,
    onChange,
    disabled,
    placeholderTextColor = COLORS.placeholderColor,
    keyboardType,
    maxLength,
    style,
    autoFocus,
    errorMessage
  } = props;

  return (
    <View>
      <TextInput
        {...props}
        ref={ref}
        value={value}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        onChangeText={onChangeText}
        onChange={onChange}
        keyboardType={keyboardType}
        maxLength={maxLength}
        autoFocus={autoFocus}
        style={{
          paddingHorizontal: 16,
          justifyContent: 'flex-end',
          color: COLORS.blackColor,
          fontWeight: 'bold',
          fontSize: 14,
          height: 500,
          ...THEME_STYLES.input,
          backgroundColor: disabled ? COLORS.primaryLight : COLORS.whiteColor,
          borderColor: errorMessage ? COLORS.brightRed : '',
          borderWidth: '1px',
          outlineStyle: 'none',
          ...style
        }}
        // errorMessage={errorMessage}
        // errorStyle={{
        //   borderWidth: 1,
        //   // borderBottomWidth: 3,
        //   borderColor: COLORS.errorColor
        // }}
      />
      {errorMessage && (
        <CText
          value={errorMessage}
          style={{
            color: COLORS.errorColor,
            fontFamily: FontFamily.Poppins_Regular,
            fontSize: 12
          }}
        />
      )}
    </View>
  );
});

export default CInput;
