import { Dimensions, StyleSheet } from "react-native";
import { COLORS } from "../../../../../config/colors";
import { THEME_COLORS } from "../../../../../theme/constants/themeColors";
import { verticalSize } from "../../../../../functions/responsive";

export default StyleSheet.create({
  root: {
    flexDirection: "column",
    backgroundColor: THEME_COLORS.bgColor,
    justifyContent: "space-between",
    position: "relative",
    height: "100%"
    // height: verticalSize(100)
  },
  mainCont: {
    justifyContent: "flex-end",
    flex: 1
  },
  rowStyle: {
    flexDirection: "row",
    alignItems: "center"
  },
  imageCont: {
    height: 240,
    width: Dimensions.get("window").width - 128,
    borderRadius: 16,
    overflow: "hidden",
    marginEnd: 32
  },
  dash: {
    height: 2,
    width: 12,
    backgroundColor: COLORS.blackColor,
    borderRadius: 30,
    marginTop: 4
  }
});
