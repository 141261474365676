import { useRoute } from "@react-navigation/native";
import { useState } from "react";
import { ScrollView } from "react-native";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ScreenBottomBar from "../../../miscellaneous/components/ScreenBottomBar";
import ScreenHeader from "../../../miscellaneous/components/ScreenHeader";
import ScreenWrapper from "../../../miscellaneous/components/ScreenWrapper";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { ScreenComponent } from "../../../miscellaneous/types/components";
import {
  PaymentModeLinkWalletScreenRouteParams,
  PaymentModeSelectionScreenRouteParams,
} from "../../../types";
import AuthenticationService from "../../../services/AuthenticationService";
import DashedLink from "../../components/DashedLink";
import CText from "../../components/CText";
import CButton from "../../components/CButton";
import { SCREEN_NAMES } from "../../../constants";
import PaymentModeSelectionContent from "../../components/PaymentModeSelectionContent";
import { ReactComponent as PersonalPay } from "../../../assets/icons/personal-pay.svg";
import { ReactComponent as DriverPay } from "../../../assets/icons/driver-pay.svg";

type Props = ScreenComponent & {};
export default function PaymentModeSelectionScreen(props: Props) {
  const { navigation } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const route = useRoute();
  const params = route.params as PaymentModeSelectionScreenRouteParams;
  const showBackButton = params?.showBackButton ?? false;
  const showLogoutButton = params?.showLogoutButton ?? true;

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const logoutUser = () => {
    AuthenticationService.logout(dispatch);
    navigation.navigate(SCREEN_NAMES.SelectLanguageScreen);
  };

  type Option = {
    id: string;
    title: string;
    desc: string;
    image: any;
  };

  const paymentModeOptions: { [key: string]: Option } = {
    personal_evs: {
      id: "personal_evs",
      title: t("UsingMyMoney"),
      desc: t("ForPersonalVehicleOwners"),
      image: <PersonalPay width={"100%"} height={"100%"} />,
    },
    fleets: {
      id: "fleets",
      title: t("UsingAccessCode"),
      desc: t("ForFleetDriversAndChauffeurs"),
      image: <DriverPay width={"100%"} height={"100%"} />,
    },
  };

  return (
    <ScreenWrapper {...props}>
      <ScreenHeader
        navigation={navigation}
        style={{
          borderBottomWidth: 0,
        }}
        showBackButton={showBackButton}
        rightComponent={
          showLogoutButton && (
            <DashedLink onPress={logoutUser}>
              <CText semiBold size={13}>
                {t("Logout")}
              </CText>
            </DashedLink>
          )
        }
      />

      <ScrollView
        style={{
          flex: 1,
        }}
        contentContainerStyle={{
          ...StyleHelpers.pageSpacing,
          paddingTop: 15,
        }}
      >
        <PaymentModeSelectionContent
          onSelect={(selection) => {
            setSelectedOption(selection);
          }}
        />
      </ScrollView>

      <ScreenBottomBar>
        <CButton
          disable={selectedOption === null}
          title={t("continue")}
          onPress={() => {
            if (selectedOption === paymentModeOptions.fleets.id)
              navigation.navigate(
                SCREEN_NAMES.PartialLoginEnterAccessCodeScreen
              );
            else if (selectedOption === paymentModeOptions.personal_evs.id) {
              navigation.navigate(SCREEN_NAMES.PaymentModeLinkWalletScreen, {
                nextScreen: SCREEN_NAMES.SearchingScreen,
                returnScreen: SCREEN_NAMES.PaymentModeLinkWalletScreen,
              } as PaymentModeLinkWalletScreenRouteParams);
            }
          }}
        />
      </ScreenBottomBar>
    </ScreenWrapper>
  );
}
