import { AppliedFilters } from "../central-atoms/filters/types";
import { getDefaultFilters } from "../helpers/filtersHelpers";
import { DispatchedAction } from "../miscellaneous/types";
import { CSFiltersState, Filter, SET_APPLIED_FILTERS, SET_APPLIED_FILTERS_CHANGE_VERSION, SET_AVAILABLE_CS_FILTERS, SET_CONNECTOR_TYPES_LIST, SET_CUSTOM_FILTERS_APPLIED } from "../types";


export const setAvailableCSFilters = (filters: CSFiltersState): DispatchedAction => {
    return {
        type: SET_AVAILABLE_CS_FILTERS,
        payload: {
            filters: filters,
        },
    };
};

export const setAppliedFilters = (appliedFilters: AppliedFilters): DispatchedAction => {
    return {
        type: SET_APPLIED_FILTERS,
        payload: {
            appliedFilters: appliedFilters,
        },
    };
};

export const setCustomFiltersApplied = (applied: boolean): DispatchedAction => {
    return {
        type: SET_CUSTOM_FILTERS_APPLIED,
        payload: {
            customFiltersApplied: applied,
        },
    };
};

export const setAppliedFiltersChangeVersion = (): DispatchedAction => {
    return {
        type: SET_APPLIED_FILTERS_CHANGE_VERSION,
        // payload: {
        //     appliedFiltersChangeVersion,
        // },
    };
};

export const setConnectorTypesFlatList = (filterSubgroups: Array<{
    filters: Filter[],
    name: string,
}>): DispatchedAction => {
    return {
        type: SET_CONNECTOR_TYPES_LIST,
        payload: {
            filterSubgroups: filterSubgroups,
        },
    };
};

export const setDefaultAppliedFilters = (): DispatchedAction => {
    return setAppliedFilters(getDefaultFilters());
};