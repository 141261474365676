import { APP_VARIANT } from '../../config/setting';
import { AvailableAppVariants } from '../enums';
import { APP_CONFIG } from './themeConfig';

/**
 * @WHITE_LABEL_CHANGES
 */

const themeColorScheme = APP_CONFIG?.[APP_VARIANT]?.colorScheme ?? APP_CONFIG?.[AvailableAppVariants.PulseEnergyLite]?.colorScheme;

export const THEME_COLORS = {
  defaultStatusBar: themeColorScheme.defaultStatusBar,

  // generic
  primaryColor: themeColorScheme.primaryColor,
  primaryBorder: themeColorScheme.primaryBorder,
  bgColor: themeColorScheme.bgColor,
  textColor: themeColorScheme.textColor,
  bgDark: themeColorScheme.bgDark,
  borderColor: themeColorScheme.borderColor,
  inputBorder: themeColorScheme.inputBorder,
  buttonBgColor: themeColorScheme.buttonBgColor,
  buttonBgColor2: themeColorScheme.buttonBgColor2,
  sectionHeader: themeColorScheme.sectionHeader,
  bgHeader: themeColorScheme.bgHeader,

  // semi-generic
  countBadgeBg: themeColorScheme.countBadgeBg,
  countBadgeText: themeColorScheme.countBadgeText,

  cardUnderlayBgColor: themeColorScheme.cardUnderlayBgColor,

  cardBorderColor: themeColorScheme.cardBorderColor,
  cardSelectedBgColor: themeColorScheme.cardSelectedBgColor,
  cardSelectedBgUnderlayColor: themeColorScheme.cardSelectedBgUnderlayColor,
  cardUnselectedBgColor: themeColorScheme.cardUnselectedBgColor,
  cardSelectedTextColor: themeColorScheme.cardSelectedTextColor,
  cardUnselectedTextColor: themeColorScheme.cardUnselectedTextColor,

  // components
  selectedPaymentMethodNameText: themeColorScheme.selectedPaymentMethodNameText,
  unselectedPaymentMethodNameText: themeColorScheme.unselectedPaymentMethodNameText,

  walletBarAppWalletBgColor: themeColorScheme.walletBarAppWalletBgColor,
  walletBarNonAppWalletBgColor: themeColorScheme.walletBarNonAppWalletBgColor,

  quickFilterItemSelectedBg: themeColorScheme.quickFilterItemSelectedBg,
  quickFilterItemSelectedText: themeColorScheme.quickFilterItemSelectedText,
  quickFilterItemUnselectedText: themeColorScheme.quickFilterItemUnselectedText,

  nearestCSContainerText: themeColorScheme.nearestCSContainerText,
  nearestCSContainerSubText: themeColorScheme.nearestCSContainerSubText,
  nearestCSContainerBg: themeColorScheme.nearestCSContainerBg,

  qrCodeTopHalfBg: themeColorScheme.qrCodeTopHalfBg,
  qrCodeBottomHalfBg: themeColorScheme.qrCodeBottomHalfBg
};