export function doArraysOverlap(arr1: any[], arr2: any[]): boolean {
    return arr1.some((arr1Item) => {
        return arr2.includes(arr1Item);
    });
}

export function byDate(propName: string, order: 'asc' | 'desc' = 'desc'): (a: any, b: any) => number {
    return (a: any, b: any) => {
        if (order === 'asc') {
            return (new Date(a[propName]).getTime() < new Date(b[propName]).getTime()) ? -1 : 1;
        }
        else if (order === 'desc') {
            return (new Date(a[propName]).getTime() > new Date(b[propName]).getTime()) ? -1 : 1;
        }
        return 0;
    };
}

export function byNumericKey(propName: string, order: 'asc' | 'desc' = 'desc'): (a: any, b: any) => number {
    return (a: any, b: any) => {
        if (order === 'asc') {
            return Number(a[propName]) < Number(b[propName]) ? -1 : 1;
        }
        else if (order === 'desc') {
            return Number(a[propName]) > Number(b[propName]) ? -1 : 1;
        }
        return 0;
    };
}

export function getRandomItem(array: any[]): any {
    return array[Math.floor(Math.random() * array.length)];
}
