import { t } from 'i18next';
import React from 'react';
import { View } from 'react-native';

import CButton from '../../components/CButton';
import CText from '../../components/CText';
import { COLORS } from '../../../config/colors';
import { StyleHelpers } from '../../../miscellaneous/constants/style-helpers';
import { CheckIcon } from '../../../icons';

type Props = {
  isSelected: boolean;
  item: any;
  onSelectItem: (item: any) => void;
};
export default function ChargingHistoryFilterItem(props: Props) {
  const { item, isSelected = false, onSelectItem = () => {} } = props;

  return (
    <View
      style={{
        ...StyleHelpers.pageSpacing,
        paddingVertical: StyleHelpers.pageSpacing.paddingLeft,
        ...StyleHelpers.themeBorder,
        ...StyleHelpers.verticallyCenteredRow,
        justifyContent: 'space-between',
        backgroundColor: isSelected ? COLORS.cyanColor : COLORS.whiteColor
      }}>
      <View
        style={{
          ...StyleHelpers.verticallyCenteredRow
        }}>
        {item.title}
      </View>
      <View>
        {isSelected ? (
          <View
            style={{
              ...StyleHelpers.verticallyCenteredRow,
              ...StyleHelpers.totalCenter,
              width: 115
            }}>
            <CheckIcon fill={COLORS.darkBlack} height={13} width={13} />
            <CText value={t('selected')} size={12} semiBold style={{ color: COLORS.blackColor, marginStart: 8 }} />
          </View>
        ) : (
          <CButton
            title={t('Select')}
            buttonStyle={{
              width: 115,
              height: 35
            }}
            onPress={() => onSelectItem(item)}
          />
        )}
      </View>
    </View>
  );
}
