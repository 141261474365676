import { useTranslation } from "react-i18next";
import { useState } from "react";
import { StyleSheet, TouchableHighlight, View } from "react-native";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import CText from "../CText";
import { COLORS } from "../../../config/colors";
import { ReactComponent as PersonalPay } from "../../../assets/icons/personal-pay.svg";
import { ReactComponent as DriverPay } from "../../../assets/icons/driver-pay.svg";

type Option = {
  id: string;
  title: string;
  desc: string;
  image: any;
};

type Props = {
  onSelect?: (selection: string | null) => void;
};
export default function PaymentModeSelectionContent(props: Props) {
  const { t } = useTranslation();
  const { onSelect = () => {} } = props;

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleOnSelect = (option: Option) => {
    const selection = option.id === selectedOption ? null : option.id;
    setSelectedOption(selection);
    onSelect(selection);
  };

  const paymentModeOptions: { [key: string]: Option } = {
    personal_evs: {
      id: "personal_evs",
      title: t("UsingMyMoney"),
      desc: t("ForPersonalVehicleOwners"),
      image: <PersonalPay width={"100%"} height={"100%"} />,
    },
    fleets: {
      id: "fleets",
      title: t("UsingAccessCode"),
      desc: t("ForFleetDriversAndChauffeurs"),
      image: <DriverPay width={"100%"} height={"100%"} />,
    },
  };

  return (
    <>
      <CText semiBold size={25} style={{ marginBottom: 8 }}>
        {t("HowWillYouBePayingForEVCharging")}
      </CText>

      {/* options */}
      {Object.keys(paymentModeOptions).map((optionKey: string) => {
        const optionItem = paymentModeOptions[optionKey];
        const isSelected = selectedOption === optionItem.id;

        return (
          <TouchableHighlight
            key={optionKey}
            underlayColor={
              isSelected
                ? THEME_COLORS.cardSelectedBgUnderlayColor
                : COLORS.dividerColor
            }
            style={{
              ...StyleHelpers.themeBorder,
              borderRadius: 24,
              marginVertical: 8,
              // maxHeight: 230,
              paddingHorizontal: StyleHelpers.pageSpacing.paddingLeft + 8,
              paddingVertical: StyleHelpers.pageSpacing.paddingLeft,
              backgroundColor: isSelected
                ? THEME_COLORS.cardSelectedBgColor
                : THEME_COLORS.cardUnselectedBgColor,
            }}
            onPress={() => {
              handleOnSelect(optionItem);
            }}
          >
            <View>
              <View style={styles.contentWrapper}>
                <View
                  style={{
                    ...StyleHelpers.verticallyCenteredRow,
                    width: 40,
                  }}
                >
                  <View
                    style={{
                      ...styles.checkIconContainer,
                      backgroundColor: isSelected
                        ? COLORS.blackColor
                        : COLORS.transparent,
                      borderColor: COLORS.blackColor,
                      borderWidth: 2,
                    }}
                  >
                    {/* {isSelected ? (
                      <CheckIcon
                        width={12}
                        height={12}
                        fill={COLORS.whiteColor}
                      />
                    ) : null} */}
                  </View>
                </View>
                <View style={styles.column14}>
                  <CText semiBold size={16}>
                    {optionItem.title}
                  </CText>
                  <CText
                    size={14}
                    style={{
                      color: isSelected ? COLORS.blackColor : COLORS.greyText,
                    }}
                  >
                    {optionItem.desc}
                  </CText>
                </View>
              </View>
              <View style={styles.container}>
                {optionItem.image}
                {/* <Image
                                            source={optionItem.image}
                                            resizeMode="cover"
                                            style={{
                                                maxHeight: 190,
                                                maxWidth: '100%',
                                                borderRadius: 5,
                                                backgroundColor: COLORS.whiteColor,
                                            }}
                                        /> */}
              </View>
            </View>
          </TouchableHighlight>
        );
      })}
    </>
  );
}

const styles = StyleSheet.create({
  contentWrapper: {
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  column14: {
    // flexDirection: 'column',
    // paddingLeft: 14,
  },
  container: {
    maxHeight: 120,
    marginVertical: 10,
  },
  checkIconContainer: {
    borderRadius: 50,
    width: 22,
    height: 22,
    justifyContent: "center",
    alignItems: "center",
  },
});
