import { useNavigation } from "@react-navigation/native";
import {
  ActivityIndicator,
  TouchableHighlight,
  View,
  ViewStyle,
} from "react-native";
import { SCREEN_NAMES } from "../../../constants";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { COLORS } from "../../../config/colors";
import { StyleHelpers } from "../../../constants/style-helpers";
import CText from "../CText";
import { Middot } from "../../../miscellaneous/views/miscellaneous";
import Timer from "../Timer";
import CButton from "../CButton";
import { NumericFormat } from "react-number-format";
import { ReactComponent as BoltIcon } from "../../../assets/icons/bolt.svg";
import { ReactComponent as InfoCircleIcon } from "../../../assets/icons/info-circle.svg";
import { useTranslation } from "react-i18next";
import { ChargeTransaction } from "../../../central-atoms/types/charging/charge-txns";
import { ConnectivityStatus } from "../../../central-atoms/enums/charge-point";

const secondHalfTextProps = {
  size: 12,
};

type Props = {
  chargeTxn: ChargeTransaction;
  style?: ViewStyle;
};
export default function OngoingChargeTxnCard(props: Props) {
  const { chargeTxn, style = {} } = props;
  const { t } = useTranslation();
  const navigation: any = useNavigation();

  const handleChargeTxnClick = () => {
    navigation.navigate(
      SCREEN_NAMES.ChargingProcessScreen as never,
      {
        chargeTxnId: chargeTxn.id,
      } as never
    );
  };

  const energyDelivered = chargeTxn?.summary?.energy_delivered;
  const lastCalculatedCost = chargeTxn?.summary?.last_calculated_cost;

  let titleText = chargeTxn.charge_point.charging_station?.name;
  if (!!chargeTxn.charge_point_connector?.type)
    titleText += ` (${chargeTxn.charge_point_connector?.type})`;

  return (
    <TouchableHighlight
      onPress={handleChargeTxnClick}
      underlayColor={THEME_COLORS.cardUnderlayBgColor}
      activeOpacity={1}
      style={{
        width: "100%",
        backgroundColor: COLORS.whiteColor,
        borderRadius: 20,
        paddingLeft: 12,
        paddingRight: 16,
        paddingVertical: 10,
        borderWidth: 1,
        borderColor: THEME_COLORS.cardBorderColor,
        ...style,
      }}
    >
      <View
        style={{
          ...StyleHelpers.verticallyCenteredRow,
        }}
      >
        <View
          style={{
            ...StyleHelpers.totalCenter,
            marginRight: 10,
            minWidth: 50,
          }}
        >
          {chargeTxn.charge_point.connectivity_status ===
          ConnectivityStatus.Online ? (
            <>
              <ActivityIndicator color={COLORS.primaryColor} size={40} />

              <BoltIcon
                fill={COLORS.primaryColor}
                height={22}
                width={22}
                style={{
                  marginRight: 2,
                  position: "absolute",
                }}
              />
            </>
          ) : (
            <>
              <InfoCircleIcon fill={COLORS.errorColor} width={35} height={35} />
            </>
          )}
        </View>

        {/* details */}
        <View style={{ flex: 1 }}>
          {/* first half */}
          <View
            style={{
              overflow: "hidden",
            }}
          >
            <CText
              semiBold
              color={THEME_COLORS.textColor}
              size={12}
              style={{ fontWeight: "600" }}
              textProps={{
                numberOfLines: 1,
                ellipsizeMode: "tail",
              }}
            >
              {titleText}
            </CText>
          </View>

          {/* second half */}
          <View
            style={{
              ...StyleHelpers.verticallyCenteredRow,
              justifyContent: "flex-start",
            }}
          >
            {chargeTxn.charge_point.connectivity_status ===
            ConnectivityStatus.Online ? (
              <>
                {/* energy delivered */}
                {!energyDelivered ? (
                  <CText {...secondHalfTextProps} value={"-"} />
                ) : (
                  <NumericFormat
                    displayType={"text"}
                    value={Number(energyDelivered)}
                    thousandSeparator={true}
                    thousandsGroupStyle={"lakh"}
                    suffix=" kW"
                    renderText={(value) => (
                      <CText {...secondHalfTextProps} value={value} />
                    )}
                  />
                )}

                <Middot
                  style={{
                    marginHorizontal: 6,
                  }}
                />

                {/* Estimated Cost */}
                {!lastCalculatedCost ? (
                  <CText {...secondHalfTextProps} value={"-"} />
                ) : (
                  <NumericFormat
                    displayType={"text"}
                    prefix={"₹"}
                    value={Number(lastCalculatedCost).toFixed(2)}
                    thousandSeparator={true}
                    thousandsGroupStyle={"lakh"}
                    renderText={(value) => (
                      <CText {...secondHalfTextProps} value={value} />
                    )}
                  />
                )}

                <Middot
                  style={{
                    marginHorizontal: 6,
                  }}
                />

                {/* Time Elapsed */}
                <View style={{ marginEnd: 8 }}>
                  <CText {...secondHalfTextProps}>
                    <Timer
                      startTimestamp={chargeTxn?.start_timestamp as string}
                      endTimestamp={
                        (chargeTxn?.stop_timestamp as string) || undefined
                      }
                    />
                  </CText>
                </View>
              </>
            ) : (
              <>
                <CText
                  {...secondHalfTextProps}
                  style={{ color: COLORS.errorColor }}
                >
                  {t("ChargerIsOffline")}
                </CText>
              </>
            )}
          </View>
        </View>

        {/* action */}
        <View
          style={{
            width: 60,
            ...StyleHelpers.verticallyCenteredRow,
            justifyContent: "flex-end",
          }}
        >
          <CButton
            title={t("View")}
            style={{}}
            buttonStyle={{
              borderRadius: 10,
              width: 50,
            }}
            onPress={handleChargeTxnClick}
            height={36}
            titleSize={10}
          />
        </View>
      </View>
    </TouchableHighlight>
  );
}
