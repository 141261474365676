import { StyleSheet } from "react-native";
import { COLORS } from "../../../config/colors";
import { StyleHelpers } from "../../../constants/style-helpers";
import { isIOS } from "../../../helpers";

export default StyleSheet.create({
	container: {
		position: "absolute",
		top: -4,
		right: -4,
		backgroundColor: COLORS.blue500,
		height: 22,
		width: 22,
		borderRadius: 22,
		borderWidth: 2,
		borderColor: COLORS.whiteColor,
		zIndex: 9,
		...StyleHelpers.totalCenter,
	},
	text: {
		color: COLORS.whiteColor,
		lineHeight: isIOS() ? 18 : 12,
	},
});
