import { ActivityIndicator, TouchableOpacity, View } from "react-native";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import CText from "../CText";
import { COLORS } from "../../../config/colors";
import styles from "./styles";

export default function CButton(props: any) {
  const {
    title,
    style,
    buttonStyle = {},
    disable,
    onPress = () => {},
    icon = () => {},
    bordered,
    height = 52,
    shadow,
    titleSize = 16,
    textStyle = {},
    loading,
    loadingText = "",
  } = props;

  return (
    <View style={style}>
      <TouchableOpacity
        disabled={disable}
        activeOpacity={0.7}
        style={[
          {
            ...styles.container,
            backgroundColor: bordered
              ? COLORS.whiteColor
              : THEME_COLORS.buttonBgColor,
            height: height,
            ...(shadow ? styles.shadowBox : {}),
            opacity: disable ? 0.7 : 1,
            ...buttonStyle,
          },
        ]}
        onPress={onPress}
      >
        {/* loader and icon */}
        {loading ? (
          <View
            style={{
              marginRight: !!loadingText ? 5 : 0,
            }}
          >
            <ActivityIndicator size={22} color={COLORS.whiteColor} />
          </View>
        ) : (
          <>{icon()}</>
        )}

        {/* text */}
        {(!loading || (loading && !!loadingText)) && (
          <CText
            value={loading ? loadingText : title}
            size={titleSize}
            semiBold
            style={{
              color: bordered ? COLORS.blackColor : COLORS.whiteColor,
              ...textStyle,
              fontWeight: "600",
            }}
          />
        )}
      </TouchableOpacity>
    </View>
  );
}
