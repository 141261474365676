import { byDate } from "../central-atoms/functions/array";
import { Action } from "../miscellaneous/types";
import { INITIAL_STATE } from "./defaultStore";
import { UpdateChargeTxnsListDispatchPayload } from "../types/actions";
import { SET_CHARGE_TXNS_FILTERS, UPDATE_CHARGE_TXNS_LIST, UPDATE_CHARGE_TXNS_LIST_PAGINATION } from "../types";

export default function chargeTxnsReducer(state = INITIAL_STATE.chargeTxns, action: Action) {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_CHARGE_TXNS_LIST: {

            const { chargeTxns, updateStrategy } = payload as UpdateChargeTxnsListDispatchPayload;

            const newChargeTxnsMap = { ...state.data.chargeTxnsMap };
            let newChargeTxnsList: any[] = [];
            if (updateStrategy === 'merge') {
                newChargeTxnsList = [...(state.data.chargeTxnsList ?? [])];
                chargeTxns.forEach((ct) => {
                    const existingItemIndex = newChargeTxnsList.findIndex((item) => ct.id === item.id);

                    // update or add to the list
                    if (existingItemIndex !== -1) {
                        newChargeTxnsList[existingItemIndex] = {
                            id: ct.id,
                            created_on: ct.created_on,
                        };
                    }
                    else {
                        newChargeTxnsList.push({
                            id: ct.id,
                            created_on: ct.created_on,
                        });
                    }

                    // update the map
                    newChargeTxnsMap[ct.id] = ct;
                });
            }

            newChargeTxnsList = newChargeTxnsList.sort(byDate('created_on', 'desc'));

            return {
                ...state,
                data: {
                    ...state.data,
                    chargeTxnsMap: newChargeTxnsMap,
                    chargeTxnsList: newChargeTxnsList,
                },
            };
        }

        case UPDATE_CHARGE_TXNS_LIST_PAGINATION: {
            return {
                ...state,
                app: {
                    ...state.app,
                    chargeTxnsListPagination: action.payload.pagination,
                }
            };
        }

        case SET_CHARGE_TXNS_FILTERS: {
            return {
                ...state,
                app: {
                    ...state.app,
                    chargeTxnsFilters: action.payload.chargeTxnsFilters,
                }
            };
        }

        default:
            return state;
    }
};
