import { useCallback, useEffect, useRef, useState } from "react";
import {
  Dimensions,
  KeyboardAvoidingView,
  Modal,
  ModalProps,
  ScrollView,
  StatusBar,
  StyleSheet,
  TouchableOpacity,
  View
} from "react-native";
import { ChargingMethodType } from "../../../../../central-atoms/enums/charging/charging-method";
import styles from "./styles";
// @ts-ignore
import { isEmpty, uniqueId } from "lodash";
import {
  getNonSIUnitChargingAmount,
  getSIUnitChargingCustomAmount
} from "../../../../../central-atoms/functions/charge-points";
import { ChargingMethod } from "../../../../../central-atoms/types/charging/charge-points";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";
import { CostBreakupLineItemCode } from "../../../../../central-atoms/bill/enums";
import { CostBreakupLineItem } from "../../../../../central-atoms/bill/types";
import { ChargePointStatus } from "../../../../../central-atoms/enums/ocpp/v1.6/generic";
import { loopCallbackUntilSatisfied } from "../../../../../central-atoms/functions/loop";
import { PaymentMethodType } from "../../../../../central-atoms/payment-methods/types/enum";
import { GenericPaymentTxnStatus } from "../../../../../central-atoms/payment-txns/enums";
import { PaymentSDK } from "../../../../../central-atoms/payment/enum";
import { GetPaymentTxnStatusResponse } from "../../../../../central-atoms/payment/types/responses";
import {
  EstimatedChargingSummaryRequest,
  InitiateTransactionAppRequest
} from "../../../../../central-atoms/types/app-requests";
import { StartChargingPayload } from "../../../../../central-atoms/types/requests/charging";
import { WalletType } from "../../../../../central-atoms/wallets/enums";
import {
  useActiveFleetOperatorOrgUser,
  useFirebaseRemoteConfigValues,
  useSelectedChargePoint,
  useSelectedChargePointConnector,
  useSelectedLanguage,
  useUsersPhysicalLocation
} from "../../../../../hooks";
import Accordion from "../../../../../miscellaneous/components/Accordion";
import { FullScreenOverlay } from "../../../../../miscellaneous/components/FullScreenOverlay";
import { NumberFormatText } from "../../../../../miscellaneous/components/NumberFormatText";
import OptionRadioButton from "../../../../../miscellaneous/components/OptionRadioButton";
import RichError from "../../../../../miscellaneous/components/RichError";
import ScreenBottomBar from "../../../../../miscellaneous/components/ScreenBottomBar";
import ScreenHeader from "../../../../../miscellaneous/components/ScreenHeader";
import ScreenWrapper from "../../../../../miscellaneous/components/ScreenWrapper";
import { StyleHelpers } from "../../../../../miscellaneous/constants/style-helpers";
import { ScreenComponent } from "../../../../../miscellaneous/types/components";
import { useActivePaymentMethodType, useActiveWallet } from "../../../../../hooks/walletHooks";
import { FirebaseRemoteConfigKeys, SCREEN_NAMES } from "../../../../../constants";
import { PaymentRequestService } from "../../../../../services/PaymentRequestService";
import { ErrorHandlerService } from "../../../../../services/ErrorHandlerService";
import { ChargingRequestService } from "../../../../../services/ChargingRequestService";
import {
  getEstimatedChargingSummaryCostBreakupItems,
  getTotalChargingCostLineItem
} from "../../../../../helpers/chargingStationHelper";
import { ChargingStatesScreenRouteParams } from "../../../../../types";
import { THEME_COLORS } from "../../../../../theme/constants/themeColors";
import CText from "../../../../components/CText";
import { isIOS, showErrorToast, showSuccessToast } from "../../../../../helpers";
import { COLORS } from "../../../../../config/colors";
import DashedLink from "../../../../components/DashedLink";
import CButton from "../../../../components/CButton";
import CInput from "../../../../components/CInput";
import { getKeyboardAvoidingViewBehavior } from "../../../../../miscellaneous/helpers";
import { IoIosArrowUp, IoMdClose } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoMdCloseCircle } from "react-icons/io";
import { CheckoutProvider, Checkout } from "paytm-blink-checkout-react";
import { PaytmIcon } from "../../../../../icon";
import { PAYTM_URL } from "../../../../../config/setting";
import { useTranslation } from "react-i18next";


const USE_EXISTING_CHECKOUT_INSTANCE = 'Use existing checkout instance : ';

/**
 *  Questions/Concerns
 *      - Should the connect charger screen be in the slider or be a different screen entirely?
 */
declare global {
  interface Window {
    Paytm?: any;
  }
}

type Props = ScreenComponent & {
  onBackPress: () => void;
  onNextPress: () => void;
  cpConnected: boolean;
  cpNotConnectedError: null | any;
  active: boolean;
  modalProps?: ModalProps | any;
};

export default function SelectChargingMethod(props: Props) {
  const {
    navigation,
    onBackPress = () => {},
    onNextPress = () => {},
    cpConnected,
    cpNotConnectedError,
    modalProps = {}
  } = props;
  const { t } = useTranslation();
  const isScreenFocused = useIsFocused();
  const insets = useSafeAreaInsets();
  const activeWallet = useActiveWallet();
  const activePaymentMethodType = useActivePaymentMethodType();
  const selectedLanguage = useSelectedLanguage();
  const selectedChargePoint = useSelectedChargePoint();
  const selectedChargePointConnector = useSelectedChargePointConnector();
  const activeFleetOperatorOrgUser = useActiveFleetOperatorOrgUser();
  const usersPhysicalLocation = useUsersPhysicalLocation();
  const firebaseRemoteConfigValues = useFirebaseRemoteConfigValues();
  const amountInputRef = useRef();
  const [invalidAmount, setInvalidAmount] = useState(false);
  const [isEnterAmountModalOpen, setIsEnterAmountModalOpen] = useState(false);
  const [waitForPayAndStartCharging, setWaitForPayAndStartCharging] = useState<any | null>(null);
  const [waitForEstimatedChargingSummary, setWaitForEstimatedChargingSummary] = useState<any | null>(null);
  const [chargeTxnId, setChargeTxnId] = useState<string | null>(null);
  const [chosenSuggestedAmount, setChosenSuggestedAmount] = useState<string>("");
  const [customChargingAmount, setCustomChargingAmount] = useState<string>("");
  const [modalCustomChargingAmount, setModalCustomChargingAmount] = useState<string>("");
  const [chargingMethodAmount, setChargingMethodAmount] = useState<string>("");
  const [selectedChargingMethodType, setSelectedChargingMethodType] = useState<ChargingMethodType | null>(null);
  const [hasManuallySelected, setHasManuallySelected] = useState<boolean>(false);
  const [availableChargingMethodTypesList, setAvailableChargingMethodTypesList] = useState<ChargingMethodType[]>([]);
  const [availableChargingMethods, setAvailableChargingMethods] = useState<{
    [methodKey: string]: ChargingMethod;
  }>({});
  const [totalChargingCostItem, setTotalChargingCostItem] = useState<CostBreakupLineItem | null>(null);
  const [costBreakupItems, setCostBreakupItems] = useState<CostBreakupLineItem[]>([]);
  const [checkoutJsInstance, setCheckoutJsInstance] = useState<any>(null);
  const [showCheckout, setShowCheckout] = useState(false);
  const [mConfig, setMConfig] = useState<any>({});
  // const [SDKData, setSDKData] = useState(null);

  // console.log('mConfig', mConfig);
  const Screen = Dimensions.get("window");
  const ScreenHeight = Screen.height;
  const [containerHeight, setContainerHeight] = useState(0);
  const paymentGateway = firebaseRemoteConfigValues?.[FirebaseRemoteConfigKeys.payment_gateway];


  // NEW PAYTM CHECKOUT /*

  // const loadCheckoutScript = () => {
  //   const url = 'https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/';
  //   const scriptElement = document.createElement('script');
  //   scriptElement.async = true;
  //   scriptElement.src = url.concat(mConfig.merchant.mid);
  //   scriptElement.type = 'application/javascript';
  //   scriptElement.onload = () => {
  //     const checkoutJsInstance = getCheckoutJsObj();

  //     if (checkoutJsInstance && checkoutJsInstance.onLoad) {
  //       checkoutJsInstance.onLoad(() => {
  //         // setMConfig(getUpdatedMerchantConfig());
  //         setCheckoutJsInstance(checkoutJsInstance);
  //       });
  //     }
  //     else {
  //       console.error(USE_EXISTING_CHECKOUT_INSTANCE + 'onload not available!');
  //     }
  //   };
  //   scriptElement.onerror = error => {
  //     console.error(USE_EXISTING_CHECKOUT_INSTANCE + 'script load fail!');
  //   }
  //   document.body.appendChild(scriptElement);
  // }

  const hideCheckout = () => {
    try {
      const paytmCheckoutEl = document.getElementById('paytm-checkoutjs');
      paytmCheckoutEl?.parentNode?.removeChild(paytmCheckoutEl);
    }
    catch(e) {
      console.log('e', e);
    }

    setShowCheckout(false);
  }

  const getCheckoutJsObj = () => {
    if (window && window.Paytm && window.Paytm.CheckoutJS) {
      return window.Paytm.CheckoutJS;
    }
    else {
      console.error(USE_EXISTING_CHECKOUT_INSTANCE + 'Checkout instance not found!');
    }

    return null;
  }

  // NEW PAYTM CHECKOUT */



  // paytm pg /*
  const initiatePayment = async () => {
    try {
      const paymentTxnResponse = await initiatePaymentTransaction();

      let sdkResponse: any = null;
      if (totalChargingCostItem?.value !== 0) {
        setChargeTxnId(paymentTxnResponse.charge_transaction_id);
        sdkResponse = await invokePaytmSdkTxn(paymentTxnResponse);
        hideCheckout();
        // setWaitForPayAndStartCharging(false);
      }

      return {
        charge_transaction_id: paymentTxnResponse.charge_transaction_id,
        order_id: paymentTxnResponse.orderId,
        sdkResponse: sdkResponse
      };
    } catch (e) {
      console.log('Error', e);
      // suppressed
      // return Promise.reject(e);
    }
  };

  const invokePaytmSdkTxn = async (payload: {
    orderId: string;
    mid: string;
    amount: string;
    tranxToken: string;
    callbackUrl?: string;
    callbackurl?: string;
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const CONFIG = {
          jsFile: "",
          data: {
            orderId: payload.orderId,
            amount: payload.amount,
            token: payload.tranxToken,
            tokenType: "TXN_TOKEN",
            userDetail: {
              mobileNumber: "",
              name: ""
            }
          },
          merchant: {
            mid: payload.mid,
            name: "Pulse Energy",
            logo: "",
            redirect: false,
          },
          mapClientMessage: {},
          labels: {},
          payMode: {
            labels: {},
            filter: {
              exclude: []
            },
            order: ["NB", "CARD", "LOGIN"]
          },
          flow: "DEFAULT",
          handler: {
            notifyMerchant: function (eventType, data) {
              if (eventType === "APP_CLOSED") {
                setWaitForPayAndStartCharging(false);
                return;
              } 
              else {
                setWaitForPayAndStartCharging(false);
                return;
              }
            },
            transactionStatus: function (data) {
              // setSDKData(data);
              resolve(data);
              // hideCheckout();
            }
          }
        };

        setMConfig(CONFIG);

        const checkoutJsInstance = getCheckoutJsObj();

        if (checkoutJsInstance) {
          // already loaded
          setMConfig(CONFIG);
          setCheckoutJsInstance(checkoutJsInstance);
          setShowCheckout(true);
          setWaitForPayAndStartCharging(false);
        }
        else {
          // not loaded
          // Load Paytm script
          try {
            const scriptElement = document.createElement("script");
            scriptElement.async = true;
            if (!PAYTM_URL.includes(CONFIG.merchant.mid))
              scriptElement.src = PAYTM_URL.concat(CONFIG.merchant.mid);
            else
              scriptElement.src = PAYTM_URL;
            scriptElement.id = "pulse-paytm-checkout";
            scriptElement.type = "application/javascript";

            scriptElement.onload = () => {
              const checkoutJsInstance = getCheckoutJsObj();

              if (checkoutJsInstance && checkoutJsInstance.onLoad) {
                checkoutJsInstance.onLoad(() => {
                  setMConfig(CONFIG);
                  setCheckoutJsInstance(checkoutJsInstance);
                  setTimeout(() => {
                    setShowCheckout(true);
                    // setWaitForPayAndStartCharging(false);
                  }, 500);
                });
              }
              else {
                console.error(USE_EXISTING_CHECKOUT_INSTANCE + 'onload not available!');
              }
            };

            scriptElement.onerror = (e) => {
              console.log('Script Error', e);
              console.error(USE_EXISTING_CHECKOUT_INSTANCE + 'script load fail!');
              reject(e);
            };

            document.body.appendChild(scriptElement);
          } 
          catch (error) {
            // Handle any errors from loadPaytmScript
            console.error("Error loading Paytm script:", error);
          }
        }

        return;
      } 
      catch (error) {
        console.error("Error during Paytm SDK initialization:", error);
        reject(error); // Reject the Promise if an error occurs
      }
    });

    //production url
    //https://securegw.paytm.in
    //Staging url
    //https://securegw-stage.paytm.in
  };

  const initiatePaymentTransaction = () => {
    if (!selectedChargePoint?.public_uid || !selectedChargePointConnector?.id || !selectedChargingMethodType) return;
    const data: InitiateTransactionAppRequest = {
      charge_point_connector_id: selectedChargePointConnector?.id,
      charge_point_id: selectedChargePoint.id,
      charging_option_type: getChargingMethod(selectedChargingMethodType),
      charging_option_unit: getChargingAmount(chargingMethodAmount || chosenSuggestedAmount),
      user_lat: usersPhysicalLocation.latitude,
      user_lng: usersPhysicalLocation.longitude,
      payment_sdk: paymentGateway
    };
    return PaymentRequestService.initiatePaymentTransaction(selectedChargePoint?.public_uid, data)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };
  // paytm pg */

  const getEstimatedChargingSummary = () => {
    const chargingOptionUnit = getChargingAmount(chargingMethodAmount || chosenSuggestedAmount);
    if (!selectedChargingMethodType || !selectedChargePoint?.id || !chargingOptionUnit) return;

    let chargingOptionType = selectedChargingMethodType;
    if (chargingOptionType === ChargingMethodType.FullCharge) {
      chargingOptionType = ChargingMethodType.SoC;
    }

    const data: EstimatedChargingSummaryRequest = {
      charge_point_id: selectedChargePoint?.id,
      charge_point_connector_id: selectedChargePointConnector?.id,
      charging_option_type: chargingOptionType,
      charging_option_unit: chargingOptionUnit,
      payment_method_type: activePaymentMethodType as PaymentMethodType,
      wallet_type: activeWallet?.type as WalletType,
      payment_sdk: paymentGateway
    };
    setWaitForEstimatedChargingSummary(true);
    ChargingRequestService.fetchEstimateChargingSummary(data)
      .then((response) => {
        setTotalChargingCostItem(getTotalChargingCostLineItem(response.data.estimated_charging_items));
        setCostBreakupItems(getEstimatedChargingSummaryCostBreakupItems(response.data.estimated_charging_items));

        setWaitForEstimatedChargingSummary(false);
      })
      .catch((e) => {
        setWaitForEstimatedChargingSummary(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const getPaymentTxnStatus = async (orderId: string): Promise<any> => {
    try {
      const response = await PaymentRequestService.fetchPaymentTxnStatus({
        order_id: orderId,
        payment_sdk: paymentGateway || "paytm"
      });
      return response;
    } catch (e) {
      return await Promise.reject(e);
    }
  };

  const handlePayAndStartCharging = async () => {
    let paytmPgRes: any = null;

    if (activePaymentMethodType === PaymentMethodType.PaymentGateway) {
      try {
        setWaitForPayAndStartCharging(true);
        paytmPgRes = await initiatePayment();

        if (!paytmPgRes) {
          showErrorToast(t("OopsSomethingWentWrongPleaseTryAgain"));
          setWaitForPayAndStartCharging(false);
          return;
        }

        // TODO: add payment gateway checks
        // 1006, 141 (User cancelled on card OTP screen), 810
        if (paytmPgRes?.sdkResponse?.RESPCODE === "1006" || paytmPgRes?.sdkResponse?.txnInfo?.RESPCODE === "141") {
          // if the payment was cancelled
          setWaitForPayAndStartCharging(false);
          window.Paytm.CheckoutJS.close();
          return;
        }

        // start polling for payment txn status
        let hasError: any = null;
        let errorMsg: any = null;
        if (totalChargingCostItem?.value !== 0) {
          await loopCallbackUntilSatisfied({
            asyncCallback: () => getPaymentTxnStatus(paytmPgRes?.order_id),
            onAsyncError: ({ error }) => {
              errorMsg = error?.response?.data?.message || error?.response?.data?.msg || "";
              hasError = true;
            },
            satisfactoryCondition: (response: GetPaymentTxnStatusResponse | any) => {
              const status = response?.data?.body?.resultInfo?.resultStatus;

              // TODO: generalize this
              if (status === GenericPaymentTxnStatus.Success) {
                window.Paytm.CheckoutJS.close();
                showSuccessToast(t("Payment transaction completed successfully"));
                hasError = false;
                return true; // abort polling
              } else if (status === GenericPaymentTxnStatus.Failed) {
                // alert(t('Payment transaction is Failed!'));
                window.Paytm.CheckoutJS.close();
                hasError = true;
                if (response?.i18n_msg?.[selectedLanguage]) errorMsg = response?.i18n_msg?.[selectedLanguage];

                return true; // abort polling
              }

              // treat as if it doesn't have any error
              hasError = false;
              return false; // continue polling
            },
            maxIterations: 5,
            delayOnIdxMultiple: 1,
            sleepDuration: 1000
          });
        }

        setWaitForPayAndStartCharging(false);

        if (hasError) {
          showErrorToast(isEmpty(errorMsg) || !errorMsg ? t("FailedToCompletePayment") : errorMsg);
          return;
        }
      } catch (e: any) {
        setWaitForPayAndStartCharging(false);
        if (paymentGateway === PaymentSDK.Razorpay) {
          const reason = e?.error?.reason || e?.reason;
          const source = e?.error?.source || e?.source;
          const description = e?.error?.description;

          if (
            (reason === "payment_cancelled" && source === "customer") ||
            e?.description.includes("cancelled by user")
          ) {
            // pass
            return;
          }

          if (description || e?.description) {
            ErrorHandlerService.handleError(e, {
              errorText: description || e?.description,
              toastOptions: {
                type: "error"
              }
            });
            return;
          }
        } else {
          if (e.message === "onBackPressedCancelTransaction") {
            // pass
            return;
          }
        }
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
        return;
      }
    }
    triggerStartCharging({
      chargeTxnId: paytmPgRes?.charge_transaction_id
    });
  };

  const triggerStartCharging = ({ chargeTxnId }: { chargeTxnId?: string }) => {
    const chargingAmount = getChargingAmount(chargingMethodAmount);
    if (
      chargingAmount &&
      selectedChargingMethodType &&
      selectedChargePointConnector?.primary_connector_id &&
      selectedChargePoint?.public_uid &&
      activePaymentMethodType
    ) {
      const startChargingData: StartChargingPayload = {
        charge_point_public_uid: selectedChargePoint?.public_uid,
        charge_point_id: selectedChargePointConnector?.charge_point_id,
        charge_point_connector_id: selectedChargePointConnector?.id,
        charging_station_connector_id: Number(selectedChargePointConnector?.primary_connector_id),
        charging_option_type: getChargingMethod(selectedChargingMethodType),
        charging_option_unit: chargingAmount,
        payment_method_type: activePaymentMethodType
      };

      if (chargeTxnId) {
        startChargingData.charge_transaction_id = chargeTxnId;
      }
      if (usersPhysicalLocation?.latitude && usersPhysicalLocation?.longitude) {
        startChargingData.user_lat = usersPhysicalLocation?.latitude;
        startChargingData.user_lng = usersPhysicalLocation?.longitude;
      }
      if (activePaymentMethodType === PaymentMethodType.Wallet && activeFleetOperatorOrgUser?.org_id) {
        startChargingData.org_id = activeFleetOperatorOrgUser?.org_id;
      }

      if (isScreenFocused) {
        navigation.replace(SCREEN_NAMES.ChargingStatesScreen, {
          startChargingData,
          selectedChargePointConnector,
          requiredBalance: totalChargingCostItem?.value
        } as ChargingStatesScreenRouteParams);
      }
    }
  };

  const getChargingAmount = (amount: string): string => {
    if (selectedChargingMethodType) {
      if (selectedChargingMethodType === ChargingMethodType.FullCharge) return "100";
      return getSIUnitChargingCustomAmount(availableChargingMethods[selectedChargingMethodType], amount);
    }
    return "";
  };

  const getChargingMethod = (method: ChargingMethodType) => {
    return method === ChargingMethodType.FullCharge ? ChargingMethodType.SoC : method;
  };

  const isNonFleetPaymentMethod = () => {
    return (
      activePaymentMethodType === PaymentMethodType.PaymentGateway ||
      (activePaymentMethodType === PaymentMethodType.Wallet && activeWallet?.type !== WalletType.InstaCharge)
    );
  };

  useEffect(() => {
    if (selectedChargePoint && selectedChargePoint?.supported_charging_methods?.length > 0) {
      const chargingMethods: { [method: string]: ChargingMethod } = {};
      // TODO: optimize this. Use .sort()
      const defaultChargingMethod: ChargingMethod | undefined = selectedChargePoint?.supported_charging_methods.find(
        (cm) => cm.default
      );
      const methodTypesList: ChargingMethodType[] = [];
      if (defaultChargingMethod) {
        if (defaultChargingMethod.method === ChargingMethodType.SoC && `${defaultChargingMethod.amount}` === "100")
          methodTypesList.push(ChargingMethodType.FullCharge);
        methodTypesList.push(defaultChargingMethod.method);
      }
      selectedChargePoint?.supported_charging_methods?.forEach((chargingMethod) => {
        if (defaultChargingMethod?.method === ChargingMethodType.SoC && `${defaultChargingMethod?.amount}` === "100") {
          chargingMethods[ChargingMethodType.FullCharge] = chargingMethod;
        }
        if (defaultChargingMethod?.method !== chargingMethod.method) {
          methodTypesList.push(chargingMethod.method);
        }
        chargingMethods[chargingMethod.method] = chargingMethod;
      });

      setAvailableChargingMethodTypesList(methodTypesList);
      setAvailableChargingMethods(chargingMethods);
    }
  }, [selectedChargePoint]);

  useEffect(() => {
    if (!hasManuallySelected) {
      if (availableChargingMethodTypesList.includes(ChargingMethodType.FullCharge)) {
        setSelectedChargingMethodType(ChargingMethodType.FullCharge);
      } else {
        const defaultChargingMethod = Object.values(availableChargingMethods).find((cm) => cm.default);
        if (defaultChargingMethod) {
          setSelectedChargingMethodType(defaultChargingMethod.method);
        } else {
          setSelectedChargingMethodType(availableChargingMethodTypesList[0]);
        }
      }
    }
  }, [availableChargingMethodTypesList]);

  useEffect(() => {
    if (!!customChargingAmount) {
      setCustomChargingAmount("");
    }

    if (selectedChargingMethodType && availableChargingMethods) {
      const nonSiUnitChargingAmount = getNonSIUnitChargingAmount(availableChargingMethods[selectedChargingMethodType]);
      setChosenSuggestedAmount(nonSiUnitChargingAmount);
    }
  }, [selectedChargingMethodType]);

  useEffect(() => {
    getEstimatedChargingSummary();
  }, [chargingMethodAmount]);

  useEffect(() => {
    if (selectedChargingMethodType === ChargingMethodType.FullCharge) setChargingMethodAmount("100");
    else setChargingMethodAmount(!isEmpty(customChargingAmount) ? customChargingAmount : chosenSuggestedAmount);
  }, [customChargingAmount, chosenSuggestedAmount, selectedChargingMethodType]);

  useFocusEffect(
    useCallback(() => {
      StatusBar.setBackgroundColor(THEME_COLORS.defaultStatusBar);
    }, [])
  );

  return (
    <ScreenWrapper {...props}>
      <View style={styles.root}>
        <ScreenHeader
          navigation={navigation}
          style={{ borderBottomWidth: 0, zIndex: 100, position: "sticky" as any, top: 0, left: 0, right: 0 }}
          onBackPress={() => {
            onBackPress();
          }}
        />

        <ScrollView
          keyboardShouldPersistTaps={"always"}
          contentContainerStyle={{
            flex: 1,
            marginBottom: 30
          }}>
          <View style={styles.mainCont}>
            <View style={{ paddingLeft: 16, paddingRight: 16 }}>
              <CText value={t("charging")} size={26} semiBold style={{ lineHeight: 55, fontWeight: "700" }} />
              <CText
                value={t("optionsLC")}
                size={26}
                bold
                style={{
                  marginTop: -12,
                  lineHeight: 55,
                  fontWeight: "700"
                }}
              />
            </View>

            {/* CP not connected error */}
            {!cpConnected && cpNotConnectedError && (
              <View style={{ marginVertical: 10, paddingHorizontal: 16 }}>
                <RichError i18nError={cpNotConnectedError} />
              </View>
            )}

            <View>

              {/* <div>
                <textarea cols={50} rows={25}
                  defaultValue={mConfigTextAreaVal}
                  ref={mConfigTextAreaRef} />
              </div> */}

              {/* Select charging method */}
              <View style={{ marginTop: 24 }}>
                <View style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <CText value={t("chargeBy")} size={18} semiBold style={{ fontWeight: "600" }} />
                </View>

                <ScrollView
                  style={[{ marginTop: 16 }]}
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={{
                    paddingLeft: 16,
                    paddingRight: 16
                  }}
                  keyboardShouldPersistTaps={"always"}>
                  {availableChargingMethodTypesList?.length > 0 ? (
                    <>
                      {availableChargingMethodTypesList.map((chargingMethodType: string) => {
                        const chargingMethod = availableChargingMethods[chargingMethodType];
                        const isSelectedMethod = selectedChargingMethodType === chargingMethodType;

                        return (
                          <View key={chargingMethodType}>
                            <OptionRadioButton
                              title={
                                chargingMethodType === ChargingMethodType.FullCharge
                                  ? "Full Charge"
                                  : chargingMethod.method
                              }
                              onPress={() => {
                                setSelectedChargingMethodType(chargingMethodType as ChargingMethodType);
                                setHasManuallySelected(true);
                              }}
                              checked={isSelectedMethod}
                              style={{
                                marginRight: 16,
                                borderWidth: 1,
                                paddingTop: 10,
                                paddingBottom: 10,
                                borderBottomWidth: 1,
                                borderColor: isSelectedMethod ? COLORS.blackColor : COLORS.primaryLight,
                                backgroundColor: COLORS.whiteColor
                              }}
                            />
                          </View>
                        );
                      })}
                    </>
                  ) : (
                    <View>
                      <CText>No charging methods supported</CText>
                    </View>
                  )}
                </ScrollView>
              </View>

              {/* Select charging amount */}
              {selectedChargingMethodType !== ChargingMethodType.FullCharge ? (
                <View style={{ paddingLeft: 16, paddingRight: 16, marginTop: 48 }}>
                  <CText size={16} semiBold style={{ fontWeight: "600" }}>
                    {selectedChargingMethodType === ChargingMethodType.Amount && t("chargeEVForAmount")}
                    {selectedChargingMethodType === ChargingMethodType.Time && t("chargeEVForPeriod")}
                    {selectedChargingMethodType === ChargingMethodType.SoC && t("chargeEVUptoSoC")}
                    {selectedChargingMethodType === ChargingMethodType.Units && t("chargeEVForUnits")}
                  </CText>

                  {!isEmpty(customChargingAmount) ? (
                    /* custom value */
                    <View style={styles.amountCont}>
                      <CText
                        size={12}
                        semiBold
                        style={{
                          marginBottom: isIOS() ? 2 : 0
                        }}>
                        {selectedChargingMethodType === ChargingMethodType.Amount && `₹${customChargingAmount}`}
                        {selectedChargingMethodType === ChargingMethodType.SoC && `${customChargingAmount} %`}
                        {selectedChargingMethodType === ChargingMethodType.Time && `${customChargingAmount} mins`}
                        {selectedChargingMethodType === ChargingMethodType.Units && `${customChargingAmount} kWh`}
                      </CText>

                      {/* close button */}
                      <TouchableOpacity
                        activeOpacity={0.7}
                        onPress={() => {
                          setCustomChargingAmount("");
                          if (selectedChargingMethodType) {
                            const nonSiUnitChargingAmount = getNonSIUnitChargingAmount(
                              availableChargingMethods[selectedChargingMethodType]
                            );
                            setChosenSuggestedAmount(nonSiUnitChargingAmount);
                          }
                        }}>
                        <IoMdCloseCircle size={24} color={COLORS.darkblue} style={{ marginLeft: 8 }} />
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <ScrollView
                      style={{
                        marginTop: 16
                      }}
                      horizontal
                      showsHorizontalScrollIndicator={false}>
                      <OptionRadioButton
                        title={(() => {
                          if (!selectedChargingMethodType) return "";

                          const nonSiUnitChargingAmount = getNonSIUnitChargingAmount(
                            availableChargingMethods[selectedChargingMethodType]
                          );
                          if (selectedChargingMethodType === ChargingMethodType.SoC)
                            return `${nonSiUnitChargingAmount}%`;
                          else if (selectedChargingMethodType === ChargingMethodType.Amount)
                            return `₹${nonSiUnitChargingAmount}`;
                          else if (selectedChargingMethodType === ChargingMethodType.Time)
                            return `${nonSiUnitChargingAmount} mins`;
                          else if (selectedChargingMethodType === ChargingMethodType.Units)
                            return `${nonSiUnitChargingAmount} kWh`;
                        })()}
                        onPress={() => {
                          if (selectedChargingMethodType) {
                            const nonSiUnitChargingAmount = getNonSIUnitChargingAmount(
                              availableChargingMethods[selectedChargingMethodType]
                            );
                            setChosenSuggestedAmount(nonSiUnitChargingAmount);
                          }
                        }}
                        checked={!!chosenSuggestedAmount}
                        style={{
                          marginEnd: 16,
                          borderWidth: 1,
                          borderBottomWidth: 1,
                          paddingTop: 10,
                          paddingBottom: 10,
                          backgroundColor: COLORS.whiteColor,
                          borderColor: chosenSuggestedAmount ? COLORS.blackColor : COLORS.primaryLight
                        }}
                      />
                      <OptionRadioButton
                        title={t("enter") + selectedChargingMethodType}
                        onPress={() => {
                          setIsEnterAmountModalOpen(true);
                        }}
                        checked={customChargingAmount}
                        style={{
                          borderWidth: 1,
                          borderBottomWidth: 1,
                          paddingTop: 10,
                          paddingBottom: 10,
                          borderColor: chosenSuggestedAmount ? COLORS.blackColor : COLORS.primaryLight
                        }}
                      />
                    </ScrollView>
                  )}
                </View>
              ) : null}

              {!cpConnected && (
                <View
                  style={{
                    width: Dimensions.get("window").width,
                    height: "100%",
                    backgroundColor: THEME_COLORS.bgColor + "90",
                    position: "absolute"
                  }}
                />
              )}
            </View>
          </View>
        </ScrollView>

        <ScreenBottomBar
          style={{
            padding: 0,
            paddingBottom: 0,
            marginBottom: 0,
            zIndex: 100,
            position: "sticky" as any,
            bottom: 0,
            left: 0,
            right: 0
          }}>
          <View
            style={{
              backgroundColor: COLORS.whiteColor,
              borderWidth: 1,
              borderBottomWidth: 0,
              borderColor: COLORS.grey300,
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              ...StyleHelpers.pageSpacing,
              paddingBottom: isIOS()
                ? StyleHelpers.pageSpacing.paddingLeft + insets.bottom
                : StyleHelpers.pageSpacing.paddingLeft,
              marginBottom: -(insets.bottom + 5)
            }}>
            <View
              style={{
                justifyContent: "space-between",
                marginTop: 15,
                marginBottom: 15,
                gap: 15
              }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}>
                <CText
                  size={11}
                  medium
                  style={{
                    marginRight: 5,
                    color: COLORS.grey600
                  }}>
                  {t("PayUsing")} :
                </CText>
                <PaytmIcon />
              </View>
            </View>

            {isNonFleetPaymentMethod() && !!totalChargingCostItem?.value && (
              <View
                style={{
                  marginTop: 5
                }}>
                {/* total cost */}
                <View>
                  {/* total */}
                  {!!totalChargingCostItem?.value && (
                    <View
                      style={{
                        ...StyleHelpers.verticallyCenteredRow,
                        justifyContent: "space-between"
                      }}>
                      <View>
                        <CText semiBold size={14} style={{ fontWeight: "600" }}>
                          {totalChargingCostItem?.name}
                        </CText>
                      </View>
                      <View>
                        <CText semiBold size={13}>
                          <NumberFormatText
                            prefix={"₹"}
                            value={Number(totalChargingCostItem?.value).toFixed(2)}
                            style={{ fontWeight: "600", fontSize: 14 }}
                          />
                        </CText>
                      </View>
                    </View>
                  )}
                </View>

                {!!totalChargingCostItem?.note && (
                  <CText
                    size={10}
                    style={{
                      color: COLORS.grey400
                    }}>
                    {totalChargingCostItem?.note}
                  </CText>
                )}

                {/* breakdown */}
                <View
                  style={{
                    marginBottom: 15
                  }}>
                  <Accordion
                    items={[
                      {
                        itemKey: "breakup",
                        head: (
                          <View
                            style={{
                              ...StyleHelpers.verticallyCenteredRow,
                              justifyContent: "flex-end",
                              marginBottom: 10,
                              flex: 1
                            }}>
                            <CText size={10} medium style={{ color: COLORS.blue500 }}>
                              {t("ViewCostBreakup")}
                            </CText>
                          </View>
                        ),
                        openIcon: (
                          <IoIosArrowUp
                            size={16}
                            style={{
                              marginTop: isIOS() ? 0 : 3,
                              marginRight: -2
                            }}
                            color={COLORS.blue500}
                          />
                        ),
                        closeIcon: (
                          <IoIosArrowDown
                            size={16}
                            style={{
                              marginTop: 3,
                              marginRight: -2
                            }}
                            color={COLORS.blue500}
                          />
                        ),
                        body: (
                          <View>
                            {/* cost breakup */}
                            {costBreakupItems.map((item) => {
                              return (
                                <View
                                  key={item.code}
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginVertical: 2
                                  }}>
                                  <View
                                    style={{
                                      flex: 0.8
                                    }}>
                                    <CText size={12}>{item.name}</CText>
                                    {/* note */}
                                    {!!item.note && (
                                      <CText size={10} style={{ color: COLORS.grey500 }}>
                                        {item.note}
                                      </CText>
                                    )}
                                    {/* details */}
                                    {!!item.details?.length && (
                                      <View
                                        style={{
                                          ...StyleHelpers.verticallyCenteredRow,
                                          flexWrap: "wrap"
                                        }}>
                                        <CText
                                          size={10}
                                          style={{
                                            alignSelf: "flex-end",
                                            paddingTop: 2
                                          }}>
                                          Unpaid bills:{" "}
                                        </CText>
                                        {item.details.map((detailItem, index) => {
                                          if (item.code === CostBreakupLineItemCode.UnpaidAmount) {
                                            return (
                                              <>
                                                {index !== 0 && (
                                                  <CText key={uniqueId()} size={10}>
                                                    ,{" "}
                                                  </CText>
                                                )}
                                                <DashedLink
                                                  key={uniqueId()}
                                                  onPress={() => {
                                                    if (isScreenFocused) {
                                                      navigation.navigate(
                                                        SCREEN_NAMES.ChargingSuccessfulScreen as never,
                                                        {
                                                          chargeTxnId: detailItem.charge_transaction_id,
                                                          returnScreen: SCREEN_NAMES.PreStartChargingSelectionScreen
                                                        } as never
                                                      );
                                                    }
                                                  }}
                                                  style={{
                                                    borderBottomColor: COLORS.blue500
                                                  }}>
                                                  <CText
                                                    size={10}
                                                    semiBold
                                                    style={{
                                                      color: COLORS.blue500,
                                                      fontFamily: "monospace"
                                                    }}>
                                                    {detailItem.bill_uid}
                                                  </CText>
                                                </DashedLink>
                                              </>
                                            );
                                          }

                                          return null;
                                        })}
                                      </View>
                                    )}
                                  </View>
                                  <View>
                                    <CText size={12}>
                                      <NumberFormatText prefix={"₹"} value={Number(item.value).toFixed(2)} />
                                    </CText>
                                  </View>
                                </View>
                              );
                            })}
                          </View>
                        )
                      }
                    ]}
                  />
                </View>
              </View>
            )}

            <CButton
              title={
                activePaymentMethodType === PaymentMethodType.PaymentGateway
                  ? `${t("Pay")}${totalChargingCostItem?.value ? ` ₹${totalChargingCostItem?.value}` : ""} ${t(
                      "and"
                    )} ${t("startCharging")}`
                  : t("startCharging")
              }
              loading={waitForEstimatedChargingSummary || waitForPayAndStartCharging}
              // style={{ marginTop: 96 }}
              onPress={handlePayAndStartCharging}
              disable={
                waitForEstimatedChargingSummary ||
                waitForPayAndStartCharging ||
                !cpConnected ||
                !(
                  selectedChargePointConnector?.status === ChargePointStatus.Available ||
                  selectedChargePointConnector?.status === ChargePointStatus.Preparing
                )
              }
            />
          </View>
        </ScreenBottomBar>
      </View>
      {waitForPayAndStartCharging && <FullScreenOverlay />}
      <Modal
        style={{
          flex: 1,
          margin: 0,
          justifyContent: "flex-end",
          position: "relative"
        }}
        visible={isEnterAmountModalOpen}
        transparent={true}
        useNativeDriver={true}
        useNativeDriverForBackdrop={true}
        onDismiss={() => setIsEnterAmountModalOpen(false)}
        {...modalProps}>
        {/* Close Button */}
        <TouchableOpacity
          style={{
            padding: 10,
            ...StyleSheet.absoluteFillObject,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 10
          }}
          onPress={() => {
            setIsEnterAmountModalOpen(false);
            setInvalidAmount(false);
          }}>
          <IoMdClose size={30} display="none" />
        </TouchableOpacity>
        <View
          style={{
            flex: 1,
            justifyContent: "flex-end",
            zIndex: 100
          }}>
          <KeyboardAvoidingView
            behavior={getKeyboardAvoidingViewBehavior()}
            style={{
              justifyContent: "flex-end",
              zIndex: 100
            }}>
            <View
              onLayout={(event) => {
                const { height } = event.nativeEvent.layout;
                setContainerHeight(height);
              }}
              style={[
                styles.modalCont,

                {
                  justifyContent: "flex-end",
                  marginTop: ScreenHeight - containerHeight
                }
              ]}>
              <View style={styles.modalWhiteCont}>
                <TouchableOpacity
                  style={{
                    padding: 12,
                    marginRight: -12,
                    marginTop: -18,
                    marginBottom: -22,
                    alignSelf: "flex-end"
                  }}
                  onPress={() => {
                    setIsEnterAmountModalOpen(false);
                    setInvalidAmount(false);
                  }}>
                  <CloseIcon />
                </TouchableOpacity>

                <CText
                  value={t("enter") + selectedChargingMethodType}
                  size={26}
                  semiBold
                  style={{ lineHeight: 55, fontWeight: "600" }}
                />
                <View
                  style={{
                    marginTop: 10,
                    width: "100%"
                  }}>
                  <CInput
                    ref={amountInputRef}
                    /* @ts-ignore */
                    value={modalCustomChargingAmount}
                    onChangeText={setModalCustomChargingAmount}
                    keyboardType="number-pad"
                    placeholder="How much do you want to charge for?"
                    autoFocus
                    style={{
                      lineHeight: 17,
                      fontWeight: "400",
                      width: "100%",
                      borderWidth: "1px",
                      borderColor: invalidAmount ? COLORS.brightRed : ""
                    }}
                  />
                </View>

                {invalidAmount && (
                  <CText
                    value={`${t("invalidAmount")}`}
                    size={12}
                    style={{
                      color: COLORS.errorColor
                    }}
                  />
                )}

                <CButton
                  title={t("done")}
                  style={{
                    marginTop: 48,
                    marginBottom: insets.bottom
                  }}
                  onPress={() => {
                    const amount = Number(modalCustomChargingAmount);
                    if (!/^\d*$/.test(`${amount}`)) {
                      setInvalidAmount(true);
                      return;
                    }

                    if (amount === 0) {
                      setInvalidAmount(true);
                    } else if (selectedChargingMethodType === ChargingMethodType.SoC && amount > 100) {
                      setInvalidAmount(true);
                    } else if (selectedChargingMethodType != ChargingMethodType.SoC && amount > 2000) {
                      setInvalidAmount(true);
                    } else {
                      setInvalidAmount(false);
                      setIsEnterAmountModalOpen(false);
                      setModalCustomChargingAmount("");
                      setChosenSuggestedAmount("");
                      setCustomChargingAmount(modalCustomChargingAmount);
                    }
                  }}
                />
              </View>
            </View>
          </KeyboardAvoidingView>
        </View>
      </Modal>

      {/* <CheckoutProvider config={mConfig}
          checkoutJsInstance={checkoutJsInstance}
          openInPopup={openInPopup} 
          env="STAGE">
          <InjectedCheckout />
          {showCheckout && <Checkout />}
        </CheckoutProvider> */}

      {
        !isEmpty(mConfig) &&
        <CheckoutProvider config={mConfig} checkoutJsInstance={checkoutJsInstance} env="PROD">
          {
            showCheckout && <Checkout />
          }
        </CheckoutProvider>
      }
    </ScreenWrapper>
  );
}
