import { ChargingMethodType } from "../../enums/charging/charging-method";
import { ChargingMethod } from "../../types/charging/charge-points";

export const getSIUnitChargingAmount = (cm: ChargingMethod): string => {
    if (cm.method === ChargingMethodType.Time) {
        return `${Number(cm.amount) * 60}`;
    }
    else if (cm.method === ChargingMethodType.Units) {
        return `${Number(cm.amount) * 1000}`;
    }
    return `${cm.amount}`;
};

export const getSIUnitChargingCustomAmount = (cm: ChargingMethod, amount: string) => {
    /**
     * this function's argument needs some fixing, `cm` (ChargingMethod) can be replaced by `method` (ChargingMethodType)
     */

    if (cm.method === ChargingMethodType.Time) {
        return `${Number(amount) * 60}`;
    }
    else if (cm.method === ChargingMethodType.Units) {
        return `${Number(amount) * 1000}`;
    }
    return `${amount}`;
};

export const getNonSIUnitChargingAmount = (cm: ChargingMethod): string => {
    if (cm.method === ChargingMethodType.Time) {
        return `${Number(cm.amount) / 60}`;
    }
    else if (cm.method === ChargingMethodType.Units) {
        return `${Number(cm.amount) / 1000}`;
    }
    return `${cm.amount}`;
};
