import { Text } from "react-native";
import { NumericFormat } from "react-number-format";

export const NumberFormatText = (props: any) => {
  const { value } = props;

  return (
    <NumericFormat
      displayType={"text"}
      decimalScale={2}
      fixedDecimalScale={true}
      value={value}
      thousandSeparator={true}
      thousandsGroupStyle={"lakh"}
      renderText={(value) => <Text style={{ fontWeight: "600", fontSize: 14 }}>{value}</Text>}
      {...props}
    />
  );
};
