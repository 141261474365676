import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import styles from './styles';
import { COLORS } from '../../../config/colors';
import { FaAngleLeft } from 'react-icons/fa';

export default function BackButton(props: any) {
  const { onPress = () => {}, shadow } = props;
  return (
    <TouchableOpacity activeOpacity={0.7} onPress={onPress}>
      <View>
        <View style={styles.container}>
          <FaAngleLeft size={20} color={COLORS.blackColor} />
        </View>
        <View style={styles.subView} />
      </View>
    </TouchableOpacity>
  );
}
