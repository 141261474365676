import { useTranslation } from "react-i18next";
import AnimatedLottieView from "lottie-react";
import { StyleSheet, View } from "react-native";
import CText from "../CText";
import { StyleHelpers } from "../../../constants/style-helpers";
import { LottieAnimation } from "../../../config/lottie";
import { horizontalSize, verticalSize } from "../../../functions/responsive";

// type Props = {}
export function FullScreenRipple() {
  const { t } = useTranslation();
  return (
    <View style={styles.root}>
      <AnimatedLottieView animationData={LottieAnimation.searching_ripple} autoPlay style={styles.animation} />
      <View style={styles.subView}></View>
      <View style={styles.absoluteCont}>
        <CText style={{ fontWeight: "600" }} value={t("searchingNearest")} size={26} bold />
        <CText style={{ fontWeight: "600" }} value={t("chargersForYouLC")} size={26} bold />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    // alignItems: "center",
    // height: verticalSize(100),
    // width: horizontalSize(100)
  },
  absoluteCont: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    top: verticalSize(60)
  },
  subView: {
    position: "absolute",
    height: "100%",
    width: "100%",
    left: 0,
    top: 0,
    ...StyleHelpers.totalCenter
  },
  animation: {
    // height: verticalSize(100),
  }
});
