import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import { AxiosError } from "axios";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { ActivityIndicator, Dimensions, Modal, View } from "react-native";
import { batch, useDispatch } from "react-redux";
import { useActiveFleetOperatorOrgUser } from "../../../hooks";
import EnterAccessCodeContent from "./components/EnterAccessCodeContent";
import { AccessCodeType } from "../../../central-atoms/access-code/enums";
import { AccessibilityType } from "../../../central-atoms/accessibility/enums";
import { ChargePointErrorCode, ChargePointStatus } from "../../../central-atoms/enums/ocpp/v1.6/generic";
import { ChargePointConnector, UnifiedCPConnector } from "../../../central-atoms/types/charging/charge-points";
import { I18NRichMessageResponse } from "../../../central-atoms/types/responses/i18n";
import { COLORS } from "../../../config/colors";
import ScreenWrapper from "../../../miscellaneous/components/ScreenWrapper";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { useInterval } from "../../../miscellaneous/hooks";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import ChargePointRequestService from "../../../services/ChargePointRequestService";
import {
  useSelectedChargePoint,
  useSelectedChargePointConnector,
  useSelectedMixedChargingStation,
  useTrackedCPConnectionError,
  useTrackedCPErrorConnector
} from "../../../hooks";
import {
  setSelectedChargePoint,
  setSelectedChargePointConnector,
  setTrackedCPConnectionError,
  setTrackedCPErrorConnector
} from "../../../actions/doChargingActions";
import SelectConnector from "./components/SelectConnector";
import { useActiveWallet } from "../../../hooks/walletHooks";
import { isFleetWallet } from "../../../helpers/walletHelpers";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import SelectChargingMethod from "./components/SelectChargingMethod";
import { ScreenComponent } from "../../../types";
import ConfirmCS from "./components/ConfirmCS";
import ConnectCharger from "./components/ConnectCharger";
import { verticalSize } from "../../../functions/responsive";

const faultyCPCStatuses = new Set<ChargePointStatus>([
  ChargePointStatus.SuspendedEVSE,
  ChargePointStatus.SuspendedEV,
  ChargePointStatus.Faulted,
  ChargePointStatus.Unavailable
]);

type Props = ScreenComponent & {};
export default function PreStartChargingSelectionScreen(props: Props) {
  const { navigation, route } = props;
  const { slideIndex = null, shouldPromptForOrgVerification = false } = route?.params ?? ({} as any);

  const dispatch = useDispatch();
  const isScreenFocused = useIsFocused();

  const selectedMixedChargingStation = useSelectedMixedChargingStation();
  const selectedChargePoint = useSelectedChargePoint();
  const selectedChargePointConnector = useSelectedChargePointConnector();
  const activeFleetOperatorOrgUser = useActiveFleetOperatorOrgUser();

  const activeWallet = useActiveWallet();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fullScreenLoaderVisible, setFullScreenLoaderVisible] = useState(
    slideIndex !== null || shouldPromptForOrgVerification || false
  );

  const [accessCodeVerificationModalVisible, setAccessCodeVerificationModalVisible] = useState(false);

  // CP connection state polling
  const [cpNotConnectedError, setCpNotConnectedError] = useState<I18NRichMessageResponse | null>(null);
  const [cpConnected, setCPConnected] = useState(true);
  const [waitForCPConnected, setWaitForCPConnected] = useState<boolean | null>(null);
  const [shouldContinueCPConnectedLongPolling, setShouldContinueCPConnectedLongPolling] = useState<boolean>(false);

  // CP connectors polling
  const [shouldContinueCPConnectorsLongPolling, setShouldContinueCPConnectorsLongPolling] = useState<boolean>(false);
  const [waitForCPConnectors, setWaitForCPConnectors] = useState<boolean | null>(null);

  // analytics
  const trackedCPConnectionErr = useTrackedCPConnectionError();
  const trackedCPErrorConnector = useTrackedCPErrorConnector();

  const accessCodeType = (() => {
    let type;
    if (selectedChargePoint?.accessibility === AccessibilityType.Private)
      type = AccessCodeType.ChargingStationAccessCode;
    else if (!activeFleetOperatorOrgUser?.org_id) type = AccessCodeType.SharedWalletCode;
    else if (!!activeFleetOperatorOrgUser?.org_id) type = AccessCodeType.FleetOperatorCode;

    return type;
  })();

  const checkIsConnected = (): Promise<void> => {
    if (waitForCPConnected) return Promise.resolve();

    setWaitForCPConnected(true);
    return ChargePointRequestService.isConnected(selectedChargePoint?.public_uid as string)
      .then(({ data, i18n_error }) => {
        setWaitForCPConnected(false);
        setCPConnected(data?.is_connected);

        if (!data.is_connected && i18n_error) setCpNotConnectedError(i18n_error);
        else setCpNotConnectedError(null);
      })
      .catch((e: AxiosError) => {
        setWaitForCPConnected(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const getCPConnectors = (): Promise<void> => {
    if (selectedChargePoint?.id && cpConnected && !waitForCPConnectors) {
      setWaitForCPConnectors(true);
      return ChargePointRequestService.fetchCPConnectors(selectedChargePoint?.id)
        .then(({ data }) => {
          setWaitForCPConnectors(false);
          batch(() => {
            // TODO: update CS Connectors
            const cpConnectors: ChargePointConnector[] = [];
            let updatedSelectedCPC = selectedChargePointConnector as ChargePointConnector;
            data.forEach((unifiedCPConnector: UnifiedCPConnector) => {
              cpConnectors.push(unifiedCPConnector.cp_connector);
              if (updatedSelectedCPC?.id === unifiedCPConnector.cp_connector.id) {
                updatedSelectedCPC = unifiedCPConnector.cp_connector;
              }
            });
            dispatch(
              setSelectedChargePoint({
                ...selectedChargePoint,
                connectors: cpConnectors
              })
            );

            dispatch(
              setSelectedChargePointConnector({
                ...(selectedChargePointConnector ?? {}),
                ...(updatedSelectedCPC ?? {})
              } as ChargePointConnector)
            );
          });
        })
        .catch((e) => {
          setWaitForCPConnectors(false);
          ErrorHandlerService.handleError(e, {
            toastOptions: {
              type: "error"
            }
          });
        });
    }

    return Promise.resolve();
  };

  const onBackPress = () => {
    if (currentIndex == 0) {
      if (isScreenFocused) {
        navigation.goBack();
      }
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const onNextPress = (screenItemIndex?: number) => {
    if (currentIndex <= screenList.length - 1) {
      setCurrentIndex(Number(screenItemIndex));
    }
  };

  const screenList = [
    {
      title: "CP Details",
      screen: (
        <ConfirmCS
          active={currentIndex === 0}
          route={route}
          navigation={navigation}
          onBackPress={onBackPress}
          onNextPress={() => onNextPress(1)}
          cpConnected={cpConnected}
          cpNotConnectedError={cpNotConnectedError}
        />
      )
    },
    {
      title: "Select Connectors",
      screen: (
        <SelectConnector
          active={currentIndex === 1}
          route={route}
          navigation={navigation}
          onBackPress={onBackPress}
          onNextPress={() => onNextPress(2)}
          cpConnected={cpConnected}
          cpNotConnectedError={cpNotConnectedError}
        />
      )
    },
    {
      title: "Connect Charger",
      screen: (
        <ConnectCharger
          active={currentIndex === 2}
          route={route}
          navigation={navigation}
          onBackPress={onBackPress}
          onNextPress={() => onNextPress(3)}
          cpConnected={cpConnected}
          cpNotConnectedError={cpNotConnectedError}
        />
      )
    },
    {
      title: "Charging Option",
      screen: (
        <SelectChargingMethod
          active={currentIndex === 3}
          route={route}
          navigation={navigation}
          onBackPress={() => setCurrentIndex(1)}
          onNextPress={onNextPress}
          cpConnected={cpConnected}
          cpNotConnectedError={cpNotConnectedError}
        />
      )
    }
    // {
    //     title: 'Charging Started',
    //     screen: (
    //         <ChargingStartingSoon
    //             navigation={navigation}
    //             onBackPress={onBackPress}
    //             onNextPress={onNextPress}
    //             cpConnected={cpConnected}
    //             cpNotConnectedError={cpNotConnectedError}
    //         />
    //     ),
    // },
  ];

  // long poll for CP connections state
  useInterval(
    () => {
      checkIsConnected();
    },
    shouldContinueCPConnectedLongPolling ? 5000 : null
  );

  // long poll for CP connectors
  useInterval(
    () => {
      getCPConnectors();
    },
    shouldContinueCPConnectorsLongPolling ? 5000 : null
  );

  // enable/disable long polling for CP connectors
  useEffect(() => {
    if (cpConnected) {
      // do an initial call to get connectors immediately after connection is restored
      getCPConnectors();
      setShouldContinueCPConnectorsLongPolling(true);
    } else setShouldContinueCPConnectorsLongPolling(false);

    return () => {
      setShouldContinueCPConnectorsLongPolling(false);
    };
  }, [cpConnected]);

  // CP connection error
  useEffect(() => {
    if (!cpConnected && !isEmpty(cpNotConnectedError) && !trackedCPConnectionErr) {
      dispatch(setTrackedCPConnectionError(true));
    }

    if (cpConnected) {
      dispatch(setTrackedCPConnectionError(null));
    }
  }, [cpConnected, cpNotConnectedError]);

  // for setting/clearing all intervals when the screen is focused/exited
  useFocusEffect(
    useCallback(() => {
      setShouldContinueCPConnectedLongPolling(true);
      setShouldContinueCPConnectorsLongPolling(true);

      return () => {
        setShouldContinueCPConnectedLongPolling(false);
        setShouldContinueCPConnectorsLongPolling(false);
      };
    }, [])
  );

  // send segment analytics events
  useEffect(() => {
    if (
      // has status
      selectedChargePointConnector?.status &&
      // has a faulty status
      faultyCPCStatuses.has(selectedChargePointConnector?.status) &&
      // CPC has some error
      selectedChargePointConnector.error !== ChargePointErrorCode.NoError &&
      // is not tracked
      (!trackedCPErrorConnector ||
        // already tracked, but...
        (trackedCPErrorConnector &&
          // ...is new, or...
          (trackedCPErrorConnector.id !== selectedChargePointConnector?.id ||
            // ...is same, but...
            (trackedCPErrorConnector.id === selectedChargePointConnector?.id &&
              // ...has different errors
              trackedCPErrorConnector.error !== selectedChargePointConnector?.error) ||
            trackedCPErrorConnector.vendor_error_code !== selectedChargePointConnector?.vendor_error_code)))
    ) {
      dispatch(setTrackedCPErrorConnector(selectedChargePointConnector));
    } else {
      dispatch(setTrackedCPErrorConnector(null));
    }
  }, [
    selectedChargePointConnector?.status,
    selectedChargePointConnector?.error,
    selectedChargePointConnector?.vendor_error_code
  ]);

  // if the active fleet operator is not set yet, ask for it
  useEffect(() => {
    if (!activeFleetOperatorOrgUser?.org_id && isFleetWallet(activeWallet)) {
      setAccessCodeVerificationModalVisible(true);
    }
  }, [activeFleetOperatorOrgUser?.org_id]);

  useLayoutEffect(() => {
    if (!isEmpty(selectedChargePointConnector) && slideIndex !== null) {
      setTimeout(() => {
        setCurrentIndex(Number(slideIndex));
        if (!shouldPromptForOrgVerification) setFullScreenLoaderVisible(false);
      }, 500);
    }

    if (shouldPromptForOrgVerification) {
      setTimeout(() => {
        setAccessCodeVerificationModalVisible(true);
        setFullScreenLoaderVisible(false);
      }, 600);
    }

    if (!shouldPromptForOrgVerification && slideIndex === null) {
      setFullScreenLoaderVisible(false);
    }

    return () => {
      dispatch(setTrackedCPConnectionError(null));
    };
  }, []);

  return (
    <View
      style={{
        height: verticalSize(100),
        backgroundColor: THEME_COLORS.bgColor
      }}>
      {fullScreenLoaderVisible && (
        <View
          style={{
            position: "absolute",
            flex: 1,
            backgroundColor: THEME_COLORS.bgColor,
            height: "100%",
            width: "100%",
            zIndex: 1,
            ...StyleHelpers.totalCenter
          }}>
          <ActivityIndicator size={40} color={COLORS.darkBlack} />
        </View>
      )}
      <View
        style={{
          flex: 1,
          top: 0,
          bottom: 0,
          height: Dimensions.get("window").height,
          width: Dimensions.get("window").width
        }}>
        {screenList[currentIndex].screen}
      </View>

      <Modal style={{ flex: 1, margin: 0 }} visible={accessCodeVerificationModalVisible}>
        <ScreenWrapper edges={["left", "right"]} {...props}>
          <EnterAccessCodeContent
            mixedChargingStation={selectedMixedChargingStation}
            accessCodeType={accessCodeType}
            fleetOperator={!activeFleetOperatorOrgUser?.org_id ? null : activeFleetOperatorOrgUser.org}
            onVerificationSuccess={() => {
              setAccessCodeVerificationModalVisible(false);
            }}
          />
        </ScreenWrapper>
      </Modal>
    </View>
  );
}
