import { Provider } from "react-redux";
import { setGlobalVar } from "./miscellaneous/helpers";
import { GLOBAL_VARS } from "./constants";
import { AppAnalyticsService } from "./app-analytics/AppAnalyticsService";
import FirebaseService from "./services/FirebaseService";
import { store } from "./redux/store";
import NavStart from "./navigation";
import { FaCircleCheck } from "react-icons/fa6";
import {
  NavigationContainer,
  useNavigationContainerRef,
} from "@react-navigation/native";
import {
  isReadyRef,
  routeNameRef,
} from "./miscellaneous/helpers/RootNavigation";
import { ToastContainer } from "react-toastify";
import { CloseIcon } from "./icons";
import { COLORS } from "./config/colors";
import { useEffect } from "react";
import config from "./config/config";

const CloseButton = ({ closeToast }) => {
  return (
    <div style={{ marginTop: 10 }}>
      <CloseIcon onClick={closeToast} fill={COLORS.whiteColor} />
    </div>
  );
};

// ref: https://github.com/jhen0409/react-native-debugger/blob/master/docs/network-inspect-of-chrome-devtools.md#how-it-works
window.XMLHttpRequest = (window as any).originalXMLHttpRequest
  ? (window as any).originalXMLHttpRequest
  : window.XMLHttpRequest;
window.FormData = (window as any).originalFormData
  ? (window as any).originalFormData
  : window.FormData;

// fetch; // Ensure to get the lazy property

if ((window as any).__FETCH_SUPPORT__) {
  // it's RNDebugger only to have
  (window as any).__FETCH_SUPPORT__.blob = false;
} else {
  /*
   * Set __FETCH_SUPPORT__ to false is just work for `fetch`.
   * If you're using another way you can just use the native Blob and remove the `else` statement
   */
  window.Blob = (window as any).originalBlob
    ? (window as any).originalBlob
    : window.Blob;
  window.FileReader = (window as any).originalFileReader
    ? (window as any).originalFileReader
    : window.FileReader;
}

setGlobalVar(GLOBAL_VARS.AppAnalytics, new AppAnalyticsService());
setGlobalVar(GLOBAL_VARS.FirebaseServiceInstance, new FirebaseService());

const AppWithStore = () => {
  const navigationRef = useNavigationContainerRef();

  const getVariant = () => {
    const root = document.querySelector('#root');
    const variant = root?.getAttribute('data-app-variant');
    if (variant) {
      GLOBAL_VARS.AppVariant = variant as string;
      config.WHITE_LABEL_ID = variant;
    }
  };

  useEffect(() => {
    getVariant();
  }, []);

  return (
    <Provider store={store}>
      <NavigationContainer
        // @ts-ignore
        ref={navigationRef}
        onReady={() => {
          routeNameRef.current = navigationRef?.getCurrentRoute()?.name;
          isReadyRef.current = true;
        }}
      >
        <NavStart />
        <ToastContainer
          closeButton={CloseButton}
          icon={({ type }) => {
            if (type === "success")
              return <FaCircleCheck color={COLORS.whiteColor} size={20} />;
            // if (type === 'error') return '🚨';
            // else return 'ℹ️';
          }}
        />
      </NavigationContainer>
    </Provider>
  );
};

export default AppWithStore;
