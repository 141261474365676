import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useState } from "react";
import { ScrollView, View, ViewStyle } from "react-native";
import { batch, useDispatch } from "react-redux";
import {
  useAppliedFilters,
  useAppliedFiltersChangeVersion,
  useAppliedFiltersCount,
  useCSFilters,
  useStoredUserPreferences,
} from "../../../hooks";
import { CSFilterGroup } from "../../../central-atoms/filters/enums";
import { Filter, FiltersScreenRouteParams } from "../../../types";
import {
  generateQuickFiltersList,
  getNewAppliedFilters,
} from "../../../helpers/filtersHelpers";
import {
  setAppliedFilters,
  setAppliedFiltersChangeVersion,
} from "../../../actions/filtersActions";
import AuthenticatedUserService from "../../../services/AuthenticatedUserService";
import { AppliedFilters } from "../../../central-atoms/filters/types";
import { SCREEN_NAMES } from "../../../constants";
import { StyleHelpers } from "../../../constants/style-helpers";
import IconButton from "../../../miscellaneous/components/IconButton";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import { COLORS } from "../../../config/colors";
import { Middot } from "../../../miscellaneous/views/miscellaneous";
import QuickFilterItem from "../QuickFilterItem";
import { verticalSize } from "../../../functions/responsive";

type Props = {
  context?: "maps_screen" | "home_screen" | "search_screen";
  onSelectAnyFilter?: () => void;
  disableItems?: boolean;
  style?: ViewStyle;
};
const QuickFilters = (props: Props) => {
  const {
    context,
    onSelectAnyFilter = () => {},
    disableItems = false,
    style = {},
  } = props;

  const navigation: any = useNavigation();
  const dispatch = useDispatch();

  const appliedFilters = useAppliedFilters();
  const csFilters = useCSFilters();
  const storedUserPreferences = useStoredUserPreferences();
  const appliedFiltersCount = useAppliedFiltersCount();
  const appliedFiltersChangeVersion = useAppliedFiltersChangeVersion();

  const [qfList, setQFList] = useState<any>({});

  const nonSelectedFilters = useMemo(() => {
    const filters: any = [];
    if (qfList[CSFilterGroup.ConnectorTypes]?.length) {
      filters.push(...qfList[CSFilterGroup.ConnectorTypes], "middot");
    }
    if (qfList[CSFilterGroup.Brands]?.length) {
      filters.push(...qfList[CSFilterGroup.Brands], "middot");
    }
    if (qfList[CSFilterGroup.Amenities]?.length) {
      filters.push(...qfList[CSFilterGroup.Amenities], "middot");
    }
    if (qfList[CSFilterGroup.Payable]?.length) {
      filters.push(...qfList[CSFilterGroup.Payable]);
    }

    return filters;
  }, [qfList]);

  const handleQuickFilterPress = (filterItem: Filter) => {
    const isSelected = !!appliedFilters?.[
      filterItem.group as CSFilterGroup
    ]?.find((filter: string) => filter === filterItem.value);
    onSelectFilter(filterItem, isSelected, filterItem.group as CSFilterGroup);
  };

  const onSelectFilter = (
    filter: Filter,
    isSelected: boolean,
    group: CSFilterGroup
  ): void => {
    const newAppliedFilters = getNewAppliedFilters(
      appliedFilters,
      filter,
      isSelected,
      group
    );

    batch(() => {
      dispatch(setAppliedFilters(newAppliedFilters));
      dispatch(setAppliedFiltersChangeVersion());
    });
    syncPreferences(newAppliedFilters);

    const _qfList = generateQuickFiltersList(
      csFilters.available_filters,
      newAppliedFilters
    );

    setQFList(_qfList);

    onSelectAnyFilter();
  };

  const syncPreferences = (newAppliedFilters: AppliedFilters) => {
    AuthenticatedUserService.syncPreferences(
      newAppliedFilters,
      storedUserPreferences,
      dispatch
    );
  };

  const handleQuickFilterItemPress = (filter: Filter) => {
    if (filter.value === "more") {
      navigation.navigate(
        SCREEN_NAMES.FiltersScreen as never,
        {
          context: context,
          filterGroupToOpen: filter.group,
        } as FiltersScreenRouteParams as never
      );
    } else {
      handleQuickFilterPress(filter);
    }
  };

  useEffect(() => {
    const _qfList = generateQuickFiltersList(
      csFilters.available_filters,
      appliedFilters
    );
    setQFList(_qfList);
  }, [appliedFiltersChangeVersion]);

  return (
    <View
      style={{
        ...StyleHelpers.verticallyCenteredRow,
        ...style,
      }}
    >
      <View>
        <IconButton
          icon={
            <FilterIcon
              fill={COLORS.darkBlack}
              height={verticalSize(2)}
              width={verticalSize(2)}
            />
          }
          showIndicator={!!appliedFiltersCount}
          indicatorText={`${appliedFiltersCount}`}
          style={{
            marginRight: 12,
            marginLeft: StyleHelpers.pageSpacing.paddingLeft,
          }}
          onPress={() => {
            navigation.navigate(
              SCREEN_NAMES.FiltersScreen as never,
              {
                context: context,
              } as FiltersScreenRouteParams as never
            );
          }}
        />
      </View>

      <ScrollView
        style={{
          height: verticalSize(8),
        }}
        showsHorizontalScrollIndicator={false}
        horizontal={true}
        contentContainerStyle={{
          paddingRight: 80,
          paddingTop: 13,
          paddingBottom: 12,
          ...StyleHelpers.verticallyCenteredRow,
        }}
      >
        {nonSelectedFilters.map((filter: Filter, index) => {
          if ((filter as unknown as string) === "middot") {
            return (
              <Middot key={`middot-${index}`} style={{ marginHorizontal: 6 }} />
            );
          }

          const isSelected = !!appliedFilters?.[
            filter.group as CSFilterGroup
          ]?.find((filterType) => filterType === filter.value);

          return (
            <QuickFilterItem
              key={`ns-${filter.value}-${index}`}
              filter={filter}
              selected={isSelected}
              disabled={disableItems}
              onPress={() => handleQuickFilterItemPress(filter)}
              style={{
                marginHorizontal: 5,
              }}
            />
          );
        })}
      </ScrollView>
    </View>
  );
};

export default QuickFilters;
