import { useFocusEffect } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import moment from "moment";
import { useCallback, useState } from "react";
import {
  Image,
  Linking,
  RefreshControl,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";
// @ts-ignore
import Avatar from "react-avatar";

import { useDispatch, useSelector } from "react-redux";
import {
  BookmarkIcon,
  HistoryIcon,
  InfoIcon,
  LanguageIcon,
  PageIcon,
  PrivacyIcon,
  QuestionFilledIcon,
} from "../../../icons";
import AuthenticatedUserRequestService from "../../../services/AuthenticatedUserRequestService";
import AuthenticationService from "../../../services/AuthenticationService";
import { MediaType } from "../../../central-atoms/enums/generic/file-upload";
import { CentralMedia } from "../../../central-atoms/types";
import CText from "../../components/CText";
import { COLORS } from "../../../config/colors";
import HttpRequestService from "../../../config/HttpRequestService";
import {
  API_BASE_PATH,
  APP_BUILD_NUMBER,
  APP_ENV,
  APP_VERSION,
} from "../../../config/setting";
import ScreenBottomBar from "../../../miscellaneous/components/ScreenBottomBar";
import ScreenHeader from "../../../miscellaneous/components/ScreenHeader";
import ScreenWrapper from "../../../miscellaneous/components/ScreenWrapper";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { ScreenComponent } from "../../../miscellaneous/types/components";
import AuthAction from "../../../redux/reducer/auth/actions";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import { getCentralUserWallet } from "../../../helpers/walletHelpers";
import { updateActiveWallet } from "../../../actions/walletActions";
import { RefreshControlLoaderColors, SCREEN_NAMES } from "../../../constants";
import { FirebaseRemoteConfigKeys } from "../../../constants";
import {
  useFirebaseRemoteConfigValues,
  useShouldUpdateUserProfile,
} from "../../../hooks";
import { setShouldUpdateUserProfile } from "../../../actions/mainActions";
import { APP_STATE } from "../../../types";
import DashedLink from "../../components/DashedLink";
import styles from "./styles";
import { AngleRightIcon } from "../../../icon";
import ReactModal from "../../components/ReactModal";
import { horizontalSize, verticalSize } from "../../../functions/responsive";
import config from "../../../config/config";

type Props = ScreenComponent & {};
export default function ProfileScreen(props: Props) {
  const { navigation } = props;
  const { t } = useTranslation();
  const { userData, token, centralUserData } = useSelector(
    (state: APP_STATE) => state.auth
  );
  const dispatch = useDispatch();

  const firebaseRemoteConfigValues = useFirebaseRemoteConfigValues();
  const aboutPageLink =
    firebaseRemoteConfigValues?.[FirebaseRemoteConfigKeys.about_page_link] ||
    "https://pulseenergy.io";
  const privacyPageLink =
    firebaseRemoteConfigValues?.[FirebaseRemoteConfigKeys.privacy_page_link] ||
    "https://pulseenergy.io/privacy?hide_header=true&hide_footer=true";
  const termsPageLink =
    firebaseRemoteConfigValues?.[FirebaseRemoteConfigKeys.terms_page_link] ||
    "https://pulseenergy.io/terms?hide_header=true&hide_footer=true";

  const shouldUpdateUserProfile: boolean | null = useShouldUpdateUserProfile();

  const uploadedPhoto = centralUserData?.medias?.find(
    (m: CentralMedia) => m.type === MediaType.DP
  );

  const [waitForRefresh, setWaitForRefresh] = useState(false);

  const { setUserData, setCentralUserData } = AuthAction;

  const optionArr = [
    {
      id: "saved-chargers",
      title: t("savedChargers"),
      icon: <BookmarkIcon height={20} width={20} />,
      onPress: () => {
        navigation.navigate(SCREEN_NAMES.SavedChargingStationsScreen);
      },
    },
    {
      id: "charging-history",
      title: t("ChargingHistory"),
      icon: <HistoryIcon height={20} width={20} />,
      onPress: () => {
        navigation.navigate(SCREEN_NAMES.ChargingHistoryScreen);
      },
    },
    {
      id: "app-language",
      title: t("appLanguage"),
      icon: <LanguageIcon fill={COLORS.blackColor} height={20} width={20} />,
      onPress: () => {
        navigation.navigate("AuthStack", {
          screen: SCREEN_NAMES.SelectLanguageScreen,
          params: {
            type: "profile",
          },
        });
      },
    },

    {
      id: "about",
      title: t("aboutApp"),
      icon: <InfoIcon fill={COLORS.blackColor} height={20} width={20} />,
      onPress: () => {
        Linking.openURL(aboutPageLink);
      },
    },
    {
      id: "help",
      title: t("helpNSupport"),
      icon: (
        <QuestionFilledIcon fill={COLORS.blackColor} height={20} width={20} />
      ),
      onPress: () => {
        Linking.openURL(aboutPageLink);
      },
    },
    {
      id: "privacy",
      title: t("privacyPolicy"),
      icon: <PrivacyIcon fill={COLORS.blackColor} height={20} width={20} />,
      onPress: () => {
        Linking.openURL(privacyPageLink);
      },
    },
    {
      id: "terms",
      title: t("termsOfUse"),
      icon: <PageIcon fill={COLORS.blackColor} height={20} width={20} />,
      onPress: () => {
        Linking.openURL(termsPageLink);
      },
    },
  ];

  const fetchUserProfile = async () => {
    try {
      const { data } = await AuthenticatedUserRequestService.fetchDriverUserProfile();

      const centralUser = data.central_user;
      dispatch(setUserData(data.user));
      dispatch(setCentralUserData(centralUser));

      const wallet = getCentralUserWallet(centralUser);
      if (wallet) dispatch(updateActiveWallet(wallet));
    } 
    catch (e) {
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error",
        },
      });
    }
  };

  const onRefresh = async () => {
    setWaitForRefresh(true);
    try {
      // update profile & active wallet /*
      await fetchUserProfile();
      // update profile & active wallet */

      setWaitForRefresh(false);
    } catch (e) {
      setWaitForRefresh(false);
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: "error",
        },
      });
    }
  };

  const logoutUser = () => {
    const HttpRequestInstance = new HttpRequestService();
    HttpRequestInstance.setInstance(token);
    HttpRequestInstance.getInstance()
      .put(`${API_BASE_PATH}/logout`)
      .then(async () => {
        AuthenticationService.logout(dispatch);
        navigation.navigate("AuthStack", {
          screen: SCREEN_NAMES.SelectLanguageScreen,
        });
      })
      .catch((e: any) => {
        ErrorHandlerService.handleError(e, {
          openToast: false,
        });
        AuthenticationService.logout(dispatch);
        navigation.navigate("AuthStack", {
          screen: SCREEN_NAMES.SelectLanguageScreen,
        });
      });
  };

  const renderOptionItem = ({ item, index }: any) => {
    if (!item) return null;

    return (
      <View key={item.id}>
        <TouchableOpacity
          style={styles.optionCont}
          activeOpacity={0.7}
          onPress={item?.onPress}
        >
          <View style={styles.rowStyle}>
            {item.icon}
            <CText
              value={item.title}
              size={16}
              semiBold
              style={{ marginStart: 16, fontWeight: "600" }}
            />
          </View>
          <View style={styles.rowStyle}>
            {item.extraText ? (
              <CText
                value={item.extraText}
                size={12}
                semiBold
                style={{ marginStart: 16 }}
              />
            ) : null}
            <AngleRightIcon height={24} width={24} />
          </View>
        </TouchableOpacity>

        <View style={styles.divider} />
      </View>
    );
  };

  useFocusEffect(
    useCallback(() => {
      if (shouldUpdateUserProfile) {
        fetchUserProfile().then(() => {
          dispatch(setShouldUpdateUserProfile(false));
        });
      }
    }, [shouldUpdateUserProfile])
  );

  const [isLogoutModalVisible, setLogoutModalVisible] = useState(false);

  const handlePress = () => {
    setLogoutModalVisible(true);
  };

  const closeModal = () => {
    setLogoutModalVisible(false);
  };

  const handleLogout = () => {
    logoutUser();
    closeModal();
  };

  return (
    <View style={styles.root}>
      <ScreenHeader
        navigation={navigation}
        style={{
          borderBottomWidth: 0,
          position: "sticky" as any,
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
        }}
        onBackPress={() => navigation.navigate(SCREEN_NAMES.MapScreen)}
      />

      <ScrollView
        style={{
          ...StyleHelpers.pageSpacing,
          flex: 1,
        }}
        refreshControl={
          <RefreshControl
            colors={RefreshControlLoaderColors}
            refreshing={waitForRefresh}
            onRefresh={onRefresh}
          />
        }
      >
        <View
          style={{
            flexDirection: "row",
            gap: 20,
            alignItems: "center",
          }}
        >
          <View
            style={[
              styles.profileCont,
              {
                alignSelf: "flex-start",
                marginTop: 16,
                marginBottom: !!userData?.name ? 8 : 30,
              },
            ]}
          >
            {isEmpty(uploadedPhoto?.full_url) ? (
              <Avatar
                name={userData?.name?.charAt(0) ?? "Hi"}
                size="100"
                color={userData?.accent_color}
                style={{ fontWeight: "600" }}
                round
              />
            ) : (
              <Image
                source={{ uri: uploadedPhoto?.full_url }}
                style={styles.profileStyle}
                resizeMode={"cover"}
              />
            )}
          </View>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              marginTop: verticalSize(3),
            }}
          >
            <CText
              value={userData?.name || "User"}
              size={24}
              medium
              style={{
                fontWeight: "bold",
              }}
            />

            <CText
              value={`Joined ${moment(centralUserData.created_on).format(
                "MMM DD, YYYY"
              )}`}
              size={12}
              color={COLORS.primaryGrey}
            />
            <View
              style={{ alignSelf: "flex-start", marginTop: verticalSize(1.4) }}
            >
              <DashedLink
                onPress={() => {
                  navigation.navigate(SCREEN_NAMES.EditProfileScreen);
                }}
              >
                <CText semiBold size={12} style={{ fontWeight: "600" }}>
                  {t("editProfile")}
                </CText>
              </DashedLink>
            </View>
          </View>
        </View>
        <View
          style={{
            marginVertical: 40,
          }}
        >
          {optionArr.map((item, index) => {
            return renderOptionItem({ item, index });
          })}
        </View>
      </ScrollView>

      <ScreenBottomBar
        style={{
          position: "sticky" as any,
          bottom: 0,
          left: 0,
          right: 0,
          borderBottomWidth: 0,
        }}
      >
        <View
          style={{
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              marginVertical: 10,
            }}
          >
            <CText
              size={10}
              style={{
                color: COLORS.grey700,
                // ...(isAndroid() && {fontFamily: 'monospace'}),
              }}
            >
              App Version: v{APP_VERSION}{" "}
              {APP_ENV !== "prod" && `(${APP_ENV})`}
            </CText>
          </View>
          <TouchableOpacity activeOpacity={0.7} onPress={handlePress}>
            <CText
              value={t("logOut")}
              style={styles.dottedLine}
              size={12}
              semiBold
            />
          </TouchableOpacity>
        </View>
      </ScreenBottomBar>
      <ReactModal
        isOpen={isLogoutModalVisible}
        closeModal={closeModal}
        handleAccept={handleLogout}
        description="Are you sure want to logout?"
      />
    </View>
  );
}
