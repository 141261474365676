import { View, ViewStyle } from "react-native";
import CText from "../../../../components/CText";
import { COLORS } from "../../../../../config/colors";
import { ChargePointStatus } from "../../../../../central-atoms/enums/ocpp/v1.6/generic";

type Props = {
  status: ChargePointStatus;
  style?: ViewStyle;
};
export const CPConnectorStatusChip = (props: Props) => {
  const { status, style = {} } = props;

  let bgColor = COLORS.lightGrey;
  let textColor = COLORS.whiteColor;
  let text: string = status;
  if (status === ChargePointStatus.Available) {
    bgColor = COLORS.primaryColor;
    textColor = COLORS.whiteColor;
  } else if (status === ChargePointStatus.Preparing) {
    bgColor = COLORS.yellow600;
    textColor = COLORS.blackColor;
    text = "Plugged In";
  } else if (status === ChargePointStatus.Charging) {
    bgColor = COLORS.blue500;
    textColor = COLORS.whiteColor;
  } else if (status === ChargePointStatus.Finishing) {
    bgColor = COLORS.purple500;
    textColor = COLORS.whiteColor;
  } else if (status === ChargePointStatus.Reserved) {
    bgColor = COLORS.purple500;
    textColor = COLORS.whiteColor;
  } else if (status === ChargePointStatus.Faulted) {
    bgColor = COLORS.red600;
    textColor = COLORS.whiteColor;
  } else if (
    status === ChargePointStatus.SuspendedEV ||
    status === ChargePointStatus.SuspendedEVSE
  ) {
    bgColor = COLORS.deepOrange500;
    textColor = COLORS.whiteColor;
  } else if (status === ChargePointStatus.Unavailable) {
    bgColor = COLORS.primaryLight;
    textColor = COLORS.blackColor;
  }

  return (
    <View
      style={{
        paddingVertical: 4,
        paddingHorizontal: 14,
        backgroundColor: bgColor,
        borderRadius: 100,
        ...style,
      }}
    >
      <CText
        size={10}
        semiBold
        style={{
          marginTop: 2,
          lineHeight: 18,
          color: textColor,
          fontWeight: "600",
        }}
      >
        {text.toUpperCase()}
      </CText>
    </View>
  );
};

export default CPConnectorStatusChip;
