import { useState } from "react";
import { TouchableOpacity, View } from "react-native";

type AccordionItem = {
  itemKey: string;
  head: any;
  body: any;
  openIcon?: any;
  closeIcon?: any;
};

type AccordionProps = {
  items: AccordionItem[];
};
const Accordion = (props: AccordionProps) => {
  const [isOpenMap, setIsOpenMap] = useState<{ [itemKey: string]: boolean }>(
    {}
  );

  const { items } = props;

  const handleOpenPress = (item: AccordionItem) => {
    setIsOpenMap((prevState) => {
      return {
        ...prevState,
        [item.itemKey]: !prevState[item.itemKey],
      };
    });
  };

  return (
    <View>
      {items.map((item) => {
        const isItemOpen = isOpenMap[item.itemKey];
        return (
          <View key={item.itemKey}>
            <TouchableOpacity
              activeOpacity={0.7}
              onPress={() => handleOpenPress(item)}
            >
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                {item.head}
                {isItemOpen ? item.openIcon : item.closeIcon}
              </View>
            </TouchableOpacity>
            {isItemOpen && <View>{item.body}</View>}
          </View>
        );
      })}
    </View>
  );
};

export default Accordion;
