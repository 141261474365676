import { StyleSheet } from "react-native";
import { COLORS } from "../../../config/colors";
import { THEME_STYLES } from "../../../theme/constants/themeStyles";

export default StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: COLORS.whiteColor,
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    height: 45,
    flex: 1,
  },
  touch: {
    backgroundColor: COLORS.blackColor,
    alignSelf: "center",
    borderRadius: 8,
    height: 28,
    width: 28,
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    fontSize: 15,
    color: COLORS.blackColor,
    marginStart: 8,
    borderRadius: 8,
    height: 44,
    //marginStart: -10,
    marginEnd: 8,
    borderBottomWidth: 0,
    alignSelf: "flex-end",
    flex: 1,
    // marginTop: 24,
    ...THEME_STYLES.input,
  },
  subContainer: { flexDirection: "row", alignItems: "center", flex: 1 },
});
