import AsyncStorage from "@react-native-async-storage/async-storage";
import { useRoute } from "@react-navigation/native";
import { isEmpty } from "lodash";
import { ScreenComponent } from "../../../miscellaneous/types/components";
import { EnterAccessCodeScreenRouteParams } from "../../../types";
import { useSelectedLanguage, useUserAuthenticated } from "../../../hooks";
import { LangCodes } from "../../../central-atoms/enums/generic/i18n/LangCodes";
import AuthenticatedUserRequestService from "../../../services/AuthenticatedUserRequestService";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import ScreenWrapper from "../../../miscellaneous/components/ScreenWrapper";
import { LS_KEYS, SCREEN_NAMES } from "../../../constants";
import EnterAccessCodeContent from "../../components/EnterAccessCodeContent";

type Props = ScreenComponent & {};
export default function EnterAccessCodeScreen(props: Props) {
  const { navigation } = props;

  const route = useRoute();
  const params = route.params as EnterAccessCodeScreenRouteParams;
  const nextScreen = params?.nextScreen ?? null;

  const userAuthenticated = useUserAuthenticated();
  const selectedLanguage = useSelectedLanguage();

  const updateActiveLanguage = (lang: LangCodes) => {
    AuthenticatedUserRequestService.updateActiveLanguage({ lang: lang }).catch(
      (e) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      }
    );
  };

  return (
    <ScreenWrapper {...props}>
      <EnterAccessCodeContent
        fleetOperator={null}
        showLogoutButton={false}
        onVerificationSuccess={async (accessCode) => {
          await AsyncStorage.setItem(LS_KEYS.paymentMode, "access-code");
          if (userAuthenticated && !isEmpty(selectedLanguage)) {
            updateActiveLanguage(selectedLanguage);
          }
          navigation.navigate(nextScreen || SCREEN_NAMES.SearchingScreen);
        }}
        onVerificationError={(operatorCode, handledError) => {}}
      />
    </ScreenWrapper>
  );
}
