import { CheckWalletBalance } from "../central-atoms/types/app-requests";
import { RemoveAccessReqPayload, ResendAccessCodeReqPayload, ShareWalletAccessReqPayload } from "../central-atoms/wallets/types/requests";
import { ExternalWalletCheckBalanceResponse, ExternalWalletRefillAmountResponse, ExternalWalletSendOTPResponse, ExternalWalletValidateOTPResponse, FetchWalletConsumersResponse, RemoveAccessResponse, ResendAccessCodeResponse, ShareWalletAccessResponse } from "../central-atoms/wallets/types/responses";
import HttpRequestService from "../config/HttpRequestService";
import { CENTRAL_HOST } from "../config/setting";
import { GLOBAL_VARS } from "../constants";
import { getGlobalVar } from "../miscellaneous/helpers";

export default class ExternalWalletRequestService {
    static sendOTP(payload: {phone_number: string}): Promise<ExternalWalletSendOTPResponse> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .put(`${CENTRAL_HOST}/paytm/send_otp`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static validateOTP(payload: {otp: string, shared_wallet_id?: string}): Promise<ExternalWalletValidateOTPResponse> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .put(`${CENTRAL_HOST}/paytm/validate_otp`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static refillAmount(payload: {amount: string, wallet_id: string}): Promise<ExternalWalletRefillAmountResponse> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .post(`${CENTRAL_HOST}/paytm/refill_amount`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static checkWalletBalance(payload: CheckWalletBalance): Promise<ExternalWalletCheckBalanceResponse> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .put(`${CENTRAL_HOST}/wallet/balance`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static shareWalletAccess(payload: ShareWalletAccessReqPayload): Promise<ShareWalletAccessResponse> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .post(`${CENTRAL_HOST}/wallet/share`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static fetchWalletConsumers(walletId: string): Promise<FetchWalletConsumersResponse> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .get(`${CENTRAL_HOST}/wallet/${walletId}/consumers`)
            .then((response: any) => {
                return response.data;
            });
    }

    static resendAccessCode(payload: ResendAccessCodeReqPayload): Promise<ResendAccessCodeResponse> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .put(`${CENTRAL_HOST}/wallet/resend_access_code`, payload)
            .then((response: any) => {
                return response.data;
            });
    }

    static removeAccess(payload: RemoveAccessReqPayload): Promise<RemoveAccessResponse> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return centralHttpRequest.getInstance()
            .delete(`${CENTRAL_HOST}/wallet/remove_consumers`, {
                data: payload,
            })
            .then((response: any) => {
                return response.data;
            });
    }
}
