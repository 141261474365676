import { StyleSheet } from 'react-native';
import { StyleHelpers } from '../../../../../miscellaneous/constants/style-helpers';

const styles = StyleSheet.create({
    root: {
        ...StyleHelpers.root,
    },
    innerRoot: {
        flexGrow: 1,
        padding: 16,
        // backgroundColor: 'blue',
        // width: '100%',
        height: '81%',
        // position: 'absolute',
        // zIndex: 1,
    },
    lottieCont: {
        // backgroundColor: COLORS.whiteColor,
        padding: 16,
        alignSelf: 'center',
        borderRadius: 200,
        height: 200,
        width: 200,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    centerCont: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
});

export default styles;