//External dependencies
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';
import { SCREEN_NAMES } from '../../constants';
import SearchingScreen from '../../ui/screens/SearchingScreen';
import ChargingStationDetailScreen from '../../ui/screens/ChargingStationDetailScreen';
import PreStartChargingSelectionScreen from '../../ui/screens/PreStartChargingSelectionScreen';
import ChargePointQRScannerScreen from '../../ui/screens/ChargePointQRScannerScreen';
import SearchScreen from '../../ui/screens/SearchScreen';
import EnterFleetCodeScreen from '../../ui/screens/EnterFleetCodeScreen';
import EnterAccessCodeScreen from '../../ui/screens/EnterAccessCodeScreen';
import PartialLoginEnterAccessCodeScreen from '../../ui/screens/PartialLoginEnterAccessCodeScreen';
import MapScreen from '../../ui/screens/MapScreen';
import EnterChargePointIdScreen from '../../ui/screens/EnterChargePointIdScreen';
import FiltersScreen from '../../ui/screens/FilterScreen/FiltersScreen';
import ProfileScreen from '../../ui/screens/ProfileScreen';
import EditProfileScreen from '../../ui/screens/EditProfileScreen';
import ChargingHistoryScreen from '../../ui/screens/ChargingHistoryScreen';
import ChargingHistoryFiltersScreen from '../../ui/screens/ChargingHistoryFiltersScreen';
import SavedChargingStationsScreen from '../../ui/components/SavedChargingStationsScreen';
import ChargingStartingSoonScreen from '../../ui/screens/ChargingStartingSoonScreen';
import ChargingStatesScreen from '../../ui/screens/ChargingStatesScreen';
import PaymentModeSelectionScreen from '../../ui/screens/PaymentModeSelectionScreen';
import ChargingProcessScreen from '../../ui/screens/ChargingProcessScreen';
import ChargingSuccessfulScreen from '../../ui/screens/ChargingSuccessfulScreen';

const MainStackNavigator = createStackNavigator();

export const MainStack = () => {
  return (
    <MainStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
      }}
      initialRouteName={SCREEN_NAMES.SearchingScreen}>
      <MainStackNavigator.Screen name={SCREEN_NAMES.SearchingScreen} component={SearchingScreen} />
      <MainStackNavigator.Screen
        name={SCREEN_NAMES.ChargingStationDetailScreen}
        component={ChargingStationDetailScreen}
      />
      <MainStackNavigator.Screen
        name={SCREEN_NAMES.PreStartChargingSelectionScreen}
        component={PreStartChargingSelectionScreen}
      />

      <MainStackNavigator.Screen
        name={SCREEN_NAMES.ChargePointQRScannerScreen}
        component={ChargePointQRScannerScreen}
      />
      <MainStackNavigator.Screen name={SCREEN_NAMES.SearchScreen} component={SearchScreen} />
      <MainStackNavigator.Screen name={SCREEN_NAMES.EnterFleetCodeScreen} component={EnterFleetCodeScreen} />
      <MainStackNavigator.Screen name={SCREEN_NAMES.EnterAccessCodeScreen} component={EnterAccessCodeScreen} />
      <MainStackNavigator.Screen
        name={SCREEN_NAMES.PartialLoginEnterAccessCodeScreen}
        component={PartialLoginEnterAccessCodeScreen}
      />

      <MainStackNavigator.Screen name={SCREEN_NAMES.MapScreen} component={MapScreen} />
      <MainStackNavigator.Screen name={SCREEN_NAMES.EnterChargePointIdScreen} component={EnterChargePointIdScreen} />
      <MainStackNavigator.Screen name={SCREEN_NAMES.FiltersScreen} component={FiltersScreen} />

      <MainStackNavigator.Screen
        name={SCREEN_NAMES.ChargingStartingSoonScreen}
        component={ChargingStartingSoonScreen}
      />
      <MainStackNavigator.Screen name={SCREEN_NAMES.ChargingStatesScreen} component={ChargingStatesScreen} />
      <MainStackNavigator.Screen name={SCREEN_NAMES.ChargingProcessScreen} component={ChargingProcessScreen} />
      <MainStackNavigator.Screen name={SCREEN_NAMES.ChargingSuccessfulScreen} component={ChargingSuccessfulScreen} />

      <MainStackNavigator.Screen name={SCREEN_NAMES.ProfileScreen} component={ProfileScreen} />
      <MainStackNavigator.Screen name={SCREEN_NAMES.EditProfileScreen} component={EditProfileScreen} />
      <MainStackNavigator.Screen name={SCREEN_NAMES.ChargingHistoryScreen} component={ChargingHistoryScreen} />
      <MainStackNavigator.Screen
        name={SCREEN_NAMES.ChargingHistoryFiltersScreen}
        component={ChargingHistoryFiltersScreen}
      />
      <MainStackNavigator.Screen
        name={SCREEN_NAMES.SavedChargingStationsScreen}
        component={SavedChargingStationsScreen}
      />
      <MainStackNavigator.Screen
        name={SCREEN_NAMES.PaymentModeSelectionScreen}
        component={PaymentModeSelectionScreen}
      />
    </MainStackNavigator.Navigator>
  );
};
