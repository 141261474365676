export enum WalletType {
    InstaCharge = '10',
    Paytm = '20',
    PhonePe = '30', // Not yet developed
    NumadicFASTag = '40',
    EMSPWallet = '50',
    PulseWallet = '1000',
}

export enum SharedWalletStatus {
    active = '10',
    inactive = '20',
}

export enum WalletStatus {
    Linked = '10',
    NotLinked = '20',
}
