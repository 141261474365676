import { TouchableHighlight, View } from "react-native";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoIosRadioButtonOff } from "react-icons/io";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { isIOS } from "../../helpers";
import { COLORS } from "../../../config/colors";
import CText from "../../../ui/components/CText";
import { useTranslation } from "react-i18next";

export default function OptionRadioButton(props: any) {
  const { title, checked, onPress = () => {}, style, popular } = props;
  const { t } = useTranslation();
  return (
    <View>
      <TouchableHighlight
        underlayColor={checked ? THEME_COLORS.cardSelectedBgUnderlayColor : COLORS.lightGrey}
        activeOpacity={1}
        style={[
          {
            backgroundColor: checked ? THEME_COLORS.cardSelectedBgColor : THEME_COLORS.cardUnselectedBgColor,
            borderRadius: 12,
            borderWidth: 1,
            borderColor: COLORS.blackColor,
            padding: 10,
            paddingVertical: 6,
            borderBottomWidth: 3
          },
          style
        ]}
        onPress={onPress}>
        <View
          style={{
            alignSelf: "flex-start",
            flexDirection: "row",
            alignItems: "center"
          }}>
          <CText
            value={title}
            size={14}
            semiBold
            style={{
              lineHeight: 22,
              fontWeight: "600",
              marginBottom: isIOS() ? 2 : 0,
              color: checked ? THEME_COLORS.cardSelectedTextColor : THEME_COLORS.cardUnselectedTextColor
            }}
          />
          {checked ? (
            <IoIosCheckmarkCircle size={20} color={COLORS.darkblue} style={{ marginLeft: 8 }} />
          ) : (
            <IoIosRadioButtonOff size={20} color={COLORS.blackColor} style={{ marginLeft: 8 }} />
          )}

          {popular && (
            <View
              style={{
                position: "absolute",
                bottom: -16,
                right: 0,
                left: 0,
                alignItems: "center"
              }}>
              <View
                style={{
                  backgroundColor: COLORS.lightGreen,
                  borderRadius: 8,
                  borderWidth: 1,
                  borderBottomWidth: 2,
                  borderColor: COLORS.blackColor,
                  paddingHorizontal: 8,
                  padding: 2
                }}>
                <CText value={t("popular")} size={8} semiBold />
              </View>
            </View>
          )}
        </View>
      </TouchableHighlight>
    </View>
  );
}
