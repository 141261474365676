import React, { ReactElement } from "react";
import { Image, StyleSheet, TouchableHighlight, View } from "react-native";
import CButton from "../../components/CButton";
import CText from "../../components/CText";
import { StyleHelpers } from "../../../constants/style-helpers";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { COLORS } from "../../../config/colors";
import Pill from "../../../miscellaneous/components/Pill";
import { CheckIcon } from "../../../icons";
import { horizontalSize, rFont } from "../../../functions/responsive";

enum SIZE {
  SMALL = "small",
  LARGE = "large",
}

type Props = {
  item: any;
  isSelected?: boolean;
  onPress: () => void;
  size: SIZE;
};

const FilterItem = (props: Props): ReactElement => {
  const { item, isSelected = false, size, onPress = () => {} } = props;

  // const windowDimensions = useWindowDimensions();

  return (
    <TouchableHighlight
      onPress={onPress}
      style={[
        styles.itemContainer,
        // { width: (windowDimensions.width * 0.75 - 48) / 2 },
        { width: "44%" },
        isSelected && {
          borderColor: COLORS.darkblue,
        },
      ]}
      underlayColor={isSelected ? COLORS.whiteColor : COLORS.dividerColor}
      activeOpacity={1}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 8,
          paddingTop: 25,
        }}
      >
        <View>
          {!!item.image && (
            <View style={styles.imageContainer}>
              <Image
                source={{ uri: item.image }}
                resizeMethod="resize"
                style={[
                  styles.image,
                  size === SIZE.SMALL
                    ? {
                        height: 34,
                        width: 34,
                      }
                    : {
                        height: 60,
                        width: 60,
                      },
                ]}
              />
            </View>
          )}

          {!!item.image ? (
            <CText
              textProps={{
                numberOfLines: 2,
                ellipsizeMode: "tail",
              }}
              medium
              size={rFont(1.25)}
              style={{
                ...styles.itemLabel,
                fontSize: rFont(1.5),
                fontWeight: "600",
              }}
            >
              {item.viewValue}
            </CText>
          ) : (
            <CText
              style={{
                ...styles.itemLabel,
                marginBottom: 8,
                fontSize: rFont(1.5),
                fontWeight: "600",
              }}
              textProps={{
                numberOfLines: 2,
                ellipsizeMode: "tail",
              }}
            >
              {item.viewValue}
            </CText>
          )}

          {/* only for connectors */}
          {!!item.speed_type && (
            <CText
              textProps={{
                numberOfLines: 2,
                ellipsizeMode: "tail",
              }}
              medium
              size={rFont(1.25)}
              style={{
                textAlign: "center",
                color: COLORS.grey700,
                fontSize: rFont(1.5),
                fontWeight: "600",
              }}
            >
              {item.speed_type} Charger
            </CText>
          )}
        </View>

        {!!item.flag ? <Pill flag={item.flag} /> : null}

        {isSelected ? (
          <CButton
            title={"Selected"}
            onPress={onPress}
            textStyle={{
              color: COLORS.darkblue,
              marginLeft: 6,
              fontSize: 13,
              fontWeight: "600",
            }}
            icon={() => <CheckIcon fill={COLORS.darkblue} />}
            buttonStyle={{
              ...styles.button,
              backgroundColor: COLORS.dividerColor,
              borderColor: COLORS.transparent,
            }}
            height={35}
          ></CButton>
        ) : (
          <CButton
            height={35}
            title={"Select"}
            onPress={onPress}
            textStyle={{
              fontSize: 13,
              fontWeight: "600",
            }}
            buttonStyle={styles.button}
          ></CButton>
        )}
      </View>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    // overflow: 'hidden',
    margin: 8,
    backgroundColor: COLORS.whiteColor,
    ...StyleHelpers.themeBorder,
    borderRadius: 15,
    borderBottomWidth: 1,
  },
  imageContainer: {
    marginBottom: 8,
    height: 60,
    // width: 60,
    ...StyleHelpers.totalCenter,
  },
  itemFlag: {
    // borderTopLeftRadius: 8,
    // borderTopRightRadius: 8,
    paddingVertical: 2,
    // width: (getWindowDimensions().width * 0.75 - 48) / 2,
    // width: '100%',
    alignItems: "center",
    position: "absolute",
    top: -12,
    left: 15,
    right: 15,
    ...StyleHelpers.themeBorder,
    // borderRadius: 10,
  },
  image: {
    resizeMode: "contain",
    borderRadius: 6,
  },
  itemLabel: {
    textAlign: "center",
  },
  button: {
    // borderColor: COLORS.grey700,
    // height: 28,
    // width: '100%',
    marginTop: 10,
    marginBottom: 7,
    borderRadius: 10,
    width: horizontalSize(24),
  },
  buttonText: {
    color: THEME_COLORS.primaryColor,
    // ...Typography.Overline,
  },
});

export default FilterItem;
