import { StyleSheet } from 'react-native';
import { THEME_COLORS } from '../../../theme/constants/themeColors';
import { COLORS } from '../../../config/colors';

export default StyleSheet.create({
    cont: {
        backgroundColor: COLORS.whiteColor,
        padding: 16,
        borderRadius: 22,
        borderWidth: 1,
        borderColor: THEME_COLORS.cardBorderColor,
    },
    chipCont: {
        padding: 4,
        marginEnd: 12,
        borderRadius: 100,
        borderWidth: 1,
        borderColor: COLORS.greenBorderColor,
        paddingHorizontal: 12,
    },
    rowStyle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    mt8: {
        marginTop: 8,
    },
    iconImg: {
        height: 45,
        width: 45,
    },
    iconCont: {
        marginTop: 8,
        marginLeft: 10,
        borderRadius: 8,
        overflow: 'hidden',
    },
    rateTxt: {
        color: COLORS.greenColor,
        marginTop: 6,
        marginStart: 4,
    },
    divider: {
        height: 1,
        width: '100%',
        backgroundColor: COLORS.dividerColor,
        marginVertical: 10,
        // marginBottom: 16,
        borderRadius: 32,
    },
    detailIcon: {
        height: 28,
        width: 28,
    },
    detailBlock: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    multipleChargeIcon: {
        height: 28,
        width: 28,
    },
    multipleChargeIconCont: {
        marginStart: -6,
        borderWidth: 2,
        borderColor: COLORS.whiteColor,
        borderRadius: 100,
    },
    moreChargeCont: {
        height: 34,
        width: 34,
        marginStart: -6,
        borderWidth: 2,
        borderColor: COLORS.whiteColor,
        borderRadius: 100,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.lightGrey,
    },
    greyIconCirculerCont: {
        height: 28,
        width: 28,
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: COLORS.dividerColor,
        borderRadius: 50
    }
});
