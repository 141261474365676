import { useFocusEffect } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal, View } from "react-native";
// @ts-ignore
import styles from "./styles";
import { ScreenComponent } from "../../../types/components";
import ScreenHeader from "../../components/ScreenHeader";
import { StyleHelpers } from "../../../constants/style-helpers";
import CText from "../../components/CText";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import CInput from "../../components/CInput";
import ScreenBottomBar from "../../components/ScreenBottomBar";
import CButton from "../../components/CButton";
import BackButton from "../../components/BackButton";
import RichError from "../../components/RichError";
import { SCREEN_NAMES } from "../../../constants";
import AuthService from "../../../services/AuthService";
import { RequestSMSOTPResponse } from "../../../central-atoms/types/responses/auth";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import { COLORS } from "../../../config/colors";
import { I18NRichMessageResponse } from "../../../central-atoms/types/responses/i18n";

type Props = ScreenComponent & {};
export default function EnterPhoneNumberScreen(props: Props) {
  const { navigation } = props;
  const { t } = useTranslation();

  const [number, setNumber] = useState("");
  const [numberErr, setNumberErr] = useState("");
  const [i18nError, setI18nError] = useState<I18NRichMessageResponse | null>(
    null
  );
  const [i18nErrorModalOpen, setI18nErrorModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const phoneNumberInputRef = useRef<any>();

  const validate = () => {
    if (isEmpty(number) || number.length != 10) {
      setNumberErr("Invalid Phone Number");
    } else {
      setNumberErr("");
      requestSmsOtp();
    }
  };

  const requestSmsOtp = () => {
    setLoading(true);
    const data = {
      phone: `+91${number}`,
    };
    navigation.navigate(SCREEN_NAMES.EnterOTPScreen, { number: number });
    AuthService.requestSmsOtp(data)
      .then(() => {
        setNumber("");
        navigation.navigate(SCREEN_NAMES.EnterOTPScreen, { number: number });
        setLoading(false);
        setI18nError(null);
        setI18nErrorModalOpen(false);
      })
      .catch((e) => {
        setLoading(false);

        ErrorHandlerService.handleError(e);

        // const res = e?.response?.data as RequestSMSOTPResponse;
        // if (res?.i18n_error) {
        //   setI18nError(res?.i18n_error);
        //   setI18nErrorModalOpen(true);
        // }
        // const inputFieldErr =
        //   e?.response?.data?.msg ||
        //   e?.response?.data?.message ||
        //   e?.response?.data?.error;
        // if (inputFieldErr) setNumberErr(inputFieldErr);
        // else ErrorHandlerService.handleError(e);
      });
  };

  const promptForPhoneNumber = async () => {
    try {
    } catch (e) {
      phoneNumberInputRef.current.focus();
      ErrorHandlerService.handleError(e, {
        openToast: false,
      });
    }
  };

  useFocusEffect(
    useCallback(() => {
      setTimeout(() => {
        promptForPhoneNumber();
      }, 400);
    }, [])
  );

  // const [divHeight, setDivHeight] = useState<number | undefined>(0);

  // useEffect(() => {
  //   if (document.getElementById("bottomBarHeight")) {
  //     const height = document.getElementById("bottomBarHeight")?.clientHeight;
  //     setDivHeight(height);
  //   }
  // }, []);

  return (
    <View style={styles.root}>
      <ScreenHeader
        navigation={navigation}
        style={{
          borderBottomWidth: 0,
          position: "sticky" as any,
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
        }}
      />

      <View
        // id="bodyBarHeight"
        style={{
          ...StyleHelpers.pageSpacing,
          flexDirection: "column",
          justifyContent: "flex-end",
          flex: 1,
          paddingBottom: 30,
        }}
      >
        <View>
          <CText
            value={t("enterYour")}
            size={26}
            semiBold
            style={{
              lineHeight: 70,
              color: THEME_COLORS.sectionHeader,
              fontWeight: "600",
            }}
          />
          <CText
            value={t("phoneNumberLC")}
            size={26}
            semiBold
            style={{
              marginTop: -16,
              marginBottom: 16,
              color: THEME_COLORS.sectionHeader,
              fontWeight: "600",
            }}
          />
          <CInput
            ref={phoneNumberInputRef}
            // @ts-ignore
            placeholder={t("10digitNumberPH")}
            value={number}
            onChangeText={setNumber}
            keyboardType="number-pad"
            maxLength={10}
            autoFocus={true}
            returnKeyType="next"
            onSubmitEditing={() => {
              validate();
            }}
            errorMessage={numberErr}
          />
        </View>
      </View>

      <ScreenBottomBar
        style={{
          padding: 16,
          backgroundColor: COLORS.whiteColor,
          // marginTop: divHeight,
          position: "sticky" as any,
          bottom: 0,
          left: 0,
          right: 0,
          borderBottomWidth: 0,
        }}
      >
        <CButton
          title={t("continue")}
          onPress={() => {
            validate();
          }}
          // style={{ marginTop: 24 }}
          loading={loading}
          disable={loading}
        />
      </ScreenBottomBar>
      <Modal
        style={{
          flex: 1,
          margin: 0,
          justifyContent: "flex-end",
        }}
        visible={i18nErrorModalOpen}
        onRequestClose={() => setI18nErrorModalOpen(false)}
      >
        <View
          style={{
            backgroundColor: THEME_COLORS.bgColor,
            padding: 16,
            paddingTop: 16,
            width: "100%",
            height: "100%",
          }}
        >
          <BackButton
            onPress={() => {
              setI18nErrorModalOpen(false);
            }}
          />
          {i18nError && (
            <RichError
              i18nError={i18nError}
              msgType={"info"}
              style={{
                marginTop: 50,
              }}
              expandFirstAction={true}
            />
          )}
        </View>
      </Modal>
    </View>
  );
}
