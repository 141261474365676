import React from 'react';
import { TouchableOpacity } from 'react-native';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { THEME_COLORS } from '../../../theme/constants/themeColors';
import { StyleHelpers } from '../../constants/style-helpers';
import CText from '../../../ui/components/CText';
import { COLORS } from '../../../config/colors';
import { horizontalSize, rFont } from '../../../functions/responsive';

export default function FilterRadioButton(props: any) {
  const { title, checked, onPress = () => {} } = props;
  return (
    <>
      <TouchableOpacity
        activeOpacity={0.7}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: checked ? COLORS.blackColor : THEME_COLORS.cardUnselectedBgColor,
          ...StyleHelpers.themeBorder,
          paddingVertical: 8,
          justifyContent: 'center'
        }}
        onPress={onPress}>
        {checked && (
          <CheckIcon
            fill={COLORS.whiteColor}
            style={{
              height: 12,
              width: 12
            }}
          />
        )}
        <CText
          value={title}
          size={12}
          semiBold
          style={{
            color: checked ? COLORS.whiteColor : COLORS.blackColor,
            marginStart: checked ? 5 : 0,
            marginTop: 2,
            fontWeight: '600'
          }}
        />
      </TouchableOpacity>
    </>
  );
}
