/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */

import { sleep } from "./time";

// eslint-disable-next-line import/prefer-default-export
export async function syncLoop(options: {
    items: any[],
    asyncCallback?: (item: any) => void | undefined,
    callback?: (item: any) => void | undefined,
    onAsyncError?: ({error, item}: any) => void | undefined,
    onError?: ({error, item}: any) => void | undefined,
    sleepDuration?: number | undefined,
    delayOnIdxMultiple?: number | undefined,
}) {
    const {
        asyncCallback = undefined,
        callback = undefined,
        onAsyncError = undefined,
        onError = undefined,
        items = [],
        sleepDuration = undefined,
        delayOnIdxMultiple = undefined,
    } = options;

    let idx = 0;
    while (idx < items.length) {
        const item = items[idx];
        idx += 1;
        try {
            if (typeof asyncCallback === 'function') {
                await asyncCallback(item);
            }
            else if (typeof callback === 'function') {
                callback(item);
            }
        }
        catch (error) {
            if (typeof onAsyncError === 'function') {
                await onAsyncError({error, item});
            }
            else if (typeof onError === 'function') {
                onError({error, item});
            }
        }
        if (sleepDuration) {
            if (delayOnIdxMultiple) {
                if (idx % delayOnIdxMultiple === 0) {
                    await sleep(sleepDuration);
                }
            }
            else {
                await sleep(sleepDuration);
            }
        }
    }
}


// eslint-disable-next-line import/prefer-default-export
export async function loopCallbackUntilSatisfied(options: {
    asyncCallback?: () => Promise<any>,
    callback?: () => any,
    onAsyncError?: ({error, item}: any) => void | undefined,
    onError?: ({ error, item }: any) => void | undefined,
    satisfactoryCondition: (data: any) => boolean,
    sleepDuration?: number | undefined,
    maxIterations?: number | undefined,
    delayOnIdxMultiple?: number | undefined,
}) {
    const {
        asyncCallback = undefined,
        satisfactoryCondition = () => true,
        callback = undefined,
        onAsyncError = undefined,
        onError = undefined,
        sleepDuration = undefined,
        delayOnIdxMultiple = undefined,
        maxIterations = undefined,
    } = options;

    let idx = 0;
    let result: any = null;
    do {
        try {
            if (typeof asyncCallback === 'function') {
                result = await asyncCallback();
            }
            else if (typeof callback === 'function') {
                result = callback();
            }
        }
        catch (error) {
            if (typeof onAsyncError === 'function') {
                await onAsyncError({ error });
            }
            else if (typeof onError === 'function') {
                onError({ error });
            }
        }

        if (sleepDuration) {
            if (delayOnIdxMultiple) {
                if (idx % delayOnIdxMultiple === 0) {
                    await sleep(sleepDuration);
                }
            }
            else {
                await sleep(sleepDuration);
            }
        }

        if (maxIterations && idx >= maxIterations) {
            break;
        }

        idx += 1;
    }
    while (!satisfactoryCondition(result));

    return result;
}
