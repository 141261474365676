import { isArray, orderBy, sortBy } from "lodash";
import { AvailableFilters, Filter, FilterSubGroupItem } from "../types";
import { AppliedFilters } from "../central-atoms/filters/types";
import { CSFilterGroup } from "../central-atoms/filters/enums";

export const getAllAppliedFiltersList = (appliedFilters: AppliedFilters): string[] => {
    return [
        ...(appliedFilters.connector_types ?? []),
        ...(appliedFilters.brands ?? []),
        ...(appliedFilters.payable ?? []),
        ...(appliedFilters.amenities ?? []),
    ];
}

export const getAllFiltersSubgroups = (availableFilters: AvailableFilters) => {
    const allFilterSubgroups: FilterSubGroupItem[] = [];
    Object.keys(availableFilters).forEach((filterName) => {
        const filterSubgroups = availableFilters[filterName as CSFilterGroup].filter_subgroup;
        allFilterSubgroups.push(...filterSubgroups);
    });
    return allFilterSubgroups;
}

export const getSelectedFilterItemsList = (availableFilters: AvailableFilters, appliedFilters: AppliedFilters): Filter[] => {
    const list: Filter[] = [];
    const filtersList: Filter[] = [];

    const allAppliedFiltersList: string[] = getAllAppliedFiltersList(appliedFilters);
    const allFilterSubgroups = getAllFiltersSubgroups(availableFilters);

    allFilterSubgroups.forEach((filterSubgroup) => {
        filtersList.push(...filterSubgroup.filters);
    });

    filtersList.forEach((filter: Filter) => {
        if (allAppliedFiltersList.includes(filter.value)) {
            // const isConnector = !!filter.speed_type;
            // filter.qf_icon_url = isConnector ? [
            //     'https://img.icons8.com/ios-filled/100/null/scooter.png',

            // ] : null;
            list.push(filter);
        }
    });

    return sortBy(list, 'qf_rank');
}

export const getNonSelectedFilterItemsList = (availableFilters: AvailableFilters, appliedFilters: AppliedFilters): Filter[] => {
    const list: Filter[] = [];
    const filtersList: Filter[] = [];

    const allAppliedFiltersList: string[] = getAllAppliedFiltersList(appliedFilters);
    const allFilterSubgroups = getAllFiltersSubgroups(availableFilters);

    allFilterSubgroups.forEach((filterSubgroup) => {
        filtersList.push(...filterSubgroup.filters);
    });

    filtersList.forEach((filter: Filter) => {
        if (!allAppliedFiltersList.includes(filter.value)) {
            // const isConnector = !!filter.speed_type;
            // filter.qf_icon_url = isConnector ? [
            //     'https://img.icons8.com/material-rounded/96/null/auto-rickshaw.png',
            //     'https://img.icons8.com/ios-glyphs/90/null/carpool--v1.png',
            //     'https://img.icons8.com/ios-filled/100/null/scooter.png',
            // ] : null;
            list.push(filter);
        }
    });

    return sortBy(list, 'qf_rank');
}

export const getNewAppliedFilters = (appliedFilters: AppliedFilters, filter: Filter, isSelected: boolean, group: CSFilterGroup) => {
    const newAppliedFilters: AppliedFilters = { ...appliedFilters };
    if (isSelected)
        newAppliedFilters[group] = Array.from(new Set(newAppliedFilters[group]?.filter((item: string) => item !== filter.value)));
    else
        newAppliedFilters[group] = Array.from(new Set([...(newAppliedFilters?.[group] ?? []), filter.value]));
    return newAppliedFilters;
}

export const generateQuickFiltersList = (availableFilters: AvailableFilters, appliedFilters: AppliedFilters) => {
    const quickFiltersList: {
        // selected: Filter[],
        [CSFilterGroup.ConnectorTypes]: Filter[],
        [CSFilterGroup.Brands]: Filter[],
        [CSFilterGroup.Amenities]: Filter[],
        [CSFilterGroup.Payable]: Filter[],
        [CSFilterGroup.Accessibility]: Filter[],
        [key: string]: Filter[],
    } = {
        // selected: [],
        [CSFilterGroup.ConnectorTypes]: [],
        [CSFilterGroup.Brands]: [],
        [CSFilterGroup.Amenities]: [],
        [CSFilterGroup.Payable]: [],
        [CSFilterGroup.Accessibility]: [],
    };

    const addedFilters: { [filerName: string]: boolean } = {};

    Object.keys(availableFilters).forEach((csFilterGroup) => {
        const group = availableFilters[csFilterGroup as CSFilterGroup];
        group.filter_subgroup.forEach((filterSubgroup) => {
            filterSubgroup.filters.forEach((filter) => {
                const extendedFilter = {
                    ...filter,
                    group: csFilterGroup,
                    subgroup: filterSubgroup.name,
                };

                // const isApplied = appliedFilters[csFilterGroup as CSFilterGroup].includes(extendedFilter.value);
                // if (isApplied) {
                //     quickFiltersList.selected.push(extendedFilter);
                //     addedFilters[extendedFilter.value] = true;
                // }
                // else {
                if (!addedFilters[extendedFilter.value]) {
                    if (!isArray(quickFiltersList[extendedFilter.group as CSFilterGroup]))
                        quickFiltersList[extendedFilter.group as CSFilterGroup] = [];

                    quickFiltersList[extendedFilter.group as CSFilterGroup].push(extendedFilter);
                    addedFilters[extendedFilter.value] = true;
                }
                // }
            });
        });
    });

    // console.log('quickFiltersList before', quickFiltersList);

    Object.keys(quickFiltersList).forEach((key) => {
        if (key !== 'selected') {
            const groupList = quickFiltersList[key as CSFilterGroup];
            // console.log('groupList', groupList);
            const sortedList = orderBy(groupList, ['qf_visibility', 'qf_rank'], ['desc', 'desc']);
            // console.log('sortedList', sortedList);

            const qfVisibleItems: any = [];
            const qfNonVisibleItems: any = [];
            for (let i = 0; i < sortedList.length; i++) {
                const f = sortedList[i];
                const isApplied = appliedFilters[f.group as CSFilterGroup]?.includes(f.value);
                if (f.qf_visibility || isApplied) {
                    qfVisibleItems.push(f);
                }
                else {
                    qfNonVisibleItems.push(f);
                }
            }

            const finalList = [...qfVisibleItems];
            const totalLength = qfVisibleItems.length + qfNonVisibleItems.length;

            const minVisibleQFCount = 3;

            if (qfVisibleItems.length < minVisibleQFCount) {
                finalList.push(...qfNonVisibleItems.splice(0, minVisibleQFCount - qfVisibleItems.length));
            }

            // should add +X more button
            if (totalLength > minVisibleQFCount) {
                const remainingCount = totalLength - qfVisibleItems.length;
                finalList.push({
                    value: 'more',
                    viewValue: `+${remainingCount} More`,
                    group: key,
                } as Filter);
            }
            quickFiltersList[key as CSFilterGroup] = finalList;
        }
    });

    // console.log('quickFiltersList after', quickFiltersList);

    return quickFiltersList;
}

export const getLeftIcons = (filter: Filter): string[] | null => {
    let icon: any = null;
    if (filter.qf_icon_url?.length)
        icon = filter.qf_icon_url;
    else if (!!filter.image)
        icon = [filter.image];
    return icon;
}

export const getDefaultFilters = (): AppliedFilters => {
    const defaultFilters: {[key: string]: []} = {};
    Object.values(CSFilterGroup).forEach((key) => {
        defaultFilters[key] = [];
    });
    return defaultFilters as unknown as AppliedFilters;
}