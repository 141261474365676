import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Keyboard, View } from "react-native";
import { batch, useDispatch } from "react-redux";
import AuthenticatedOrgRequestService from "../../../../src/services/AuthenticatedOrgRequestService";
import { useCentralUserProfile } from "../../../../src/hooks";
import { useActiveFleetOperatorOrgUser } from "../../../../src/hooks";
import { PaymentMethodType } from "../../../../src/central-atoms/payment-methods/types/enum";
import { WalletType } from "../../../../src/central-atoms/wallets/enums";
import CButton from "../../components/CButton";
import CInput from "../../components/CInput";
import CText from "../../components/CText";
import { useSelectedLanguage } from "../../../../src/hooks";
import ScreenBottomBar from "../../../../src/miscellaneous/components/ScreenBottomBar";
import ScreenHeader from "../../../../src/miscellaneous/components/ScreenHeader";
import ScreenWrapper from "../../../../src/miscellaneous/components/ScreenWrapper";
import { StyleHelpers } from "../../../../src/miscellaneous/constants/style-helpers";
import { ScreenComponent } from "../../../../src/miscellaneous/types/components";
import { ErrorHandlerService } from "../../../../src/services/ErrorHandlerService";
import {
  useActivePaymentMethodType,
  useActiveWallet,
} from "../../../../src/hooks/walletHooks";
import { LS_KEYS, SCREEN_NAMES } from "../../../../src/constants";
import ChargePointRequestService from "../../../../src/services/ChargePointRequestService";
import {
  setSelectedChargePoint,
  setSelectedChargingStation,
} from "../../../../src/actions/doChargingActions";
import styles from "./styles";

type Props = ScreenComponent & {};
export default function EnterChargePointIdScreen(props: Props) {
  const { navigation } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedLanguage = useSelectedLanguage();
  const centralUserProfile = useCentralUserProfile();
  const activeWallet = useActiveWallet();
  const activePaymentMethodType = useActivePaymentMethodType();
  const activeFleetOperatorOrgUser = useActiveFleetOperatorOrgUser();
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [chargerID, setChargePointId] = useState("");
  const [waitForChargingStation, setWaitForChargingStation] = useState(false);

  const handleContinuePress = async () => {
    Keyboard.dismiss();

    setWaitForChargingStation(true);

    try {
      const enteredAccessCode = await AsyncStorage.getItem(
        LS_KEYS.enteredAccessCode
      );

      const shouldPromptForOrgVerification = !!(
        activePaymentMethodType === PaymentMethodType.Wallet &&
        activeWallet?.type === WalletType.InstaCharge &&
        enteredAccessCode &&
        centralUserProfile?.phone
      );

      if (shouldPromptForOrgVerification) {
        await AuthenticatedOrgRequestService.verifyOperatorCode({
          org_id: activeFleetOperatorOrgUser.org_id,
          operator_code: enteredAccessCode,
        });
      }

      getChargePointByQuery(shouldPromptForOrgVerification);
    } catch (e) {
      getChargePointByQuery(false);

      ErrorHandlerService.handleError(e, {
        openToast: false,
      });
    }
  };

  const getChargePointByQuery = (shouldPromptForOrgVerification: boolean) => {
    setWaitForChargingStation(true);
    ChargePointRequestService.fetchChargePointByQuery({ q: chargerID })
      .then(({ data }) => {
        const chargePoint =
          data.central_charging_station?.charge_points?.find(
            (cp) => cp.qr_code_id === chargerID || cp.public_uid === chargerID
          ) ?? data.central_charging_station?.charge_points[0];
        batch(() => {
          dispatch(setSelectedChargingStation(data.charging_station));
          if (chargePoint) {
            dispatch(setSelectedChargePoint(chargePoint));
          }
        });

        navigation.navigate(SCREEN_NAMES.PreStartChargingSelectionScreen, {
          shouldPromptForOrgVerification: shouldPromptForOrgVerification,
        });

        setWaitForChargingStation(false);
      })
      .catch((e: any) => {
        setWaitForChargingStation(false);
        if (
          e?.response?.data?.i18n_error &&
          e?.response?.data?.i18n_error?.[selectedLanguage]
        ) {
          console.log(e);
          setErrorMsg(
            e?.response?.i18n_error?.[selectedLanguage] || t("invalidChargeID")
          );
        }
        ErrorHandlerService.handleError(e, {
          lang: selectedLanguage,
        });
      });
  };

  return (
    <View style={styles.root}>
      <ScreenHeader
        navigation={navigation}
        style={{
          borderBottomWidth: 0,
        }}
      />

      <View
        style={{
          ...StyleHelpers.pageSpacing,
          flex: 1,
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <View>
          <CText
            value={t("enterChargerID")}
            size={26}
            semiBold={false}
            bold
            style={{
              lineHeight: 70,
              fontWeight: "bold",
            }}
          />
          <CInput
            // @ts-ignore
            placeholder={`CQ22AA33BB`}
            value={chargerID}
            onChangeText={setChargePointId}
            maxLength={10}
            autoFocus
            errorMessage={errorMsg}
          />
        </View>
      </View>

      <ScreenBottomBar>
        <CButton
          disable={waitForChargingStation}
          loading={waitForChargingStation}
          loadingText={t("verifying")}
          title={t("continue")}
          style={{ marginTop: 24 }}
          onPress={() => {
            handleContinuePress();
          }}
        />
      </ScreenBottomBar>
    </View>
  );
}
