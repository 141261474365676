import { StyleSheet } from "react-native";
import { COLORS } from "../../../config/colors";
import { StyleHelpers } from "../../../constants/style-helpers";
import { THEME_COLORS } from "../../../theme/constants/themeColors";

const styles = StyleSheet.create({
	cont_card: {
		backgroundColor: COLORS.whiteColor,
		// borderRadius: 14,
		// borderWidth: 1,
		// borderBottomWidth: 3,
		...StyleHelpers.themeBorder,
		borderColor: THEME_COLORS.cardBorderColor,
		padding: 16,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	btnCont: {
		backgroundColor: THEME_COLORS.buttonBgColor,
		borderRadius: 8,
		padding: 4,
		paddingHorizontal: 16,
	},
	rowStyle: {
		flexDirection: "row",
		alignItems: "center",
	},
	view: {
		height: 52,
		overflow: "hidden",
		borderRadius: 16,
		backgroundColor: COLORS.whiteColor,
		justifyContent: "center",
		alignItems: "center",
	},
	img: {
		width: "100%",
		height: "100%",
	},
});

export default styles;
