import moment from "moment";

export const getTimeDiff = (startTimestamp: string, endTimestamp: string) => {
	// ref: https://stackoverflow.com/a/18624295/4331993
	const ms = moment(endTimestamp).diff(moment(startTimestamp));
	const d = moment.duration(ms);

	const diffDays: string | number = d.days();
	let diffHours: string | number = d.hours();
	let diffMins: string | number = d.minutes();
	const diffTotalSecs: string | number = d.seconds();
	let diffSecs: string | number = diffTotalSecs % 60;

	diffHours = diffHours < 10 ? `0${diffHours}` : diffHours;
	diffMins = diffMins < 10 ? `0${diffMins}` : diffMins;
	diffSecs = diffSecs < 10 ? `0${diffSecs}` : diffSecs;

	return {
		days: diffDays,
		hours: diffHours,
		minutes: diffMins,
		seconds: diffSecs,
		totalSeconds: diffTotalSecs,
	};
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function formatDuration(seconds: number): string {
	// Calculate hours, minutes, and remaining seconds
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = Math.floor(seconds % 60);

	// Format the duration as HH:mm:ss
	return `${padZero(hours)}:${padZero(minutes)}:${padZero(
		remainingSeconds
	)}`;
}

// Helper function to pad single-digit numbers with a leading zero
export function padZero(number) {
	return (number < 10 ? "0" : "") + number;
}

// convert utc time to local time
export function convertUTCToLocalTime(input: string) {
	return moment.utc(input).local().format("YYYY-MM-DDTHH:mm:ss");
}
