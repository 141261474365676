import { Dispatch } from "redux";
import AuthenticatedUserRequestService from "./AuthenticatedUserRequestService";
import { AppliedFilters } from "../central-atoms/filters/types";
import { UserPreferences } from "../central-atoms/users/types";
import { SyncUserPreferenceReqPayload } from "../central-atoms/types/user/requests";
import { ErrorHandlerService } from "./ErrorHandlerService";

export default class AuthenticatedUserService {

    static syncPreferences(newFilters: AppliedFilters, storedUserPreferences: UserPreferences, dispatch: Dispatch) {
        const payload: SyncUserPreferenceReqPayload = {
            ...storedUserPreferences,
            app_exp: {
                ...storedUserPreferences.app_exp,
                selected_cs_filters: newFilters,
            },
        };
        AuthenticatedUserRequestService.syncPreferences(payload)
            .then((response) => {
            })
            .catch((e) => {
                ErrorHandlerService.handleError(e, {
                    openToast: false,
                });
            });
    }
}
