import { createStore, Store } from 'redux';
import { APP_ENV } from '../../config/setting';
import rootReducer from '../reducer/allReducers';



/**
 * Redux Setting
 */
// const persistConfig = {
//     key: 'root',
//     storage: AsyncStorage,
//     stateReconciler: autoMergeLevel2,
//     // blacklist: ['filter', 'booking'],
// };

// let middleware = [thunk];
// middleware = [...middleware];

// const persistedReducer = persistReducer(persistConfig, rootReducer);
// const store = createStore(persistedReducer, applyMiddleware(...middleware));
// const persistor = persistStore(store);

let store: Store;

if (APP_ENV === 'prod') {
    store = createStore(
        rootReducer,
        // (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
    );
}
else {
    store = createStore(
        rootReducer,
        // composeWithDevTools(applyMiddleware(thunk))
        // ref: https://github.com/zalmoxisus/redux-devtools-extension#11-basic-store
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
    );
}

export const storeDispatch = store.dispatch;

export {
    store,
    // persistor,
};
