import { useTranslation } from "react-i18next";
import { useRef, useCallback } from "react";
import Webcam from "react-webcam";
import { isEmpty } from "lodash";
import { useState } from "react";
import {
  Dimensions,
  Image,
  Keyboard,
  Modal,
  ModalProps,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from "react-native";
import { batch, useDispatch, useSelector } from "react-redux";
import { CentralMedia } from "../../../central-atoms/types";
import HttpRequestService from "../../../config/HttpRequestService";
import { CENTRAL_HOST, ENVIRONMENT } from "../../../config/setting";
import CButton from "../../components/CButton";
import CInput from "../../components/CInput";
import CText from "../../components/CText";
import SimpleButton from "../../components/SimpleButton";
import ScreenBottomBar from "../../../miscellaneous/components/ScreenBottomBar";
import ScreenHeader from "../../../miscellaneous/components/ScreenHeader";
import ScreenWrapper from "../../../miscellaneous/components/ScreenWrapper";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { getFileTypeFromMime } from "../../../miscellaneous/helpers";
import { ScreenComponent } from "../../../miscellaneous/types/components";
import AuthAction from "../../../redux/reducer/auth/actions";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import { MediaType, UploadFileGroup } from "../../../central-atoms/enums/generic/file-upload";
import { getRandomItem } from "../../../central-atoms/functions/array";
import { FontFamily } from "../../../config/typography";
import { useCentralUserProfile } from "../../../hooks";
import AuthenticatedUserRequestService from "../../../services/AuthenticatedUserRequestService";
import { APP_STATE } from "../../../types";
import UserAvatarComponent from "../../components/UserAvatar";
import styles from "./styles";
import { IoMdClose } from "react-icons/io";
import { COLORS } from "../../../config/colors";
import { UploadPhotoIcon } from "../../../icon";
import { RemovePhotoIcon } from "../../../icon";
import { SelfieIcon } from "../../../icon";
import DashedLink from "../../components/DashedLink";
import { showSuccessToast } from "../../../helpers";

type Props = ScreenComponent & { modalProps?: ModalProps | any };

export default function EditProfileScreen(props: Props) {
  const { t } = useTranslation();
  const { navigation, modalProps = {} } = props;
  const { userData, centralUserData } = useSelector((state: APP_STATE) => state.auth);
  const centralUserProfile = useCentralUserProfile();
  const uploadedPhoto = centralUserData.medias?.find((m: CentralMedia) => m.type === MediaType.DP);
  const Screen = Dimensions.get("window");
  const ScreenHeight = Screen.height;
  const [containerHeight, setContainerHeight] = useState(0);
  const { setUserData, setCentralUserData, setToken } = AuthAction;
  const dispatch = useDispatch();
  const [name, setName] = useState(userData?.name?.trim());
  const [email, setEmail] = useState(centralUserData?.email?.trim());
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const openCamera = () => {
    setIsCameraOpen(true);
  };
  const closeCamera = () => {
    setIsCameraOpen(false);
  };
  const [gst, setGST] = useState(centralUserData?.additional_props?.billing_details?.[0]?.gstin?.trim());
  const [emailPlaceholder] = useState(
    getRandomItem(["steve@apple.com", "elon@tesla.com", "bill@microsoft.com", "sundar@google.com", "you@gmail.com"])
  );
  const [locallyUpdatedImg, setLocallyUpdatedImg] = useState<null | any>(null);
  const [changePhotoModalOpen, setChangePhotoModalOpen] = useState(false);
  const [waitForSaveProfile, setWaitForSaveProfile] = useState(false);
  const [waitForPhotoUpload, setWaitForPhotoUpload] = useState(false);

  const deletePhoto = () => {
    if (!uploadedPhoto?.id) return;

    const payload = {
      media_ids: [uploadedPhoto?.id]
    };

    const centralHttpService = new HttpRequestService();
    centralHttpService.setCustomInstance({
      authToken: centralUserData?.central_token,
      config: {
        baseURL: CENTRAL_HOST
      }
    });
    setChangePhotoModalOpen(false);
    centralHttpService
      .getInstance()
      .delete(`/delete_file`, {
        data: payload
      })
      .then(({ data }) => {
        if (data?.data?.count > 0) {
          // ToAddLater
          showSuccessToast(t("SuccessfullyPhotoRemoved"), {
            position: "bottom-right",
            autoClose: 2000

            // topOffset: 10
          });
          saveProfile();
        }
        setWaitForSaveProfile(false);
      })
      .catch((e: any) => {
        setWaitForSaveProfile(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const handleFileFromGallery = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setLocallyUpdatedImg(selectedFile);
      setChangePhotoModalOpen(false);
    }
  };

  const webcamRef = useRef<any>(null);
  const fileInputRef = useRef<any>(null);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      const blob = dataURItoBlob(imageSrc);
      const file = new File([blob], "selfie.jpg", { type: "image/jpeg" });
      closeCamera();
      setLocallyUpdatedImg(file);
      setChangePhotoModalOpen(false);
    }
  }, []);

  // Helper function to convert data URI to Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  };

  const fromGallery = async () => {
    // Trigger the file input click when the "Upload from Gallery" button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadPhoto = () => {
    const formData = new FormData();
    formData.append("files", locallyUpdatedImg);
    formData.append("file_group", UploadFileGroup.UserProfile);
    formData.append("id", centralUserData.id);
    formData.append(
      "metadata",
      JSON.stringify({
        type: getFileTypeFromMime(locallyUpdatedImg.mime),
        size: locallyUpdatedImg?.size ?? "",
        res: `${locallyUpdatedImg?.width ?? ""}x${locallyUpdatedImg?.height ?? ""}`
      })
    );

    if (!centralUserData?.central_token) {
      //ToAddLater showToast(t('UnauthorizedPleaseLogin'));
      return;
    }

    setWaitForPhotoUpload(true);
    return fetch(`${CENTRAL_HOST}/upload_file`, {
      method: "post",
      headers: {
        ENV: ENVIRONMENT.type,
        Authorization: `Bearer ${centralUserData?.central_token}`
      },
      body: formData
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.data?.count === 1) {
          setLocallyUpdatedImg(null);
        }
        setWaitForPhotoUpload(false);
      })
      .catch((e: any) => {
        setWaitForPhotoUpload(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const saveProfile = () => {
    const data = {
      name: name,
      email: email,
      billing_details: [
        {
          gstin: gst
        }
      ]
    };

    setWaitForSaveProfile(true);
    AuthenticatedUserRequestService.updateDriverUserProfile(data)
      .then(({ data }) => {
        // console.log('data', data);
        batch(() => {
          dispatch(setUserData(data?.user));
          dispatch(setCentralUserData({ ...centralUserData, ...data?.central_user }));
          dispatch(setToken(data?.user?.token));
        });
        // ToAddLater
        showSuccessToast(t("SuccessfullyUpdateProfile"), {
          position: "top-right"
          // topOffset: 10
        });
        Keyboard.dismiss();
        setWaitForSaveProfile(false);
      })
      .catch((e: any) => {
        setWaitForSaveProfile(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  return (
    <View style={styles.root}>
      <ScreenHeader
        style={{
          borderBottomWidth: 0,
          position: "sticky" as any,
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100
        }}
        navigation={navigation}
      />

      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: StyleHelpers.pageSpacing.paddingLeft
        }}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled">
        <View style={{ flex: 1, marginTop: 24 }}>
          <View
            style={{
              alignSelf: "center"
            }}>
            <View style={styles.profileCont}>
              {!waitForSaveProfile && !locallyUpdatedImg && isEmpty(uploadedPhoto?.full_url) ? (
                <UserAvatarComponent
                  size={92}
                  name={userData?.name?.charAt(0) || "Hi"}
                  borderRadius={100}
                  textStyle={{
                    fontFamily: FontFamily.Poppins_SemiBold,
                    fontWeight: 600,
                    fontSize: 36
                  }}
                  bgColor={userData?.accent_color}
                />
              ) : (
                <Image
                  source={{
                    uri: (locallyUpdatedImg && URL.createObjectURL(locallyUpdatedImg)) || uploadedPhoto?.full_url
                  }}
                  style={styles.profileStyle}
                  resizeMode={"cover"}
                />
              )}
            </View>
            <View style={{ alignSelf: "center", marginTop: 12 }}>
              <DashedLink>
                <TouchableOpacity
                  activeOpacity={0.7}
                  onPress={() => {
                    setChangePhotoModalOpen(true);
                  }}>
                  <CText value={t("changePhoto")} size={16} semiBold style={{ fontWeight: "600" }} />
                </TouchableOpacity>
              </DashedLink>
            </View>
          </View>

          <>
            <CText size={16} semiBold style={{ marginTop: 32, marginBottom: 10, fontWeight: "600" }}>
              {t("name")}
            </CText>
            <CInput
              // @ts-ignore
              value={name}
              onChangeText={setName}
              returnKeyType="done"
              style={{ borderColor: COLORS.primaryGrey, fontSize: 14 }}
            />
          </>

          <>
            <CText size={16} style={{ marginTop: 10, marginBottom: 10, fontWeight: "600" }}>
              {t("PhoneNumber")}
            </CText>
            <CInput
              // @ts-ignore
              value={centralUserProfile.phone}
              disabled={true}
              returnKeyType="done"
              style={{ borderColor: COLORS.primaryGrey, fontSize: 14 }}
            />
          </>

          <>
            <CText size={16} style={{ marginTop: 10, marginBottom: 10, fontWeight: "600" }}>
              {t("Email")}
            </CText>
            <CInput
              // @ts-ignore
              value={email}
              onChangeText={setEmail}
              placeholder={emailPlaceholder}
              returnKeyType="done"
              style={{ borderColor: COLORS.primaryGrey, fontSize: 14 }}
            />
          </>

          <>
            <CText size={16} style={{ marginTop: 10, marginBottom: 10, fontWeight: "600" }}>
              {t("GSTNumber")} ({t("Optional")})
            </CText>
            <CInput
              // @ts-ignore
              value={gst}
              onChangeText={setGST}
              placeholder={"22ABCDE1234X1Z5"}
              returnKeyType="done"
              style={{ borderColor: COLORS.primaryGrey, fontSize: 14 }}
            />
          </>
        </View>
      </ScrollView>

      <ScreenBottomBar
        style={{
          position: "sticky" as any,
          bottom: 0,
          left: 0,
          right: 0,
          borderBottomWidth: 0,
          display: "block" as any
        }}>
        <CButton
          title={waitForPhotoUpload ? t("UploadingPhoto") : waitForSaveProfile ? t("Saving") : t("save")}
          loading={waitForSaveProfile || waitForPhotoUpload}
          onPress={() => {
            console.log("locallyUpdatedImg new", locallyUpdatedImg, locallyUpdatedImg ? "there" : "not");
            if (locallyUpdatedImg) {
              uploadPhoto()?.then(() => {
                saveProfile();
              });
            } else {
              saveProfile();
            }
          }}
        />
      </ScreenBottomBar>
      <Modal
        style={{
          flex: 1,
          margin: 0,
          justifyContent: "flex-end",
          bottom: 0,
          position: "relative"
        }}
        visible={changePhotoModalOpen}
        animationType="fade"
        transparent={true}
        useNativeDriverForBackdrop={true}
        onDismiss={() => setChangePhotoModalOpen(false)}
        useNativeDriver={true}
        {...modalProps}>
        {/* Close Button */}
        <TouchableOpacity
          style={{
            padding: 10,
            ...StyleSheet.absoluteFillObject,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 10
          }}
          onPress={() => setChangePhotoModalOpen(false)}>
          <IoMdClose size={30} display="none" />
        </TouchableOpacity>
        <View
          onLayout={(event) => {
            const { height } = event.nativeEvent.layout;
            setContainerHeight(height);
          }}
          style={{
            marginTop: ScreenHeight - containerHeight,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: COLORS.whiteColor,
            borderTopEndRadius: 16,
            borderTopStartRadius: 16,
            padding: 20,
            paddingTop: 10,
            paddingBottom: 40,
            zIndex: 100
          }}>
          {!!uploadedPhoto?.id && (
            <View style={{ width: "100%" }}>
              <SimpleButton
                title={t("removePhoto")}
                style={{
                  marginTop: 18,
                  justifyContent: "space-between",
                  borderColor: COLORS.primaryLight,
                  borderBottomWidth: 1
                }}
                rightIcon={
                  <RemovePhotoIcon
                    style={{
                      height: 24,
                      width: 24
                    }}
                  />
                }
                onPress={deletePhoto}
              />
            </View>
          )}

          <View style={{ width: "100%" }}>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileFromGallery}
              accept="image/jpeg, image/png, image/webp"
              style={{ display: "none" }}
            />
            <SimpleButton
              title="Upload from Gallery"
              style={{
                marginTop: 18,
                justifyContent: "space-between",
                borderColor: COLORS.primaryLight,
                borderBottomWidth: 1
              }}
              onPress={fromGallery}
              rightIcon={
                <UploadPhotoIcon
                  style={{
                    height: 24,
                    width: 24
                  }}
                />
              }
            />
          </View>
          <View style={{ width: "100%" }}>
            <Modal visible={isCameraOpen} animationType="slide" transparent={false} onRequestClose={closeCamera}>
              <View style={{ width: "100%" }}>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  style={{
                    height: "34vh",
                    width: "100%"
                  }}
                />
                <View style={{ marginTop: 40 }}>
                  <SimpleButton
                    title={t("Capture")}
                    style={{
                      marginTop: 18,
                      justifyContent: "space-between",
                      borderColor: COLORS.primaryLight,
                      width: "80%",
                      margin: "auto",
                      marginBottom: 20,
                      borderBottomWidth: 1
                    }}
                    onPress={capture}
                    rightIcon={
                      <SelfieIcon
                        style={{
                          height: 24,
                          width: 24
                        }}
                      />
                    }
                  />
                  <SimpleButton
                    title={t("Close Camera")}
                    style={{
                      marginTop: 18,
                      justifyContent: "space-between",
                      borderColor: COLORS.primaryLight,
                      width: "80%",
                      margin: "auto",
                      borderBottomWidth: 1
                    }}
                    onPress={closeCamera}
                  />
                </View>
              </View>
            </Modal>
            <SimpleButton
              title={t("takeASelfie")}
              style={{
                marginTop: 18,
                justifyContent: "space-between",
                width: "100%",
                borderColor: COLORS.primaryLight,
                borderBottomWidth: 1
              }}
              onPress={openCamera}
              rightIcon={
                <SelfieIcon
                  style={{
                    height: 24,
                    width: 24
                  }}
                />
              }
            />
          </View>
        </View>
      </Modal>
    </View>
  );
}
