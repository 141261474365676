import { useNavigation } from "@react-navigation/native";
import { Image, ScrollView, View } from "react-native";
import { useDispatch } from "react-redux";
import CButton from "../../components/CButton";
import CText from "../../components/CText";
import { COLORS } from "../../../config/colors";
import { Images } from "../../../config/image";
import ScreenBottomBar from "../../../miscellaneous/components/ScreenBottomBar";
import ScreenHeader from "../../../miscellaneous/components/ScreenHeader";
import ScreenWrapper from "../../../miscellaneous/components/ScreenWrapper";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { ScreenComponent } from "../../../miscellaneous/types/components";
import DashedLink from "../../components/DashedLink";
import { useChargeTxnsFilter as useChargeTxnsFilters } from "../../../hooks/chargeTxn";
import { setChargeTxnsFilters } from "../../../actions/ChargeTxn";
import ChargingHistoryFilterItem from "../../components/ChargingHistoryFilterItem";
import { useTranslation } from "react-i18next";

type Props = ScreenComponent & {};
export default function ChargingHistoryFiltersScreen(props: Props) {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const chargeTxnsFilters = useChargeTxnsFilters();

  const handleClearAll = () => {
    dispatch(
      setChargeTxnsFilters({
        wallet_ids: [],
      })
    );
  };

  const handleOnSelect = (item: any) => {
    const newWalletIds = [...chargeTxnsFilters.wallet_ids];
    const itemIndex = newWalletIds.findIndex((i: string) => i === item.id);
    if (itemIndex === -1) {
      newWalletIds.push(item.id);
    } else {
      newWalletIds.splice(itemIndex, 1);
    }
    dispatch(
      setChargeTxnsFilters({
        wallet_ids: chargeTxnsFilters.wallet_ids,
      })
    );
  };

  return (
    <ScreenWrapper {...props}>
      <ScreenHeader
        navigation={navigation}
        rightComponent={
          <DashedLink onPress={handleClearAll}>
            <CText semiBold size={13}>
              {t("ClearAll")}
            </CText>
          </DashedLink>
        }
      />

      <ScrollView
        style={{
          ...StyleHelpers.pageSpacing,
          paddingBottom: 30,
        }}
      >
        <CText
          size={14}
          semiBold
          style={{
            marginBottom: 12,
            marginTop: 30,
          }}
        >
          {t("Wallets")}
        </CText>

        <View>
          <ChargingHistoryFilterItem
            item={{
              id: "323",
              title: (
                <CText size={14} semiBold>
                  Mahesh Reddy
                </CText>
              ),
            }}
            isSelected={false}
            onSelectItem={(item: any) => handleOnSelect(item)}
          />
        </View>

        <CText
          size={14}
          semiBold
          style={{
            marginBottom: 12,
            marginTop: 30,
          }}
        >
          {t("User")}
        </CText>

        <View>
          <ChargingHistoryFilterItem
            isSelected={true}
            onSelectItem={(item: any) => handleOnSelect(item)}
            item={{
              id: "323",
              title: (
                <View
                  style={{
                    backgroundColor: COLORS.whiteColor,
                    borderRadius: 4,
                    borderWidth: 2,
                    borderColor: COLORS.whiteColor,
                  }}
                >
                  <Image
                    source={Images.paytm_logo}
                    resizeMode="contain"
                    style={{
                      height: 40,
                      maxWidth: 90,
                    }}
                  />
                </View>
              ),
            }}
          />
        </View>
      </ScrollView>

      <ScreenBottomBar>
        <CButton
          title={t("ShowChargingSessions")}
          onPress={() => {
            navigation.goBack();
          }}
        />
      </ScreenBottomBar>
    </ScreenWrapper>
  );
}
