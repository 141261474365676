import config from "./config";
import { environments } from "./environments";
import packageJson from '../../package.json';


export const APP_ENV = config.APP_ENV;
export const ENVIRONMENT = environments[`${APP_ENV}`];

export const APP_VERSION = packageJson.version;
export const API_VERSION = "4.1.4";
export const APP_BUILD_NUMBER = "5";

export const APP_VARIANT = config.WHITE_LABEL_ID;
export const API_BASE_PATH = ENVIRONMENT.apiBasePath;
export const PULSE_API_HOST = ENVIRONMENT.apiHost;
export const CENTRAL_HOST = ENVIRONMENT.centralHost;
export const APP_WEBSITE_HOST = ENVIRONMENT.appWebsiteHost;
export const DEFAULT_PLUG_IMG = `https://media.pulseenergy.in/connectors/plug.png`;
export const WHATSAPP_CHAT_LINK = `https://wa.me/917777021222`;
export const MEDIA_CDN_HOST = ENVIRONMENT.mediaCdnHost;
export const WHATSAPP_LOGO = `${MEDIA_CDN_HOST}/whatsapp-logo/whatsapp.png`;
export const SENTRY_DSN = `https://4fd8059cf60e4191ba158fe2d7298ae4@o918959.ingest.sentry.io/6539882`;
export const API_APP_VARIANT = config.API_APP_VARIANT;
export const PLAY_STORE_APP_URL = config.PLAY_STORE_APP_URL;
export const APP_STORE_APP_URL = config.APP_STORE_APP_URL;
export const PACKAGE_BUNDLE_ID = config.BUNDLE_ID;
export const PAYTM_URL = ENVIRONMENT.paytmURL;
export const GOOGLE_MAPS_KEY = ENVIRONMENT.googleMapsKey;

console.log(`Version:`, APP_VERSION);
