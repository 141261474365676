import { useEffect, useRef, useState } from "react";
import { View } from "react-native";
import styles from "./styles";
// @ts-ignore
import { ChargePointStatus } from "../../../../../central-atoms/enums/ocpp/v1.6/generic";
import ScreenBottomBar from "../../../../../miscellaneous/components/ScreenBottomBar";
import ScreenHeader from "../../../../../miscellaneous/components/ScreenHeader";
import ScreenWrapper from "../../../../../miscellaneous/components/ScreenWrapper";
import { ScreenComponent } from "../../../../../miscellaneous/types/components";
import { THEME_COLORS } from "../../../../../theme/constants/themeColors";
import { useSelectedChargePointConnector } from "../../../../../hooks";
import CText from "../../../../components/CText";
import CButton from "../../../../components/CButton";
import AnimatedLottieView from "lottie-react";
import { LottieAnimation } from "../../../../../config/lottie";
import ReactPlayer from "react-player";
import {
  horizontalSize,
  verticalSize,
} from "../../../../../functions/responsive";
import { useTranslation } from "react-i18next";

type Props = ScreenComponent & {
  onBackPress: () => void;
  onNextPress: () => void;
  cpConnected: boolean;
  cpNotConnectedError: null | any;
  active: boolean;
};
export default function ConnectCharger(props: Props) {
  const {
    navigation,
    onBackPress = () => {},
    onNextPress = () => {},
    active,
    cpConnected,
  } = props;
  const { t } = useTranslation();
  const animationRef = useRef<any>(null);

  const selectedChargePointConnector = useSelectedChargePointConnector();

  const [_chargerConnected, setChargerConnected] = useState(false);
  const [chargerConnectedAnimPlayedOnce, setChargerConnectedAnimPlayedOnce] =
    useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const goToNextScreenTimeoutRef = useRef<any>();

  const playChargerConnectedAnimation = () => {
    animationRef.current?.play();
    setChargerConnectedAnimPlayedOnce(true);
  };

  useEffect(() => {
    if (!cpConnected) setShowContinueButton(false);
  }, [cpConnected]);

  useEffect(() => {
    if (active) {
      if (
        selectedChargePointConnector?.status === ChargePointStatus.Preparing
      ) {
        if (!chargerConnectedAnimPlayedOnce) {
          playChargerConnectedAnimation();
          goToNextScreenTimeoutRef.current = setTimeout(() => {
            onNextPress();
          }, 2000);
        }
      } else {
        clearTimeout(goToNextScreenTimeoutRef.current);
        setChargerConnectedAnimPlayedOnce(false);
      }
    } else {
      clearTimeout(goToNextScreenTimeoutRef.current);
    }
  }, [active, selectedChargePointConnector?.status]);

  return (
    <ScreenWrapper {...props}>
      <View style={styles.root}>
        <ScreenHeader
          navigation={navigation}
          style={{ borderBottomWidth: 0 }}
          onBackPress={() => {
            onBackPress();
          }}
        />

        {!cpConnected ? (
          <View style={styles.innerRoot}>
            <View style={styles.centerCont}>
              <View style={styles.lottieCont}>
                <ReactPlayer
                  url={require("../../../../../assets/connect_charger_video.mp4")}
                  muted
                  loop
                  width={200}
                  height={200}
                  playing
                />
              </View>

              <CText
                value={t("pleaseConnectCharger")}
                size={26}
                bold
                style={{
                  textAlign: "center",
                  marginTop: 20,
                  fontWeight: "600",
                }}
              />
            </View>
          </View>
        ) : (
          <View
            style={{
              flex: 1,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: THEME_COLORS.bgColor,
              opacity: 1,
            }}
          >
            <AnimatedLottieView
              lottieRef={animationRef}
              animationData={LottieAnimation.check}
              // autoPlay
              loop={false}
              style={{
                height: 240,
                width: 240,
              }}
              onComplete={() => {
                setShowContinueButton(true);
                if (active) onNextPress();
              }}
            />
            <CText
              value={t("chargerConnected")}
              size={26}
              bold
              style={{
                fontWeight: "600",
                marginHorizontal: horizontalSize(10),
                textAlign: "center",
                marginTop: verticalSize(3),
              }}
            />
          </View>
        )}

        <ScreenBottomBar
          style={{ zIndex: 1, position: "absolute", bottom: 0, width: "100%" }}
        >
          {(!cpConnected || (cpConnected && showContinueButton)) && (
            <CButton
              title={!cpConnected ? t("iHaveConnected") : t("continue")}
              onPress={() => {
                if (
                  selectedChargePointConnector?.status ===
                  ChargePointStatus.Preparing
                ) {
                  onNextPress();
                } else {
                  onNextPress();
                }
              }}
            />
          )}
        </ScreenBottomBar>
      </View>
    </ScreenWrapper>
  );
}
