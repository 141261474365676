import { t } from "i18next";

import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActivityIndicator,
  BackHandler,
  Dimensions,
  FlatList,
  Image,
  Linking,
  RefreshControl,
  ScrollView,
  Share,
  TouchableHighlight,
  View
} from "react-native";
// @ts-ignore
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { isArray, isEmpty } from "lodash";
import { batch, useDispatch } from "react-redux";
import { ReactComponent as BoltIcon } from "../../../assets/icons/bolt.svg";
import { ReactComponent as BookmarkFilledIcon } from "../../../assets/icons/bookmark-filled.svg";
import { ReactComponent as BookmarkOutlinedIcon } from "../../../assets/icons/bookmark-outlined.svg";
import { ANALYTICS, FirebaseRemoteConfigKeys } from "../../../constants";
import SegmentAnalyticsService from "../../../services/SegmentAnalyticsService";
import { ReactComponent as CallIcon } from "../../../assets/icons/call.svg";
import { ReactComponent as CloudAlertIcon } from "../../../assets/icons/cloud-alert.svg";
import { ReactComponent as NavigateIcon } from "../../../assets/icons/navigate.svg";
import { ReactComponent as ScanQRIcon } from "../../../assets/icons/scan-qr.svg";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share_icon.svg";
import { AccessCodeType } from "../../../central-atoms/access-code/enums";
import { AccessibilityType } from "../../../central-atoms/accessibility/enums";
import { ControllabilityType } from "../../../central-atoms/enums/charge-point";
import { MediaType } from "../../../central-atoms/enums/generic/file-upload";
import { ChargePointStatus } from "../../../central-atoms/enums/ocpp/v1.6/generic";
import { MixedChargingStation } from "../../../central-atoms/types/charging/charging-station";
import { MergedConnector } from "../../../central-atoms/types/charging/connectors";
import { COLORS } from "../../../config/colors";
import { Images } from "../../../config/image";
import { APP_WEBSITE_HOST, WHATSAPP_CHAT_LINK } from "../../../config/setting";
import { GLOBAL_VARS, LS_KEYS, RefreshControlLoaderColors, SCREEN_NAMES } from "../../../constants";
import { isFleetWallet, isSharedWallet } from "../../../helpers/walletHelpers";
import { useActiveFleetOperatorOrgUser, useFirebaseRemoteConfigValues, useUsersPhysicalLocation } from "../../../hooks";
import { useActiveWallet } from "../../../hooks/walletHooks";
import FilterRadioButton from "../../../miscellaneous/components/FilterRadioButton";
import ScreenBottomBar from "../../../miscellaneous/components/ScreenBottomBar";
import ScreenHeader from "../../../miscellaneous/components/ScreenHeader";
import SmallBorderedButton from "../../../miscellaneous/components/SmallBorderedButton";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { consoleLog, getGlobalVar, isAndroid, vibrate } from "../../../miscellaneous/helpers/index";
import { useInterval, usePrevious } from "../../../miscellaneous/hooks";
import { HelpInfoMessage } from "../../../miscellaneous/views/miscellaneous";
import AuthenticatedOrgRequestService from "../../../services/AuthenticatedOrgRequestService";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import CButton from "../../components/CButton";
import CText from "../../components/CText";
import { canGetCPAvailability } from "../../../helpers/chargingStationHelper";
import SectionMessage from "../../components/SectionMessage";
import Geolocation from "@react-native-community/geolocation";
import ChargingAction from "../../../actions/chargingActions";
import {
  setSelectedChargePoint,
  setSelectedChargePointConnector,
  setSelectedChargingStation,
  setSelectedMixedChargingStation
} from "../../../actions/doChargingActions";
import { getStoredCSAccessCodeForId } from "../../../helpers/chargingStationHelper";
import { useSelectedChargePoint, useSelectedChargePointConnector } from "../../../hooks";
import { ChargingStationRequestService } from "../../../miscellaneous/services/ChargingStationRequestService";
import { ChargingStationDetailScreenRouteParams } from "../../../types";
import PhonesModal from "../../components/PhonesModal";
import CPConnectorCard from "../PreStartChargingSelectionScreen/components/CpConnectorCard";
import styles from "./styles";
import { verticalSize } from "../../../functions/responsive";
import { ReactComponent as ExclamationMark } from "../../../assets/icons/exclamation_mark.svg";
// import Div100vh from "react-div-100vh";

type ExtendedMergedConnector = MergedConnector & { charge_point: any };

enum FilterType {
  Available = "available",
  InUse = "in-use",
  Unavailable = "unavailable"
}

type Props = {
  navigation: any;
  route: any;
};

export default function ChargingStationDetailScreen(props: Props) {
  // const {
  //     navigation,
  // } = props;

  const {
    chargerIdType = "charging-station",
    isDeepLinked = false,
    chargerId
    // chargerId = '31256'
  } = (props?.route?.params as ChargingStationDetailScreenRouteParams) || {};

  const navigation: any = useNavigation();

  const prevChargerId = usePrevious(chargerId);

  // consoleLog('Route Params', props?.route?.params, chargerId, prevChargerId);

  const dispatch = useDispatch();
  const { setSelectedStation } = ChargingAction;

  // const centralUserProfile = useCentralUserProfile();

  const activeFleetOperatorOrgUser = useActiveFleetOperatorOrgUser();

  const activeWallet = useActiveWallet();

  const [waitForStartCharging, setWaitForStartCharging] = useState(false);
  const [waitForRefresh, setWaitForRefresh] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [mergedChargingStation, setMergedChargingStation] = useState<MixedChargingStation | null>(null);
  const [stationMedia, setStationMedia] = useState<any[]>([]);

  const [shouldContinueCSPolling, setShouldContinueCSPolling] = useState<boolean | null>(null);
  const [waitForCS, setWaitForCS] = useState<boolean>(true);
  const [hasUncontrollableCPs, setHasUncontrollableCPs] = useState<boolean>(false);
  const [waitForSaveUnsaveCS, setWaitForSaveUnsaveCS] = useState<boolean>(false);

  const selectedChargePointConnector = useSelectedChargePointConnector();
  const selectedChargePoint = useSelectedChargePoint();

  const [mergedConnectorsList, setMergedConnectorsList] = useState<ExtendedMergedConnector[]>([]);
  const [filteredMergedConnectorsList, setFilteredMergedConnectorsList] = useState<ExtendedMergedConnector[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<null | string>(FilterType.Available);

  const segmentAnalytics: SegmentAnalyticsService = getGlobalVar(GLOBAL_VARS.SegmentAnalytics);

  const usersPhysicalLocation = useUsersPhysicalLocation();
  const firebaseRemoteConfigValues = useFirebaseRemoteConfigValues();
  const whatsappChatLink =
    firebaseRemoteConfigValues?.[FirebaseRemoteConfigKeys.whatsapp_chat_link] || WHATSAPP_CHAT_LINK;

  const availableFilters = [
    {
      value: FilterType.Available,
      title: t("available")
    },
    {
      value: FilterType.InUse,
      title: t("inUse")
    },
    {
      value: FilterType.Unavailable,
      title: t("unavailable")
    }
  ];

  const appDownloadLink = useMemo(() => {
    return isAndroid()
      ? mergedChargingStation?.central_charging_station?.brand?.additional_props?.android_app_link
      : mergedChargingStation?.central_charging_station?.brand?.additional_props?.ios_app_link;
  }, [mergedChargingStation?.central_charging_station?.brand?.additional_props]);

  const canDoStartCharging = useMemo(() => {
    return mergedChargingStation?.central_charging_station.charge_points.some((cp) => {
      return (
        cp.controllability.includes(ControllabilityType.OCPPControl) ||
        cp.controllability.includes(ControllabilityType.OCPIControl)
      );
    });
  }, [mergedChargingStation?.central_charging_station?.charge_points]);

  const shouldShowAppDownloadBanner = useMemo(() => {
    const hasViewOnlyCP = mergedChargingStation?.central_charging_station.charge_points.some((cp) => {
      return cp.controllability.includes(ControllabilityType.ViewOnly);
    });
    return hasViewOnlyCP && !!appDownloadLink;
  }, [mergedChargingStation?.central_charging_station?.charge_points]);

  const onSaveCS = () => {
    const data = {
      charging_station_id: mergedChargingStation?.charging_station?.id
    };

    setWaitForSaveUnsaveCS(true);
    // TODO: Fix the endpoint later on
    const httpRequest = getGlobalVar(GLOBAL_VARS.httpRequest) || {};
    httpRequest
      .getInstance()
      .post(`/api/charging_station/favorite/${mergedChargingStation?.charging_station?.id}`, data)
      .then((result: any) => {
        setWaitForSaveUnsaveCS(false);
        const response = result.data.data;
        // consoleLog(response);
        if (mergedChargingStation) {
          setMergedChargingStation({
            ...mergedChargingStation,
            charging_station: {
              ...mergedChargingStation.charging_station,
              // ...response.charging_station,
              favorited: true
            }
          });
        }
      })
      .catch((e: any) => {
        setWaitForSaveUnsaveCS(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const onUnsaveCS = () => {
    const data = {
      charging_station_id: mergedChargingStation?.charging_station?.id
    };

    setWaitForSaveUnsaveCS(true);
    const httpRequest = getGlobalVar(GLOBAL_VARS.httpRequest);
    httpRequest
      .getInstance()
      .delete(`/api/charging_station/favorite/${mergedChargingStation?.charging_station?.id}`, { data })
      .then((result: any) => {
        setWaitForSaveUnsaveCS(false);
        // const response = result?.data?.data;
        if (mergedChargingStation) {
          setMergedChargingStation({
            ...mergedChargingStation,
            charging_station: {
              ...mergedChargingStation.charging_station,
              // ...response.charging_station,
              favorited: false
            }
          });
        }
      })
      .catch((e: any) => {
        setWaitForSaveUnsaveCS(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const getChargeStationDetails = (
    id: string | number,
    showLoader = true,
    idType: "charging-station" | "charge-point" = "charging-station"
  ): Promise<void> => {
    // if (!isEmpty(mergedChargingStation) && waitForCS) {
    //     return Promise.resolve();
    // }

    if (showLoader) setWaitForCS(true);

    let req;
    if (idType === "charging-station") {
      req = ChargingStationRequestService.fetchChargingStation(id);
    } else if (idType === "charge-point") {
      req = ChargingStationRequestService.fetchChargingStationByQuery(id);
    } else {
      return Promise.resolve();
    }

    return req
      .then(({ data }) => {
        // consoleLog(data);
        setMergedChargingStation(data);
        setStationMedia(data?.central_charging_station?.medias);
        setMergedConnectorsList(getMergedConnectorsList(data));

        dispatch(setSelectedStation(data) as any);

        if (showLoader) setWaitForCS(false);
      })
      .catch((e: any) => {
        if (showLoader) setWaitForCS(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const onShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Pulse Energy",
          url: `${APP_WEBSITE_HOST}/charging-station/${mergedChargingStation?.charging_station?.id}`
        });
      }
    } catch (error: any) {
      ErrorHandlerService.handleError(error, {
        toastOptions: {
          type: "error"
        }
      });
    }
  };

  const handleOpenDirections = (destinationLatitude, destinationLongitude) => {
    const sourceLatitude = usersPhysicalLocation.latitude;
    const sourceLongitude = usersPhysicalLocation.longitude;

    const source = `${sourceLatitude},${sourceLongitude}`;
    const destination = `${destinationLatitude},${destinationLongitude}`;

    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${source}&destination=${destination}`;

    window.open(googleMapsUrl, "_blank");
  };

  const brandImg = mergedChargingStation?.central_charging_station?.brand?.medias?.find(
    (m: any) => m.type === MediaType.Logo
  );

  const renderImageSlider = ({ item, index, separators }: any) => {
    return (
      <View
        style={{
          height: 200,
          width: Dimensions.get("window").width - 120,
          marginEnd: index === stationMedia.length - 1 ? 32 : 16,
          borderRadius: 8,
          overflow: "hidden"
        }}>
        <Image
          source={{ uri: item?.full_url }}
          style={{
            height: "100%",
            width: "100%",
            borderRadius: 8,
            backgroundColor: COLORS.lightGrey
          }}
          resizeMode="cover"
        />
      </View>
    );
  };

  const getMergedConnectorsList = (mergedCS: MixedChargingStation) => {
    const primaryCSConnectorsMap: { [csConnectorId: string]: any } = {};
    mergedCS?.charging_station?.connectors?.forEach((csConnector) => {
      primaryCSConnectorsMap[csConnector.id] = csConnector;
    });

    const noOCPPControlCPs: string[] = [];

    const _mergedConnectorsList: ExtendedMergedConnector[] = [];
    mergedCS?.central_charging_station?.charge_points?.forEach((cp) => {
      cp.connectors?.forEach((cpConnector) => {
        // set CPs that cannot be controlled
        if (!canGetCPAvailability(cp.controllability)) {
          noOCPPControlCPs.push(cp.id);
        }

        if (cpConnector.primary_connector_id) {
          _mergedConnectorsList.push({
            cp_connector: {
              ...cpConnector,
              charge_point_id: cp.id
            },
            cs_connector: primaryCSConnectorsMap[cpConnector.primary_connector_id],
            charge_point: cp
          });
        }
      });
    });

    setHasUncontrollableCPs(noOCPPControlCPs.length > 0);

    return _mergedConnectorsList;
  };

  const getFilteredConnectorsList = (connectorsList: ExtendedMergedConnector[]): ExtendedMergedConnector[] => {
    if (selectedFilter === FilterType.Available) {
      return connectorsList.filter(
        (mergedConnector) =>
          mergedConnector.cp_connector.status === ChargePointStatus.Available ||
          mergedConnector.cp_connector.status === ChargePointStatus.Preparing
      );
    } else if (selectedFilter === FilterType.InUse) {
      return connectorsList.filter(
        (mergedConnector) =>
          mergedConnector.cp_connector.status === ChargePointStatus.Charging ||
          mergedConnector.cp_connector.status === ChargePointStatus.Finishing
      );
    } else if (selectedFilter === FilterType.Unavailable) {
      return connectorsList.filter(
        (mergedConnector) =>
          mergedConnector.cp_connector.status === ChargePointStatus.Unavailable ||
          mergedConnector.cp_connector.status === ChargePointStatus.SuspendedEV ||
          mergedConnector.cp_connector.status === ChargePointStatus.SuspendedEVSE ||
          mergedConnector.cp_connector.status === ChargePointStatus.Faulted ||
          mergedConnector.cp_connector.status === ChargePointStatus.Reserved
      );
    } else {
      return connectorsList;
    }
  };

  const onRefresh = () => {
    setWaitForRefresh(true);
    getChargeStationDetails(chargerId, false, chargerIdType)
      .then(() => {
        setTimeout(() => {
          setWaitForRefresh(false);
        }, 5000);
      })
      .catch(() => {
        setWaitForRefresh(false);
      });
  };

  // const getChargePointByQuery = (chargerID: string | number) => {
  //     // setWaitForChargingStation(true);
  //     return ChargePointRequestService.fetchChargePointByQuery({ q: chargerID })
  //         .then(({ data }) => {
  //             const chargePoint = data.charge_points.find(cp => cp.qr_code_id === chargerID || cp.public_uid === chargerID) ?? data.charge_points[0];
  //             batch(() => {
  //                 dispatch(setSelectedChargingStation(data));
  //                 if (chargePoint) {
  //                     dispatch(setSelectedChargePoint(chargePoint));
  //                 }
  //             });

  //             // setWaitForChargingStation(false);
  //             return data;
  //         })
  //         .catch((e: any) => {
  //             // setWaitForChargingStation(false);
  //             ErrorHandlerService.handleError(e);
  //             // ErrorHandlerService.handleError(e, {
  //             //     lang: selectedLanguage,
  //             // });
  //         });
  // }

  const handleOpenAppDownloadLink = () => {
    if (!appDownloadLink) return;

    vibrate();
    Linking.openURL(appDownloadLink)
      .then(() => {
        // pass
      })
      .catch((e) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
        // showToast(`Could not open WhatsApp. Please contact us on this number: ${WHATSAPP_BUSINESS_NUMBER}`, {
        //     type: 'info',
        // });
      });
  };

  const goToStartChargingScreen = async (shouldPromptForOrgVerification: boolean) => {
    const cpId = selectedChargePointConnector?.charge_point_id;
    const cp = mergedChargingStation?.central_charging_station?.charge_points?.find((cp) => cp.id === cpId);

    if (cp) {
      // await getChargePointByQuery(cp?.qr_code_id);

      batch(() => {
        dispatch(setSelectedChargingStation(mergedChargingStation?.charging_station ?? null));
        dispatch(setSelectedMixedChargingStation(mergedChargingStation ?? null));
        // consoleLog('goToStartChargingScreen', selectedChargePointConnector, mergedChargingStation, cp);
        dispatch(setSelectedChargePoint(cp));
      });

      navigation.navigate(
        SCREEN_NAMES.PreStartChargingSelectionScreen as never,
        {
          slideIndex: 2,
          shouldPromptForOrgVerification: shouldPromptForOrgVerification
        } as never
      );
    } else {
      navigation.navigate(
        SCREEN_NAMES.PreStartChargingSelectionScreen as never,
        {
          // slideIndex: 2,
          shouldPromptForOrgVerification: shouldPromptForOrgVerification
        } as never
      );
    }
  };

  const handleStartCharging = async () => {
    try {
      setWaitForStartCharging(true);

      // const enteredFleetCode = await AsyncStorage.getItem(LS_KEYS.enteredFleetCode);
      const enteredAccessCode = await AsyncStorage.getItem(LS_KEYS.enteredAccessCode);
      // const paymentMode = await AsyncStorage.getItem(LS_KEYS.paymentMode);
      // consoleLog('paymentMode', paymentMode);

      if (selectedChargePoint?.accessibility === AccessibilityType.Private) {
        const storedCSAccessCode = await getStoredCSAccessCodeForId(
          `${mergedChargingStation?.central_charging_station?.id}`
        );

        if (storedCSAccessCode) {
          try {
            if (storedCSAccessCode) {
              // already exists, quietly verify

              await AuthenticatedOrgRequestService.verifyAccessCode({
                access_code: storedCSAccessCode,
                code_type: AccessCodeType.ChargingStationAccessCode,
                charging_station_id: `${mergedChargingStation?.central_charging_station?.id}`
              });

              goToStartChargingScreen(false);
            } else {
              goToStartChargingScreen(true);
            }
          } catch (e) {
            ErrorHandlerService.handleError(e, {
              openToast: false
            });

            goToStartChargingScreen(true);
            setWaitForStartCharging(false);
          }
        } else {
          goToStartChargingScreen(true);
        }
      } else {
        if (isFleetWallet(activeWallet) || isSharedWallet(activeWallet)) {
          if (enteredAccessCode) {
            // await AuthenticatedOrgRequestService.verifyOperatorCode({
            //     // phone: centralUserProfile?.phone,
            //     org_id: activeFleetOperatorOrgUser.org_id,
            //     operator_code: enteredFleetCode,
            // });

            await AuthenticatedOrgRequestService.verifyAccessCode({
              access_code: enteredAccessCode,
              org_id: activeFleetOperatorOrgUser.org_id
            });

            goToStartChargingScreen(false);
          } else {
            goToStartChargingScreen(true);
          }
        } else {
          goToStartChargingScreen(false);
        }
      }

      setWaitForStartCharging(false);
    } catch (e) {
      setTimeout(() => {
        goToStartChargingScreen(true);
        setWaitForStartCharging(false);
      }, 2000);

      ErrorHandlerService.handleError(e, {
        openToast: false
      });
    }
  };

  const backAction = () => {
    if (navigation.canGoBack()) navigation.goBack();
    else if (isDeepLinked) navigation.navigate(SCREEN_NAMES.MapScreen as never);
    else navigation.goBack();

    return true;
  };

  useEffect(() => {
    setFilteredMergedConnectorsList(getFilteredConnectorsList(mergedConnectorsList));
  }, [selectedFilter, mergedConnectorsList]);

  useEffect(() => {
    // initial load
    consoleLog("initial load");
    getChargeStationDetails(chargerId, true, chargerIdType);

    // initiateCSLongPolling();

    // const backHandler = BackHandler.addEventListener(
    //   "hardwareBackPress",
    //   backAction
    // );

    // return () => {
    //   backHandler.remove();
    //   dispatch(setSelectedChargePointConnector(null));
    // };
  }, []);

  // long poll for CP connections state
  useInterval(
    () => {
      consoleLog("useInterval", prevChargerId, chargerId, chargerIdType);

      if (mergedChargingStation) getChargeStationDetails(chargerId, false, chargerIdType);
    },
    !shouldContinueCSPolling || chargerId !== prevChargerId ? null : 7500
  );

  useEffect(() => {
    consoleLog("Fetching charger", prevChargerId, chargerId, chargerIdType);

    if (prevChargerId !== chargerId && !isEmpty(chargerId)) {
      consoleLog("fetching...");
      setMergedChargingStation(null);
      getChargeStationDetails(chargerId, true, chargerIdType);
    }
  }, [prevChargerId, chargerId]);

  // for setting/clearing all intervals when the screen is focused/exited
  useFocusEffect(
    useCallback(() => {
      consoleLog("useFocusEffect getChargeStationDetails", prevChargerId, chargerId, chargerIdType);

      if (!isEmpty(mergedChargingStation) && !isEmpty(chargerId)) {
        consoleLog("useFocusEffect");
        getChargeStationDetails(chargerId, true, chargerIdType);
      }
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      setShouldContinueCSPolling(true);

      return () => {
        setShouldContinueCSPolling(false);
      };
    }, [])
  );

  const createNavigationURL = async (lat, lng) => {
    try {
      const scheme = `https://www.google.com/maps/search/?api=1&query=`;
      const latLng = `${lat},${lng}`;
      const url = `${scheme}${latLng}`;
      url && Linking.openURL(url);
    } catch (error: any) {
      ErrorHandlerService.handleError(error, {
        toastOptions: {
          type: "error"
        }
      });
    }
  };

  const handleReportIssue = () => {
    let msg = `I want to report an issue `;
    Linking.openURL(`${whatsappChatLink}?text=${msg}`)
      .then(() => {
        // pass
      })
      .catch((e) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
        // showToast(`Could not open WhatsApp. Please contact us on this number: ${WHATSAPP_BUSINESS_NUMBER}`, {
        //     type: 'info',
        // });
      });
  };

  return (
    // <Div100vh>
    <View style={styles.root}>
      <ScreenHeader
        navigation={navigation}
        onBackPress={backAction}
        style={{ position: "sticky" as any, top: 0, left: 0, right: 0, zIndex: 100 }}
        rightComponent={
          <>
            {!!brandImg && (
              <Image
                source={{
                  uri: brandImg.full_url
                }}
                style={styles.iconStyle}
                resizeMode="cover"
              />
            )}
          </>
        }
      />

      <ScrollView
        showsVerticalScrollIndicator={false}
        // contentContainerStyle={{ height: verticalSize(82) }}
        refreshControl={
          <RefreshControl colors={RefreshControlLoaderColors} refreshing={waitForRefresh} onRefresh={onRefresh} />
        }>
        {/* loading... */}
        {waitForCS && isEmpty(mergedChargingStation) && (
          <View
            style={{
              height: verticalSize(100),
              alignItems: "center",
              justifyContent: "center"
            }}>
            <ActivityIndicator size={40} color={COLORS.darkBlack} />
            <CText semiBold size={18} style={{ marginTop: 10 }}>
              {t("Loading...")}
            </CText>
          </View>
        )}

        {/* not found */}
        {!waitForCS && isEmpty(mergedChargingStation) && (
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center"
            }}>
            <SectionMessage message={t("ChargingStationNotFound")} />
          </View>
        )}

        {/* charging station */}
        {!isEmpty(mergedChargingStation) && (
          <View style={styles.cont}>
            {/* top detailed card */}
            <View style={styles.topDetailCont}>
              {!!stationMedia.length && (
                <FlatList
                  data={stationMedia}
                  keyExtractor={(item, index) => item.id ?? index}
                  renderItem={renderImageSlider}
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  style={{
                    marginHorizontal: -16,
                    marginBottom: 16
                  }}
                  contentContainerStyle={{
                    marginHorizontal: 16
                  }}
                />
              )}
              <CText
                value={(mergedChargingStation as any)?.charging_station?.name}
                size={18}
                bold
                style={{ fontWeight: "700" }}
              />

              <CText
                value={(mergedChargingStation as any)?.charging_station?.address}
                size={12}
                style={{ color: COLORS.greyText }}
              />

              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{
                  paddingBottom: 10
                }}
                style={{
                  marginTop: 16,
                  marginBottom: -8,
                  marginHorizontal: -16
                }}>
                <SmallBorderedButton
                  disabled={waitForSaveUnsaveCS}
                  iconSvg={
                    mergedChargingStation?.charging_station?.favorited ? (
                      <BookmarkFilledIcon height={16} width={14} />
                    ) : (
                      <BookmarkOutlinedIcon height={16} width={14} />
                    )
                  }
                  title={mergedChargingStation?.charging_station?.favorited ? t("saved") : t("save")}
                  style={{ marginHorizontal: 16 }}
                  onPress={() => {
                    if (mergedChargingStation?.charging_station?.favorited) onUnsaveCS();
                    else onSaveCS();
                  }}
                />
                <SmallBorderedButton
                  iconSvg={<ShareIcon height={18} width={16} />}
                  title={t("share")}
                  style={{ marginEnd: 16 }}
                  onPress={onShare}
                />
                <SmallBorderedButton
                  iconSvg={<ExclamationMark height={18} width={16} />}
                  title={t("Report issue")}
                  style={{ marginEnd: 16 }}
                  onPress={handleReportIssue}
                />
                <SmallBorderedButton
                  iconSvg={<NavigateIcon width={14} height={14} />}
                  title={t("navigate")}
                  style={{ marginEnd: 16 }}
                  onPress={() => {
                    handleOpenDirections(
                      mergedChargingStation?.charging_station?.latitude,
                      mergedChargingStation?.charging_station?.longitude
                    );
                  }}
                />
                {/* {!isEmpty(mergedChargingStation?.charging_station?.phones) &&
                  isArray(mergedChargingStation?.charging_station?.phones) && (
                    <SmallBorderedButton
                      iconSvg={<CallIcon height={18} width={16} />}
                      title={t('call')}
                      style={{ marginEnd: 16 }}
                      onPress={() => setShowPhoneModal(true)}
                    />
                  )} */}
              </ScrollView>
            </View>

            {/* no real time status */}
            {hasUncontrollableCPs && (
              <View
                style={{
                  ...StyleHelpers.verticallyCenteredRow,
                  ...StyleHelpers.themeBorder,
                  backgroundColor: COLORS.cyanColor,
                  marginTop: 20
                }}>
                <View
                  style={{
                    ...StyleHelpers.totalCenter,
                    backgroundColor: COLORS.whiteColor,
                    borderRadius: 50,
                    width: 40,
                    height: 40,
                    margin: 16
                  }}>
                  <CloudAlertIcon fontSize={22} fill={COLORS.darkBlack} />
                </View>
                <CText size={12} semiBold style={{ flex: 1, lineHeight: 22, paddingRight: 20 }}>
                  {t("realtimeStatusNotAvailableMsg")}
                </CText>
              </View>
            )}

            {/* App download banner */}
            {shouldShowAppDownloadBanner && (
              <TouchableHighlight
                underlayColor={COLORS.cyanColor2}
                activeOpacity={1}
                style={{
                  backgroundColor: COLORS.cyanColor,
                  width: "100%",
                  paddingVertical: 12,
                  paddingHorizontal: 16,
                  marginVertical: 20,
                  ...StyleHelpers.themeBorder
                }}
                onPress={handleOpenAppDownloadLink}>
                <View
                  style={{
                    ...StyleHelpers.verticallyCenteredRow
                  }}>
                  <View
                    style={{
                      flex: 0.8
                    }}>
                    <CText semiBold={true} size={14}>
                      {t("DownloadTheApp").replace(
                        " [[APP_NAME]]",
                        mergedChargingStation?.charging_station?.brand
                          ? ` ${mergedChargingStation?.charging_station?.brand}`
                          : ""
                      )}
                    </CText>
                    <Image
                      source={{
                        uri: isAndroid() ? Images.android_app_download_badge : Images.ios_app_download_badge
                      }}
                      resizeMethod="resize"
                      resizeMode="contain"
                      style={{
                        height: 40,
                        width: 110,
                        marginTop: 20
                      }}
                    />
                  </View>
                  <View
                    style={{
                      flex: 0.2
                    }}>
                    <Image
                      source={{
                        uri: Images.phone_frame
                      }}
                      resizeMethod="resize"
                      resizeMode="contain"
                      style={{
                        height: 120,
                        width: "100%"
                      }}
                    />
                    {!!brandImg && (
                      <View
                        style={{
                          ...StyleHelpers.totalCenter,
                          position: "absolute",
                          top: 0,
                          width: "100%",
                          height: 120
                        }}>
                        <Image
                          source={{
                            uri: brandImg.full_url
                          }}
                          style={styles.iconStyle}
                          resizeMode="cover"
                        />
                      </View>
                    )}
                  </View>
                </View>
              </TouchableHighlight>
            )}

            {/* success bar */}
            {/* TODO: check this */}
            {/* {
                                  // mergedChargingStation?.central_charging_station?.last_used_time &&
                                  <View style={styles.successCont}>
                                      <View style={styles.thumbCont}>
                                          <Image
                                              source={Images.thumb_up}
                                              style={{
                                                  height: 20,
                                                  width: 20,
                                              }}
                                              resizeMode="contain"
                                          />
                                      </View>
                                      <CText
                                          value={`${t('successfulCharge')} - ${moment(
                                              tempChargerData?.central_charging_station
                                                  ?.last_used_time,
                                          ).fromNow()}`}
                                          size={12}
                                          style={{
                                              color: COLORS.whiteColor,
                                              marginStart: 16,
                                              marginTop: 4,
                                          }}
                                          semiBold
                                      />
                                  </View>
                              } */}

            <CText value={t("chargers")} size={14} semiBold style={{ marginTop: 30, fontWeight: "700" }} />
            <View style={[styles.rowStyle, { marginVertical: 16 }]}>
              <ScrollView horizontal showsHorizontalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1 }}>
                {availableFilters.map((item, index) => {
                  const isSelected = selectedFilter === item.value;
                  return (
                    <View
                      key={item.title}
                      style={{
                        flex: 1,
                        marginHorizontal: index == 1 ? 12 : 0
                      }}>
                      <FilterRadioButton
                        title={item.title}
                        checked={isSelected}
                        onPress={() => {
                          if (isSelected) {
                            setSelectedFilter(null);
                          } else {
                            setSelectedFilter(item.value);
                          }
                        }}
                      />
                    </View>
                  );
                })}
              </ScrollView>
            </View>

            {/* connector msg */}
            {(!selectedFilter || selectedFilter === FilterType.InUse) && !!filteredMergedConnectorsList.length && (
              <HelpInfoMessage
                message={
                  <CText
                    size={10}
                    style={{
                      color: COLORS.grey700
                    }}>
                    {t("ReconnectConnectorGun")}
                  </CText>
                }
                style={{
                  // marginHorizontal: 16,
                  marginVertical: 16
                }}
              />
            )}

            {/* connectors list */}
            {!!filteredMergedConnectorsList.length && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "stretch",
                  position: "relative"
                }}>
                {filteredMergedConnectorsList.map((mergedConnector, index) => {
                  const isSelectable = mergedConnector.charge_point.controllability.some(
                    (c: ControllabilityType) =>
                      c === ControllabilityType.OCPPControl || c === ControllabilityType.OCPIControl
                  );
                  const showStatus = canGetCPAvailability(mergedConnector.charge_point.controllability);

                  return (
                    <View
                      key={mergedConnector.cp_connector.id}
                      style={{
                        width: "50%",
                        paddingEnd: index % 2 == 0 ? 8 : 0,
                        paddingStart: index % 2 == 0 ? 0 : 8,
                        marginTop: 10,
                        marginBottom: 10,
                        flexDirection: "row",
                        alignSelf: "stretch"
                      }}>
                      <CPConnectorCard
                        unifiedCPConnector={{
                          cp_connector: mergedConnector.cp_connector,
                          cs_connector: mergedConnector.cs_connector
                        }}
                        chargePoint={mergedConnector.charge_point}
                        name={mergedConnector.charge_point?.name}
                        showStatus={showStatus}
                        style={{
                          width: "100%"
                        }}
                        isSelectable={isSelectable}
                        selected={selectedChargePointConnector?.id === mergedConnector.cp_connector.id}
                        onPress={() => {
                          if (selectedChargePointConnector?.id === mergedConnector.cp_connector.id) {
                            dispatch(setSelectedChargePointConnector(null));
                            dispatch(setSelectedChargePoint(null));
                          } else {
                            dispatch(setSelectedChargePointConnector(mergedConnector.cp_connector));
                            dispatch(setSelectedChargePoint(mergedConnector.charge_point));
                          }
                        }}
                      />
                    </View>
                  );
                })}
              </View>
            )}

            {/* no filtered connectors */}
            {!filteredMergedConnectorsList.length && (
              <View
                style={{
                  marginVertical: 40
                }}>
                <CText
                  size={15}
                  semiBold
                  style={{
                    textAlign: "center",
                    color: COLORS.darkBlack,
                    fontWeight: "600"
                  }}>
                  {selectedFilter === null && t("noConnectors")}
                  {selectedFilter === FilterType.Available && t("noConnectorsAvailable")}
                  {selectedFilter === FilterType.InUse && t("noConnectorsInUse")}
                  {selectedFilter === FilterType.Unavailable && t("noConnectorsUnavailable")}
                </CText>
              </View>
            )}
          </View>
        )}
      </ScrollView>

      {canDoStartCharging && (
        <ScreenBottomBar
          style={{
            borderTopWidth: 2,
            backgroundColor: COLORS.whiteColor,
            borderTopColor: THEME_COLORS.primaryBorder,
            position: "sticky" as any,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100
          }}>
          {/* <View style={styles.bottomCont}> */}
          {!isEmpty(selectedChargePointConnector) ? (
            <CButton
              disable={waitForStartCharging}
              loading={waitForStartCharging}
              textStyle={{ color: COLORS.whiteColor }}
              loadingText={t("Processing")}
              title={`${t("startCharging")}`}
              icon={() => {
                return (
                  <BoltIcon
                    fill={COLORS.whiteColor}
                    style={{
                      height: 20,
                      width: 20,
                      marginRight: 8
                    }}
                  />
                );
              }}
              onPress={handleStartCharging}
            />
          ) : (
            <CButton
              disable={mergedConnectorsList.length === 0}
              textStyle={{ color: COLORS.whiteColor }}
              title={`${t("scanQRCode")}`}
              icon={() => {
                return (
                  <ScanQRIcon
                    fill={COLORS.whiteColor}
                    style={{
                      height: 24,
                      width: 24,
                      marginRight: 8
                    }}
                  />
                );
              }}
              onPress={() => {
                navigation.navigate(
                  SCREEN_NAMES.ChargePointQRScannerScreen as never,
                  {
                    stationID: mergedChargingStation?.charging_station?.id
                  } as never
                );

                // segmentAnalytics.track(ANALYTICS.eventNames.anypage_anyingress_scanqrcodeclicked, {
                //     [ANALYTICS.properties.source]: 'csdetail_screen',
                // });
              }}
            />
          )}
          {/* </View> */}
        </ScreenBottomBar>
      )}

      <PhonesModal
        onClose={() => setShowPhoneModal(false)}
        isOpen={showPhoneModal}
        phones={
          mergedChargingStation?.charging_station?.phones?.map((p) => ({
            phone: p
          })) ?? []
        }
        modalProps={{
          onBackdropPress: () => setShowPhoneModal(false),
          onBackButtonPress: () => setShowPhoneModal(false)
        }}
      />
    </View>
    // </Div100vh>
  );
}
