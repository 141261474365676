import { StyleSheet } from 'react-native';
import { THEME_COLORS } from '../../../theme/constants/themeColors';
import { COLORS } from '../../../config/colors';
export default StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: THEME_COLORS.bgColor,
  },
  rowStyle: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerCont: {
    padding: 16,
    flexDirection: 'row',
    backgroundColor: COLORS.whiteColor,
    position: 'sticky' as any,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100
  },
  emptyIconCont: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 84,
    width: 84,
    backgroundColor: COLORS.blackColor,
    borderRadius: 100,
    marginBottom: 16
  }
});
