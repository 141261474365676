import React, { ReactElement } from "react";
import { TouchableOpacity, ViewStyle } from "react-native";
import { COLORS } from "../../../config/colors";
import styles from "./styles";

type Props = {
  children?: ReactElement;
  onPress?: () => void;
  style?: ViewStyle;
  disabled?: boolean;
};
export default function DashedLink(props: Props) {
  const { children, disabled = false, onPress, style = {} } = props;

  return (
    <TouchableOpacity
      disabled={disabled}
      activeOpacity={0.8}
      onPress={onPress}
      style={{
        borderBottomColor: disabled ? COLORS.grey600 : COLORS.blackColor,
        opacity: disabled ? 0.7 : 1,
        ...styles.container,
        ...style,
      }}
    >
      {children}
    </TouchableOpacity>
  );
}
