import { useSelector } from 'react-redux';
import {
  APP_STATE,
  CSFiltersState,
} from '../types';
import { EvaluatedAppUpdate } from '../types';
import { RemoteConfigValues } from '../types';
import { UserPreferences } from '../central-atoms/users/types';
import { CentralUser, PrimaryUser } from '../central-atoms/types/user';
import { ChargingStation, MiniUCS, MixedChargingStation } from '../central-atoms/types/charging/charging-station';
import { ChargeTransaction } from '../central-atoms/types/charging/charge-txns';
import { FiltersService } from '../services/FilterService';
import { Wallet } from '../central-atoms/types/wallet';
import { AppliedFilters } from '../central-atoms/filters/types';
import { ChargePoint, ChargePointConnector } from '../central-atoms/types/charging/charge-points';
import { LangCodes } from '../central-atoms/enums/generic/i18n/LangCodes';
import { OrgUser } from '../central-atoms/types/org-user';
import { PaymentMethodType } from '../central-atoms/payment-methods/types/enum';
import { SharedWallet } from '../central-atoms/wallets/types';
import { Coords } from '../central-atoms/types';

export const useAppState = (): string => {
  return useSelector((state: APP_STATE) => state.main.app.appState);
};

export const useAppInitialized = (): boolean => {
  return useSelector((state: APP_STATE) => state.main.app.appInitialized);
};

export const useUserAuthenticated = (): boolean => {
  return useSelector((state: APP_STATE) => state.main.app.userAuthenticated);
};

export const useLocationPermissionEnabled = (): boolean => {
  return useSelector((state: APP_STATE) => state.main.app.locPermissionEnabled);
};

export const useGPSEnabled = (): boolean => {
  return useSelector((state: APP_STATE) => state.main.app.gpsEnabled);
};

export const useUsersPhysicalLocation = (): Coords => {
  return useSelector((state: APP_STATE) => state.main.app.usersPhysicalLocation);
};

export const useMapCoords = (): Coords & { radius: number } => {
  return useSelector((state: APP_STATE) => state.main.app.mapCoords);
};

export const useHomeOtherChargingStationsPagination = () => {
  return useSelector((state: APP_STATE) => state.chargingStation.app?.homeOtherChargingStationsPagination);
};

export const useAllowedCPQrCodePatterns = (): string[] => {
  return useSelector((state: APP_STATE) => state.main.app?.allowedCPQrCodePatterns);
};

export const useFirebaseRemoteConfigValues = (key?: string): RemoteConfigValues | any => {
  const remoteConfigValues = useSelector((state: APP_STATE) => state.main.app.remoteConfigValues);
  if (key) return remoteConfigValues?.[key];
  return remoteConfigValues;
};

export const useEvaluatedAppUpdate = (): EvaluatedAppUpdate => {
  return useSelector((state: APP_STATE) => state.main.app?.evaluatedAppUpdate);
};

export const useShowACChargersOnly = (): boolean | null => {
  return useSelector((state: APP_STATE) => state.main.app?.showACChargersOnly);
};

export const useShouldUpdateUserProfile = (): boolean | null => {
  return useSelector((state: APP_STATE) => state.main.app?.shouldUpdateUserProfile);
};

export const useIsPartialLogin = (): boolean => {
  return useSelector((state: APP_STATE) => state.auth.partialLogin);
};

export const useCentralUserProfile = (): CentralUser => {
  return useSelector((state: APP_STATE) => state.auth.centralUserData);
};

export const useSelectedLanguage = (): LangCodes => {
  const currentLanguage = useSelector((state: APP_STATE) => state.auth.currentLanguage);
  return currentLanguage;
};

export function useAppliedFilters(): AppliedFilters {
  return useSelector((state: APP_STATE) => state.filters.app.appliedFilters);
}

export function useAppliedFiltersCount(): number {
  const appliedFilters = useAppliedFilters();
  return FiltersService.getTotalAppliedFilters(appliedFilters);
}

export const useActiveFleetOperatorOrgUser = (): OrgUser => {
  return useSelector((state: APP_STATE) => state.auth.activeFleetOperatorOrgUser);
};

export const useAuthToken = (): string | null | undefined => {
  const token = useSelector((state: APP_STATE) => state.auth.token);
  return token;
};

export const useStoredUserPreferences = (): UserPreferences => {
  return useSelector((state: APP_STATE) => state.auth.centralUserData.preferences);
};

export const usePrimaryUserProfile = (): PrimaryUser => {
  return useSelector((state: APP_STATE) => state.auth.userData);
};

export function useTrackedNearestChargingStation(): MixedChargingStation | undefined {
  return useSelector((state: APP_STATE) => state.chargingStation.app?.trackedNearestChargingStation);
}

export function useLastChargeTransaction(): ChargeTransaction | undefined {
  return useSelector((state: APP_STATE) => state.chargingStation.app?.lastChargeTransaction);
}

export function useMasterUCSMap(): { [key: string]: MixedChargingStation } {
  return useSelector((state: APP_STATE) => state.chargingStation.data?.masterUCSMap);
}

export function useNearestUCSMiniObject(): MiniUCS | null {
  return useSelector((state: APP_STATE) => state.chargingStation.data?.nearestUCSMiniObject);
}

export function useOtherNearbyUCSMiniObjectsList(): MiniUCS[] {
  return useSelector((state: APP_STATE) => state.chargingStation.data?.otherNearbyUCSMiniObjectsList);
}

// maps
export function useMapsNearbyUCS(): { [key: string]: MiniUCS } {
  return useSelector((state: APP_STATE) => state.chargingStation.data?.mapsNearbyUCS);
}

//charging stations
export const useSelectedChargingStation = (): ChargingStation | undefined => {
  return useSelector((state: APP_STATE) => state.doCharging.app.selectedChargingStation);
};

export const useSelectedMixedChargingStation = (): MixedChargingStation | undefined => {
  return useSelector((state: APP_STATE) => state.doCharging.app.selectedMixedChargingStation);
};

export const useTrackedCPErrorConnector = (): ChargePointConnector | null => {
  return useSelector((state: APP_STATE) => state.doCharging.app.trackedCPErrorConnector);
};

export const useTrackedCPConnectionError = (): boolean | null => {
  return useSelector((state: APP_STATE) => state.doCharging.app.trackedCPConnectionError);
};

export const useSelectedChargePoint = (): ChargePoint | undefined => {
  return useSelector((state: APP_STATE) => state.doCharging.app.selectedChargePoint);
};

export const useSelectedChargePointConnector = (): ChargePointConnector | undefined => {
  return useSelector((state: APP_STATE) => state.doCharging.app.selectedChargePointConnector);
};

export function useAppliedFiltersChangeVersion(): string {
  return useSelector((state: APP_STATE) => state.filters.app.appliedFiltersChangeVersion);
}

export const useActivePaymentMethodType = (): PaymentMethodType | null => {
  return useSelector((state: APP_STATE) => state.wallet.data.activePaymentMethodType);
};

export const useActiveWallet = (): Wallet | null => {
  return useSelector((state: APP_STATE) => state.wallet.data.activeWallet);
};

export const useFleetWallets = (): Wallet[] => {
  return useSelector((state: APP_STATE) => state.wallet.data.fleetWallets);
};

export const useSharedWallets = (): Wallet[] => {
  return useSelector((state: APP_STATE) => state.wallet.data.sharedWallets);
};

export const usePersonalWallets = (): Wallet[] => {
  return useSelector((state: APP_STATE) => state.wallet.data.personalWallets);
};

export const useWalletConsumers = (walletId: string): SharedWallet[] => {
  // if (!walletId) return [];
  return useSelector((state: APP_STATE) => state.wallet?.data?.walletConsumers?.[`${walletId}`] ?? []);
};

export function useCSFilters(): CSFiltersState {
  return useSelector((state: APP_STATE) => state.filters.data.csFilters);
}

export function useAppliedFiltersGroupCount(): {[key: string]: number} {
  const appliedFilters = useAppliedFilters();
  return FiltersService.getAppliedFiltersGroupCount(appliedFilters);
}


export function useCustomFiltersApplied(): boolean {
  return useSelector((state: APP_STATE) => state.filters.app.customFiltersApplied);
}

export function useConnectorTypesList(): string[] {
  return useSelector((state: APP_STATE) => state.filters.app.connectorTypesList);
}
