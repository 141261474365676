import { Coords } from "../../../central-atoms/types";
import { CentralUser } from "../../../central-atoms/types/user";

const actions = {
    SET_USERDATA: 'auth/SET_USERDATA',
    SET_CENTRAL_USER_DATA: 'auth/SET_CENTRAL_USER_DATA',
    SET_LANGUAGE: 'auth/SET_LANGUAGE',
    SET_TOKEN: 'auth/SET_TOKEN',
    SET_FCMTOKEN: 'auth/SET_FCMTOKEN',
    SET_CURRENTLOCATION: 'auth/SET_CURRENTLOCATION',
    SET_PARTIAL_LOGIN: 'auth/SET_PARTIAL_LOGIN',
    SET_ACTIVE_FLEET_OPERATOR_ORG_USER: 'auth/SET_ACTIVE_FLEET_OPERATOR_ORG_USER',
    LOGOUT: 'auth/LOGOUT',

    setUserData: (userData: any) => ({
        type: actions.SET_USERDATA,
        userData,
    }),

    setActiveFleetOperatorOrgUser: (activeFleetOperatorOrgUser: any) => ({
        type: actions.SET_ACTIVE_FLEET_OPERATOR_ORG_USER,
        activeFleetOperatorOrgUser,
    }),

    setPartialLogin: (partialLogin: boolean | null) => ({
        type: actions.SET_PARTIAL_LOGIN,
        partialLogin,
    }),

    setCentralUserData: (centralUserData: CentralUser) => ({
        type: actions.SET_CENTRAL_USER_DATA,
        centralUserData,
    }),

    setToken: (token: string) => ({
        type: actions.SET_TOKEN,
        token,
    }),

    // setFcmToken: fcmToken => ({
    //     type: actions.SET_FCMTOKEN,
    //     fcmToken,
    // }),

    setCurrentLanguage: (currentLanguage: string) => ({
        type: actions.SET_LANGUAGE,
        currentLanguage,
    }),

    setCurrentLocation: (currentLocation: Coords) => ({
        type: actions.SET_CURRENTLOCATION,
        currentLocation,
    }),

    logout: () => ({
        type: actions.LOGOUT,
    }),
};

export default actions;
