import { encode as btoa } from "base-64";
import { ControllabilityType } from "../central-atoms/enums/charge-point";
import { ChargePoint } from "../central-atoms/types/charging/charge-points";

export default class ChargingStationService {
    static getExclusionList(csIds: Array<number | string>): string {
        return btoa(Array.from(new Set(csIds)).join(','));
    }

    static getControllableCPs(cps: ChargePoint[]): ChargePoint[] {
        return cps?.filter((cp: ChargePoint) => (
            cp?.controllability?.includes(ControllabilityType.OCPPControl) || 
            cp?.controllability?.includes(ControllabilityType.OCPIControl) || 
            cp?.controllability?.includes(ControllabilityType.APIControl)
        ));
    }
}
