import { StyleSheet } from "react-native";
import { COLORS } from "../../../config/colors";
import { FontFamily } from "../../../config/typography";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { verticalSize } from "../../../functions/responsive";

export default StyleSheet.create({
  root: {
    flexDirection: "column",
    backgroundColor: THEME_COLORS.bgColor,
    justifyContent: "space-between",
    height: verticalSize(100),
    position: "relative"
  },
  cellStyle: {
    borderRadius: 8,
    backgroundColor: COLORS.whiteColor,
    borderWidth: 1,
    borderColor: COLORS.darkBlack,
    borderBottomColor: COLORS.blackColor,
    paddingTop: 4
  },
  textStyle: {
    fontSize: 17,
    fontWeight: "bold",
    marginTop: -5,
    color: COLORS.blackColor,
    fontFamily: FontFamily.Poppins_Regular
  },
  cellStyleFocused: {
    borderWidth: 3,
    borderColor: COLORS.darkBlack
  },
  rowStyle: {
    flexDirection: "row",
    alignItems: "flex-start"
  }
});
