import { Text, TextProps, TextStyle } from "react-native";
import { COLORS } from "../../../config/colors";
import { FontFamily } from "../../../config/typography";
import { isIOS } from "../../../helpers";

type Props = {
  value?: any;
  style?: TextStyle;
  color?: string;
  semiBold?: boolean;
  bold?: boolean;
  medium?: boolean;
  size?: number;
  textProps?: TextProps;
  children?: any;
  fontWeight?: string;
};
export default function CText(props: Props) {
  const {
    value,
    style,
    semiBold,
    bold,
    medium,
    size = 16,
    textProps = {},
    children,
    color = COLORS.blackColor
  } = props;

  const fontFamily = bold
    ? FontFamily.Poppins_Bold
    : semiBold
    ? FontFamily.Poppins_SemiBold
    : medium
    ? FontFamily.Poppins_Medium
    : FontFamily.Poppins_Regular;

  return (
    <>
      <Text
        style={[
          {
            fontFamily: fontFamily,
            fontSize: size,
            color: color,
            marginTop: isIOS() ? 0 : 2,
            marginBottom: isIOS() ? 1 : 0
          },
          style
        ]}
        {...textProps}>
        {children ?? value}
      </Text>
    </>
  );
}
