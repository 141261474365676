/* eslint-disable no-console */
import types from './actions';

const initialState = {
    userData: {},
    centralUserData: {},
    currentLanguage: 'en',
    token: '',
    fcmToken: '',
    currentLocation: {},
    partialLogin: false,
    activeFleetOperatorOrgUser: {},
};

export default function reducer(state = initialState, action: {type: string} & any) {
    switch (action.type) {
        case types.SET_USERDATA:
            return {
                ...state,
                userData: action.userData,
            };
        case types.SET_PARTIAL_LOGIN:
            return {
                ...state,
                partialLogin: action.partialLogin,
            };
        case types.SET_ACTIVE_FLEET_OPERATOR_ORG_USER:
            return {
                ...state,
                activeFleetOperatorOrgUser: action.activeFleetOperatorOrgUser,
            };
        case types.SET_CENTRAL_USER_DATA:
            return {
                ...state,
                centralUserData: action.centralUserData,
            };
        case types.SET_TOKEN:
            return {
                ...state,
                token: action.token,
            };
        case types.SET_FCMTOKEN:
            return {
                ...state,
                fcmToken: action.fcmToken,
            };
        case types.SET_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.currentLanguage,
            };
        case types.SET_CURRENTLOCATION:
            return {
                ...state,
                currentLocation: action.currentLocation,
            };

        case types.LOGOUT:
            return {
                userData: {},
                centralUserData: {},
                currentLanguage: 'en',
                token: '',
                fcmToken: '',
                currentLocation: {},
                partialLogin: false,
                activeFleetOperatorOrgUser: {},
            };
        default:
            return state;
    }
}
