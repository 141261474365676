import AsyncStorage from "@react-native-async-storage/async-storage";
import { LS_KEYS } from "../constants";

export const getPrivateCSAccessCodesMap = async (): Promise<{ [csId: string]: string } | null> => {
    const privateChargingStationAccessCodesMapStr = await AsyncStorage.getItem(LS_KEYS.privateChargingStationAccessCodesMap);
    if (privateChargingStationAccessCodesMapStr) {
        return JSON.parse(privateChargingStationAccessCodesMapStr);
    }
    return null;
}

export const getStoredCSAccessCodeForId = async (csId: string): Promise<string | null> => {
    const privateChargingStationAccessCodesMap = await getPrivateCSAccessCodesMap();
    if (privateChargingStationAccessCodesMap) {
        return privateChargingStationAccessCodesMap?.[csId];
    }

    return null;
}