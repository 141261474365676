import { FC } from "react";
import CButton from "../../components/CButton";
import ScreenBottomBar from "../ScreenBottomBar";
import { COLORS } from "../../../config/colors";
import { useTranslation } from "react-i18next";

type Props = {
  onButtonPress: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  csCount?: number;
  context?: "maps_screen" | "home_screen" | "search_screen";
};

const BottomContent: FC<Props> = (props: Props) => {
  const {
    onButtonPress = () => {},
    isDisabled = false,
    isLoading = false,
    csCount = 100,
    context = "maps_screen",
  } = props;
  const { t } = useTranslation();

  return (
    <ScreenBottomBar
      style={{
        borderTopWidth: 1,
        borderTopColor: COLORS.primaryLight,
        backgroundColor: COLORS.whiteColor,
        position: "sticky" as any,
        bottom: 0,
        left: 0,
        right: 0,
        borderBottomWidth: 0,
      }}
    >
      {context !== "search_screen" && (
        <CButton
          disable={isDisabled}
          onPress={onButtonPress}
          title={
            isLoading
              ? t("Refreshing...")
              : csCount === 0
              ? t("NoChargersFound")
              : `${t("Show")} ${csCount} ${t("chargersLC")}`
          }
        />
      )}

      {context === "search_screen" && (
        <CButton onPress={onButtonPress} title={t("Apply")} />
      )}
    </ScreenBottomBar>
  );
};

export default BottomContent;
