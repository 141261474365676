import { StyleSheet } from 'react-native';
import { COLORS } from '../../../config/colors';
import { THEME_COLORS } from '../../../theme/constants/themeColors';
import { StyleHelpers } from '../../../constants/style-helpers';

export default StyleSheet.create({
  textStyle: {
    marginStart: 16,
    marginTop: 4,
    color: THEME_COLORS.primaryColor
  }
});
