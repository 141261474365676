import React, { ReactElement } from "react";
import { Image, TouchableOpacity, ViewStyle } from "react-native";
import { COLORS } from "../../../config/colors";
import CText from "../../../ui/components/CText";

type Props = {
  onPress: () => void;
  title: string;
  icon?: any;
  iconSvg?: ReactElement;
  style: ViewStyle;
  disabled?: boolean;
};
export default function SmallBorderedButton(props: Props) {
  const {
    onPress = () => {},
    title,
    icon,
    disabled = false,
    iconSvg,
    style = {},
  } = props;

  return (
    <>
      <TouchableOpacity
        activeOpacity={0.7}
        disabled={disabled}
        style={[
          {
            borderRadius: 12,
            borderWidth: 1,
            backgroundColor: COLORS.whiteColor,
            borderColor: COLORS.primaryLight,
            flexDirection: "row",
            alignItems: "center",
            padding: 8,
            paddingHorizontal: 12,
          },
          style,
        ]}
        onPress={onPress}
      >
        {!!iconSvg && <>{iconSvg}</>}
        {!iconSvg && !!icon && (
          <Image
            source={icon}
            style={{ height: 16, width: 16 }}
            resizeMode="contain"
          />
        )}
        <CText
          value={title}
          size={10}
          medium
          style={{ marginStart: 8, fontWeight: "600" }}
        />
      </TouchableOpacity>
    </>
  );
}
