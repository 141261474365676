import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { isArray, isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Dimensions, Platform, StyleSheet, View } from "react-native";
import { batch, useDispatch } from "react-redux";
import CButton from "../../../../components/CButton";
import CText from "../../../../components/CText";
import ScreenBottomBar from "../../../../../miscellaneous/components/ScreenBottomBar";
import { StyleHelpers } from "../../../../../miscellaneous/constants/style-helpers";
import AuthAction from "../../../../../redux/reducer/auth/actions";
import { ErrorHandlerService } from "../../../../../services/ErrorHandlerService";
// @ts-ignore
import AuthenticatedOrgRequestService from "../../../../../services/AuthenticatedOrgRequestService";
import { getPrivateCSAccessCodesMap } from "../../../../../helpers/chargingStationHelper";
import { HandledError } from "../../../../../miscellaneous/types";
import {
  getCentralUserWallet,
  isFleetWallet,
} from "../../../../../helpers/walletHelpers";
import {
  updateActivePaymentMethodType,
  updateActiveWallet,
} from "../../../../../actions/walletActions";
import { COLORS } from "../../../../../config/colors";
import { FontFamily } from "../../../../../config/typography";
import { LS_KEYS } from "../../../../../constants";
import { useCentralUserProfile } from "../../../../../hooks";
import { isIOS } from "../../../../../helpers";
import { THEME_COLORS } from "../../../../../theme/constants/themeColors";
import ScreenHeader from "../../../../../miscellaneous/components/ScreenHeader";
import { AccessCodeType } from "../../../../../central-atoms/access-code/enums";
import { VerifyCode } from "../../../../../central-atoms/types/app-requests";
import SmoothPinCodeInput from "../../../../components/SmoothPincodeInput";
import { useTranslation } from "react-i18next";
import { verticalSize } from "../../../../../functions/responsive";
import { Wallet } from "../../../../../central-atoms/types/wallet";
import { MixedChargingStation } from "../../../../../central-atoms/types/charging/charging-station";
import { FleerOperator } from "../../../../../central-atoms/types/org";
import { PaymentMethodType } from "../../../../../central-atoms/payment-methods/types/enum";
import { Org } from "../../../../../central-atoms/types/org";

type Props = {
  onVerificationSuccess?: (operatorCode: string) => void;
  onVerificationError?: (
    operatorCode: string,
    handledError?: HandledError
  ) => void;
  showLogoutButton?: boolean;
  showScreenHeader?: boolean;
  wallet?: Wallet | null;
  fleetOperator: FleerOperator | Org | null;
  mixedChargingStation?: MixedChargingStation | null;
  accessCodeType?: AccessCodeType;
};
export default function EnterAccessCodeContent(props: Props) {
  const {
    fleetOperator,
    wallet,
    mixedChargingStation = null,
    accessCodeType = AccessCodeType.FleetOperatorCode,
    onVerificationSuccess = () => {},
    onVerificationError = () => {},
    showLogoutButton = false,
    showScreenHeader = true,
  } = props;
  const { t } = useTranslation();
  const navigation = useNavigation<any>();
  const centralUser = useCentralUserProfile();
  const dispatch = useDispatch();
  const accessCodeInputRef = useRef<any>();

  const {
    setUserData,
    setCentralUserData,
    setPartialLogin,
    // setToken,
    setActiveFleetOperatorOrgUser,
  } = AuthAction;

  const [accessCodeError, setAccessCodeError] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [loading, setLoading] = useState(false);

  const verifyAccessCode = () => {
    if (!accessCode) {
      setAccessCodeError(t("PleaseEnterAccessCode"));
      shakeOtpInput();
      return;
    }

    setLoading(true);
    const data: VerifyCode = {
      access_code: accessCode, // 559633 // 423809
      code_type: accessCodeType,
    };
    if (mixedChargingStation?.central_charging_station?.id) {
      data.charging_station_id = `${mixedChargingStation?.central_charging_station?.id}`;
    }

    if (fleetOperator?.id) {
      data.org_id = fleetOperator.id;
    }
    AuthenticatedOrgRequestService.verifyAccessCode(data)
      .then(async ({ data }) => {
        setAccessCodeError("");

        if (accessCodeType === AccessCodeType.ChargingStationAccessCode) {
          let storedPrivateCSAccessCodesMap =
            await getPrivateCSAccessCodesMap();
          if (!storedPrivateCSAccessCodesMap) {
            storedPrivateCSAccessCodesMap = {};
          }
          storedPrivateCSAccessCodesMap[
            `${mixedChargingStation?.central_charging_station?.id}`
          ] = accessCode;

          await AsyncStorage.setItem(
            LS_KEYS.privateChargingStationAccessCodesMap,
            JSON.stringify(storedPrivateCSAccessCodesMap)
          );
        } else {
          batch(() => {
            dispatch(setUserData(data?.user));
            dispatch(setCentralUserData(data?.central_user));
            dispatch(setPartialLogin(false));
            dispatch(updateActivePaymentMethodType(PaymentMethodType.Wallet));
            const wallet = getCentralUserWallet(data?.central_user);
            if (wallet) dispatch(updateActiveWallet(wallet));
            // dispatch(setToken(data?.user?.token));

            if (isFleetWallet(wallet)) {
              const orgArr = data?.central_user?.org_users;
              if (isArray(orgArr)) {
                orgArr.map((orgUser) => {
                  if (accessCode == orgUser?.org?.operator_code) {
                    dispatch(setActiveFleetOperatorOrgUser(orgUser));
                  }
                });
              }
            } else {
              dispatch(setActiveFleetOperatorOrgUser(null));
            }
          });

          await AsyncStorage.setItem(LS_KEYS.enteredFleetCode, accessCode);
          await AsyncStorage.setItem(LS_KEYS.enteredAccessCode, accessCode);
        }

        onVerificationSuccess(accessCode);

        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        setAccessCodeError(
          e?.response?.data?.msg || e?.response?.data?.message
        );
        const handledError = ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
        onVerificationError(accessCode, handledError);
      });
  };

  const shakeOtpInput = () => {
    accessCodeInputRef.current?.animate({ animation: "shake", duration: 600 });
  };

  useEffect(() => {
    setTimeout(() => {
      accessCodeInputRef.current?.focus();
    }, 500);
  }, []);

  return (
    <View style={styles.root}>
      <View
        style={{
          ...StyleHelpers.pageSpacing,
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        {showScreenHeader ? (
          <ScreenHeader
            navigation={navigation}
            style={{
              borderBottomWidth: 0,
              marginTop: isIOS() ? 30 : 0,
              marginHorizontal: -StyleHelpers.pageSpacing.paddingLeft,
              position: "sticky" as any,
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100,
            }}
          />
        ) : (
          <View />
        )}

        <View>
          <CText size={14} style={{ color: COLORS.grey700 }}>
            {t("Hi")} {centralUser.name ?? ""}
          </CText>
          <CText size={26} semiBold style={{ fontWeight: "600" }}>
            {accessCodeType === AccessCodeType.ChargingStationAccessCode &&
              t("EnterChargerAccessCode")}
            {accessCodeType !== AccessCodeType.ChargingStationAccessCode &&
              t("EnterAccessCode")}
          </CText>

          {accessCodeType !== AccessCodeType.ChargingStationAccessCode &&
            fleetOperator &&
            !isEmpty(fleetOperator) && (
              <CText
                size={16}
                style={{
                  marginVertical: 10,
                }}
              >
                {t("operatorCodeUpdatedEnterNewOne")}
              </CText>
            )}

          <View style={{ marginTop: 12, justifyContent: "center" }}>
            <SmoothPinCodeInput
              ref={accessCodeInputRef}
              cellSpacing={16}
              codeLength={6}
              cellSize={Dimensions.get("window").width / 9}
              maskDelay={0}
              textStyle={styles.textStyle}
              cellStyle={[
                styles.cellStyle,
                {
                  borderColor: !!accessCodeError
                    ? COLORS.errorColor
                    : COLORS.darkBlack,
                },
              ]}
              cellStyleFocused={[
                styles.cellStyleFocused,
                {
                  borderColor: !!accessCodeError
                    ? COLORS.errorColor
                    : COLORS.darkBlack,
                },
              ]}
              value={accessCode}
              onTextChange={(code: string) => setAccessCode(code)}
              restrictToNumbers
              autoFocus={true}
            />
          </View>

          {!!accessCodeError && (
            <CText
              value={`${accessCodeError}`}
              size={12}
              style={{
                color: COLORS.errorColor,
                marginTop: 4,
              }}
            />
          )}

          <View
            style={{
              marginTop: 40,
            }}
          >
            <CText size={12} style={{ marginBottom: 8 }}>
              {accessCodeType === AccessCodeType.ChargingStationAccessCode &&
                t("EnterChargerAccessCodeDesc")}
              {accessCodeType !== AccessCodeType.ChargingStationAccessCode &&
                t("EnterAccessCodeDesc")}
            </CText>
          </View>
        </View>
      </View>

      <ScreenBottomBar
        style={{
          zIndex: 1,
          position: "sticky" as any,
          bottom: 0,
          left: 0,
          right: 0,
          borderBottomWidth: 0,
        }}
      >
        <CButton
          title={t("continue")}
          onPress={() => {
            verifyAccessCode();
          }}
          disable={loading}
          loading={loading}
        />
      </ScreenBottomBar>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "column",
    backgroundColor: THEME_COLORS.bgColor,
    justifyContent: "space-between",
    height: Platform.OS === "ios" ? verticalSize(100) : "100%",
    // height: "100%",
    position: "relative",
  },
  cellStyle: {
    borderRadius: 8,
    backgroundColor: COLORS.whiteColor,
    borderWidth: 1,
    borderColor: COLORS.darkBlack,
    borderBottomColor: COLORS.blackColor,
    paddingTop: 4,
  },
  textStyle: {
    fontSize: 17,
    fontWeight: "bold",
    marginTop: -5,
    color: COLORS.blackColor,
    fontFamily: FontFamily.Poppins_Regular,
  },
  cellStyleFocused: {
    borderWidth: 3,
    borderColor: COLORS.blackColor,
  },
  rowStyle: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  modalCont: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: THEME_COLORS.bgColor,
  },
  logoStyle: {
    height: 56,
    width: "90%",
  },
});
