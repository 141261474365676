import { isEmpty } from "lodash";
import { ScreenComponent } from "../../../miscellaneous/types/components";
import { useSelectedLanguage, useUserAuthenticated } from "../../../hooks";
import AuthenticatedUserRequestService from "../../../services/AuthenticatedUserRequestService";
import { LangCodes } from "../../../central-atoms/enums/generic/i18n/LangCodes";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import ScreenWrapper from "../../../miscellaneous/components/ScreenWrapper";
import { SCREEN_NAMES } from "../../../constants";
import EnterFleetOperatorCodeContent from "../../components/EnterFleetOperatorCodeContent";

type Props = ScreenComponent & {};
export default function EnterFleetCodeScreen(props: Props) {
  const { navigation } = props;

  const userAuthenticated = useUserAuthenticated();
  const selectedLanguage = useSelectedLanguage();
  const updateActiveLanguage = (lang: LangCodes) => {
    AuthenticatedUserRequestService.updateActiveLanguage({ lang: lang }).catch(
      (e) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      }
    );
  };

  return (
    <ScreenWrapper {...props}>
      <EnterFleetOperatorCodeContent
        fleetOperator={null}
        showLogoutButton={true}
        onVerificationSuccess={(operatorCode) => {
          navigation.navigate(SCREEN_NAMES.SearchingScreen);

          if (userAuthenticated && !isEmpty(selectedLanguage)) {
            updateActiveLanguage(selectedLanguage);
          }
        }}
        onVerificationError={(operatorCode, handledError) => {}}
      />
    </ScreenWrapper>
  );
}
