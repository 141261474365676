import React from "react";
import Modal from "react-modal";
import CButton from "../CButton";
import { useTranslation } from "react-i18next";
import CText from "../CText";
import { View } from "react-native";
import { COLORS } from "../../../config/colors";
import { verticalSize } from "../../../functions/responsive";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: COLORS.whiteColor,
    // marginLeft: '20px',
    maxWidth: "80%",
    // height: '180px'
  },
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: COLORS.whiteColor,
    filter: "blur(8px)",
    position: "absolute",
    zIndex: 100,

    overflow: "auto",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.3)", // Semi-transparent background
    backdropFilter: "blur(2px)", // Blur effect
    zIndex: 10, // Ensure the overlay is behind the modal
  },
};

type Props = {
  navigation?: any;
  route?: any;
  isOpen: boolean;
  closeModal: () => void;
  handleAccept: () => void;
  description: string;
};

export default function ReactModal(props: Props) {
  const { navigation, route, isOpen, closeModal, handleAccept, description } =
    props;
  const { t } = useTranslation();
  return (
    <>
      {isOpen && <div style={customStyles.overlay as any}></div>}
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={modalStyles}>
        <CText
          value={description}
          size={20}
          medium
          style={{
            fontWeight: "600",
            textAlign: "center",
            display: "flex",
          }}
        />
        <div
          style={{
            display: "flex",
            gap: 20,
            flexDirection: "row",
            marginTop: verticalSize(3),
            justifyContent: "center",
          }}
        >
          <CButton
            title={t("No")}
            height={40}
            style={{ width: 100 }}
            onPress={closeModal}
          />
          <CButton
            title={t("Yes")}
            height={40}
            style={{ width: 100 }}
            onPress={handleAccept}
          />
        </div>
      </Modal>
    </>
  );
}
