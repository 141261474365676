export const convertListToMap = (inputList: string[]): { [listElement: string]: boolean } => {
    const outputMap: { [listElement: string]: boolean } = {};
    inputList.forEach((input) => {
        outputMap[input] = true;
    });

    return outputMap;
};

export const convertSetToMap = (inputList: Set<string>): { [listElement: string]: boolean } => {
    const outputMap: { [listElement: string]: boolean } = {};
    inputList.forEach((input) => {
        outputMap[input] = true;
    });

    return outputMap;
};

export const roundNumber = (num: number, decimalPlaces = 100): number => {
    return Math.round((num + Number.EPSILON) * decimalPlaces) / decimalPlaces;
};

export function getGrammaticallyNumberizedText(singularText: string, pluralText: string, count: number): string {
    return count === 1 ? singularText : pluralText;
}

export const swapKeyVal = (json: {[key: string]: string | number}): {[key: string]: string | number} => {
    const swapped: {[key: string]: string | number} = {};
    Object.keys(json).forEach((key) => {
        const val = json[key];
        swapped[val] = key;
    });
    return swapped;
};

export function getRadius(latitudeDelta: number): number {
    return (latitudeDelta * 69) / 2;
};

export function isNotNull<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
}
