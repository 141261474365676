import { StyleSheet } from "react-native";
import { COLORS } from "../../../config/colors";
import { horizontalSize, verticalSize } from "../../../functions/responsive";

export default StyleSheet.create({
	container: {
		backgroundColor: COLORS.whiteColor,
		justifyContent: "center",
		alignItems: "center",
		width: horizontalSize(100),
		height: verticalSize(100)
	},
	img: {
		height: "100%",
		width: "65%",
		maxWidth: 400,
		margin: 20,
	},
});
