import { MixedChargingStation } from '../central-atoms/types/charging/charging-station';
import {
  EmergencyStopChargingPayload,
  StartChargingPayload,
  StopChargingPayload
} from '../central-atoms/types/requests/charging';
import {
  FetchActiveConnectionResponse,
  FetchCPConnectorAvailabilityResponse,
  FetchCPConnectorsResponse
} from '../central-atoms/types/responses/charge-points';
import { EmergencyStopChargingResponse, StopChargingResponse } from '../central-atoms/types/responses/charging';
import { I18NHelpMessageResponse, I18NRichMessageResponse } from '../central-atoms/types/responses/i18n';
import HttpRequestService from '../config/HttpRequestService';
import { API_BASE_PATH, CENTRAL_HOST } from '../config/setting';
import { getGlobalVar } from '../miscellaneous/helpers';
import { GLOBAL_VARS } from '../constants';

export default class ChargePointRequestService {
  static async fetchChargePointByQuery(queryParams: { q: string | number }): Promise<{ data: MixedChargingStation }> {
    const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
    const response = await httpRequest
      .getInstance()
      .get(`${API_BASE_PATH}/charging_station_by_query`, { params: queryParams });
    return response.data;
  }

  static async startCharging(data: StartChargingPayload): Promise<any> {
    const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
    const response = await httpRequest.getInstance().post(`${API_BASE_PATH}/start_charging`, data);
    return response.data;
  }

  static async fetchChargeTxnForCPCId(cpcId: string): Promise<any> {
    const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
    const response = await httpRequest
      .getInstance()
      .get(`${API_BASE_PATH}/charge_point_connector/${cpcId}/charging_details`);
    return response.data;
  }

  static async fetchChargingDetails(
    chargeTxnId: string
  ): Promise<{ data: any; help: I18NHelpMessageResponse; i18n_error?: I18NRichMessageResponse }> {
    const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
    const response = await httpRequest.getInstance().get(`${API_BASE_PATH}/charging_details/${chargeTxnId}`);
    return response.data;
  }

  static async stopCharging(payload: StopChargingPayload): Promise<StopChargingResponse> {
    const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
    const response = await httpRequest.getInstance().post(`${API_BASE_PATH}/stop_charging`, payload);
    return response.data;
  }

  static async emergencyStopCharging(payload: EmergencyStopChargingPayload): Promise<EmergencyStopChargingResponse> {
    const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
    const response = await httpRequest.getInstance().post(`${API_BASE_PATH}/emergency_stop_charging`, payload);
    return response.data;
  }

  static async isConnected(
    cp_uid: string,
    queryParams: { charge_transaction_id?: string } = {}
  ): Promise<FetchActiveConnectionResponse> {
    const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
    const response = await centralHttpRequest
      .getInstance()
      .get(`${CENTRAL_HOST}/charge_point/${cp_uid}/is_connected`, { params: queryParams });
    return response.data;
  }

  static async fetchCPConnectorAvailability(cpcId: string): Promise<FetchCPConnectorAvailabilityResponse> {
    const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
    const response = await httpRequest
      .getInstance()
      .get(`${API_BASE_PATH}/charge_point_connector/${cpcId}/availability`);
    return response.data;
  }

  static async fetchCPConnectors(cpcId: string): Promise<FetchCPConnectorsResponse> {
    const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
    const response = await httpRequest
      .getInstance()
      .get(`${API_BASE_PATH}/charge_point/${cpcId}/charge_point_connectors`);
    return response.data;
  }
}
