import { Dimensions, StyleSheet } from "react-native";
import { StyleHelpers } from "../../../../../miscellaneous/constants/style-helpers";
import { COLORS } from "../../../../../config/colors";
import { THEME_COLORS } from "../../../../../theme/constants/themeColors";
import { verticalSize } from "../../../../../functions/responsive";

export default StyleSheet.create({
  root: {
    flexDirection: "column",
    backgroundColor: THEME_COLORS.bgColor,
    justifyContent: "space-between",
    height: "100%",
    position: "relative"
    // height: verticalSize(100)
  },
  mainCont: {
    justifyContent: "flex-end",
    flex: 1
  },
  rowStyle: {
    flexDirection: "row",
    alignItems: "center"
  },
  imageCont: {
    // height: 220,
    width: Dimensions.get("window").width - 128,
    borderRadius: 16,
    overflow: "hidden",
    margin: 8
  },
  mapButton: {
    position: "absolute",
    height: 40,
    width: 39,
    ...StyleHelpers.totalCenter,
    backgroundColor: COLORS.whiteColor,
    borderRadius: 10,
    borderWidth: 1,
    borderBottomWidth: 3,
    borderColor: COLORS.blackColor,
    bottom: 10,
    right: 10,
    zIndex: 1
  }
});
