import AsyncStorage from "@react-native-async-storage/async-storage";
import { isEmpty } from "lodash";
import { useState } from "react";
import { FlatList, ScrollView, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { LS_KEYS, SCREEN_NAMES } from "../../../constants";
import { APP_STATE } from "../../../types";
import styles from "./styles";
import { ScreenComponent } from "../../../miscellaneous/types/components";
import AuthAction from "../../../redux/reducer/auth/actions";
import { LangCodes } from "../../../central-atoms/enums/generic/i18n/LangCodes";
import i18n from "../../../lang/i18n";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import ScreenHeader from "../../../miscellaneous/components/ScreenHeader";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import CText from "../../components/CText";
import { COLORS } from "../../../config/colors";
import LanguageSelectionCard from "../../components/LanguageSelectionCard";
import ScreenBottomBar from "../../../miscellaneous/components/ScreenBottomBar";
import CButton from "../../components/CButton";
import { useTranslation } from "react-i18next";
import { horizontalSize, verticalSize } from "../../../functions/responsive";
import { ReactComponent as PulseEnergyLogo } from "../../../assets/icons/pulse_energy_logo.svg";
import { AvailableAppVariants } from "../../../theme/enums";
import config from "../../../config/config";

type Props = ScreenComponent & {};
export default function SelectLanguageScreen(props: Props) {
  const { navigation, route } = props;
  const { t } = useTranslation();
  const type = (route?.params as any)?.type;
  const { currentLanguage } = useSelector((state: APP_STATE) => state.auth);
  const { setCurrentLanguage } = AuthAction;
  const dispatch = useDispatch();

  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

  // this has to be here
  const LanguageMaps = {
    [LangCodes.kn]: {
      id: LangCodes.kn,
      transTitle: t("kannada"),
      langText: "ಕನ್ನಡ",
      btnText: "ಆಯ್ಕೆ ಮಾಡಿ",
    },
    [LangCodes.hi]: {
      id: LangCodes.hi,
      transTitle: t("hindi"),
      langText: "हिन्दी",
      btnText: "चुन लिया",
    },
    [LangCodes.ta]: {
      id: LangCodes.ta,
      transTitle: t("tamil"),
      langText: "தமிழ்",
      btnText: "தேர்ந்தெடு",
    },
    [LangCodes.en]: {
      id: LangCodes.en,
      transTitle: t("english"),
      langText: "English",
      btnText: "Select",
    },
    [LangCodes.mr]: {
      id: LangCodes.mr,
      transTitle: t("marathi"),
      langText: "मराठी",
      btnText: "निवडा",
    },
    [LangCodes.te]: {
      id: LangCodes.te,
      transTitle: t("telugu"),
      langText: "తెలుగు",
      btnText: "ఎంచుకోండి",
    },
    [LangCodes.gu]: {
      id: LangCodes.gu,
      transTitle: t("gujarati"),
      langText: "ગુજરાતી",
      btnText: "પસંદ કરો",
    },
    [LangCodes.bn]: {
      id: LangCodes.bn,
      transTitle: t("bengali"),
      langText: "বাংলা",
      btnText: "নির্বাচন করুন",
    },
    [LangCodes.ml]: {
      id: LangCodes.ml,
      transTitle: t("malayalam"),
      langText: "മലയാളം",
      btnText: "തിരഞ്ഞെടുക്കുക",
    },
  };

  const languageOptions = Object.values(LanguageMaps);

  const changeLanguage = async (value: string) => {
    i18n
      .changeLanguage(value)
      .then(async () => {
        await AsyncStorage.setItem(LS_KEYS.activeLanguage, value);
        dispatch(setCurrentLanguage(value));
      })
      .catch((e) => {
        ErrorHandlerService.handleError(e, {
          // openToast: false,
          toastOptions: {
            type: "error",
            message: "Failed to set language. Please try again",
            position: "top",
          },
        });
      });
  };

  console.log('GLOBAL_VARS.AppVariant', config);

  return (
    <View style={styles.root}>
      {type === "profile" ? (
        <ScreenHeader
          navigation={navigation}
          style={{
            position: "sticky" as any,
            top: 0,
            left: 0,
            right: 0,
            zIndex: 100,
          }}
          onBackPress={() =>
            navigation.navigate("MainStack", {
              screen: SCREEN_NAMES.ProfileScreen,
            })
          }
        />
      ) : (
        <View
          style={{
            ...StyleHelpers.header,
            borderBottomWidth: 0,
            position: "sticky" as any,
            top: 0,
            left: 0,
            right: 0,
            zIndex: 100,
          }}
        >
          <div>
            <img src={config.FULL_LOGO_URL} alt={config.APP_NAME} style={{ height: 30, maxWidth: 120 }} />
          </div>
        </View>
      )}

      <View
        style={{
          flexDirection: "column",
          justifyContent: type === "profile" ? "flex-start" : "flex-end",
        }}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled={true}
          style={{
            ...StyleHelpers.pageSpacing,
            paddingTop: StyleHelpers.pageSpacing.paddingLeft * 0.5,
          }}
          contentContainerStyle={{
            paddingBottom: StyleHelpers.pageSpacing.paddingLeft * 2.5,
          }}
        >
          {type !== "profile" && (
            <View
              style={{
                marginTop: 100,
              }}
            >
              <CText
                value={t("selectLanguage")}
                style={{ fontWeight: "600" }}
                size={24}
                semiBold
              />
              <CText
                value={t("appWillTranslate")}
                size={12}
                style={{ color: COLORS.secondaryBlackColor }}
              />
            </View>
          )}

          <View
            style={{
              marginTop: StyleHelpers.pageSpacing.paddingLeft,
            }}
          >
            <FlatList
              data={languageOptions}
              renderItem={({ item }) => (
                <View key={item.id} style={{ marginVertical: 10 }}>
                  <LanguageSelectionCard
                    transTitle={item.transTitle}
                    langText={item.langText}
                    btnText={item.btnText}
                    selected={selectedLanguage === item.id}
                    onPress={() => {
                      setSelectedLanguage(item.id);
                      changeLanguage(item.id);
                    }}
                  />
                </View>
              )}
              keyExtractor={(item) => item.id}
            />
          </View>
        </ScrollView>
      </View>

      {type !== "profile" && (
        <ScreenBottomBar
          style={{
            padding: 16,
            backgroundColor: COLORS.whiteColor,
            position: "sticky" as any,
            bottom: 0,
            left: 0,
            right: 0,
            borderBottomWidth: 0,
          }}
        >
          <CButton
            title={t("continue")}
            onPress={() => {
              navigation.navigate(SCREEN_NAMES.EnterPhoneNumberScreen);
            }}
            disable={isEmpty(selectedLanguage)}
          />
        </ScreenBottomBar>
      )}
    </View>
  );
}
