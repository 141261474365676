/* eslint-disable no-console */

import { MiniUCS, MixedChargingStation } from "../central-atoms/types/charging/charging-station";
import { ChargingStationState, RESET_DATA, RESET_MAPS_NEARBY_UCS, RESET_NEARBY_UCS_MINI_OBJECTS_LIST, RESET_NEAREST_UCS_MINI_OBJECT, SET_FILTER_DATA, SET_HOME_SCREEN_NEARBY_UCS_PAGINATION, SET_LAST_CHARGE_TRANSACTION, SET_NEARBY_CHARGING_STATIONS, SET_NEARTEST_CHARGER, SET_RECENT_CHARGER, SET_SELECTED_CHARGER, SET_SELECTED_CHARGER_POINT, SET_SELECTED_STATION, SET_TRACKED_NEAREST_CHARGING_STATION, UPDATE_MAPS_NEARBY_UCS, UPDATE_NEARBY_UCS_MINI_OBJECTS_LIST, UPDATE_NEAREST_UCS_MINI_OBJECT, UPDATE_UCS_MASTER_MAP } from "../types";
import { INITIAL_STATE } from "./defaultStore";

export default function chargingStationReducer(state: ChargingStationState = INITIAL_STATE.chargingStation, action: any): ChargingStationState {
    switch (action.type) {
        case SET_NEARTEST_CHARGER:
            return {
                ...state,
                nearestCharger: action.nearestCharger,
            };

        case SET_TRACKED_NEAREST_CHARGING_STATION: {
            return {
                ...state,
                app: {
                    ...state.app,
                    trackedNearestChargingStation: action.mixedChargingStation,
                }
            };
        }

        case SET_LAST_CHARGE_TRANSACTION: {
            return {
                ...state,
                app: {
                    ...state.app,
                    lastChargeTransaction: action.lastChargeTransaction,
                }
            };
        }

        case SET_HOME_SCREEN_NEARBY_UCS_PAGINATION: {
            return {
                ...state,
                app: {
                    ...state.app,
                    homeOtherChargingStationsPagination: action.payload.pagination,
                }
            };
        }

        case SET_NEARBY_CHARGING_STATIONS: {
            // console.log('action', action, state);
            const newNearbyUCSList = [...(state.nearbyChargingStations ?? [])];
            action.nearbyChargingStations?.forEach((ucs: MixedChargingStation) => {
                const existingNearbyUCSIndex = newNearbyUCSList.findIndex((_ucs) => _ucs.charging_station.id === ucs.charging_station.id);
                if (existingNearbyUCSIndex !== -1) {
                    newNearbyUCSList[existingNearbyUCSIndex] = ucs;
                }
                else {
                    newNearbyUCSList.push(ucs);
                }
            });

            return {
                ...state,
                nearbyChargingStations: newNearbyUCSList,
            };
        }

        case SET_SELECTED_CHARGER:
            return {
                ...state,
                selectedCharger: action.selectedCharger,
            };

        case SET_SELECTED_STATION:
            return {
                ...state,
                selectedStation: action.selectedStation,
            };

        case SET_SELECTED_CHARGER_POINT:
            return {
                ...state,
                selectedChargerPoint: action.selectedChargerPoint,
            };

        case SET_RECENT_CHARGER:
            return {
                ...state,
                recentCharger: action.recentCharger,
            };

        case SET_FILTER_DATA:
            return {
                ...state,
                filterData: action.filterData,
            };

        case UPDATE_UCS_MASTER_MAP: {
            const newMasterUCSMap: { [key: string]: MixedChargingStation } = {};
            action.payload.ucs?.forEach((ucs: MixedChargingStation) => {
                if (ucs?.central_charging_station?.id)
                    newMasterUCSMap[ucs.central_charging_station.id] = ucs;
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    masterUCSMap: {
                        ...state.data.masterUCSMap,
                        ...newMasterUCSMap,
                    }
                }
            };
        }

        case UPDATE_NEAREST_UCS_MINI_OBJECT: {
            const { miniUCS } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    nearestUCSMiniObject: miniUCS,
                }
            };
        }

        case UPDATE_NEARBY_UCS_MINI_OBJECTS_LIST: {
            const { miniUCSList, updateStrategy } = action.payload;

            let newNearbyMiniUCSList: MiniUCS[] = [];
            if (updateStrategy === 'merge') {
                newNearbyMiniUCSList = [...(state.data.otherNearbyUCSMiniObjectsList ?? [])];
                miniUCSList?.forEach((miniUCS: MiniUCS) => {
                    const existingNearbyMiniUCSIndex = newNearbyMiniUCSList.findIndex((_miniUCS) => _miniUCS.id === miniUCS.id);
                    if (existingNearbyMiniUCSIndex !== -1) {
                        newNearbyMiniUCSList[existingNearbyMiniUCSIndex] = miniUCS;
                    }
                    else {
                        newNearbyMiniUCSList.push(miniUCS);
                    }
                });
            }
            else if (updateStrategy === 'override') {
                newNearbyMiniUCSList = miniUCSList;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    otherNearbyUCSMiniObjectsList: newNearbyMiniUCSList,
                }
            };
        }

        case RESET_NEARBY_UCS_MINI_OBJECTS_LIST: {
            return {
                ...state,
                data: {
                    ...state.data,
                    otherNearbyUCSMiniObjectsList: [],
                },
            };
        }
            
        case RESET_NEAREST_UCS_MINI_OBJECT: {
            return {
                ...state,
                data: {
                    ...state.data,
                    nearestUCSMiniObject: null,
                },
            };
        }

        // maps /*
        case UPDATE_MAPS_NEARBY_UCS: {
            const newNearbyUCSMap: { [key: string]: MiniUCS } = {};
            action.payload.ucs?.forEach((ucs: MixedChargingStation) => {
                newNearbyUCSMap[ucs.central_charging_station.id] = {
                    id: `${ucs.central_charging_station.id}`,
                    primary_cs_id: ucs.charging_station.id,
                    created_on: ucs.central_charging_station.created_on,
                };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    mapsNearbyUCS: {
                        ...state.data.mapsNearbyUCS,
                        ...newNearbyUCSMap,
                    }
                }
            };
        }
            
        case RESET_MAPS_NEARBY_UCS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    mapsNearbyUCS: {},
                }
            };
        }

        // RESET

        case RESET_DATA:
            return INITIAL_STATE.chargingStation;

        default:
            return state;
    }
}
