import {
  InitiateTransactionAppRequest,
  PaymentTransactionStatus,
  PostRegisterPaymentTxnRequest
} from '../central-atoms/types/app-requests';
import HttpRequestService from '../config/HttpRequestService';
import { CENTRAL_HOST } from '../config/setting';
import { getGlobalVar } from '../miscellaneous/helpers';
import { GLOBAL_VARS } from '../constants';

export class PaymentRequestService {
  static async fetchPaymentTxnStatus(payload: PaymentTransactionStatus): Promise<any> {
    const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
    const response = await centralHttpRequest.getInstance().post(`${CENTRAL_HOST}/payment/transaction_status`, payload);
    return response.data;
  }

  static async registerPaymentTxn(payload: PostRegisterPaymentTxnRequest): Promise<any> {
    const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
    const response = await centralHttpRequest.getInstance().post(`${CENTRAL_HOST}/register_payment_txn`, payload);
    return response.data;
  }

  static async initiatePaymentTransaction(cpPublicUid: string, payload: InitiateTransactionAppRequest): Promise<any> {
    const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
    const response = await centralHttpRequest
      .getInstance()
      .post(`${CENTRAL_HOST}/charge_point/${cpPublicUid}/initiate_transaction`, payload);
    return response.data;
  }
}
