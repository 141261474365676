import { View, ViewStyle } from "react-native";
import { THEME_COLORS } from "../../theme/constants/themeColors";
import { StyleHelpers } from "../constants/style-helpers";
import BackButton from "../../ui/components/BackButton";
import CText from "../../ui/components/CText";

type Props = {
  navigation?: any;
  title?: string | React.ReactElement;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  style?: ViewStyle;
  showBackButton?: boolean;
  onBackPress?: () => void;
};
const ScreenHeader = (props: Props) => {
  const {
    navigation,
    title,
    showBackButton = true,
    leftComponent = null,
    rightComponent = null,
    style = {},
    onBackPress,
  } = props;

  return (
    <View style={[StyleHelpers.header, style]}>
      {leftComponent && leftComponent}
      {showBackButton && (
        <BackButton
          shadow
          onPress={() => {
            if (typeof onBackPress === "function") onBackPress();
            else navigation.goBack();
          }}
        />
      )}
      <View style={{ flex: 1 }}>
        {!!title && (
          <CText
            size={12}
            style={{
              marginStart: 16,
              marginTop: 4,
              color: THEME_COLORS.primaryColor,
            }}
            semiBold
          >
            {title}
          </CText>
        )}
      </View>
      {rightComponent && rightComponent}
    </View>
  );
};

export default ScreenHeader;
