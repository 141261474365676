import { PaymentMethodType } from "../central-atoms/payment-methods/types/enum";
import { Wallet } from "../central-atoms/types/wallet";
import { SharedWallet } from "../central-atoms/wallets/types";
import { FetchWalletConsumersResponse } from "../central-atoms/wallets/types/responses";
import { REMOVE_SHARED_WALLET_FROM_ACTIVE_WALLET, REMOVE_WALLET_CONSUMER, UPDATE_ACTIVE_PAYMENT_METHOD_TYPE, UPDATE_ACTIVE_WALLET, UPDATE_FLEET_WALLETS, UPDATE_PERSONAL_WALLETS, UPDATE_SHARED_WALLETS, UPDATE_SINGLE_WALLET_CONSUMER, UPDATE_WALLET_CONSUMERS } from "../types";

export const updateActivePaymentMethodType = (activePaymentMethodType: PaymentMethodType | null) => {
    return {
        type: UPDATE_ACTIVE_PAYMENT_METHOD_TYPE,
        payload: {
            activePaymentMethodType,
        },
    };
}

export const updateActiveWallet = (activeWallet: Wallet | null) => {
    return {
        type: UPDATE_ACTIVE_WALLET,
        payload: {
            activeWallet,
        },
    };
}

export const removeSharedWalletFromActiveWallet = (sharedWalletId: string) => {
    return {
        type: REMOVE_SHARED_WALLET_FROM_ACTIVE_WALLET,
        payload: {
            sharedWalletId,
        },
    };
}

export const updateFleetWallets = (wallets: Wallet[]) => {
    return {
        type: UPDATE_FLEET_WALLETS,
        payload: {
            wallets,
        },
    };
}

export const updatePersonalWallets = (wallets: Wallet[]) => {
    return {
        type: UPDATE_PERSONAL_WALLETS,
        payload: {
            wallets,
        },
    };
}

export const updateSharedWallets = (wallets: Wallet[]) => {
    return {
        type: UPDATE_SHARED_WALLETS,
        payload: {
            wallets,
        },
    };
}

export const updateWalletConsumers = (walletId: string, walletConsumers: FetchWalletConsumersResponse[]) => {
    return {
        type: UPDATE_WALLET_CONSUMERS,
        payload: {
            walletId,
            walletConsumers,
        },
    };
}


export const updateSingleWalletConsumer = (walletId: string, sharedWalletId: string, sharedWallet: SharedWallet) => {
    return {
        type: UPDATE_SINGLE_WALLET_CONSUMER,
        payload: {
            walletId,
            sharedWalletId,
            sharedWallet,
        },
    };
}


export const deleteWalletConsumer = (walletId: string, sharedWalletId: string) => {
    return {
        type: REMOVE_WALLET_CONSUMER,
        payload: {
            walletId,
            sharedWalletId,
        },
    };
}