import { useSelector } from 'react-redux';
import { ChargeTransaction } from '../central-atoms/types/charging/charge-txns';
import { APP_STATE } from '../types';

export const useChargeTxnsMap = (): { [chargeTxnId: string]: ChargeTransaction } => {
  return useSelector((state: APP_STATE) => state.chargeTxns.data.chargeTxnsMap);
};

export const useChargeTxnsList = (): ChargeTransaction[] => {
  return useSelector((state: APP_STATE) => state.chargeTxns.data.chargeTxnsList);
};

export const useChargeTxnsListPagination = () => {
  return useSelector((state: APP_STATE) => state.chargeTxns.app?.chargeTxnsListPagination);
};

export const useChargeTxnsFilter = () => {
  return useSelector((state: APP_STATE) => state.chargeTxns.app?.chargeTxnsFilters);
};
