import { uniqueId } from "lodash";
import { Image, TouchableHighlight, View, ViewStyle } from "react-native";
import { Filter } from "../../../types";
import { getLeftIcons } from "../../../helpers/filtersHelpers";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { COLORS } from "../../../config/colors";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import CText from "../CText";
import { Middot } from "../../../miscellaneous/views/miscellaneous";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { CSFilterGroup } from "../../../central-atoms/filters/enums";

type Props = {
  filter: Filter;
  selected: boolean;
  disabled?: boolean;
  onPress?: (filter: Filter) => void;
  style?: ViewStyle;
};
const QuickFilterItem = (props: Props) => {
  const { filter, selected, disabled = false, onPress = () => {}, style = {} } = props;

  const mainText = filter.viewValue;
  const leftIcons = getLeftIcons(filter);
  const subText = filter.speed_type || "";
  // const selected = filter.selected;
  const leftText = null;

  const textColor = selected ? THEME_COLORS.quickFilterItemSelectedText : THEME_COLORS.quickFilterItemUnselectedText;

  return (
    <TouchableHighlight
      onPress={() => onPress(filter)}
      disabled={disabled}
      underlayColor={COLORS.primaryLight}
      activeOpacity={1}
      style={{
        // backgroundColor: COLORS.transparent,
        // borderColor: selected ? COLORS.blackColor : THEME_COLORS.primaryBorder,
        // backgroundColor: selected
        //   ? THEME_COLORS.quickFilterItemSelectedBg
        //   : COLORS.transparent,
        borderColor: selected ? COLORS.blackColor : COLORS.primaryLight,
        borderWidth: 1,
        borderRadius: 25,
        paddingVertical: 4,
        paddingHorizontal: 10,
        opacity: disabled ? 0.6 : 1,
        ...style
      }}>
      <View
        style={{
          ...StyleHelpers.verticallyCenteredRow,
          flex: 1
        }}>
        {(!!leftIcons?.length || !!leftText) && (
          <View
            style={{
              marginRight: 4,
              paddingRight: 4
              // borderRightWidth: !!leftText ? 1 : 0,
              // borderColor: selected ? COLORS.primaryDark : COLORS.primaryDark,
              // height: 25,
            }}>
            {!!leftIcons?.length && (
              <View
                style={{
                  ...StyleHelpers.verticallyCenteredRow
                }}>
                {leftIcons.map((iconUrl: string, index) => {
                  let categoryStyle: ViewStyle = {};
                  if (filter.group === CSFilterGroup.Payable) {
                    categoryStyle = {
                      width: 25,
                      height: 25,
                      borderRadius: 25,
                      marginLeft: -4,
                      backgroundColor: COLORS.whiteColor
                    };
                  } else if (filter.group === CSFilterGroup.ConnectorTypes) {
                    categoryStyle = {
                      width: 25,
                      height: 25,
                      padding: 3,
                      borderRadius: 20,
                      backgroundColor: COLORS.whiteColor,
                      marginLeft: -5
                    };
                  }

                  const overlappingImagesStyle = {
                    ...categoryStyle,
                    marginLeft: index === 0 ? -4 : -10
                  };
                  const singleImageStyle = {
                    width: 22,
                    height: 22,
                    ...categoryStyle
                  };

                  const style = leftIcons.length === 1 ? singleImageStyle : overlappingImagesStyle;

                  return (
                    <View key={index.toString()} style={style}>
                      <Image
                        source={{ uri: iconUrl }}
                        style={{
                          width: "100%",
                          height: "100%"
                        }}
                        resizeMode="cover"
                      />
                    </View>
                  );
                })}
              </View>
            )}
            {!!leftText && !leftIcons && (
              <CText semiBold size={12} style={{ fontWeight: "500" }}>
                {leftText}
              </CText>
            )}
          </View>
        )}
        {/* <VerticalLine style={{height: '80%'}} /> */}
        <View
          style={{
            // alignItems: 'center',
            // justifyContent: 'center',
            // flexDirection: 'column',
            ...StyleHelpers.verticallyCenteredRow
            // backgroundColor: COLORS.cyanColor,
          }}>
          <CText
            medium
            size={12}
            style={{
              lineHeight: 18,
              textAlign: "center",
              marginTop: 1,
              color: textColor,
              fontWeight: "500"
              // backgroundColor: COLORS.amber700,
              // fontFamily: 'Inter',
            }}>
            {mainText}
          </CText>
          {!!subText && (
            <>
              <Middot style={{ marginHorizontal: 4, backgroundColor: textColor }} />
              <CText
                size={12}
                style={{
                  lineHeight: 18,
                  textAlign: "center",
                  marginTop: 1,
                  color: textColor,
                  fontWeight: "500"
                  // backgroundColor: COLORS.green,
                }}>
                {subText}
              </CText>
            </>
          )}
        </View>
        {selected && (
          <CheckIcon
            fill={textColor}
            style={{
              marginLeft: 6
            }}
          />
        )}
      </View>
    </TouchableHighlight>
  );
};

export default QuickFilterItem;
