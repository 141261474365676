import { getAnalytics, setUserId, setUserProperties, logEvent } from "firebase/analytics";
import { MixedUserProfile } from '../central-atoms/types/user';
import { ENVIRONMENT } from '../config/setting';


export class AppAnalyticsService {
    userProfile: {
        user_id: string,
    } | null = null;

    setUserProfile(mixedUserProfile: MixedUserProfile): void {
        // DevNote: We can set UserId just once:
        // https://firebase.google.com/docs/analytics/userid#setting_the_user_id

        this.userProfile = {
            user_id: `${mixedUserProfile.central_user.id}`,
        };
    }

    unsetUserProfile(): void {
        this.userProfile = null;
    }

    async setUserId(userId: string): Promise<void> {
        if (!ENVIRONMENT.analyticsEnabled) {
            return;
        }

        return setUserId(getAnalytics(), userId);
    }

    async setUserProperties(properties: { [key: string]: string | null }): Promise<void> {
        if (!ENVIRONMENT.analyticsEnabled) {
            return;
        }

        return setUserProperties(getAnalytics(), properties);
    }

    async logAppOpen(): Promise<void> {
        if (!ENVIRONMENT.analyticsEnabled) {
            return;
        }

        return logEvent(getAnalytics(), 'app_open');
    }

    async logEvent(eventName: string, params?: { [key: string]: any }): Promise<void> {
        if (!ENVIRONMENT.analyticsEnabled) {
            return;
        }

        const paramsToSend = {
            ...params,
            userId: this.userProfile?.user_id ?? null,
        };

        // console.log(eventName, paramsToSend);

        return logEvent(getAnalytics(), eventName, paramsToSend);
    }

    async logLogin(method = 'mobile_otp'): Promise<void> {
        if (!ENVIRONMENT.analyticsEnabled) {
            return;
        }
        
        return logEvent(getAnalytics(), 'login');
    }

    async logSignUp(method = 'mobile_otp'): Promise<void> {
        if (!ENVIRONMENT.analyticsEnabled) {
            return;
        }

        return logEvent(getAnalytics(), 'sign_up');
    }

    async logSelectContent(params: any): Promise<void> {
        if (!ENVIRONMENT.analyticsEnabled) {
            return;
        }

        return logEvent(getAnalytics(), 'select_content', {
            content_type: params.content_type,
            item_id: params.item_id,
        })
    }

    async logShare(params: any): Promise<void> {
        if (!ENVIRONMENT.analyticsEnabled) {
            return;
        }

        return logEvent(getAnalytics(), 'share');
    }

    async logSearch(searchTerm: string): Promise<void> {
        if (!ENVIRONMENT.analyticsEnabled) {
            return;
        }

        return logEvent(getAnalytics(), 'search', {
            search_term: searchTerm,
        });
    }

    async logViewSearchResults(searchTerm: string): Promise<void> {
        if (!ENVIRONMENT.analyticsEnabled) {
            return;
        }

        return logEvent(getAnalytics(), 'view_search_results', {
            search_term: searchTerm,
        });
    }

    // async setCurrentScreen(screenName: string, screenClass?: string): Promise<void> {
    //     if (!ENVIRONMENT.analyticsEnabled) {
    //         return;
    //     }

    //     return logEvent(getAnalytics(), 'screen_view', {
    //         screen_name: screenName,
    //         screen_class: screenClass || screenName,
    //     });
    // }
}
