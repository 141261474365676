import { StyleSheet } from 'react-native';
import { COLORS } from '../../../config/colors';
import { THEME_STYLES } from '../../../theme/constants/themeStyles';

export default StyleSheet.create({
  container: {
    backgroundColor: COLORS.whiteColor,
    borderWidth: 1,
    borderColor: COLORS.primaryLight,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    zIndex: 9,
    height: 40,
    width: 40
    // ...THEME_STYLES.buttonBorder
  },
  subView: {
    height: '100%',
    width: 40,
    position: 'absolute',
    // backgroundColor: COLORS.blackColor,
    bottom: -1,
    left: 0,
    right: 0,
    zIndex: 0,
    borderRadius: 8
  }
});
