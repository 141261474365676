import { CSFilterGroup } from "../central-atoms/filters/enums";
import { APP_STATE } from "../types";

export const INITIAL_STATE: APP_STATE = {
    main: {
        app: {
            appState: undefined,
            appInitialized: false,
            userAuthenticated: false,
            locPermissionEnabled: false,
            gpsEnabled: false,
            usersPhysicalLocation: {
                latitude: 0,
                longitude: 0,
            },
            mapCoords: {
                latitude: 0,
                longitude: 0,
            },
            allowedCPQrCodePatterns: [
                'pulseenergy.io/charge',
                'pulseenergy.io/instacharge',
                // 'instacharge.com',
                // 'localhost'
            ],
            showACChargersOnly: null,
            // remoteConfigValues: {},
            // evaluatedAppUpdate: {},
            shouldUpdateUserProfile: null,
            shouldFetchWalletBalance: null,
        },
        data: {},
    },
    doCharging: {
        app: {},
        data: {
            ongoingChargeTxns: {},
        },
    },
    filters: {
        app: {
            appliedFilters: {
                [CSFilterGroup.ConnectorTypes]: [],
                [CSFilterGroup.Brands]: [],
                [CSFilterGroup.Amenities]: [],
            },
            customFiltersApplied: false,
        },
        data: {
            csFilters: {
                filter_group: [],
                available_filters: {},
            }
        },
    },
    chargingStation: {
        // filterData: {
        //     connector_types: [],
        //     brands: [],
        // },
        // nearestChargingStation: null,
        // nearestCharger: null,
        // recentCharger: null,
        // selectedCharger: null,
        // selectedChargerPoint: null,
        // selectedStation: null

        nearestCharger: {},
        recentCharger: {},
        filterData: {
            connector_types: [],
            brands: [],
        },
        selectedCharger: {},
        selectedChargerPoint: {},
        selectedStation: {},

        app: {
            homeOtherChargingStationsPagination: {
                page: 1,
                hasNext: true,
            },
            trackedNearestChargingStation: null,
            lastChargeTransaction: null,
        },

        data: {
            masterUCSMap: {},
            mapsNearbyUCS: {},
            nearestUCSMiniObject: null,
            otherNearbyUCSMiniObjectsList: [],
        },
    },
    chargeTxns: {
        app: {
            chargeTxnsListPagination: {
                page: 0,
                hasNext: true,
            },
            chargeTxnsFilters: {
                wallet_ids: [],
            },
        },
        data: {
            chargeTxnsMap: {},
            chargeTxnsList: [],
        },
    },
    wallet: {
        app: {},
        data: {
            activePaymentMethodType: null,
            activeWallet: null,
            personalWallets: [],
            sharedWallets: [],
            fleetWallets: [],
            walletConsumers: {},
        },
    },
};
