import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const UserAvatarComponent = ({ size = 92, name, borderRadius = 100, textStyle, bgColor }) => {
  return (
    <View
      style={{
        height: size,
        width: size,
        borderRadius: borderRadius,
        backgroundColor: bgColor,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text style={[styles.text, textStyle]}>{name}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: 'Poppins-SemiBold',
    color: 'white',
  },
});

export default UserAvatarComponent;
