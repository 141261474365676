import { StyleSheet } from "react-native";
import { COLORS } from "../../../config/colors";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { verticalSize } from "../../../functions/responsive";

export default StyleSheet.create({
  root: {
    flexDirection: "column",
    backgroundColor: THEME_COLORS.bgColor,
    justifyContent: "space-between",
    position: "relative"
    // height: verticalSize(100)
  },
  successCont: {
    backgroundColor: COLORS.greenColor,
    borderRadius: 16,
    padding: 16,
    borderWidth: 1,
    borderBottomWidth: 3,
    borderColor: COLORS.primaryLight,
    marginVertical: 8,
    flexDirection: "row",
    alignItems: "center"
  },
  thumbCont: {
    backgroundColor: COLORS.lightBackgroundColor,
    borderRadius: 100,
    padding: 12,
    justifyContent: "center",
    alignItems: "center"
  },
  rowStyle: {
    flexDirection: "row",
    // alignItems: 'center',
    justifyContent: "space-between"
  },
  whiteBox: {
    backgroundColor: COLORS.dividerGrey,
    borderWidth: 1,
    borderBottomWidth: 3,
    borderColor: COLORS.primaryLight,
    borderRadius: 16,
    padding: 16
  },
  divider: {
    height: 2,
    backgroundColor: COLORS.dividerColor,
    marginTop: 12,
    marginBottom: 6,
    borderRadius: 8
  }
});
