import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
import { setGlobalVar } from '../miscellaneous/helpers';
import { FirebaseRemoteConfigKeys, GLOBAL_VARS } from '../constants';
import { ErrorHandlerService } from './ErrorHandlerService';
import { APP_ENV } from '../config/setting';

export default class FirebaseService {
  private remoteConfigPoller: any;

  initialize() {
    // Initialize Remote Config and get a reference to the service
    const firebaseRemoteConfig = getRemoteConfig();
    firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 60;
  }

  clearRemoteConfigPoller() {
    clearInterval(this.remoteConfigPoller);
  }

  static setDefaults(firebaseRemoteConfigValues: { [key: string]: any }): void {
    setGlobalVar(
      GLOBAL_VARS.bgTaskFunctionIntervalTime,
      firebaseRemoteConfigValues?.[FirebaseRemoteConfigKeys.location_reporting_interval] || 15000
    );
    setGlobalVar(
      GLOBAL_VARS.batteryLevelThreshold,
      parseFloat(firebaseRemoteConfigValues?.[FirebaseRemoteConfigKeys.battery_level_threshold]) || 0.2
    );
  }

  fetchAndApplyRemoteConfig(): Promise<void> {
    try {
      const firebaseRemoteConfig = getRemoteConfig();
      return fetchAndActivate(firebaseRemoteConfig).then((fetchedRemotely) => {
      });
    } catch (e: any) {
      ErrorHandlerService.handleError(e, {
        toastOptions: {
          type: 'error'
        }
      });
      return Promise.reject(e);
    }
  }

  setRemoteConfigPoller() {
    const timeout =
      APP_ENV === 'dev'
        ? // ? 1000 * 60 * 1 // 1 min
          1000 * 60 * 60 // 1 hour
        : 1000 * 60 * 5; // 5 mins

    this.fetchAndApplyRemoteConfig().finally(() => {
      this.clearRemoteConfigPoller();
      this.remoteConfigPoller = setTimeout(() => {
        this.setRemoteConfigPoller();
      }, timeout);
    });
  }
}
