import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import CText from "../../components/CText";
import ScreenHeader from "../ScreenHeader";
import { COLORS } from "../../../config/colors";
import { getGrammaticallyNumberizedText } from "../../../central-atoms/functions/utils";
import { StyleHelpers } from "../../../constants/style-helpers";

type Props = {
  onClearPress?: () => void;
  filterCount?: number;
  style?: ViewStyle;
};

const CSFiltersHeader: FC<Props> = (props: Props) => {
  const { filterCount = 0, style = {}, onClearPress = () => {} } = props;

  const navigation = useNavigation();
  const { t } = useTranslation();

  return (
    <ScreenHeader
      style={style}
      navigation={navigation}
      title={
        <View style={styles.header}>
          <CText
            semiBold
            size={14}
            style={{
              lineHeight: 24,
              fontWeight: "600",
            }}
          >
            Filters
          </CText>
          <CText
            size={10}
            style={{
              color: COLORS.grey700,
              lineHeight: 14,
            }}
          >
            {filterCount === 0
              ? "No filters selected"
              : `${filterCount} ${getGrammaticallyNumberizedText(
                  "filter",
                  "filters",
                  filterCount
                )} selected`}
          </CText>
        </View>
      }
      rightComponent={
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={() => {
            onClearPress();
          }}
          style={{
            borderBottomColor: COLORS.darkBlack,
            borderBottomWidth: 2,
            borderStyle: "dotted",
            borderRadius: 1,
          }}
        >
          <CText
            value={t("clearAll")}
            size={10}
            semiBold
            style={{ fontWeight: "600" }}
          />
        </TouchableOpacity>
      }
    />
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.whiteColor,
    height: 60,
    borderBottomColor: COLORS.grey600,
    borderBottomWidth: 1,
    ...StyleHelpers.horizontallyCenteredRow,
    ...StyleHelpers.verticallyCenteredRow,
  },
  iconButton: {
    alignSelf: "stretch",
  },
  header: {
    flex: 1,
    justifyContent: "center",
  },
});

export default CSFiltersHeader;
