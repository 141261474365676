import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { ActivityIndicator, RefreshControl, View } from "react-native";
import { batch, useDispatch } from "react-redux";
import CText from "../../components/CText";
import { COLORS } from "../../../config/colors";
import ScreenHeader from "../../../miscellaneous/components/ScreenHeader";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { ScreenComponent } from "../../../miscellaneous/types/components";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import ChargeTxnCard from "../../components/ChargeTxnCard";
import { RefreshControlLoaderColors } from "../../../constants";
import SectionMessage from "../../components/SectionMessage";
import {
  useChargeTxnsList,
  useChargeTxnsListPagination,
  useChargeTxnsMap,
} from "../../../hooks/chargeTxn";
import { ChargeTxnsRequestService } from "../../../services/ChargeTxnsRequestService";
import {
  setChargeTxnsListPagination,
  updateChargeTxnsList,
} from "../../../actions/ChargeTxn";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

type Props = ScreenComponent & {};
export default function ChargingHistoryScreen(props: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const chargeTxnsMap = useChargeTxnsMap();
  const chargeTxnsList = useChargeTxnsList();
  const chargeTxnsListPagination = useChargeTxnsListPagination();
  const [waitForChargingHistory, setWaitForChargingHistory] = useState(false);
  const [waitForRefresh, setWaitForRefresh] = useState(false);
  const filteredWalletIds = "";

  const renderItem = ({ item, index }: any) => {
    const chargeTxn = chargeTxnsMap[item.id];
    if (!chargeTxn) return null;
    return <ChargeTxnCard key={chargeTxn.id} chargeTxn={chargeTxn} />;
  };

  const onRefresh = () => {
    setWaitForRefresh(true);
    getChargingTxns(0).finally(() => {
      setWaitForRefresh(false);
    });
  };

  const getChargingTxns = (page?: number | string) => {
    setWaitForChargingHistory(true);
    return ChargeTxnsRequestService.fetchChargingHistory({
      wallet_id: filteredWalletIds || undefined,
      page: page ?? chargeTxnsListPagination.page,
      per_page: 10,
    })
      .then((response) => {
        batch(() => {
          dispatch(
            setChargeTxnsListPagination({
              page: chargeTxnsListPagination.page + 1,
              hasNext: response.data.has_next,
            })
          );
          dispatch(updateChargeTxnsList(response.data.records, "merge"));
        });

        setWaitForChargingHistory(false);
      })
      .catch((e) => {
        setWaitForChargingHistory(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      });
  };

  useEffect(() => {
    getChargingTxns(0);
  }, []);

  return (
    <View style={{ flex: 1, position: "relative" }}>
      <ScreenHeader
        navigation={navigation}
        style={{
          borderBottomWidth: 0,
          padding: 16,
          flexDirection: "row",
          backgroundColor: COLORS.whiteColor,
          position: "sticky" as any,
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
        }}
      />

      <View>
        <CText
          semiBold
          size={25}
          style={{
            ...StyleHelpers.pageSpacing,
            marginBottom: 10,
            marginTop: 10,
            fontWeight: "600",
          }}
        >
          {t("ChargingHistory")}
        </CText>
        <InfiniteScroll
          dataLength={chargeTxnsList?.length}
          next={() => {
            if (chargeTxnsListPagination.hasNext) {
              getChargingTxns();
            }
          }}
          scrollThreshold={1}
          hasMore={chargeTxnsListPagination.hasNext}
          endMessage={
            <View
              style={{
                ...StyleHelpers.totalCenter,
                height: 150,
              }}
            >
              <SectionMessage message={t("NoChargingSessionsFound")} />
            </View>
          }
          releaseToRefreshContent={
            <RefreshControl
              colors={RefreshControlLoaderColors}
              refreshing={waitForRefresh}
              onRefresh={onRefresh}
            />
          }
          loader={
            <View>
              <View
                style={{
                  ...StyleHelpers.totalCenter,
                  paddingVertical: 50,
                }}
              >
                <ActivityIndicator size={30} color={COLORS.blackColor} />
                <CText medium style={{ marginTop: 5 }}>
                  {t("Loading...")}
                </CText>
              </View>
            </View>
          }
        >
          {chargeTxnsList.map((item, index) => (
            <View key={index} style={{ marginLeft: 10, marginRight: 10 }}>
              {renderItem({ item })}
            </View>
          ))}
        </InfiniteScroll>
      </View>
    </View>
  );
}
