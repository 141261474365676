// commonly used Lottie animation in whole application

export const LottieAnimation = {
  check_animaiton: require('../assets/lottie/check_animation.json'),
  searching_animaiton: require('../assets/lottie/searching_animation.json'),
  searching_ripple: require('../assets/lottie/searching_ripple.json'),
  searching: require('../assets/lottie/searching.json'),
  connect: require('../assets/lottie/connect.json'),
  charging: require('../assets/lottie/charging.json'),
  charging_wait_time: require('../assets/lottie/charging_wait_time.json'),
  check: require('../assets/lottie/check.json'),
  only_check: require('../assets/lottie/only_check.json'),
  checking_wallet_balance: require('../assets/lottie/checking-wallet-balance.json'),
};
