import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActivityIndicator,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import styles from "./styles";
import { COLORS } from "../../../config/colors";
import { FaAngleLeft } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import CInput from "../CInput";
import { horizontalSize } from "../../../functions/responsive";

type Props = {
  value: string;
  onBackPress: () => void;
  onChangeText: (val: string) => void;
  onSubmitEditing: () => void;
  loading: boolean;
};
export default function SearchBar(props: Props) {
  const {
    value,
    onBackPress = () => {},
    onChangeText = () => {},
    onSubmitEditing = () => {},
    loading,
  } = props;
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <View
      style={[
        styles.container,
        { borderColor: isFocused ? COLORS.blackColor : COLORS.primaryLight },
      ]}
    >
      <View style={styles.subContainer}>
        <TouchableOpacity
          // activeOpacity={0.7}
          onPress={onBackPress}
          style={styles.touch}
        >
          <FaAngleLeft size={18} color={COLORS.whiteColor} />
        </TouchableOpacity>
        <CInput
          // @ts-ignore
          placeholder={t("FindByAreaNameOrType")}
          value={value}
          onChangeText={onChangeText}
          placeholderTextColor={COLORS.placeholderColor}
          style={{
            borderWidth: 0,
            height: 40,
            width: horizontalSize(55),
            fontWeight: "400",
          }}
          autoFocus={false}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          returnKeyType="search"
          onSubmitEditing={() => {
            onSubmitEditing();
          }}
        />
      </View>
      {loading ? (
        <View style={{ width: 30 }}>
          <ActivityIndicator size={20} color={COLORS.darkBlack} />
        </View>
      ) : value && value.length > 0 ? (
        <TouchableOpacity
          onPress={() => onChangeText("")}
          style={{ width: 20 }}
        >
          <IoMdClose size={18} color={COLORS.primaryGrey} />
        </TouchableOpacity>
      ) : null}
    </View>
  );
}
