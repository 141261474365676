import { View, ViewStyle } from "react-native";
import { StyleHelpers } from "../constants/style-helpers";
import { COLORS } from "../../config/colors";

type Props = {
  count: number | string;
  style?: ViewStyle;
};
export default function CountBadge(props: Props) {
  return (
    <View
      style={{
        position: "absolute",
        backgroundColor: COLORS.primaryColor,
        height: 8,
        width: 8,
        right: -3,
        top: -2,
        borderRadius: 8,
        borderColor: COLORS.whiteColor,
        zIndex: 9,
        ...StyleHelpers.totalCenter,
      }}
    ></View>
  );
}
