import { StyleSheet } from "react-native";
import { COLORS } from "../../../../../config/colors";
import { StyleHelpers } from "../../../../../miscellaneous/constants/style-helpers";
import { THEME_COLORS } from "../../../../../theme/constants/themeColors";
import { verticalSize } from "../../../../../functions/responsive";

export default StyleSheet.create({
  root: {
    height: "100vh" as any,
    // height: "100%",
    position: "relative",
    flexDirection: "column",
    backgroundColor: THEME_COLORS.bgColor,
    justifyContent: "space-between"
  },
  mainCont: {
    justifyContent: "flex-end",
    flex: 1
  },
  rowStyle: {
    flexDirection: "row",
    alignItems: "center"
  },
  modalWhiteCont: {
    backgroundColor: COLORS.whiteColor,
    borderTopEndRadius: 16,
    borderTopStartRadius: 16,
    padding: 16,
    paddingTop: 24
  },
  modalCont: {
    // flex: 1,
  },
  amountCont: {
    backgroundColor: COLORS.whiteColor,
    // borderRadius: 12,
    // borderWidth: 2,
    ...StyleHelpers.themeBorder,
    borderRadius: 12,
    borderColor: COLORS.blackColor,
    alignSelf: "flex-start",
    padding: 10,
    marginTop: 16,
    paddingVertical: 6,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between"
  }
});
