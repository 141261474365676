import {
  CancelChargingResponse,
  EstimatedChargingSummaryResponse
} from '../central-atoms/charging/charging-flow/types/responses';
import {
  ChargingAllowedAppPostReqPayload,
  EstimatedChargingSummaryRequest,
  PostCancelChargingReq
} from '../central-atoms/types/app-requests';
import HttpRequestService from '../config/HttpRequestService';
import { CENTRAL_HOST } from '../config/setting';
import { getGlobalVar } from '../miscellaneous/helpers';
import { GLOBAL_VARS } from '../constants';

export class ChargingRequestService {
  static async fetchEstimateChargingSummary(
    payload: EstimatedChargingSummaryRequest
  ): Promise<EstimatedChargingSummaryResponse> {
    const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
    const response = await centralHttpRequest.getInstance().post(`${CENTRAL_HOST}/estimated_charging_summary`, payload);
    return response.data;
  }

  static async isChargingAllowed(payload: ChargingAllowedAppPostReqPayload): Promise<any> {
    const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
    const response = await centralHttpRequest.getInstance().put(`${CENTRAL_HOST}/is_charging_allowed`, payload);
    return response.data;
  }

  static async cancelCharging(payload: PostCancelChargingReq): Promise<CancelChargingResponse> {
    const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
    const response = await centralHttpRequest.getInstance().post(`${CENTRAL_HOST}/cancel_charging`, payload);
    return response.data;
  }
}
