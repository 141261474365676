import { uniqueId } from "lodash";
import { INITIAL_STATE } from "../../store/defaultStore";
import { Action } from "../../miscellaneous/types";
import { Filter, SET_APPLIED_FILTERS, SET_APPLIED_FILTERS_CHANGE_VERSION, SET_AVAILABLE_CS_FILTERS, SET_CONNECTOR_TYPES_LIST, SET_CUSTOM_FILTERS_APPLIED } from "../../types";

export function FiltersReducer(
    state = INITIAL_STATE.filters,
    action: Action,
) {
    const { type, payload } = action;
    
    switch (type) {
        case SET_AVAILABLE_CS_FILTERS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    csFilters: payload.filters,
                },
            };
        }

        case SET_APPLIED_FILTERS: {
            return {
                ...state,
                app: {
                    ...state.app,
                    appliedFilters: payload.appliedFilters,
                },
            };
        }

        case SET_CUSTOM_FILTERS_APPLIED: {
            return {
                ...state,
                app: {
                    ...state.app,
                    customFiltersApplied: payload.customFiltersApplied,
                },
            };
        }

        case SET_APPLIED_FILTERS_CHANGE_VERSION: {
            return {
                ...state,
                app: {
                    ...state.app,
                    // appliedFiltersChangeVersion: payload.appliedFiltersChangeVersion,
                    appliedFiltersChangeVersion: uniqueId(),
                },
            };
        }

        case SET_CONNECTOR_TYPES_LIST: {
            const filterSubgroups: Array<{
                filters: Filter[],
                name: string,
            }> = payload.filterSubgroups;

            const connectorTypesList = new Set();
            filterSubgroups.forEach((filterSubgroup) => {
                connectorTypesList.add(filterSubgroup.filters.map((f) => f.value));
            });

            return {
                ...state,
                app: {
                    ...state.app,
                    connectorTypesList: Array.from(connectorTypesList),
                },
            };
        }
    }

    return state;
}