import { UserPreferences } from "../central-atoms/users/types";
import actions from "../config/actions";
import { UPDATE_USER_PREFERENCES } from "../types";
import { UpdateUserPreferencesDispatchedAction } from "../types/actionsDispatch";

export const setAuthToken = (authToken: string | null) => {
    return {
        type: actions.SET_TOKEN,
        token: authToken,
    };
}

export const updateUserPreferences = (userPreferences: UserPreferences): UpdateUserPreferencesDispatchedAction => {
    return {
        type: UPDATE_USER_PREFERENCES,
        payload: {
            userPreferences,
        },
    };
}