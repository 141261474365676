import { useEffect, useState } from "react";
import { ActivityIndicator, View } from "react-native";
import { useSelector } from "react-redux";
import { ChargingStationDetailScreenRouteParams } from "../../../types";
import { COLORS } from "../../../config/colors";
import { API_BASE_PATH } from "../../../config/setting";
import ScreenHeader from "../../../miscellaneous/components/ScreenHeader";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { getGlobalVar } from "../../../miscellaneous/helpers";
import { ScreenComponent } from "../../../miscellaneous/types/components";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import { GLOBAL_VARS, SCREEN_NAMES } from "../../../constants";
import { APP_STATE } from "../../../types";
import styles from "./styles";
import ChargingStationCard from "../ChargingStationCard";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import CText from "../CText";
import SectionMessage from "../SectionMessage";

type Props = ScreenComponent & {};
export default function SavedChargingStationsScreen(props: Props) {
  const { navigation } = props;
  const { t } = useTranslation();
  const { token } = useSelector((state: APP_STATE) => state.auth);
  const [favoriteList, setFavoriteList] = useState<any[]>([]);
  const [nextPage, setNextPage] = useState(false);
  const [hasNext, setHasNext] = useState(false);

  useEffect(() => {
    getFavoriteList();
  }, []);

  const getFavoriteList = () => {
    const url = hasNext
      ? `${API_BASE_PATH}/charging_station/saved?page=${nextPage}`
      : `${API_BASE_PATH}/charging_station/saved`;

    const httpRequest = getGlobalVar(GLOBAL_VARS.httpRequest);
    httpRequest.setInstance(token);
    httpRequest
      .getInstance()
      .get(url)
      .then((result: any) => {
        const response = result.data;
        setFavoriteList((p: any[]) => {
          const existingSavedCSMap: any = {};
          p.forEach((item) => {
            existingSavedCSMap[item.id] = item;
          });
          response?.data?.forEach((savedCS: any) => {
            existingSavedCSMap[savedCS.id] = savedCS;
          });
          return Object.values(existingSavedCSMap);
        });
        setNextPage(response?.next_page);
        setHasNext(response?.has_next);
      })
      .catch((e: any) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      });
  };

  const onSaveStation = (csID: string) => {
    const data = {
      charging_station_id: csID,
    };

    const httpRequest = getGlobalVar(GLOBAL_VARS.httpRequest);
    httpRequest
      .getInstance()
      .post(`/api/charging_station/favorite/${csID}`, data)
      .then((result: any) => {
        getFavoriteList();
      })
      .catch((e: any) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      });
  };

  const onUnsaveStation = (csID: string) => {
    const data = {
      charging_station_id: csID,
    };

    const httpRequest = getGlobalVar(GLOBAL_VARS.httpRequest);
    httpRequest
      .getInstance()
      .delete(`/api/charging_station/favorite/${csID}`, { data })
      .then((result: any) => {
        getFavoriteList();
      })
      .catch((e: any) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      });
  };

  const renderFavorites = ({ item, index }: any) => {
    return (
      <View key={index} style={{ marginVertical: 8 }}>
        <ChargingStationCard
          source={"savedcs_screen"}
          mixedChargingStation={item}
          showPrimaryActionButton={false}
          showConnectors={false}
          showPower={false}
          showPrice={false}
          coords={{
            latitude: item?.charging_station?.latitude,
            longitude: item?.charging_station?.longitude,
          }}
          cardType={"saved"}
          onViewChargerPress={() => {
            navigation.navigate(SCREEN_NAMES.ChargingStationDetailScreen, {
              chargerId: item?.charging_station?.id,
              chargerIdType: "charging-station",
              isDeepLinked: false,
            } as ChargingStationDetailScreenRouteParams);
          }}
          onStartChargingPress={() => {
            navigation.navigate(SCREEN_NAMES.ChargePointQRScannerScreen);
          }}
          onSavePress={() => {
            if (item?.favorited) onUnsaveStation(item?.charging_station?.id);
            else onSaveStation(item?.charging_station?.id);
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.root}>
      <ScreenHeader
        navigation={navigation}
        style={{
          padding: 16,
          flexDirection: "row",
          backgroundColor: COLORS.whiteColor,
          position: "sticky" as any,
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
        }}
      />
      <InfiniteScroll
        dataLength={favoriteList?.length}
        next={() => {
          if (hasNext) {
            getFavoriteList();
          }
        }}
        scrollThreshold={1}
        hasMore={hasNext}
        endMessage={
          <View
            style={{
              ...StyleHelpers.totalCenter,
              height: 150,
            }}
          >
            <SectionMessage message={t("NoSavedCS")} />
          </View>
        }
        loader={
          <View>
            <View
              style={{
                ...StyleHelpers.totalCenter,
                paddingVertical: 50,
              }}
            >
              <ActivityIndicator size={30} color={COLORS.blackColor} />
              <CText medium style={{ marginTop: 5 }}>
                {t("Loading...")}
              </CText>
            </View>
          </View>
        }
      >
        {favoriteList.map((item, index) => (
          <View key={index} style={{ marginLeft: 10, marginRight: 10 }}>
            {renderFavorites({ item })}
          </View>
        ))}
      </InfiniteScroll>
    </View>
  );
}
