import { View, ViewStyle } from "react-native";
import { StyleHelpers } from "../../../constants/style-helpers";

type Props = {
  children: React.ReactNode;
  style?: ViewStyle;
  id?: string;
};
const ScreenBottomBar = (props: Props) => {
  const { children, style = {}, id } = props;

  return (
    <View
      id={id}
      style={[
        StyleHelpers.actionBar,
        {
          paddingBottom: StyleHelpers.pageSpacing.paddingLeft,
          ...style
        }
      ]}>
      {children}
    </View>
  );
};

export default ScreenBottomBar;
